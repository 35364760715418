import {SimpleDynamicObjectArray} from "./types/wrapperTypes";
import {Button, Card, Form, Space} from "antd";
import {CloseOutlined} from '@ant-design/icons';
import _SpecificControl from "./_SpecificControl";
import {Path} from "./types/baseTypes";
import {calculateWrapperInitialValues, computeWrapperLabel, computeWrapperTitle} from "./util";

const _SimpleDynamicObjectArray = (
  {
    control,
    parentPath = [],
    disabled = false,
  } : {
    control: SimpleDynamicObjectArray,
    parentPath?: Path,
    disabled?: boolean,
  }
) => {

  const content = <>
    <Form.Item
      label={computeWrapperLabel(control, control.label)}
      labelCol={{span: 24}}
    >
      {computeWrapperTitle(control.label)}
      <Form.List
        name={[...parentPath, ...control.path]}
      >
        {(subFields, subOpt) => (
          <Space
            direction="vertical"
          >
            {subFields.map(subField => (
              <Space
                key={subField.key}
                // align="end"
                direction="horizontal"
              >
                {control.elements.map((elem, index) => (
                  <_SpecificControl
                    key={index}
                    control={elem}
                    parentPath={[subField.name]}
                  />
                ))}
                <CloseOutlined
                  style={{marginTop: 13}}
                  onClick={() => !disabled && subOpt.remove(subField.name)}
                />
              </Space>
            ))}
            <Button
              type="dashed"
              disabled={disabled}
              onClick={() => subOpt.add(calculateWrapperInitialValues(control))}
              block
            >
              + Add Sub Item
            </Button>
          </Space>
        )}
      </Form.List>
    </Form.Item>
  </>

  if(!control.path) {
    return (
      <>Simple Dynamic object array has incorrect path {control.path}</>
    );
  }

  if(control.path.length < 1) {
    return (
      <>Simple Dynamic object array path must have at least one path element</>
    )
  }

  if(typeof control.path[control.path.length - 1] !== "string") {
    return (
      <>Last path element of simple dynamic object array must be a string value</>
    );
  }

  return (
    <>
      {!control.noWrapper ? (
        <Card
          type="inner"
          title={control.label?.text}
        >
          <Space direction="vertical">
            {content}
          </Space>
        </Card>
      ) : (
        content
      )}
    </>
  );
}

export default _SimpleDynamicObjectArray;