/* tslint:disable */

/* eslint-disable */

/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { AxiosInstance, AxiosPromise, RawAxiosRequestConfig } from "axios";
import globalAxios from "axios";

// @ts-ignore
import {
  BASE_PATH,
  BaseAPI,
  COLLECTION_FORMATS,
  RequestArgs,
  RequiredError,
  operationServerMap,
} from "../base";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  createRequestFunction,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  toPathString,
} from "../common";
import type { Configuration } from "../configuration";
// @ts-ignore
import { AvatarServiceResponseDto } from "../models";
// @ts-ignore
import { PageResponseSysCfgRegularDto } from "../models";
// @ts-ignore
import { QueryDto } from "../models";
// @ts-ignore
import { QueryFiltersDto } from "../models";
// @ts-ignore
import { ResultDtoLong } from "../models";
// @ts-ignore
import { ResultDtoSysCfgRegularDto } from "../models";
// @ts-ignore
import { SysCfgAddProxiesDto } from "../models";
// @ts-ignore
import { SysCfgApplyAuthGroupsRequestDto } from "../models";
// @ts-ignore
import { SysCfgCreateRequestDto } from "../models";
// @ts-ignore
import { SysCfgGetMultipleRequestDto } from "../models";
// @ts-ignore
import { SysCfgListRawDto } from "../models";
// @ts-ignore
import { SysCfgPatchDataRequestDto } from "../models";
// @ts-ignore
import { SysCfgRawDto } from "../models";
// @ts-ignore
import { SysCfgRegularDto } from "../models";
// @ts-ignore
import { SysCfgUpdateRequestDto } from "../models";

/**
 * SysCfgControllerApi - axios parameter creator
 * @export
 */
export const SysCfgControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} domain
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addAvatars: async (
      domain: string,
      file: File,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("addAvatars", "domain", domain);
      // verify required parameter 'file' is not null or undefined
      assertParamExists("addAvatars", "file", file);
      const localVarPath = `/api/v2/sys-cfg/{domain}/add-avatars-configs`.replace(
        `{${"domain"}}`,
        encodeURIComponent(String(domain))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) ||
        FormData)();

      if (file !== undefined) {
        localVarFormParams.append("file", file as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} domain
     * @param {Set<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addNicknames: async (
      domain: string,
      requestBody: Set<string>,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("addNicknames", "domain", domain);
      // verify required parameter 'requestBody' is not null or undefined
      assertParamExists("addNicknames", "requestBody", requestBody);
      const localVarPath = `/api/v2/sys-cfg/{domain}/add-name-configs`.replace(
        `{${"domain"}}`,
        encodeURIComponent(String(domain))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} roomName
     * @param {SysCfgAddProxiesDto} sysCfgAddProxiesDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProxies: async (
      roomName: string,
      sysCfgAddProxiesDto: SysCfgAddProxiesDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'roomName' is not null or undefined
      assertParamExists("addProxies", "roomName", roomName);
      // verify required parameter 'sysCfgAddProxiesDto' is not null or undefined
      assertParamExists("addProxies", "sysCfgAddProxiesDto", sysCfgAddProxiesDto);
      const localVarPath = `/api/v2/sys-cfg/{room-name}/proxy/add`.replace(
        `{${"room-name"}}`,
        encodeURIComponent(String(roomName))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sysCfgAddProxiesDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} domain
     * @param {string} cfgName
     * @param {SysCfgApplyAuthGroupsRequestDto} sysCfgApplyAuthGroupsRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyAuthGroups: async (
      domain: string,
      cfgName: string,
      sysCfgApplyAuthGroupsRequestDto: SysCfgApplyAuthGroupsRequestDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("applyAuthGroups", "domain", domain);
      // verify required parameter 'cfgName' is not null or undefined
      assertParamExists("applyAuthGroups", "cfgName", cfgName);
      // verify required parameter 'sysCfgApplyAuthGroupsRequestDto' is not null or undefined
      assertParamExists(
        "applyAuthGroups",
        "sysCfgApplyAuthGroupsRequestDto",
        sysCfgApplyAuthGroupsRequestDto
      );
      const localVarPath = `/api/v2/sys-cfg/{domain}/{cfg-name}/apply-auth-groups`
        .replace(`{${"domain"}}`, encodeURIComponent(String(domain)))
        .replace(`{${"cfg-name"}}`, encodeURIComponent(String(cfgName)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sysCfgApplyAuthGroupsRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} domain
     * @param {string} cfgName
     * @param {boolean} enabled
     * @param {QueryFiltersDto} queryFiltersDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeCfgEnabled: async (
      domain: string,
      cfgName: string,
      enabled: boolean,
      queryFiltersDto: QueryFiltersDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("changeCfgEnabled", "domain", domain);
      // verify required parameter 'cfgName' is not null or undefined
      assertParamExists("changeCfgEnabled", "cfgName", cfgName);
      // verify required parameter 'enabled' is not null or undefined
      assertParamExists("changeCfgEnabled", "enabled", enabled);
      // verify required parameter 'queryFiltersDto' is not null or undefined
      assertParamExists("changeCfgEnabled", "queryFiltersDto", queryFiltersDto);
      const localVarPath = `/api/v2/sys-cfg/{domain}/{cfg-name}/change-enabled/{enabled}`
        .replace(`{${"domain"}}`, encodeURIComponent(String(domain)))
        .replace(`{${"cfg-name"}}`, encodeURIComponent(String(cfgName)))
        .replace(`{${"enabled"}}`, encodeURIComponent(String(enabled)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        queryFiltersDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} domain
     * @param {string} cfgName
     * @param {string} groupingBy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countCfgsGroupingBy: async (
      domain: string,
      cfgName: string,
      groupingBy: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("countCfgsGroupingBy", "domain", domain);
      // verify required parameter 'cfgName' is not null or undefined
      assertParamExists("countCfgsGroupingBy", "cfgName", cfgName);
      // verify required parameter 'groupingBy' is not null or undefined
      assertParamExists("countCfgsGroupingBy", "groupingBy", groupingBy);
      const localVarPath = `/api/v2/sys-cfg/{domain}/{cfg-name}/count-grouping-by`
        .replace(`{${"domain"}}`, encodeURIComponent(String(domain)))
        .replace(`{${"cfg-name"}}`, encodeURIComponent(String(cfgName)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (groupingBy !== undefined) {
        localVarQueryParameter["grouping-by"] = groupingBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} domain
     * @param {string} cfgName
     * @param {SysCfgCreateRequestDto} sysCfgCreateRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCfg: async (
      domain: string,
      cfgName: string,
      sysCfgCreateRequestDto: SysCfgCreateRequestDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("createCfg", "domain", domain);
      // verify required parameter 'cfgName' is not null or undefined
      assertParamExists("createCfg", "cfgName", cfgName);
      // verify required parameter 'sysCfgCreateRequestDto' is not null or undefined
      assertParamExists("createCfg", "sysCfgCreateRequestDto", sysCfgCreateRequestDto);
      const localVarPath = `/api/v2/sys-cfg/{domain}/{cfg-name}`
        .replace(`{${"domain"}}`, encodeURIComponent(String(domain)))
        .replace(`{${"cfg-name"}}`, encodeURIComponent(String(cfgName)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sysCfgCreateRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} domain
     * @param {string} cfgName
     * @param {Array<SysCfgCreateRequestDto>} sysCfgCreateRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCfgs: async (
      domain: string,
      cfgName: string,
      sysCfgCreateRequestDto: Array<SysCfgCreateRequestDto>,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("createCfgs", "domain", domain);
      // verify required parameter 'cfgName' is not null or undefined
      assertParamExists("createCfgs", "cfgName", cfgName);
      // verify required parameter 'sysCfgCreateRequestDto' is not null or undefined
      assertParamExists("createCfgs", "sysCfgCreateRequestDto", sysCfgCreateRequestDto);
      const localVarPath = `/api/v2/sys-cfg/{domain}/{cfg-name}/multi`
        .replace(`{${"domain"}}`, encodeURIComponent(String(domain)))
        .replace(`{${"cfg-name"}}`, encodeURIComponent(String(cfgName)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sysCfgCreateRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} domain
     * @param {string} cfgName
     * @param {QueryFiltersDto} queryFiltersDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCfg: async (
      domain: string,
      cfgName: string,
      queryFiltersDto: QueryFiltersDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("deleteCfg", "domain", domain);
      // verify required parameter 'cfgName' is not null or undefined
      assertParamExists("deleteCfg", "cfgName", cfgName);
      // verify required parameter 'queryFiltersDto' is not null or undefined
      assertParamExists("deleteCfg", "queryFiltersDto", queryFiltersDto);
      const localVarPath = `/api/v2/sys-cfg/{domain}/{cfg-name}`
        .replace(`{${"domain"}}`, encodeURIComponent(String(domain)))
        .replace(`{${"cfg-name"}}`, encodeURIComponent(String(cfgName)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        queryFiltersDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} domain
     * @param {string} name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadAvatar: async (
      domain: string,
      name: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("downloadAvatar", "domain", domain);
      // verify required parameter 'name' is not null or undefined
      assertParamExists("downloadAvatar", "name", name);
      const localVarPath = `/api/v2/sys-cfg/{domain}/avatar/download/{name}`
        .replace(`{${"domain"}}`, encodeURIComponent(String(domain)))
        .replace(`{${"name"}}`, encodeURIComponent(String(name)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} domain
     * @param {string} cfgName
     * @param {QueryDto} queryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCfgs: async (
      domain: string,
      cfgName: string,
      queryDto: QueryDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("getAllCfgs", "domain", domain);
      // verify required parameter 'cfgName' is not null or undefined
      assertParamExists("getAllCfgs", "cfgName", cfgName);
      // verify required parameter 'queryDto' is not null or undefined
      assertParamExists("getAllCfgs", "queryDto", queryDto);
      const localVarPath = `/api/v2/sys-cfg/{domain}/{cfg-name}/find-all`
        .replace(`{${"domain"}}`, encodeURIComponent(String(domain)))
        .replace(`{${"cfg-name"}}`, encodeURIComponent(String(cfgName)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        queryDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} domain
     * @param {SysCfgGetMultipleRequestDto} sysCfgGetMultipleRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCfgsRawCombined: async (
      domain: string,
      sysCfgGetMultipleRequestDto: SysCfgGetMultipleRequestDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("getAllCfgsRawCombined", "domain", domain);
      // verify required parameter 'sysCfgGetMultipleRequestDto' is not null or undefined
      assertParamExists(
        "getAllCfgsRawCombined",
        "sysCfgGetMultipleRequestDto",
        sysCfgGetMultipleRequestDto
      );
      const localVarPath = `/api/v2/sys-cfg/{domain}/find-all/combined/raw`.replace(
        `{${"domain"}}`,
        encodeURIComponent(String(domain))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sysCfgGetMultipleRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} cfgName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCfgsRawMultiRoom: async (
      cfgName: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'cfgName' is not null or undefined
      assertParamExists("getAllCfgsRawMultiRoom", "cfgName", cfgName);
      const localVarPath = `/api/v2/sys-cfg/multi-room/{cfg-name}/find-all/raw`.replace(
        `{${"cfg-name"}}`,
        encodeURIComponent(String(cfgName))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} domain
     * @param {string} cfgName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCfgsRow: async (
      domain: string,
      cfgName: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("getAllCfgsRow", "domain", domain);
      // verify required parameter 'cfgName' is not null or undefined
      assertParamExists("getAllCfgsRow", "cfgName", cfgName);
      const localVarPath = `/api/v2/sys-cfg/{domain}/{cfg-name}/find-all/raw`
        .replace(`{${"domain"}}`, encodeURIComponent(String(domain)))
        .replace(`{${"cfg-name"}}`, encodeURIComponent(String(cfgName)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} domain
     * @param {string} cfgName
     * @param {SysCfgPatchDataRequestDto} sysCfgPatchDataRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchCfgData: async (
      domain: string,
      cfgName: string,
      sysCfgPatchDataRequestDto: SysCfgPatchDataRequestDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("patchCfgData", "domain", domain);
      // verify required parameter 'cfgName' is not null or undefined
      assertParamExists("patchCfgData", "cfgName", cfgName);
      // verify required parameter 'sysCfgPatchDataRequestDto' is not null or undefined
      assertParamExists(
        "patchCfgData",
        "sysCfgPatchDataRequestDto",
        sysCfgPatchDataRequestDto
      );
      const localVarPath = `/api/v2/sys-cfg/{domain}/{cfg-name}/patch-cfg-data`
        .replace(`{${"domain"}}`, encodeURIComponent(String(domain)))
        .replace(`{${"cfg-name"}}`, encodeURIComponent(String(cfgName)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sysCfgPatchDataRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} domain
     * @param {string} cfgName
     * @param {string} name
     * @param {SysCfgUpdateRequestDto} sysCfgUpdateRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateConfig: async (
      domain: string,
      cfgName: string,
      name: string,
      sysCfgUpdateRequestDto: SysCfgUpdateRequestDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("updateConfig", "domain", domain);
      // verify required parameter 'cfgName' is not null or undefined
      assertParamExists("updateConfig", "cfgName", cfgName);
      // verify required parameter 'name' is not null or undefined
      assertParamExists("updateConfig", "name", name);
      // verify required parameter 'sysCfgUpdateRequestDto' is not null or undefined
      assertParamExists("updateConfig", "sysCfgUpdateRequestDto", sysCfgUpdateRequestDto);
      const localVarPath = `/api/v2/sys-cfg/{domain}/{cfg-name}`
        .replace(`{${"domain"}}`, encodeURIComponent(String(domain)))
        .replace(`{${"cfg-name"}}`, encodeURIComponent(String(cfgName)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (name !== undefined) {
        localVarQueryParameter["name"] = name;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sysCfgUpdateRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} domain
     * @param {Set<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadUsernames: async (
      domain: string,
      requestBody: Set<string>,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("uploadUsernames", "domain", domain);
      // verify required parameter 'requestBody' is not null or undefined
      assertParamExists("uploadUsernames", "requestBody", requestBody);
      const localVarPath = `/api/v2/sys-cfg/{domain}/upload-usernames`.replace(
        `{${"domain"}}`,
        encodeURIComponent(String(domain))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SysCfgControllerApi - functional programming interface
 * @export
 */
export const SysCfgControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SysCfgControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} domain
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addAvatars(
      domain: string,
      file: File,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvatarServiceResponseDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addAvatars(
        domain,
        file,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysCfgControllerApi.addAvatars"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} domain
     * @param {Set<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addNicknames(
      domain: string,
      requestBody: Set<string>,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvatarServiceResponseDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addNicknames(
        domain,
        requestBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysCfgControllerApi.addNicknames"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} roomName
     * @param {SysCfgAddProxiesDto} sysCfgAddProxiesDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addProxies(
      roomName: string,
      sysCfgAddProxiesDto: SysCfgAddProxiesDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<ResultDtoSysCfgRegularDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addProxies(
        roomName,
        sysCfgAddProxiesDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysCfgControllerApi.addProxies"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} domain
     * @param {string} cfgName
     * @param {SysCfgApplyAuthGroupsRequestDto} sysCfgApplyAuthGroupsRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applyAuthGroups(
      domain: string,
      cfgName: string,
      sysCfgApplyAuthGroupsRequestDto: SysCfgApplyAuthGroupsRequestDto,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.applyAuthGroups(
        domain,
        cfgName,
        sysCfgApplyAuthGroupsRequestDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysCfgControllerApi.applyAuthGroups"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} domain
     * @param {string} cfgName
     * @param {boolean} enabled
     * @param {QueryFiltersDto} queryFiltersDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changeCfgEnabled(
      domain: string,
      cfgName: string,
      enabled: boolean,
      queryFiltersDto: QueryFiltersDto,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.changeCfgEnabled(
        domain,
        cfgName,
        enabled,
        queryFiltersDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysCfgControllerApi.changeCfgEnabled"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} domain
     * @param {string} cfgName
     * @param {string} groupingBy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countCfgsGroupingBy(
      domain: string,
      cfgName: string,
      groupingBy: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number }>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countCfgsGroupingBy(
        domain,
        cfgName,
        groupingBy,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysCfgControllerApi.countCfgsGroupingBy"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} domain
     * @param {string} cfgName
     * @param {SysCfgCreateRequestDto} sysCfgCreateRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCfg(
      domain: string,
      cfgName: string,
      sysCfgCreateRequestDto: SysCfgCreateRequestDto,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SysCfgRegularDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createCfg(
        domain,
        cfgName,
        sysCfgCreateRequestDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysCfgControllerApi.createCfg"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} domain
     * @param {string} cfgName
     * @param {Array<SysCfgCreateRequestDto>} sysCfgCreateRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCfgs(
      domain: string,
      cfgName: string,
      sysCfgCreateRequestDto: Array<SysCfgCreateRequestDto>,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SysCfgRegularDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createCfgs(
        domain,
        cfgName,
        sysCfgCreateRequestDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysCfgControllerApi.createCfgs"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} domain
     * @param {string} cfgName
     * @param {QueryFiltersDto} queryFiltersDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteCfg(
      domain: string,
      cfgName: string,
      queryFiltersDto: QueryFiltersDto,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultDtoLong>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCfg(
        domain,
        cfgName,
        queryFiltersDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysCfgControllerApi.deleteCfg"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} domain
     * @param {string} name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async downloadAvatar(
      domain: string,
      name: string,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.downloadAvatar(
        domain,
        name,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysCfgControllerApi.downloadAvatar"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} domain
     * @param {string} cfgName
     * @param {QueryDto} queryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllCfgs(
      domain: string,
      cfgName: string,
      queryDto: QueryDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseSysCfgRegularDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCfgs(
        domain,
        cfgName,
        queryDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysCfgControllerApi.getAllCfgs"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} domain
     * @param {SysCfgGetMultipleRequestDto} sysCfgGetMultipleRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllCfgsRawCombined(
      domain: string,
      sysCfgGetMultipleRequestDto: SysCfgGetMultipleRequestDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SysCfgListRawDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCfgsRawCombined(
        domain,
        sysCfgGetMultipleRequestDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysCfgControllerApi.getAllCfgsRawCombined"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} cfgName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllCfgsRawMultiRoom(
      cfgName: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SysCfgRawDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCfgsRawMultiRoom(
        cfgName,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysCfgControllerApi.getAllCfgsRawMultiRoom"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} domain
     * @param {string} cfgName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllCfgsRow(
      domain: string,
      cfgName: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SysCfgRawDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCfgsRow(
        domain,
        cfgName,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysCfgControllerApi.getAllCfgsRow"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} domain
     * @param {string} cfgName
     * @param {SysCfgPatchDataRequestDto} sysCfgPatchDataRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchCfgData(
      domain: string,
      cfgName: string,
      sysCfgPatchDataRequestDto: SysCfgPatchDataRequestDto,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patchCfgData(
        domain,
        cfgName,
        sysCfgPatchDataRequestDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysCfgControllerApi.patchCfgData"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} domain
     * @param {string} cfgName
     * @param {string} name
     * @param {SysCfgUpdateRequestDto} sysCfgUpdateRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateConfig(
      domain: string,
      cfgName: string,
      name: string,
      sysCfgUpdateRequestDto: SysCfgUpdateRequestDto,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SysCfgRegularDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateConfig(
        domain,
        cfgName,
        name,
        sysCfgUpdateRequestDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysCfgControllerApi.updateConfig"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} domain
     * @param {Set<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadUsernames(
      domain: string,
      requestBody: Set<string>,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvatarServiceResponseDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadUsernames(
        domain,
        requestBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysCfgControllerApi.uploadUsernames"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * SysCfgControllerApi - factory interface
 * @export
 */
export const SysCfgControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SysCfgControllerApiFp(configuration);
  return {
    /**
     *
     * @param {string} domain
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addAvatars(
      domain: string,
      file: File,
      options?: any
    ): AxiosPromise<AvatarServiceResponseDto> {
      return localVarFp
        .addAvatars(domain, file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} domain
     * @param {Set<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addNicknames(
      domain: string,
      requestBody: Set<string>,
      options?: any
    ): AxiosPromise<AvatarServiceResponseDto> {
      return localVarFp
        .addNicknames(domain, requestBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} roomName
     * @param {SysCfgAddProxiesDto} sysCfgAddProxiesDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProxies(
      roomName: string,
      sysCfgAddProxiesDto: SysCfgAddProxiesDto,
      options?: any
    ): AxiosPromise<Array<ResultDtoSysCfgRegularDto>> {
      return localVarFp
        .addProxies(roomName, sysCfgAddProxiesDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} domain
     * @param {string} cfgName
     * @param {SysCfgApplyAuthGroupsRequestDto} sysCfgApplyAuthGroupsRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyAuthGroups(
      domain: string,
      cfgName: string,
      sysCfgApplyAuthGroupsRequestDto: SysCfgApplyAuthGroupsRequestDto,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .applyAuthGroups(domain, cfgName, sysCfgApplyAuthGroupsRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} domain
     * @param {string} cfgName
     * @param {boolean} enabled
     * @param {QueryFiltersDto} queryFiltersDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeCfgEnabled(
      domain: string,
      cfgName: string,
      enabled: boolean,
      queryFiltersDto: QueryFiltersDto,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .changeCfgEnabled(domain, cfgName, enabled, queryFiltersDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} domain
     * @param {string} cfgName
     * @param {string} groupingBy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countCfgsGroupingBy(
      domain: string,
      cfgName: string,
      groupingBy: string,
      options?: any
    ): AxiosPromise<{ [key: string]: number }> {
      return localVarFp
        .countCfgsGroupingBy(domain, cfgName, groupingBy, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} domain
     * @param {string} cfgName
     * @param {SysCfgCreateRequestDto} sysCfgCreateRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCfg(
      domain: string,
      cfgName: string,
      sysCfgCreateRequestDto: SysCfgCreateRequestDto,
      options?: any
    ): AxiosPromise<SysCfgRegularDto> {
      return localVarFp
        .createCfg(domain, cfgName, sysCfgCreateRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} domain
     * @param {string} cfgName
     * @param {Array<SysCfgCreateRequestDto>} sysCfgCreateRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCfgs(
      domain: string,
      cfgName: string,
      sysCfgCreateRequestDto: Array<SysCfgCreateRequestDto>,
      options?: any
    ): AxiosPromise<Array<SysCfgRegularDto>> {
      return localVarFp
        .createCfgs(domain, cfgName, sysCfgCreateRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} domain
     * @param {string} cfgName
     * @param {QueryFiltersDto} queryFiltersDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCfg(
      domain: string,
      cfgName: string,
      queryFiltersDto: QueryFiltersDto,
      options?: any
    ): AxiosPromise<ResultDtoLong> {
      return localVarFp
        .deleteCfg(domain, cfgName, queryFiltersDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} domain
     * @param {string} name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadAvatar(domain: string, name: string, options?: any): AxiosPromise<string> {
      return localVarFp
        .downloadAvatar(domain, name, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} domain
     * @param {string} cfgName
     * @param {QueryDto} queryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCfgs(
      domain: string,
      cfgName: string,
      queryDto: QueryDto,
      options?: any
    ): AxiosPromise<PageResponseSysCfgRegularDto> {
      return localVarFp
        .getAllCfgs(domain, cfgName, queryDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} domain
     * @param {SysCfgGetMultipleRequestDto} sysCfgGetMultipleRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCfgsRawCombined(
      domain: string,
      sysCfgGetMultipleRequestDto: SysCfgGetMultipleRequestDto,
      options?: any
    ): AxiosPromise<Array<SysCfgListRawDto>> {
      return localVarFp
        .getAllCfgsRawCombined(domain, sysCfgGetMultipleRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} cfgName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCfgsRawMultiRoom(cfgName: string, options?: any): AxiosPromise<Array<SysCfgRawDto>> {
      return localVarFp
        .getAllCfgsRawMultiRoom(cfgName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} domain
     * @param {string} cfgName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCfgsRow(
      domain: string,
      cfgName: string,
      options?: any
    ): AxiosPromise<Array<SysCfgRawDto>> {
      return localVarFp
        .getAllCfgsRow(domain, cfgName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} domain
     * @param {string} cfgName
     * @param {SysCfgPatchDataRequestDto} sysCfgPatchDataRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchCfgData(
      domain: string,
      cfgName: string,
      sysCfgPatchDataRequestDto: SysCfgPatchDataRequestDto,
      options?: any
    ): AxiosPromise<number> {
      return localVarFp
        .patchCfgData(domain, cfgName, sysCfgPatchDataRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} domain
     * @param {string} cfgName
     * @param {string} name
     * @param {SysCfgUpdateRequestDto} sysCfgUpdateRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateConfig(
      domain: string,
      cfgName: string,
      name: string,
      sysCfgUpdateRequestDto: SysCfgUpdateRequestDto,
      options?: any
    ): AxiosPromise<SysCfgRegularDto> {
      return localVarFp
        .updateConfig(domain, cfgName, name, sysCfgUpdateRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} domain
     * @param {Set<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadUsernames(
      domain: string,
      requestBody: Set<string>,
      options?: any
    ): AxiosPromise<AvatarServiceResponseDto> {
      return localVarFp
        .uploadUsernames(domain, requestBody, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SysCfgControllerApi - object-oriented interface
 * @export
 * @class SysCfgControllerApi
 * @extends {BaseAPI}
 */
export class SysCfgControllerApi extends BaseAPI {
  /**
   *
   * @param {string} domain
   * @param {File} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysCfgControllerApi
   */
  public addAvatars(domain: string, file: File, options?: RawAxiosRequestConfig) {
    return SysCfgControllerApiFp(this.configuration)
      .addAvatars(domain, file, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} domain
   * @param {Set<string>} requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysCfgControllerApi
   */
  public addNicknames(
    domain: string,
    requestBody: Set<string>,
    options?: RawAxiosRequestConfig
  ) {
    return SysCfgControllerApiFp(this.configuration)
      .addNicknames(domain, requestBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} roomName
   * @param {SysCfgAddProxiesDto} sysCfgAddProxiesDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysCfgControllerApi
   */
  public addProxies(
    roomName: string,
    sysCfgAddProxiesDto: SysCfgAddProxiesDto,
    options?: RawAxiosRequestConfig
  ) {
    return SysCfgControllerApiFp(this.configuration)
      .addProxies(roomName, sysCfgAddProxiesDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} domain
   * @param {string} cfgName
   * @param {SysCfgApplyAuthGroupsRequestDto} sysCfgApplyAuthGroupsRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysCfgControllerApi
   */
  public applyAuthGroups(
    domain: string,
    cfgName: string,
    sysCfgApplyAuthGroupsRequestDto: SysCfgApplyAuthGroupsRequestDto,
    options?: RawAxiosRequestConfig
  ) {
    return SysCfgControllerApiFp(this.configuration)
      .applyAuthGroups(domain, cfgName, sysCfgApplyAuthGroupsRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} domain
   * @param {string} cfgName
   * @param {boolean} enabled
   * @param {QueryFiltersDto} queryFiltersDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysCfgControllerApi
   */
  public changeCfgEnabled(
    domain: string,
    cfgName: string,
    enabled: boolean,
    queryFiltersDto: QueryFiltersDto,
    options?: RawAxiosRequestConfig
  ) {
    return SysCfgControllerApiFp(this.configuration)
      .changeCfgEnabled(domain, cfgName, enabled, queryFiltersDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} domain
   * @param {string} cfgName
   * @param {string} groupingBy
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysCfgControllerApi
   */
  public countCfgsGroupingBy(
    domain: string,
    cfgName: string,
    groupingBy: string,
    options?: RawAxiosRequestConfig
  ) {
    return SysCfgControllerApiFp(this.configuration)
      .countCfgsGroupingBy(domain, cfgName, groupingBy, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} domain
   * @param {string} cfgName
   * @param {SysCfgCreateRequestDto} sysCfgCreateRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysCfgControllerApi
   */
  public createCfg(
    domain: string,
    cfgName: string,
    sysCfgCreateRequestDto: SysCfgCreateRequestDto,
    options?: RawAxiosRequestConfig
  ) {
    return SysCfgControllerApiFp(this.configuration)
      .createCfg(domain, cfgName, sysCfgCreateRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} domain
   * @param {string} cfgName
   * @param {Array<SysCfgCreateRequestDto>} sysCfgCreateRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysCfgControllerApi
   */
  public createCfgs(
    domain: string,
    cfgName: string,
    sysCfgCreateRequestDto: Array<SysCfgCreateRequestDto>,
    options?: RawAxiosRequestConfig
  ) {
    return SysCfgControllerApiFp(this.configuration)
      .createCfgs(domain, cfgName, sysCfgCreateRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} domain
   * @param {string} cfgName
   * @param {QueryFiltersDto} queryFiltersDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysCfgControllerApi
   */
  public deleteCfg(
    domain: string,
    cfgName: string,
    queryFiltersDto: QueryFiltersDto,
    options?: RawAxiosRequestConfig
  ) {
    return SysCfgControllerApiFp(this.configuration)
      .deleteCfg(domain, cfgName, queryFiltersDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} domain
   * @param {string} name
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysCfgControllerApi
   */
  public downloadAvatar(domain: string, name: string, options?: RawAxiosRequestConfig) {
    return SysCfgControllerApiFp(this.configuration)
      .downloadAvatar(domain, name, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} domain
   * @param {string} cfgName
   * @param {QueryDto} queryDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysCfgControllerApi
   */
  public getAllCfgs(
    domain: string,
    cfgName: string,
    queryDto: QueryDto,
    options?: RawAxiosRequestConfig
  ) {
    return SysCfgControllerApiFp(this.configuration)
      .getAllCfgs(domain, cfgName, queryDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} domain
   * @param {SysCfgGetMultipleRequestDto} sysCfgGetMultipleRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysCfgControllerApi
   */
  public getAllCfgsRawCombined(
    domain: string,
    sysCfgGetMultipleRequestDto: SysCfgGetMultipleRequestDto,
    options?: RawAxiosRequestConfig
  ) {
    return SysCfgControllerApiFp(this.configuration)
      .getAllCfgsRawCombined(domain, sysCfgGetMultipleRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} cfgName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysCfgControllerApi
   */
  public getAllCfgsRawMultiRoom(cfgName: string, options?: RawAxiosRequestConfig) {
    return SysCfgControllerApiFp(this.configuration)
      .getAllCfgsRawMultiRoom(cfgName, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} domain
   * @param {string} cfgName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysCfgControllerApi
   */
  public getAllCfgsRow(domain: string, cfgName: string, options?: RawAxiosRequestConfig) {
    return SysCfgControllerApiFp(this.configuration)
      .getAllCfgsRow(domain, cfgName, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} domain
   * @param {string} cfgName
   * @param {SysCfgPatchDataRequestDto} sysCfgPatchDataRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysCfgControllerApi
   */
  public patchCfgData(
    domain: string,
    cfgName: string,
    sysCfgPatchDataRequestDto: SysCfgPatchDataRequestDto,
    options?: RawAxiosRequestConfig
  ) {
    return SysCfgControllerApiFp(this.configuration)
      .patchCfgData(domain, cfgName, sysCfgPatchDataRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} domain
   * @param {string} cfgName
   * @param {string} name
   * @param {SysCfgUpdateRequestDto} sysCfgUpdateRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysCfgControllerApi
   */
  public updateConfig(
    domain: string,
    cfgName: string,
    name: string,
    sysCfgUpdateRequestDto: SysCfgUpdateRequestDto,
    options?: RawAxiosRequestConfig
  ) {
    return SysCfgControllerApiFp(this.configuration)
      .updateConfig(domain, cfgName, name, sysCfgUpdateRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} domain
   * @param {Set<string>} requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysCfgControllerApi
   */
  public uploadUsernames(
    domain: string,
    requestBody: Set<string>,
    options?: RawAxiosRequestConfig
  ) {
    return SysCfgControllerApiFp(this.configuration)
      .uploadUsernames(domain, requestBody, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
