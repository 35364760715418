import React, { useEffect, useState } from "react";
import {Checkbox, Divider, Form, Input, Modal, Typography} from "antd";


const UpdateNicknameModal = (
  {
    title,
    onCancel,
    confirmLoading = false,
    onSave,
    isOpen
  }: {
    title?: string,
    confirmLoading?: boolean,
    onCancel: () => void;
    onSave?: (customNickname?: string) => void
    isOpen: boolean
  }
) => {
  const [isRandom, setIsRandom] = useState<boolean>(true);

  const [form] = Form.useForm();
  const formValues = Form.useWatch([], form);

  function handleSaveClick() {
    form.validateFields({validateOnly: false})
      .then(() =>
        onSave && onSave(formValues["nickname"]?.trim())
      )
      .catch(err => console.error(err));
  }

  useEffect(() => {
    form.resetFields()
  }, [form, isOpen]);

  return (<>
    <Modal open={isOpen}
           okText="Submit"
           onCancel={() => onCancel && onCancel()}
           maskClosable={false}
           width={600}
           confirmLoading={confirmLoading}
           onOk={() => handleSaveClick()}
           title={title && <Typography.Title level={3} style={{marginTop: 15}}>{title}</Typography.Title>}>

      {title && (<Divider style={{marginTop: 10, marginBottom: 30}}/>)}

      <Form
        form={form}
        size="large"
        className="a-common-form"
        autoComplete="off"
        key="addingMode"
      >
        <Form.Item
          name="nickname"
          label="Custom nickname"
          rules={[
            {required: !isRandom},
          ]}
        >
          <Input placeholder="New custom nickname" disabled={isRandom}/>
        </Form.Item>

        <Form.Item
          name="isRandom"
          label="Set random nickname from the pool"
          // valuePropName="checked"
        >
          <Checkbox
            style={{width: 25, height: 25}}
            checked={isRandom}
            onChange={() => {
              form.setFieldsValue({nickname: undefined});
              setIsRandom(!isRandom)
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  </>)
}

export default UpdateNicknameModal;