import { Navigate, createFileRoute } from "@tanstack/react-router";
import { useAtom } from "jotai/index";

import { isOwner } from "@/lib/auth-predicates";
import AuthGroupsPage from "@/pages/auth-groups-page";
import { meStore } from "@/store/store";

export const Route = createFileRoute("/auth-groups/")({
  component: Component,
});

function Component() {
  const [me] = useAtom(meStore);

  if (!isOwner(me)) {
    return <Navigate to="/" />;
  }

  return <AuthGroupsPage />;
}
