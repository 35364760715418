import { useApi } from "@/shared/api/use-api";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { JsonFormRegularDto, KeyFilter } from "@/shared/api";


export const useJsonFormByFiltersQuery = (
  {
    keyFilters
  } : {
    keyFilters?: KeyFilter[];
  },
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<JsonFormRegularDto[]>>, "queryKey">
) => {
  const { jsonFormApi } = useApi();

  return useQuery({
    queryKey: ["/json-form/get-by-filters", keyFilters],
    queryFn: ({ signal }) => jsonFormApi.getAllJsonFromsByFilters(
      { filters: keyFilters ? new Set(keyFilters) : undefined },
      { signal }
    ),
    ...queryOptions
  })
}