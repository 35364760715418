import { createTheme, responsiveFontSizes } from "@mui/material";

declare module '@mui/material/styles' {
  interface Palette {
    sidebar: Palette['primary'];
  }

  interface PaletteOptions {
    sidebar?: PaletteOptions['primary'];
  }

  interface PaletteColor {
    lightText?: string;
  }

  interface SimplePaletteColorOptions {
    lightText?: string;
  }
}

const regularTheme = createTheme({
  palette: {
    sidebar: {
      light: "#002140",
      main: "#001529",
      lightText: "rgba(255, 255, 255, 0.90)",
      contrastText: 'rgba(255, 255, 255, 0.65)'
    },
    // secondary: {
    //   main: red[500]
    // },
    // success: {
    //   main: purple[500]
    // }
  }
})

export default responsiveFontSizes(regularTheme)