import { Avatar } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { RoomTablesCachePlayerGameInfoDto } from "@/shared/api";
import { dateFilterFn, dateFilterProps, getDate } from "@/lib/common-utils";

export type PlayerInfo = RoomTablesCachePlayerGameInfoDto & {
  tid?: string;
  isObserver: boolean;
};

export const usePlayersTableColumns = () => {
  const { t } = useTranslation("service-configs");

  return useMemo<MRT_ColumnDef<PlayerInfo>[]>(
    () => [
      {
        id: "tid",
        header: t("playersTable.tid"),
        accessorKey: "tid",
      },
      {
        header: t("playersTable.tech"),
        id: "tech",
        accessorKey: "technology",
        sortingFn: "alphanumeric",
        filterFn: "contains",
        accessorFn: (row) => row.technology?.replace("TECHNOLOGY_", ""),
      },
      {
        header: t("playersTable.isObserver"),
        id: "isObserver",
        accessorKey: "isObserver",
        accessorFn: (row) => (row.isObserver ? "True" : "False"),
      },
      {
        header: t("playersTable.gameGroup"),
        id: "gameGroup",
        accessorKey: "group",
      },
      {
        header: t("playersTable.pid"),
        id: "pid",
        accessorKey: "base.pid",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("playersTable.nickname"),
        id: "nickname",
        accessorKey: "base.nickname",
      },
      {
        header: t("playersTable.uid"),
        id: "uid",
        accessorKey: "base.uuid",
      },
      {
        header: t("playersTable.avatar"),
        id: "avatar",
        accessorKey: "base.avatarURL",
        accessorFn: (row) => <Avatar src={row.base?.avatarURL} />,
      },
      {
        header: t("playersTable.patterns"),
        id: "patterns",
        accessorKey: "clusters",
        accessorFn: (row) => (row.clusters.length ? row.clusters.join(", ") : "-"),
      },
      {
        header: t("playersTable.hands"),
        id: "handCount",
        accessorKey: "handCount",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("playersTable.profit"),
        id: "profit",
        accessorKey: "profit",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("playersTable.vpip"),
        id: "vpip",
        accessorKey: "stats.vpip",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("playersTable.pfr"),
        id: "pfr",
        accessorKey: "stats.pfr",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("playersTable.totalHands"),
        id: "hands",
        accessorKey: "stats.hands",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("playersTable.lastUpdate"),
        id: "timestamp",
        accessorKey: "timestamp",
        accessorFn: (row) => getDate(row.timestamp),
        filterFn: dateFilterFn,
        filterVariant: "datetime-range",
        muiFilterDateTimePickerProps: dateFilterProps,
      },
    ],
    [t]
  );
};
