import {
  DataObject,
  Dataset,
  Groups,
  Home,
  Logout,
  ManageAccounts,
} from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "@tanstack/react-router";
import { useAtom } from "jotai";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useCfgPerm, useLogout } from "@/auth/hooks";
import { LocaleSwitcher } from "@/components/locale-switcher";
import SidebarMenuItem, {
  SidebarMenuItemType,
} from "@/components/sidebar/sidebar-menu-item-type";
import { RoomSelectTextField } from "@/components/sidebar/styled";
import { isOwner, isSuperAdmin } from "@/lib/auth-predicates";
import { ROOM_CONFIGS_NAMES, ROOM_NAMES } from "@/lib/constants";
import { SysDomainRegularDto } from "@/shared/api";
import { appCurrentRoom, meStore } from "@/store/store";

const Sidebar = ({
  domains,
  dataIsLoading = false,
}: {
  domains: SysDomainRegularDto[];
  dataIsLoading?: boolean;
}) => {
  const { t } = useTranslation("sidebar");
  const [me] = useAtom(meStore);

  const chatsPerms = useCfgPerm({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.REPORT_CHAT,
  });

  const rulesPerm = useCfgPerm({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.REPORT_RULE,
  });

  const roomTablesPerms = useCfgPerm({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.ROOM_TABLES_CACHE_ROOM_TABLES,
  });

  const navigate = useNavigate();

  const [selectedRoom, setSelectedRoom] = useAtom(appCurrentRoom);

  const rooms = useMemo(() => domains.filter((it) => it.is_room) ?? [], [domains]);

  const serviceDomain = useMemo(() => {
    return domains.find((it) => it.name === ROOM_NAMES.SERVICES);
  }, [domains]);

  const serviceConfigs = useMemo(() => {
    const resultConfigs = [];

    if (chatsPerms.read || rulesPerm.read) {
      resultConfigs.push(ROOM_CONFIGS_NAMES.PLAYERS_REPORTER);
    }

    if (roomTablesPerms.read) {
      resultConfigs.push(ROOM_CONFIGS_NAMES.TABLE_CACHE);
    }

    const configNames =
      serviceDomain?.config_names
        ?.filter(
          (it) =>
            it !== ROOM_CONFIGS_NAMES.REPORT_CHAT && it !== ROOM_CONFIGS_NAMES.REPORT_RULE
        )
        ?.filter(
          (it) =>
            it !== ROOM_CONFIGS_NAMES.ROOM_TABLES_CACHE_ROOM_TABLES &&
            it !== ROOM_CONFIGS_NAMES.ROOM_TABLES_CACHE_ROOM_INFO
        ) ?? [];

    return [...configNames, ...resultConfigs];
  }, [chatsPerms, roomTablesPerms, rulesPerm, serviceDomain]);

  const selectedRoomConfigs = useMemo(() => {
    return selectedRoom?.config_names.filter((it) => it !== ROOM_CONFIGS_NAMES.ACCOUNT) ?? [];
  }, [selectedRoom]);

  const accountIsPresent = useMemo(
    () => selectedRoom?.config_names.some((it) => it === ROOM_CONFIGS_NAMES.ACCOUNT) ?? false,
    [selectedRoom]
  );

  const theme = useTheme();

  const logout = useLogout();

  const menuItems = useMemo<SidebarMenuItemType[]>(() => {
    return [
      {
        name: t("home"),
        icon: <Home />,
        link: { to: "/" },
      },
      {
        name: t("accounts"),
        icon: <AccountCircleIcon />,
        link: {
          to: "/accounts",
          isDisabled: true,
        },
        onClick: () =>
          navigate({
            to: "/accounts/$room/$type",
            params: { room: selectedRoom?.name, type: "regular" },
          }),
        isHiddenAccessor: () => !accountIsPresent,
      },
      {
        name: t("roomSettings"),
        icon: <DataObject />,
        link: { to: "/room-config" },
        subItemsAccessor: () =>
          selectedRoomConfigs.map((cfg) => ({
            name: cfg,
            link: {
              to: "/room-config/$roomName/$configName",
              params: { roomName: selectedRoom?.name, configName: cfg },
            },
          })),
        isHiddenAccessor: () => selectedRoomConfigs.length <= 0,
      },
      {
        name: t("services"),
        icon: <SettingsIcon />,
        link: { to: "/service-config" },
        subItemsAccessor: () =>
          serviceConfigs.map((cfg) => {
            return {
              name: t(`servicesMenu.${cfg}`, cfg),
              link: {
                to: "/service-config/$configName",
                params: {
                  configName: cfg,
                },
              },
            };
          }) ?? [],
        isHiddenAccessor: () => !serviceDomain || serviceDomain.config_names.length <= 0,
      },
      {
        name: t("userManagement"),
        link: {
          to: "/users",
        },
        icon: <ManageAccounts />,
        isHiddenAccessor: () => !isOwner(me),
      },
      {
        name: t("Auth Groups"),
        link: {
          to: "/auth-groups",
        },
        icon: <Groups />,
        isHiddenAccessor: () => !isOwner(me),
      },
      {
        name: t("dashboard"),
        link: {
          to: "/dashboard",
        },
        icon: <DashboardIcon />,
        isHiddenAccessor: () => !isOwner(me),
      },
      {
        name: "Room Management",
        link: {
          to: "/room-management",
        },
        icon: <Dataset />,
        isHiddenAccessor: () => !isSuperAdmin(me),
      },
    ];
  }, [
    accountIsPresent,
    me,
    navigate,
    selectedRoom,
    selectedRoomConfigs,
    serviceConfigs,
    serviceDomain,
    t,
  ]);

  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleRoomChange = (room: SysDomainRegularDto | null) => {
    if (room != null) {
      setSelectedRoom(room);
    }
  };

  const handleDropdownOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleCollapseToggle = () => {
    setSidebarCollapsed((prev) => !prev);
  };

  return (
    <div
      style={{
        width: sidebarCollapsed ? 80 : 250,
        transition: "width 0.3s ease",
      }}
    >
      <Box
        sx={{
          width: sidebarCollapsed ? 80 : 250,
          position: "fixed",
          height: "100vh",
          backgroundColor: theme.palette.sidebar.main,
          color: theme.palette.sidebar.contrastText,
          display: "flex",
          flexDirection: "column",
          transition: "width 0.3s ease",
          overflowX: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            p: 2,
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {sidebarCollapsed ? (
            <>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", textAlign: "center" }}
                onMouseOver={(e) => handleDropdownOpen(e)}
              >
                {selectedRoom?.name
                  .toUpperCase()
                  .split("_")
                  .map((it) => it[0]) ?? "R"}
              </Typography>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleDropdownClose}
                MenuListProps={{ onMouseLeave: handleDropdownClose }}
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
              >
                {rooms.map((it) => (
                  <MenuItem
                    key={it.name}
                    selected={it.name === selectedRoom?.name}
                    onClick={() => handleRoomChange(it)}
                  >
                    {it.name.toLowerCase().split("_").join(" ")}
                  </MenuItem>
                ))}
              </Menu>
            </>
          ) : (
            <Autocomplete
              loading={dataIsLoading}
              fullWidth
              size="small"
              options={rooms}
              value={selectedRoom === undefined ? null : selectedRoom}
              onChange={(_e, v) => handleRoomChange(v)}
              getOptionLabel={(option) => option.name.toLowerCase().split("_").join(" ")}
              getOptionKey={(option) => option.name}
              renderInput={(params) => (
                <RoomSelectTextField {...params} placeholder={t("selectRoom")} />
              )}
            />
          )}
        </Box>

        <Divider sx={{ borderColor: theme.palette.sidebar.contrastText }} />

        <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
          <List component="nav" aria-labelledby="nested-list-subheader">
            {menuItems.map((item) => (
              <SidebarMenuItem
                key={item.name}
                item={item}
                sidebarCollapsed={sidebarCollapsed}
              />
            ))}
          </List>
        </Box>

        <Divider sx={{ borderColor: theme.palette.sidebar.contrastText }} />

        <Box
          sx={{
            backgroundColor: theme.palette.sidebar.light,
            textAlign: "center",
            p: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              pb: 4,
            }}
          >
            <LocaleSwitcher />
          </Box>
          <div onClick={logout}>
            {sidebarCollapsed ? (
              <IconButton sx={{ color: "#fff" }}>
                <Logout />
              </IconButton>
            ) : (
              <Button variant="contained" endIcon={<Logout />} sx={{ color: "#fff" }}>
                {t("logout")}
              </Button>
            )}
          </div>
        </Box>

        <Box
          sx={{
            backgroundColor: theme.palette.sidebar.light,
            width: "100%",
            height: "50px",
            textAlign: "center",
            cursor: "pointer",
          }}
          onClick={handleCollapseToggle}
        >
          <IconButton sx={{ color: "#ffffff" }}>
            {sidebarCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </Box>
      </Box>
    </div>
  );
};

export default Sidebar;
