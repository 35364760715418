import { useMemo } from "react";
import { SysAccountRegularDto } from "@/shared/api";
import { MRT_OrderedColumnDef, NilObj } from "@/types/commonTypes";
import { ACCOUNT_STAT_GAME_TYPE_KEYS, ACCOUNT_STAT_GAME_TYPES, ACCOUNT_STAT_ROW_ID_PREFIX } from "@/lib/constants";
import { renderClubsCell } from "@/lib/accountsUtils";
import useAccountCommonColumns from "@/module/accounts-module/hooks/account-common-columns-hook";


export const useRegularAccountsTableColumns = ({ room } : { room: string}) => {
  const gameTypeStats = useMemo<MRT_OrderedColumnDef<SysAccountRegularDto>[]>(() =>
    ACCOUNT_STAT_GAME_TYPES.flatMap(gameType => (
      ACCOUNT_STAT_GAME_TYPE_KEYS.map(stat => (
        {
          id: `${ACCOUNT_STAT_ROW_ID_PREFIX}.${gameType}.${stat}`,
          header: `${gameType.toUpperCase()} ${stat.toUpperCase()}`,
          order: 602,
          accessorFn: ({ data }) => (data as any)?.aid_stats?.[gameType]?.[stat] ?? "-"
        }
      ))
    )),
    []
  );

  const commonColumns = useAccountCommonColumns({room});

  return useMemo<MRT_OrderedColumnDef<SysAccountRegularDto>[]>(() => [
    {
      id: "data.pid",
      header: "Pid",
      order: 399,
      accessorFn: ({data}) => (data as NilObj)?.pid ?? "-",
    },
    {
      id: "data.aid",
      header: "Aid",
      order: 401,
      accessorFn: ({data}) => (data as NilObj)?.aid ?? "-",
    },
    {
      id: "data.nick",
      header: "Nick",
      order: 501,
      accessorFn: ({data}) => (data as NilObj)?.nick ?? "-",
    },
    {
      id: "data.clubs",
      header: "Clubs",
      order: 601,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ row: { original } }) => renderClubsCell(original.data)
    },
    {
      id: "data.alc",
      header: "Alc",
      order: 601,
      accessorFn: ({data}) => (data as NilObj)?.alc ?? "-",
    },
    {
      id: "data.gold",
      header: "Gold",
      order: 601,
      accessorFn: ({data}) => (data as NilObj)?.gold ?? "-",
    },
    {
      id: "data.game_group",
      header: "Group",
      order: 601,
      accessorFn: ({ data }) => (data as NilObj)?.game_group ?? "-",
    },
    {
      id: "data.game_type",
      header: "Game type",
      order: 601,
      accessorFn: ({ data }) => (data as NilObj)?.game_type ?? "-",
    },
    {
      id: "data.proxy",
      header: "Proxy",
      order: 601,
      accessorFn: ({ data }) => (data as NilObj)?.proxy ?? "-",
    },
    {
      id: "data.behaviour",
      header: "Behaviour",
      order: 601,
      accessorFn: ({ data }) => (data as NilObj)?.behaviour ?? "-",
    },
    {
      id: "data.schedule",
      header: "Schedule",
      order: 601,
      accessorFn: ({ data }) => (data as NilObj)?.schedule ?? "-",
    },
    {
      id: "data.ai_profile",
      header: "AI profile",
      order: 601,
      accessorFn: ({ data }) => (data as NilObj)?.ai_profile ?? "-",
    },
    {
      id: "data.ai_profile_ob",
      header: "AI profile Ob",
      order: 601,
      accessorFn: ({ data }) => (data as NilObj)?.ai_profile_ob ?? "-",
    },
    {
      id: "data.timing_profile",
      header: "Timing",
      order: 601,
      accessorFn: ({ data }) => (data as NilObj)?.timing_profile ?? "-",
    },
    {
      id: "data.timing_profile_ob",
      header: "Timing Ob",
      order: 601,
      accessorFn: ({ data }) => (data as NilObj)?.timing_profile_ob ?? "-",
    },
    {
      id: "data.insurance_profile",
      header: "Insurance",
      order: 601,
      accessorFn: ({ data }) => (data as NilObj)?.insurance_profile ?? "-",
    },
    {
      id: "data.insurance_profile_ob",
      header: "Insurance Ob",
      order: 601,
      accessorFn: ({ data }) => (data as NilObj)?.insurance_profile_ob ?? "-",
    },
    ...gameTypeStats,
    ...commonColumns
  ], [commonColumns, gameTypeStats])
}