import { Navigate, createFileRoute } from "@tanstack/react-router";
import { useAtom } from "jotai/index";

import { isOwner } from "@/lib/auth-predicates";
import UserPage from "@/pages/user-page";
import { meStore } from "@/store/store";

export const Route = createFileRoute("/users/$id")({
  component: Component,
});

function Component() {
  const [me] = useAtom(meStore);
  const { id } = Route.useParams();

  if (!isOwner(me)) {
    return <Navigate to="/" />;
  }

  return <UserPage userId={id} />;
}
