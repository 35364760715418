/* tslint:disable */

/* eslint-disable */

/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { AxiosInstance, AxiosPromise, RawAxiosRequestConfig } from "axios";
import globalAxios from "axios";

// @ts-ignore
import {
  BASE_PATH,
  BaseAPI,
  COLLECTION_FORMATS,
  RequestArgs,
  RequiredError,
  operationServerMap,
} from "../base";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  createRequestFunction,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  toPathString,
} from "../common";
import type { Configuration } from "../configuration";
// @ts-ignore
import { StatServiceResponseDto } from "../models";
// @ts-ignore
import { StatserviceCsvQueryFiltersDto } from "../models";
// @ts-ignore
import { StatserviceQueryDto } from "../models";

/**
 * SysStatserviceControllerApi - axios parameter creator
 * @export
 */
export const SysStatserviceControllerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {StatserviceCsvQueryFiltersDto} statserviceCsvQueryFiltersDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportStatserviceToCsv: async (
      statserviceCsvQueryFiltersDto: StatserviceCsvQueryFiltersDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'statserviceCsvQueryFiltersDto' is not null or undefined
      assertParamExists(
        "exportStatserviceToCsv",
        "statserviceCsvQueryFiltersDto",
        statserviceCsvQueryFiltersDto
      );
      const localVarPath = `/api/v2/sys-data/statservice/export/csv`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        statserviceCsvQueryFiltersDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {StatserviceQueryDto} statserviceQueryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findStatserviceData: async (
      statserviceQueryDto: StatserviceQueryDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'statserviceQueryDto' is not null or undefined
      assertParamExists("findStatserviceData", "statserviceQueryDto", statserviceQueryDto);
      const localVarPath = `/api/v2/sys-data/statservice`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        statserviceQueryDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SysStatserviceControllerApi - functional programming interface
 * @export
 */
export const SysStatserviceControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    SysStatserviceControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {StatserviceCsvQueryFiltersDto} statserviceCsvQueryFiltersDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportStatserviceToCsv(
      statserviceCsvQueryFiltersDto: StatserviceCsvQueryFiltersDto,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exportStatserviceToCsv(
        statserviceCsvQueryFiltersDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysStatserviceControllerApi.exportStatserviceToCsv"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {StatserviceQueryDto} statserviceQueryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findStatserviceData(
      statserviceQueryDto: StatserviceQueryDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatServiceResponseDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findStatserviceData(
        statserviceQueryDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysStatserviceControllerApi.findStatserviceData"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * SysStatserviceControllerApi - factory interface
 * @export
 */
export const SysStatserviceControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SysStatserviceControllerApiFp(configuration);
  return {
    /**
     *
     * @param {StatserviceCsvQueryFiltersDto} statserviceCsvQueryFiltersDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportStatserviceToCsv(
      statserviceCsvQueryFiltersDto: StatserviceCsvQueryFiltersDto,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .exportStatserviceToCsv(statserviceCsvQueryFiltersDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {StatserviceQueryDto} statserviceQueryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findStatserviceData(
      statserviceQueryDto: StatserviceQueryDto,
      options?: any
    ): AxiosPromise<StatServiceResponseDto> {
      return localVarFp
        .findStatserviceData(statserviceQueryDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SysStatserviceControllerApi - object-oriented interface
 * @export
 * @class SysStatserviceControllerApi
 * @extends {BaseAPI}
 */
export class SysStatserviceControllerApi extends BaseAPI {
  /**
   *
   * @param {StatserviceCsvQueryFiltersDto} statserviceCsvQueryFiltersDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysStatserviceControllerApi
   */
  public exportStatserviceToCsv(
    statserviceCsvQueryFiltersDto: StatserviceCsvQueryFiltersDto,
    options?: RawAxiosRequestConfig
  ) {
    return SysStatserviceControllerApiFp(this.configuration)
      .exportStatserviceToCsv(statserviceCsvQueryFiltersDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {StatserviceQueryDto} statserviceQueryDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysStatserviceControllerApi
   */
  public findStatserviceData(
    statserviceQueryDto: StatserviceQueryDto,
    options?: RawAxiosRequestConfig
  ) {
    return SysStatserviceControllerApiFp(this.configuration)
      .findStatserviceData(statserviceQueryDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
