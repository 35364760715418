import { AddCircleOutline } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { keepPreviousData } from "@tanstack/react-query";
import { Divider, Typography } from "antd";
import { useAtom } from "jotai/index";
import { enqueueSnackbar } from "notistack";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  useCreateUserMutation,
  useUpdateUserMutation,
  useUsersQuery,
} from "@/api/hooks/user-hooks";
import CreateUserModal from "@/components/create-user-modal";
import { COMMON_REFETCH_INTERVAL, SUPER_ADMIN_NAME } from "@/lib/constants";
import UsersListTable from "@/module/users-list-module/users-list-table";
import { CreateUserRequestDto, UpdateUserRequestDto } from "@/shared/api";
import { meStore } from "@/store/store";

const UsersListModule = () => {
  const [me] = useAtom(meStore);
  const { t } = useTranslation("users-list");

  const {
    data: usersRs,
    isLoading,
    isRefetching,
    refetch,
  } = useUsersQuery({
    placeholderData: keepPreviousData,
    staleTime: 0,
    refetchInterval: COMMON_REFETCH_INTERVAL,
    enabled: !!me,
  });

  const users = useMemo(() => {
    return (
      usersRs?.data?.filter(
        (item) => me?.username === SUPER_ADMIN_NAME || item.username !== SUPER_ADMIN_NAME
      ) ?? []
    );
  }, [me, usersRs?.data]);

  const [createUser, setCreateUser] = useState<boolean>(false);
  const { mutate: createUserMutate, isPending: createUserIsPending } = useCreateUserMutation();
  const { mutate: updateUser, isPending: updateUserIsPending } = useUpdateUserMutation();

  const handleUpdate = (data: UpdateUserRequestDto) => {
    updateUser(
      { updateUser: data },
      {
        onSuccess: () => {
          refetch().then();
        },
      }
    );
  };

  const onCreateUserHandler = (data: CreateUserRequestDto) => {
    createUserMutate(
      {
        request: data,
      },
      {
        onSuccess: () => {
          setCreateUser(false);
          enqueueSnackbar("user successfully created!", { variant: "success" });
          refetch().then();
        },
        onError: (err) => {
          console.error(err);
          enqueueSnackbar(`Error creating user: ${err.message}`, { variant: "error" });
        },
      }
    );
  };

  return (
    <>
      <Grid container spacing={2} height="100%" direction="column" flexWrap="nowrap">
        <Grid item>
          <Typography.Title level={2}>{t("users")}</Typography.Title>
        </Grid>
        <Grid item>
          <Divider style={{ marginTop: 0 }} />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutline width={24} />}
            onClick={() => setCreateUser(true)}
          >
            {t("createUser")}
          </Button>
        </Grid>
        <Grid item xs={10}>
          <UsersListTable
            users={users}
            usersIsLoading={isLoading}
            userIsRefetching={isRefetching || updateUserIsPending}
            onUpdate={(data) => handleUpdate(data)}
            onDeleted={() => refetch()}
          />
        </Grid>
      </Grid>
      <CreateUserModal
        open={createUser}
        onClose={() => setCreateUser(false)}
        onSave={(data) => onCreateUserHandler(data)}
        isLoading={createUserIsPending}
      />
    </>
  );
};

export default UsersListModule;
