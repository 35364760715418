import {
  MRT_ColumnDef,
  MRT_ColumnFilterFnsState,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  MRT_TableInstance,
  useMaterialReactTable,
} from "material-react-table";
import React, { ReactNode } from "react";
import { SysAccountRegularDto } from "@/shared/api";

export const useAccountsTable = (
  {
    columns,
    data,
    isDataLoading,
    isDataRefetching,
    isDataLoadingError,
    loadingErrorMessage,
    totalElements,
    totalPages,
    pagination,
    setPagination,
    columnFilters,
    setColumnFilters,
    columnFilterFns,
    setColumnFilterFns,
    sorting,
    setSorting,
    renderToolbarCustomActions,
    columnVisibility
  } : {
    columns: MRT_ColumnDef<SysAccountRegularDto>[];
    data?: SysAccountRegularDto[];
    isDataLoading?: boolean;
    isDataRefetching?: boolean;
    isDataLoadingError?: boolean;
    loadingErrorMessage?: string;
    totalElements?: number;
    totalPages?: number;
    pagination: MRT_PaginationState;
    setPagination: React.Dispatch<React.SetStateAction<MRT_PaginationState>>;
    columnFilters: MRT_ColumnFiltersState;
    setColumnFilters: React.Dispatch<React.SetStateAction<MRT_ColumnFiltersState>>;
    columnFilterFns: MRT_ColumnFilterFnsState;
    setColumnFilterFns: React.Dispatch<React.SetStateAction<MRT_ColumnFilterFnsState>>;
    sorting: MRT_SortingState;
    setSorting: React.Dispatch<React.SetStateAction<MRT_SortingState>>;
    renderToolbarCustomActions?: (props: {
      table: MRT_TableInstance<SysAccountRegularDto>;
    }) => ReactNode;
    columnVisibility?: {[key: string]: boolean};
  }
) => useMaterialReactTable({
  data: data ?? [],
  columns: columns,
  getRowId: (originalRow) => originalRow.name,
  initialState: {
    columnVisibility: {"data.clubs": false, "data.alc": false, "data.gold": false, ...columnVisibility },
    density: "compact",
  },
  // selectAllMode: "page",
  enableSelectAll: false,
  displayColumnDefOptions: {
    'mrt-row-select': { header: "" }
  },
  enableRowSelection: true,
  enableColumnDragging: false,
  enableColumnFilterModes: true,
  muiToolbarAlertBannerProps: isDataLoadingError ? {
    color: 'error',
    children: `Error loading data ${loadingErrorMessage ? ":" + loadingErrorMessage : ""}`,
  } : undefined,

  enablePagination: true,
  manualPagination: true,
  rowCount: totalElements ?? 0,
  pageCount: totalPages ?? 0,

  enableStickyHeader: true,
  enableSorting: true,
  autoResetPageIndex: false,
  enableGlobalFilter: false,
  state: {
    pagination,
    columnFilterFns,
    columnFilters,
    sorting,
    isLoading: isDataLoading,
    showProgressBars: isDataRefetching,
  },
  defaultColumn: {
    minSize: 20, //allow columns to get smaller than default
    maxSize: 40, //allow columns to get larger than default
    size: 40, //make columns wider by default
  },

  muiTablePaperProps: {
    sx: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
    },
  },
  muiTableContainerProps: {
    sx: {
      flexGrow: 10,
      overflowY: "scroll",
      height: "500px",
    },
  },
  muiTableBodyRowProps: ({ row }) => {
    const data = row.original.data

    return (data as any)?.status !== 0 && !isDataLoading
      ? { sx: { backgroundColor: "#e9b1cd" }}
      : {};
  },

  onPaginationChange: (value) => {
    setPagination(value)
  },
  onColumnFiltersChange: setColumnFilters,
  onColumnFilterFnsChange: setColumnFilterFns,
  onSortingChange: setSorting,
  renderTopToolbarCustomActions: renderToolbarCustomActions,
})