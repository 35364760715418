import {
  MRT_ColumnDef,
  MRT_ColumnFilterFnsState,
  MRT_ColumnFiltersState,
} from "material-react-table";
import React from "react";

import { Input, Select, TextInput } from "@/components/BulkEditModal/types";
import {
  ACC_OS_TP,
  ACC_TP,
  accountType,
} from "@/components/config-modals/router-edit-modal/constants";
import { mapRoomConfigByDataStructureToMap } from "@/lib/cfg-utils";
import propertyResolver from "@/lib/property-resolver";
import { AccountType } from "@/module/accounts-module/accounts-module";
import {
  ResultStatsDto,
  SysAccountRegularDto,
  SysAccountSessionGameTypeDto,
  SysAccountSessionRegularDto,
  SysAccountUpdateDto,
  SysCfgRegularDto,
} from "@/shared/api";
import {
  AccountSessionType,
  IAccountRegularResponse,
  IAccountSessionRegularResponse,
  IAccountSessionTableDisplayable,
  IAccountTableDisplayable,
  IEditableAccount,
  SysAccountEditForm,
} from "@/types/accountTypes";
import { NameIdentityItem, NilObj, SearchParams } from "@/types/commonTypes";
import { IManageableServiceInstanceRegularResponse } from "@/types/manageableServiceTypes";
import {
  IRoomConfigByDataStructureResponse,
  IRoomConfigRawResponse,
} from "@/types/roomConfigTypes";

import { mapExploitationStageToStringName } from "./common-utils";
import { ROOM_CONFIGS_NAMES, ROOM_NAMES } from "./constants";

const gameTypeMapping = new Map<string, Map<string, string>>([
  [
    "hh_eco",
    new Map<string, string>([
      ["NLH", "nlh"],
      ["NLP", "nlh"],
      ["NLHB", "nlh"],
      ["NLR", "nlh"],
      ["CSDNL", "nlh"],
      ["CSDNLB", "nlh"],
      ["NLHS", "nlh"],
      ["NLSD", "nlh"],
      ["PLO", "plo"],
      ["PLO5", "plo"],
      ["PLO6", "plo"],
      ["NLO", "plo"],
      ["NLO5", "plo"],
    ]),
  ],
  [
    "pw_eco",
    new Map<string, string>([
      ["NLH", "nlh"],
      ["NLP", "nlh"],
      ["NLHB", "nlh"],
      ["NLR", "nlh"],
      ["CSDNL", "nlh"],
      ["CSDNLB", "nlh"],
      ["NLHS", "nlh"],
      ["NLSD", "nlh"],
      ["PLO", "plo"],
      ["PLO5", "plo"],
      ["PLO6", "plo"],
      ["NLO", "plo"],
      ["NLO5", "plo"],
    ]),
  ],
]);

export const mapAccountBulkEditInputs = <T extends NameIdentityItem> (
  shortenConfigs:  Map<string, T[]> | undefined
) : Input[] => {
  return [
    new TextInput("username", "Username"),
    new TextInput("password", "Password"),
    new TextInput("wallet_code", "Wallet code"),
    new TextInput("cid", "Club ID"),
    new Select<string>(
      "account_type",
      "Account type",
      accountType?.map(item => ({value: item.value.toString(), title: item.label})) || [],
    ),
    new Select<string>(
      "game_group",
      "Game group",
      shortenConfigs?.get(ROOM_CONFIGS_NAMES.GAME_GROUP)?.map(item => ({value: item.name, title: item.name})) || []
    ),
    new Select<string>(
      "behaviour",
      "Behaviour",
      shortenConfigs?.get(ROOM_CONFIGS_NAMES.BEHAVIOUR)?.map(item => ({value: item.name, title: item.name})) || []
    ),
    new Select<string>(
      "proxy",
      "Proxy",
      shortenConfigs?.get(ROOM_CONFIGS_NAMES.PROXY)?.map(item => ({value: item.name, title: item.name})) || []
    ),
    new Select<string>(
      "schedule",
      "Schedule",
      shortenConfigs?.get(ROOM_CONFIGS_NAMES.SCHEDULE)?.map(item => ({value: item.name, title: item.name})) || []
    ),
    new Select<string>(
      "game_type",
      "Game type",
      shortenConfigs?.get(ROOM_CONFIGS_NAMES.GAME_TYPE)?.map(item => ({value: item.name, title: item.name})) || [],
      true
    ),
    new Select<string>(
      "ai_profile",
      "AI Profile",
      shortenConfigs?.get(ROOM_CONFIGS_NAMES.AI_PROFILE)?.map(item => ({value: item.name, title: item.name})) || []
    ),
    new Select<string>(
      "ai_profile_ob",
      "AI Profile OB",
      shortenConfigs?.get(ROOM_CONFIGS_NAMES.AI_PROFILE)?.map(item => ({value: item.name, title: item.name})) || []
    ),
    new Select<string>(
      "timing_profile",
      "Timing profile",
      shortenConfigs?.get(ROOM_CONFIGS_NAMES.TIMING)?.map(item => ({value: item.name, title: item.name})) || []
    ),
    new Select<string>(
      "timing_profile_ob",
      "Timing profile OB",
      shortenConfigs?.get(ROOM_CONFIGS_NAMES.TIMING)?.map(item => ({value: item.name, title: item.name})) || []
    ),
    new Select<string>(
        "insurance_profile",
        "Insurance",
        shortenConfigs?.get(ROOM_CONFIGS_NAMES.INSURANCE)?.map(item => ({value: item.name, title: item.name})) || []
    ),
    new Select<string>(
        "insurance_profile_ob",
        "Insurance OB",
        shortenConfigs?.get(ROOM_CONFIGS_NAMES.INSURANCE)?.map(item => ({value: item.name, title: item.name})) || []
    ),
  ]
}

export const mapAccountsToAccountsTableDisplayable = (
  accounts: IAccountRegularResponse[],
  relatedConfigs: IRoomConfigByDataStructureResponse[]
): IAccountTableDisplayable[] => {
  const configs = mapRoomConfigByDataStructureToMap(relatedConfigs);
  return accounts.map(account => mapAccountToAccountTableDisplayable(account, configs));
}

export const mapAccountToAccountTableDisplayable = (
  account: IAccountRegularResponse,
  relatedConfigs: Map<string, IRoomConfigRawResponse[]>
): IAccountTableDisplayable => {
  if (!relatedConfigs) {
    return {
      ...getAccountBaseInfo(account),
      ...getAccountSimpleDataFields(account.data),
    };
  }

  return {
    ...getAccountBaseInfo(account),
    ...getAccountSimpleDataFields(account.data),
    proxy: relatedConfigs.get("proxy")?.find(config => config.name === account.data.proxy),
    gameGroup: relatedConfigs.get("game_group")?.find(config => config.name === account.data.game_group),
    behaviour: relatedConfigs.get("behaviour")?.find(config => config.name === account.data.behaviour),
    schedule: relatedConfigs.get("schedule")?.find(config => config.name === account.data.schedule),
    gameType: relatedConfigs.get("game_type")?.find(config => config.name === account.data.game_type),
    aiProfile: relatedConfigs.get("ai_profile")?.find(config => config.name === account.data.ai_profile),
    aiProfileOb: relatedConfigs.get("ai_profile")?.find(config => config.name === account.data.ai_profile_ob),
    timingProfile: relatedConfigs.get("timing")?.find(config => config.name === account.data.timing_profile),
    timingProfileOb: relatedConfigs.get("timing")?.find(config => config.name === account.data.timing_profile_ob),
    insuranceProfile: relatedConfigs.get("insurance")?.find(config => config.name === account.data.insurance_profile),
    insuranceProfileOb: relatedConfigs.get("insurance")?.find(config => config.name === account.data.insurance_profile_ob),
  }
}

function getAccountBaseInfo(account: IAccountRegularResponse) {
  return {
    id: account.id,
    roomName: account.roomName,
    name: account.name,
    createdAt: account.createdAt,
    updatedAt: account.updatedAt,
    enabled: account.enabled
  }
}

function getAccountSimpleDataFields(accountData: any) {
  return {
    accountType: accountData.account_type,
    comment: accountData.comment,
    clubs: accountData.clubs,
    password: accountData.password,
    walletCode: accountData.wallet_code,
    source: accountData.source,
    osType: accountData.device?.os_type,
    pid: accountData.pid,
    cid: accountData.cid,
    nick: accountData.nick,
    status: accountData.status,
    aid: accountData.aid,
    alc: accountData.alc,
    gold: accountData.gold
  }
}

export const rollupTableDisplayableAccounts = (accounts: IAccountTableDisplayable[]) => {
  return accounts.map(account => rollupWideAccount(account));
}
export const rollupWideAccount = (account: IAccountTableDisplayable): IEditableAccount => {
  return {
    ...account,
    proxy: account.proxy?.name,
    gameGroup: account.gameGroup?.name,
    behaviour: account.behaviour?.name,
    schedule: account.schedule?.name,
    gameType: account.gameType?.name,
    aiProfile: account.aiProfile?.name,
    aiProfileOb: account.aiProfileOb?.name,
    timingProfile: account.timingProfile?.name,
    timingProfileOb: account.timingProfileOb?.name,
    insuranceProfile: account.insuranceProfile?.name,
    insuranceProfileOb: account.insuranceProfileOb?.name,
  }
}

export const mapSysAccountRegularToEditableAccount = (account: SysAccountRegularDto): IEditableAccount => {
  const data = account.data as NilObj;
  return {
    id: account.id,
    name: account.name,
    accountType: data?.account_type as unknown as number,
    password: data?.password as unknown as string,
    walletCode: data?.wallet_code as unknown as string,
    source: data?.source as unknown as string,
    osType: data?.device?.os_type as unknown as number,
    comment: data?.comment as unknown as string,
    cid: data?.cid as unknown as string,
    pid: data?.pid as unknown as number,
    nick: data?.nick as unknown as string,
    gameGroup: data?.game_group as unknown as string ,
    proxy: data?.proxy as unknown as string,
    behaviour: data?.behaviour as unknown as string,
    schedule: data?.schedule as unknown as string,
    gameType: data?.game_type as unknown as string,
    aiProfile: data?.ai_profile as unknown as string,
    aiProfileOb: data?.ai_profile_ob as unknown as string,
    timingProfile: data?.timing_profile as unknown as string,
    timingProfileOb: data?.timing_profile_ob as unknown as string,
    insuranceProfile: data?.insurance_profile as unknown as string,
    insuranceProfileOb: data?.insurance_profile_ob as unknown as string
  };
}

export const mapWideAccountToEditableAccount = (account: IAccountTableDisplayable): IEditableAccount => {
  return {
    id: account.id,
    name: account.name,
    password: account.password,
    walletCode: account.walletCode,
    source: account.source,
    osType: account.osType,
    accountType: account.accountType,
    comment: account.comment,
    cid: account.cid,
    pid: account.pid,
    nick: account.nick,
    gameGroup: account.gameGroup?.name,
    proxy: account.proxy?.name,
    behaviour: account.behaviour?.name,
    schedule: account.schedule?.name,
    gameType: account.gameType?.name,
    aiProfile: account.aiProfile?.name,
    aiProfileOb: account.aiProfileOb?.name,
    timingProfile: account.timingProfile?.name,
    timingProfileOb: account.timingProfileOb?.name,
    insuranceProfile: account.insuranceProfile?.name,
    insuranceProfileOb: account.insuranceProfileOb?.name
  };
}

export const mapAccountsToSessionsTableDisplayable = (
  roomName: string,
  accounts: IAccountRegularResponse[],
  tesMap: Map<string, IManageableServiceInstanceRegularResponse> | undefined,
  gsMap: Map<string, IManageableServiceInstanceRegularResponse> | undefined
) => {
  return (accounts || [])
    .filter(item => !!item.session)
    .map(item => {
      const session = item.session as IAccountSessionRegularResponse;

      return mapSessionToSessionTableDisplayable(
        roomName,
        item.id,
        session,
        session.tes !== undefined ? tesMap?.get(session.tes) : undefined,
        session.gs !== undefined ? gsMap?.get(session.gs) : undefined
      )
    });
}

export const mapSessionToSessionTableDisplayable = (
  roomName: string,
  accountId: string,
  session: IAccountSessionRegularResponse,
  tes: IManageableServiceInstanceRegularResponse | undefined,
  gs: IManageableServiceInstanceRegularResponse | undefined
) : IAccountSessionTableDisplayable => {
  // session.statistic[session.aid]
  const aidStatistic = getAidStatistic(roomName, session);

  const statisticOfGameType = (aidStatistic && session.gameInfo?.type)
    ? aidStatistic.get(getStatisticGameTypeKey(roomName, session.gameInfo.type))
    : undefined;

  return {
    id: session.id,
    sessionType: session.sessionType,
    accountId: accountId,
    username: session.username,
    pid: session.pid,
    nick: session.nick,
    clubs: session.clubs,
    cid: session.cid,
    aid: session.aid,
    tid: session.gameInfo?.tid,
    tableName: session.gameInfo?.name,
    gameType: session.gameInfo?.type,
    tableLimit: session.gameInfo?.limit,
    tableHands: session.gameInfo?.hands,
    tableResult: session.gameInfo?.result,
    proxy: session.proxy,
    schedule: session.schedule,
    gs: session.gs,
    gsVersion: gs?.version,
    gsMode: gs?.exploitationStage ? mapExploitationStageToStringName(gs.exploitationStage) : undefined,
    tes: session.tes,
    tesVersion: tes?.version,
    tesMode: tes?.exploitationStage ? mapExploitationStageToStringName(tes.exploitationStage) : undefined,
    vpip: statisticOfGameType?.vpip,
    pfr: statisticOfGameType?.pfr,
    hands: statisticOfGameType?.hands,
  };
}

export const getAidStatisticRG = (roomName: string, session: SysAccountSessionRegularDto) => {
  if(roomName === ROOM_NAMES.PW_ECO)
    return session.statistic?.["0"];

  if(session.aid && session.aid.trim())
    return session.statistic?.[session.aid];

  return undefined;
}

export const getStatisticGameTypeKeyRG = (roomName: string, gameType: string | undefined): string | undefined => {
  if (gameType === undefined) return undefined;
  const key = gameTypeMapping.get(roomName)?.get(gameType);
  return key ?? gameType;
}

export const getAidStatistic = (roomName: string, session: IAccountSessionRegularResponse) => {
  if(roomName === ROOM_NAMES.PW_ECO)
    return session.statistic?.get("0");

  if(session.aid && session.aid.trim())
    return session.statistic?.get(session.aid);

  return undefined;
}

export const getStatisticGameTypeKey = (roomName: string, gameType: string): string => {
  const key = gameTypeMapping.get(roomName)?.get(gameType);
  return key ?? gameType;
}

export const getGameTypeStat = (
  {
    roomName,
    session
  } : {
    roomName: string;
    session: SysAccountSessionRegularDto;
  }
): SysAccountSessionGameTypeDto | undefined => {
  const aidStatisticRG = getAidStatisticRG(roomName, session);
  const gameTypeKey = getStatisticGameTypeKeyRG(roomName, session.game_info?.type);
  let gameTypeStat : SysAccountSessionGameTypeDto | undefined;

  if (gameTypeKey) {
    gameTypeStat = aidStatisticRG?.[gameTypeKey]
  }

  return gameTypeStat
}

export const renderClubsCell = (accountData: object | undefined) => {
  const clubs = (accountData as NilObj)?.clubs;

  return clubs ? (
    Object.keys(clubs).map((key) => {
      const club = clubs[key];

      return club && Array.isArray(club) ? (
        <div style={{ display: "block" }} key={key}>
          {key}: {club.join(", ")}
        </div>
      ) : ( "-" )
    })
  ) : ( "-" )
}

export const assembleSessionsDefaultFilters = (
  {sessionId} : {sessionId: string | null}
): MRT_ColumnFiltersState => {
  return sessionId
    ? [{ id: "$.username", value: sessionId }]
    : []
}


export const assembleSessionFilterFns = (
  {
    columns,
    sessionId
  } : {
    columns:  MRT_ColumnDef<SysAccountSessionRegularDto>[],
    sessionId: string | null
  }
): MRT_ColumnFilterFnsState => {
  return Object.fromEntries(columns.map(({ id }) => {
    if(sessionId && id === "$.username") {
      return [ id, "equals"]
    }

    return [ id, 'contains' ]
  }))
}

export const assembleRegularAccountsFiltersFns = (
  {
    columns,
    searchParams
  } : {
    columns:  MRT_ColumnDef<SysAccountRegularDto | SysCfgRegularDto>[],
    searchParams : SearchParams
  }
): MRT_ColumnFilterFnsState => {
  const list = Object.entries(searchParams);

  return Object.fromEntries(columns.map(({ id, filterFn }) => {
    const fn = filterFn ? filterFn : "contains";

    if(list.length > 0 ) {
      const a = list.find(el => el[0] === id)
      return a ?  [ id, "equals"] : [id, fn]
    }
    return [ id, fn ]
  }))
}

export const assembleFiltersFns = (
  {
    columns,
    searchParams
  } : {
    columns:  MRT_ColumnDef<ResultStatsDto>[],
    searchParams : SearchParams
  }
): MRT_ColumnFilterFnsState => {
  const list = Object.entries(searchParams);

  return Object.fromEntries(columns.map(({ id }) => {
    if(list.length > 0 ) {
      const a = list.find(el => el[0] === id)
      return a ?  [ id, "equals"] : [id, 'contains']
    }
    return [ id, 'contains' ]
  }))
}

export const getTabTypeBySessionType = (sessionType?: number): AccountType => {
  if(sessionType === AccountSessionType.GAME) return AccountType.gameSessions;
  if(sessionType === AccountSessionType.DEBUG) return AccountType.debugSessions;
  return AccountType.serviceSessions;
}

export const getTabNumberBySessionType = (sessionType?: number): number => {
  if(sessionType === AccountSessionType.GAME) return 2;
  if(sessionType === AccountSessionType.DEBUG) return 3;
  return 4;
}

export const resetSessionIdQueryFilterIfNeeded = (
  {
    sessionId,
    columnFilters,
    columnFilterFns,
    deleteParam
  } : {
    sessionId: string | null
    columnFilters: MRT_ColumnFiltersState;
    columnFilterFns: MRT_ColumnFilterFnsState;
    deleteParam: () => void;
  }
) => {
  if(sessionId === null) return

  const usernameFilter = columnFilters.find(item => item.id === "$.username");
  const columnFilterFn = columnFilterFns["$.username"];

  let needReset = false;

  if(usernameFilter?.value !== sessionId) needReset = true;
  if(!needReset && columnFilterFn !== "equals") needReset = true;

  if (needReset) deleteParam();
}

export const mapSysAccountToEditForm = (account?: SysAccountRegularDto): SysAccountEditForm => {
  const resolver = propertyResolver(account?.data);
  return {
    name: account?.name ?? "",
    authGroups: account?.auth_groups ? Array.from(account.auth_groups) : [],
    password: resolver.string("password", ""),
    walletCode: resolver.string("wallet_code", ""),
    source: resolver.string("source", ""),
    cid: resolver.string("cid", ""),
    accountType: resolver.number("account_type", ACC_TP.REGULAR),
    comment: resolver.string("comment", null),
    osType: resolver.number("device.os_type", ACC_OS_TP.ANY),
    proxy: resolver.string("proxy", null),
    gameGroup: resolver.string("game_group", null),
    behaviour: resolver.string("behaviour", null),
    schedule: resolver.string("schedule", null),
    gameType: resolver.string("game_type", null),
    aiProfile: resolver.string("ai_profile", null),
    aiProfileOb: resolver.string("ai_profile_ob", null),
    timingProfile: resolver.string("timing_profile", null),
    timingProfileOb: resolver.string("timing_profile_ob", null),
    insuranceProfile: resolver.string("insurance_profile", null),
    insuranceProfileOb: resolver.string("insurance_profile_ob", null)
  }
}

export function convertEditFormToUpdateDto(form: SysAccountEditForm): SysAccountUpdateDto {

  return {
    authGroups: new Set(form.authGroups),
    password: form.password,
    walletCode: form.walletCode,
    source: form.source ?? undefined,
    cid: form.cid ?? undefined,
    accountType: form.accountType,
    comment: form.comment ?? undefined,
    osType: form.osType,
    proxy: form.proxy ?? undefined,
    gameGroup: form.gameGroup ?? undefined,
    behaviour: form.behaviour ?? undefined,
    schedule: form.schedule ?? undefined,
    gameType: form.gameType ?? undefined,
    aiProfile: form.aiProfile ?? undefined,
    aiProfileOb: form.aiProfileOb ?? undefined,
    timingProfile: form.timingProfile ?? undefined,
    timingProfileOb: form.timingProfileOb ?? undefined,
    insuranceProfile: form.insuranceProfile ?? undefined,
    insuranceProfileOb: form.insuranceProfileOb ?? undefined
  }
}
