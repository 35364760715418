import { useCallback, useMemo } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { ME_HOOK_QUERY_KEY, useLogoutMutation } from "@/api/hooks/auth-hooks";
import { useAtom } from "jotai/index";
import { meStore } from "@/store/store";
import { ROOM_NAMES, SUPER_ADMIN_NAME } from "@/lib/constants";
import { ConfigDto } from "@/shared/api";
import { isOwner, isSuperAdmin } from "@/lib/auth-predicates";


export const useLogout = () => {
  const queryClient = useQueryClient();
  const mutation = useLogoutMutation();
  const [, setMe] = useAtom(meStore);

  return useCallback(() => {
    mutation.mutate(undefined, {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [ME_HOOK_QUERY_KEY]
        })
        setMe(undefined)
      }
    })
  }, [mutation, queryClient]);
}

export const useIsSuperAdmin = () => {
  const [me] = useAtom(meStore);
  return isSuperAdmin(me)
}

export const useIsOwnerOrSuperAdmin = () => {
  const [me] = useAtom(meStore);
  return isOwner(me)
}

export const useCfgPerm = (
  {
    roomName,
    cfgName,
  } : {
    roomName: string;
    cfgName: string;
  }
) => {
  const [me] = useAtom(meStore);

  return useMemo(() => {
    if(me?.username === SUPER_ADMIN_NAME || me?.is_owner) {
      return { read: true, write: true, delete: true, execute: true, exclusive: true }
    }

    let configs: ConfigDto[] | undefined;

    if(roomName === ROOM_NAMES.SERVICES) {
      configs = me?.service.configs
    } else {
      configs = me?.rooms.find(rm => rm.room_name === roomName)?.configs
    }

    const config = configs?.find(item => item.name === cfgName);

    return {
      read: config?.read ?? false,
      write: config?.write ?? false,
      delete: config?.delete ?? false,
      execute: config?.execute ?? false,
      exclusive: config?.exclusive ?? false,
    }
  }, [me])
}

export const useLoginRedirect = () => {
  return useCallback(() => {
    const redirectUri = window.location.href;
    window.location.href = `${window.location.origin}/auth/?redirect_uri=${encodeURIComponent(redirectUri)}`;
  }, []);
}
