import { useState } from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import UserRoomAccessForm from "@/components/user-room-access-form";
import { RoomDto } from "@/shared/api";
import { ColumnName } from "@/components/user-configs-form";


const UserRoomsAccessForm = (
  {
    name = "",
    rooms,
    onSelectColumn,
    onClearColumn,
    onSelectRow,
    onClearRow,
    onSelectAll,
    onClearAll
  } : {
    name?: string;
    rooms: RoomDto[];
    onSelectColumn?: (roomName: string, column: ColumnName) => void;
    onClearColumn?: (roomName: string, column: ColumnName) => void;
    onSelectRow?: (roomName: string, configName: string) => void;
    onClearRow?: (roomName: string, configName: string) => void;
    onSelectAll?: (roomName: string) => void;
    onClearAll?: (roomName: string) => void;
  }
) => {
  const [selectedRoom, setSelectedRoom] = useState<string>("");

  return (
    <>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <FormControl style={{ width: "100%", maxWidth: "320px" }} variant="standard">
            <InputLabel id="select_room_label">Room</InputLabel>
            <Select
              labelId="select_room_label"
              id="select_room"
              onChange={(e) => setSelectedRoom(e.target.value)}
              value={selectedRoom}
            >
              {rooms.map((room) => (
                <MenuItem key={room.room_name} value={room.room_name}>
                  {room.room_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          {rooms.map((item, index) => (
            <div
              key={item.room_name}
              // style={{display: selectedRoom === item.room_name ? "block" : "none"}}
            >
              {selectedRoom === item.room_name && (
                <UserRoomAccessForm
                  key={item.room_name}
                  room={item}
                  name={`${name}.${index}`}
                  onSelectColumn={clm => onSelectColumn && onSelectColumn(item.room_name, clm) }
                  onClearColumn={clm => onClearColumn && onClearColumn(item.room_name, clm) }
                  onSelectRow={cfg => onSelectRow && onSelectRow(item.room_name, cfg)}
                  onClearRow={cfg => onClearRow && onClearRow(item.room_name, cfg)}
                  onSelectAll={() => onSelectAll && onSelectAll(item.room_name)}
                  onClearAll={() => onClearAll && onClearAll(item.room_name)}
                />
              )}
            </div>
          ))}
        </Grid>
      </Grid>
    </>
  )
}

export default UserRoomsAccessForm;