import { UseQueryOptions, keepPreviousData, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import {
  RoomTablesCacheFetchRoomInfoResponseDto,
  RoomTablesCacheFetchRoomListResponseDto,
} from "@/shared/api";
import { useApi } from "@/shared/api/use-api";

export const useRoomInfoQuery = (
  room: string | null,
  queryOptions?: Omit<
    UseQueryOptions<AxiosResponse<RoomTablesCacheFetchRoomInfoResponseDto>>,
    "queryKey"
  >
) => {
  const { roomTablesCacheControllerApi } = useApi();
  return useQuery({
    queryKey: [`/rooms-tables-cache/fetch-room-info`, room],
    queryFn: ({ signal }) => {
      return roomTablesCacheControllerApi.roomInfo(room as string, {}, { signal });
    },
    placeholderData: keepPreviousData,
    ...queryOptions,
  });
};

export const useRoomsListQuery = (
  queryOptions?: Omit<
    UseQueryOptions<AxiosResponse<RoomTablesCacheFetchRoomListResponseDto>>,
    "queryKey"
  >
) => {
  const { roomTablesCacheControllerApi } = useApi();
  return useQuery({
    queryKey: [`/rooms-tables-cache/fetch-room-list`],
    queryFn: ({ signal }) => {
      return roomTablesCacheControllerApi.roomList({ signal });
    },
    placeholderData: keepPreviousData,
    ...queryOptions,
  });
};
