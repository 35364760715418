import { useSearch } from "@tanstack/react-router";
import {
  MRT_ColumnFilterFnsState,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  MaterialReactTable,
} from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";

import { useSysAccountsQuery } from "@/api/hooks/sys-account-hooks";
import { ACC_TP } from "@/components/config-modals/router-edit-modal/constants";
import { assembleRegularAccountsFiltersFns } from "@/lib/accountsUtils";
import { assembleKeyFilters, assembleSort } from "@/lib/cfg-utils";
import { assembleSearchParamsFilters } from "@/lib/common-utils";
import { ACCOUNT_GAME_STATS_COLUMN_NAMES, COMMON_REFETCH_INTERVAL } from "@/lib/constants";
import { useOrderedColumns } from "@/lib/util-hooks";
import AccountsTopToolbar from "@/module/accounts-module/components/accounts-top-toolbar";
import { useAccountsTable } from "@/module/accounts-module/hooks/accounts-table-hook";
import { useRegularAccountsTableColumns } from "@/module/accounts-module/regular-accounts-table/regular-accounts-table-columns";
import { KeyFilter, KeySort } from "@/shared/api";
import { SearchParams } from "@/types/commonTypes";

const requiredFilters: KeyFilter[] = [
  { key: "data.account_type", mode: "EQUALS", value: ACC_TP.REGULAR as unknown as object },
];

const RegularAccountsTable = ({
  roomName,
  isOpen = true,
}: {
  roomName: string;
  isOpen?: boolean;
}) => {
  const tableColumns = useRegularAccountsTableColumns({ room: roomName });
  const columns = useOrderedColumns(tableColumns);

  const searchParams: SearchParams = useSearch({ strict: false });

  const [sorting, setSorting] = useState<MRT_SortingState>([{ id: "created_at", desc: true }]);

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(() =>
    assembleSearchParamsFilters(searchParams)
  );

  const [columnFilterFns, setColumnFilterFns] = useState<MRT_ColumnFilterFnsState>(
    assembleRegularAccountsFiltersFns({ columns, searchParams: searchParams })
  );

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 30,
  });

  const filters = useMemo<Set<KeyFilter>>(
    () => assembleKeyFilters(columnFilters, columnFilterFns, requiredFilters),
    [columnFilters, columnFilterFns]
  );

  const sort = useMemo<KeySort[]>(() => assembleSort(sorting), [sorting]);

  const {
    data: sysAccountsResponse,
    isError: isSysAccountsError,
    isLoading: isSysAccountsLoading,
    isRefetching: isSysAccountsRefetching,
    refetch: refetchSysAccounts,
  } = useSysAccountsQuery(
    {
      domain: roomName,
      filters: filters,
      pagination,
      sort: sort,
    },
    {
      enabled: isOpen,
      refetchInterval: COMMON_REFETCH_INTERVAL,
    }
  );

  const table = useAccountsTable({
    columns: columns,
    data: sysAccountsResponse?.data.content ?? [],
    isDataLoading: isSysAccountsLoading,
    isDataRefetching: isSysAccountsRefetching,
    isDataLoadingError: isSysAccountsError,
    loadingErrorMessage: sysAccountsResponse?.statusText,
    totalElements: sysAccountsResponse?.data.totalElements,
    totalPages: sysAccountsResponse?.data.totalPages,
    pagination: pagination,
    setPagination: setPagination,
    columnFilters: columnFilters,
    setColumnFilters: setColumnFilters,
    columnFilterFns: columnFilterFns,
    setColumnFilterFns: setColumnFilterFns,
    sorting: sorting,
    setSorting: setSorting,
    columnVisibility: ACCOUNT_GAME_STATS_COLUMN_NAMES.reduce(
      (prev, cur) => ({ ...prev, [cur]: false }),
      {}
    ),
    renderToolbarCustomActions: ({ table }) => (
      <AccountsTopToolbar
        table={table}
        roomName={roomName}
        filters={filters}
        refetchAccounts={() => refetchSysAccounts()}
        totalElementsCount={sysAccountsResponse?.data.totalElements ?? 0}
      />
    ),
  });

  useEffect(() => {
    table.resetRowSelection();
  }, [table, columnFilters, sorting, pagination]);

  return <MaterialReactTable table={table} />;
};

export default RegularAccountsTable;
