import { Button } from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";
import { useState } from "react";
import CreateDomainModal from "@/module/rooms-management-module/components/create-domain-modal";
import { useSysDomainCreateMutation } from "@/api/hooks/sys-domain-hooks";
import { CreateSysDomainDto } from "@/shared/api";
import { enqueueSnackbar } from "notistack";


const CreateDomainTableControl = ({
  onSuccess
} : {
  onSuccess?: () => void
}) => {

  const [createDomain, setCreateDomain] = useState<boolean>(false);
  const mutation = useSysDomainCreateMutation();

  const handleSave = (dto: CreateSysDomainDto) => {
    mutation.mutate({
      name: dto.name,
      isRoom: dto.is_room,
    }, {
      onSuccess: () => {
        enqueueSnackbar("Domain successfully added", { variant: "success" });
        setCreateDomain(false)
        onSuccess && onSuccess()
      },
      onError: (err) => {
        enqueueSnackbar("Operation failure, contact an administrator", { variant: "error" })
        console.error("Add domain failure:", err);
      }
    })
  }


  return (
    <>
      <Button
        variant="text"
        color="primary"
        size="small"
        startIcon={<AddCircleOutline width={24}/>}
        onClick={() => setCreateDomain(true)}
      >New domain</Button>
      <CreateDomainModal
        open={createDomain}
        onClose={() => setCreateDomain(false)}
        onSave={dto => handleSave(dto)}
      />
    </>
  )
}


export default CreateDomainTableControl