import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";

import { Route } from "@/routes/service-config/$configName"
import { useCfgPerm } from "@/auth/hooks";
import { ROOM_CONFIGS_NAMES, ROOM_NAMES } from "@/lib/constants";
import { ReportChatTable } from "@/module/service-configs-module/players-reporter-module/chats/report-chat-table";
import { ReportRuleTable } from "@/module/service-configs-module/players-reporter-module/rules/report-rule-table";

const PlayersReporterModule = () => {
  const navigate = Route.useNavigate();
  const {playersReporterTab: tabValue} = Route.useSearch();

  const chatsPerms = useCfgPerm({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.REPORT_CHAT,
  });

  const rulesPerm = useCfgPerm({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.REPORT_RULE,
  });

  const setTabValue = (value: number) => {
    void navigate({ search: { playersReporterTab: value } })
  }

  const [chatsAmount, setChatsAmount] = useState<number>(0);
  const [rulesAmount, setRulesAmount] = useState<number>(0);

  return (
    <>
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs onChange={(_e, value) => setTabValue(value)} value={tabValue}>
            {chatsPerms.read && (
              <Tab label={`Chats (${chatsAmount})`} value={0}/>
            )}
            {rulesPerm.read && (
              <Tab label={`Rules (${rulesAmount})`} value={1}/>
            )}
          </Tabs>
        </Box>

        <div style={{ height: "100%" }}>
          {tabValue === 0 && chatsPerms.read && (
            <div hidden={tabValue !== 0} style={{ height: "100%" }}>
              <ReportChatTable setRowsAmount={(amount: number) => setChatsAmount(amount)} />
            </div>
          )}

          {tabValue === 1 && rulesPerm.read && (
            <div hidden={tabValue !== 1} style={{ height: "100%" }}>
              <ReportRuleTable setRowsAmount={(amount: number) => setRulesAmount(amount)} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PlayersReporterModule;