import { Box, Tab, Tabs } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useRoomInfoQuery, useRoomsListQuery } from "@/api/hooks/table-cache-hooks";
import { useCfgPerm } from "@/auth/hooks";
import { COMMON_REFETCH_INTERVAL, ROOM_CONFIGS_NAMES, ROOM_NAMES } from "@/lib/constants";
import PlayersTable from "@/module/service-configs-module/table-cache-module/players-table";
import { PlayerInfo } from "@/module/service-configs-module/table-cache-module/players-table-columns";
import TableCacheTable from "@/module/service-configs-module/table-cache-module/table-cache-table";
import { Route } from "@/routes/service-config/$configName";

const TableCacheModule = () => {
  const { t } = useTranslation("service-configs");
  const navigate = Route.useNavigate();
  const { tablesCacheTab: tabValue } = Route.useSearch();

  const roomTablesPerms = useCfgPerm({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.ROOM_TABLES_CACHE_ROOM_TABLES,
  });

  const roomInfoPerm = useCfgPerm({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.ROOM_TABLES_CACHE_ROOM_INFO,
  });

  const [tables, setTables] = useState<number>(0);

  const setTabValue = (value: number) => {
    void navigate({ search: { tablesCacheTab: value } });
  };

  const [selectedRoom, setSelectedRoom] = useState<string | null>(null);

  const tableQuery = useRoomInfoQuery(selectedRoom, {
    enabled: !!selectedRoom,
    refetchInterval: COMMON_REFETCH_INTERVAL,
  });

  const roomsQuery = useRoomsListQuery();

  const tablesList = useMemo(() => {
    return tableQuery.data?.data.tables || [];
  }, [tableQuery.data]);

  const playersList = useMemo(() => {
    if (tablesList) {
      const result: PlayerInfo[] = [];
      tablesList.forEach((table) => {
        const extendedPlayers = table.players.map((player) => {
          return {
            ...player,
            tid: table.tid,
            isObserver: !!table.observers.find((obs) => obs.pid === player.base?.pid),
          };
        });
        result.push(...extendedPlayers);
      });

      return result;
    }

    return [];
  }, [tablesList]);

  return (
    <>
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs onChange={(_e, value) => setTabValue(value)} value={tabValue}>
            {roomTablesPerms.read && (
              <Tab label={t("tablesCount", { count: tables })} value={0} />
            )}
            {roomInfoPerm.read && (
              <Tab label={t("playersCount", { count: playersList.length })} value={1} />
            )}
          </Tabs>
        </Box>

        <div style={{ height: "100%" }}>
          {tabValue === 0 && roomTablesPerms.read && (
            <div hidden={tabValue !== 0} style={{ height: "100%" }}>
              <TableCacheTable
                setRowsAmount={(amount: number) => setTables(amount)}
                selectedRoom={selectedRoom}
                setSelectedRoom={setSelectedRoom}
                tablesQuery={tableQuery}
                roomsQuery={roomsQuery}
              />
            </div>
          )}

          {tabValue === 1 && roomInfoPerm.read && (
            <div hidden={tabValue !== 1} style={{ height: "100%" }}>
              <PlayersTable
                selectedRoom={selectedRoom}
                setSelectedRoom={setSelectedRoom}
                tablesQuery={tableQuery}
                roomsQuery={roomsQuery}
                playersList={playersList}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TableCacheModule;
