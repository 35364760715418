import { Grid } from "@mui/material";
import { keepPreviousData } from "@tanstack/react-query";
import { useMemo } from "react";

import { useGetUserByIdQuery } from "@/api/hooks/user-hooks";
import ACenteredSpinner from "@/components/ACenteredSpinner";
import UserFrom from "@/module/user-form-module";
import { UserResponseRegularDto } from "@/shared/api";

const UserPage = ({ userId }: { userId: string }) => {
  const {
    data: userData,
    isLoading: userIsLoading,
    isError: userIsError,
    error: userError,
    refetch,
  } = useGetUserByIdQuery(
    {
      userId: Number(userId) as number,
    },
    {
      enabled: Boolean(userId),
      placeholderData: keepPreviousData,
      refetchOnWindowFocus: false,
    }
  );

  const user = useMemo<UserResponseRegularDto | undefined>(() => {
    return userData ? { ...userData.data } : undefined;
  }, [userData]);

  if (userIsLoading) {
    return <ACenteredSpinner />;
  }

  if (userIsError) {
    return (
      <p>
        Something went wrong while getting user data from the server:{" "}
        {userError?.message ?? "Unknown error"}
      </p>
    );
  }

  if (!user) {
    return <p>There is no user with id {userId}</p>;
  }

  return (
    <>
      <Grid container spacing={2} height="100%" direction="column" flexWrap="nowrap">
        <Grid item xs={10}>
          <UserFrom user={user} onUpdated={() => refetch()} />
        </Grid>
      </Grid>
    </>
  );
};

export default UserPage;
