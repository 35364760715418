import { createFileRoute } from '@tanstack/react-router'
import ErrorPage from "@/pages/error-page";
import { z } from "zod"

const schema = z.object({
  errorMessage: z.string().optional(),
});


export const Route = createFileRoute('/error/')({
  component: Component,
  validateSearch: opts => schema.parse(opts)
})


function Component() {
  const { errorMessage } = Route.useSearch();
  return <ErrorPage errorMessage={errorMessage} />
}
