import { Box, Button, CircularProgress, Tab, Tabs } from "@mui/material";
import { Navigate } from "@tanstack/react-router";
import { Form, Select, Typography } from "antd";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { FormContainer, SwitchElement, useForm } from "react-hook-form-mui";

import { useAuthGroupsQuery } from "@/api/hooks/auth-groups-hooks";
import { useSysCfgQuery } from "@/api/hooks/sys-cfg-hooks";
import { useSysDomainsQuery } from "@/api/hooks/sys-domain-hooks";
import { useUpdateUserMutation } from "@/api/hooks/user-hooks";
import { ColumnName } from "@/components/user-configs-form";
import UserRoomAccessForm from "@/components/user-room-access-form";
import UserRoomsAccessForm from "@/components/user-rooms-access-form";
import {
  mapCommonRoomsToRoomDto,
  mapPlayhubRoomsToRoomDto,
  mapServiceRoomToRoomDto,
} from "@/lib/common-utils";
import { ROOM_CONFIGS_NAMES, ROOM_NAMES } from "@/lib/constants";
import {
  ConfigDto,
  RoomDto,
  UpdateUserRequestDto,
  UserResponseRegularDto,
} from "@/shared/api";

const getToggledCfg = (configName: string, value: boolean): ConfigDto => ({
  name: configName,
  read: value,
  write: value,
  delete: value,
  execute: value,
  exclusive: value,
  disable_auth_filters: value,
});

const UserForm = ({
  user,
  onUpdated,
}: {
  user: UserResponseRegularDto;
  onUpdated?: () => void;
}) => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const { data: authGroups } = useAuthGroupsQuery({
    refetchOnWindowFocus: false,
  });

  const updateUserMutation = useUpdateUserMutation();

  const {
    data: domains,
    isLoading: domainsIsLoading,
    isError: domainsIsError,
    error: domainsError,
  } = useSysDomainsQuery();

  const { data: playhubRoomsRs } = useSysCfgQuery({
    domain: ROOM_NAMES.SERVICES,
    config_name: ROOM_CONFIGS_NAMES.PLAYHUB_ROOM,
  });

  const playhubRoomsData = useMemo(
    () => playhubRoomsRs?.data?.content ?? [],
    [playhubRoomsRs]
  );

  const [usualRooms, setUsualRooms] = useState(
    mapCommonRoomsToRoomDto(domains?.data ?? [], user)
  );

  useEffect(() => {
    setUsualRooms(mapCommonRoomsToRoomDto(domains?.data ?? [], user));
  }, [user, domains]);

  const usualRoomsToggleColumn = (roomName: string, name: ColumnName, value: boolean) => {
    const rooms: RoomDto[] = usualRooms.map((room) => {
      return room.room_name === roomName
        ? {
            room_name: room.room_name,
            configs: room.configs.map((item) => ({ ...item, [name]: value })),
          }
        : room;
    });

    setUsualRooms(rooms);
  };

  const roomsToggleRow = (rn: string, cfg: string, val: boolean) => {
    const rooms: RoomDto[] = usualRooms.map((room) => {
      return room.room_name === rn
        ? {
            room_name: room.room_name,
            configs: room.configs.map((it) =>
              it.name === cfg ? getToggledCfg(it.name, val) : it
            ),
          }
        : room;
    });

    setUsualRooms(rooms);
  };

  const roomsToggleAll = (rn: string, val: boolean) => {
    const rooms: RoomDto[] = usualRooms.map((room) => {
      return room.room_name === rn
        ? {
            room_name: room.room_name,
            configs: room.configs.map((it) => getToggledCfg(it.name, val)),
          }
        : room;
    });

    setUsualRooms(rooms);
  };

  const [serviceRoom, setServiceRoom] = useState(
    mapServiceRoomToRoomDto(domains?.data ?? [], user)
  );

  useEffect(() => {
    setServiceRoom(mapServiceRoomToRoomDto(domains?.data ?? [], user));
  }, [user, domains]);

  const serviceToggleColumn = (name: ColumnName, value: boolean) => {
    setServiceRoom((prevState) => ({
      room_name: prevState.room_name,
      configs: prevState.configs.map((item) => ({ ...item, [name]: value })),
    }));
  };

  const serviceToggleRow = (configName: string, value: boolean) => {
    setServiceRoom({
      room_name: serviceRoom.room_name,
      configs: serviceRoom.configs.map((item) =>
        item.name === configName ? getToggledCfg(item.name, value) : item
      ),
    });
  };

  const serviceToggleAll = (value: boolean) => {
    setServiceRoom({
      room_name: serviceRoom.room_name,
      configs: serviceRoom.configs.map((item) => getToggledCfg(item.name, value)),
    });
  };

  const [playhubRooms, setPlayhubRooms] = useState(
    mapPlayhubRoomsToRoomDto(playhubRoomsData, user)
  );

  useEffect(() => {
    setPlayhubRooms(mapPlayhubRoomsToRoomDto(playhubRoomsData ?? [], user));
  }, [playhubRoomsData, user]);

  const playhubRoomsToggleColumn = (roomName: string, name: ColumnName, value: boolean) => {
    const rooms: RoomDto[] = playhubRooms.map((room) => {
      return room.room_name === roomName
        ? {
            room_name: room.room_name,
            configs: room.configs.map((item) => ({ ...item, [name]: value })),
          }
        : room;
    });

    setPlayhubRooms(rooms);
  };

  const playhubToggleRow = (rn: string, cfg: string, val: boolean) => {
    const rooms: RoomDto[] = playhubRooms.map((room) => {
      return room.room_name === rn
        ? {
            room_name: room.room_name,
            configs: room.configs.map((it) =>
              it.name === cfg ? getToggledCfg(it.name, val) : it
            ),
          }
        : room;
    });

    setPlayhubRooms(rooms);
  };

  const playhubToggleAll = (rn: string, val: boolean) => {
    const rooms: RoomDto[] = playhubRooms.map((room) => {
      return room.room_name === rn
        ? {
            room_name: room.room_name,
            configs: room.configs.map((it) => getToggledCfg(it.name, val)),
          }
        : room;
    });

    setPlayhubRooms(rooms);
  };

  const authGroupsOptions = useMemo(
    () => authGroups?.data?.map((item) => ({ value: item.name, label: item.name })) ?? [],
    [authGroups]
  );

  const formContext = useForm<UpdateUserRequestDto>({
    defaultValues: {
      id: user.id,
      enabled: user.enabled,
      is_owner: user.is_owner,
      group: [...user.group],
      rooms: [...usualRooms],
      service: { ...serviceRoom },
      playhub: [...playhubRooms],
    },
  });

  const isOwner = formContext.watch("is_owner");
  const groups = formContext.watch("group");

  useEffect(() => {
    formContext.setValue("id", user.id);
    formContext.setValue("enabled", user.enabled);
    formContext.setValue("is_owner", user.is_owner);
    formContext.setValue("group", Array.from(user.group));
  }, [formContext, user]);

  useEffect(() => {
    formContext.setValue("rooms", usualRooms);
  }, [formContext, usualRooms]);

  useEffect(() => {
    formContext.setValue("service", serviceRoom);
  }, [formContext, serviceRoom]);

  useEffect(() => {
    formContext.setValue("playhub", playhubRooms);
  }, [formContext, playhubRooms]);

  function handleSave(data: UpdateUserRequestDto) {
    updateUserMutation.mutate(
      {
        updateUser: data,
      },
      {
        onSuccess: () => {
          enqueueSnackbar("User data  successfully updated!", { variant: "success" });
          onUpdated && onUpdated();
        },
      }
    );
  }

  if (domainsIsLoading) {
    return <CircularProgress />;
  }

  if (domainsIsError) {
    return (
      <Navigate
        to="/errors/"
        search={{ errorMessage: domainsError.message ?? "Unknown loading error" }}
      />
    );
  }

  if (!domains) {
    return <p>Unknown error: app rooms are not available</p>;
  }

  return (
    <>
      <FormContainer formContext={formContext} onSuccess={(val) => handleSave(val)}>
        <Box
          sx={{
            position: "fixed",
            top: 16,
            right: 16,
            zIndex: 500, // Делаем кнопку поверх всех элементов
          }}
        >
          <Button
            type="submit"
            size="large"
            variant="contained"
            color="primary"
            disabled={updateUserMutation.isPending}
            startIcon={
              updateUserMutation.isPending ? <CircularProgress size={18} /> : undefined
            }
            style={{ width: "100%", maxWidth: "150px", marginRight: "40px" }}
          >
            Save
          </Button>
        </Box>

        <Box
          sx={{ mb: 5, display: "flex" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography.Title level={2} style={{ margin: "10px 0" }}>
            User {user.username}
          </Typography.Title>
        </Box>

        <SwitchElement label="Is owner" name="is_owner" />

        {!isOwner && (
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={currentTab}
                onChange={(_, val) => setCurrentTab(val)}
                aria-label="basic tabs example"
              >
                <Tab label="Details" id="simple-tab-0" aria-controls="simple-tab-panel-0" />
                <Tab
                  label="Room configs access settings"
                  id="simple-tab-1"
                  aria-controls="simple-tab-panel-1"
                />
                <Tab
                  label="Service configs access settings"
                  id="simple-tab-2"
                  aria-controls="simple-tab-panel-2"
                />
                <Tab
                  label="Playhub access settings"
                  id="simple-tab-3"
                  aria-controls="simple-tab-panel-3"
                />
              </Tabs>
            </Box>
            <div
              role="tabpanel"
              hidden={currentTab !== 0}
              id="simple-tabpanel-0"
              aria-labelledby="simple-tab-0"
            >
              {currentTab === 0 && (
                <Box sx={{ py: 3 }}>
                  <Form.Item label="Auth Groups">
                    <Select
                      mode="multiple"
                      style={{ width: "200px" }}
                      placeholder="Auth Groups"
                      options={authGroupsOptions}
                      value={groups}
                      onChange={(val) => formContext.setValue("group", val)}
                    />
                  </Form.Item>
                </Box>
              )}
            </div>
            <div
              role="tabpanel"
              hidden={currentTab !== 1}
              id="simple-tabpanel-1"
              aria-labelledby="simple-tab-1"
            >
              <Box sx={{ py: 3 }}>
                <UserRoomsAccessForm
                  rooms={usualRooms}
                  name="rooms"
                  onSelectColumn={(roomName, column) =>
                    usualRoomsToggleColumn(roomName, column, true)
                  }
                  onClearColumn={(roomName, column) =>
                    usualRoomsToggleColumn(roomName, column, false)
                  }
                  onSelectRow={(roomName, cfg) => roomsToggleRow(roomName, cfg, true)}
                  onClearRow={(roomName, column) => roomsToggleRow(roomName, column, false)}
                  onSelectAll={(roomName) => roomsToggleAll(roomName, true)}
                  onClearAll={(roomName) => roomsToggleAll(roomName, false)}
                />
              </Box>
            </div>

            <div
              role="tabpanel"
              hidden={currentTab !== 2}
              id="simple-tabpanel-2"
              aria-labelledby="simple-tab-2"
            >
              {currentTab === 2 && (
                <Box sx={{ py: 3 }}>
                  <UserRoomAccessForm
                    room={serviceRoom}
                    name="service"
                    onSelectColumn={(column) => serviceToggleColumn(column, true)}
                    onClearColumn={(column) => serviceToggleColumn(column, false)}
                    onSelectRow={(cfg) => serviceToggleRow(cfg, true)}
                    onClearRow={(cfg) => serviceToggleRow(cfg, false)}
                    onSelectAll={() => serviceToggleAll(true)}
                    onClearAll={() => serviceToggleAll(false)}
                  />
                </Box>
              )}
            </div>

            <div
              role="tabpanel"
              hidden={currentTab !== 3}
              id="simple-tabpanel-3"
              aria-labelledby="simple-tab-3"
            >
              <Box sx={{ py: 3 }}>
                <UserRoomsAccessForm
                  rooms={playhubRooms}
                  name="playhub"
                  onSelectColumn={(roomName, column) =>
                    playhubRoomsToggleColumn(roomName, column, true)
                  }
                  onClearColumn={(roomName, column) =>
                    playhubRoomsToggleColumn(roomName, column, false)
                  }
                  onSelectRow={(roomName, cfg) => playhubToggleRow(roomName, cfg, true)}
                  onClearRow={(roomName, column) => playhubToggleRow(roomName, column, false)}
                  onSelectAll={(roomName) => playhubToggleAll(roomName, true)}
                  onClearAll={(roomName) => playhubToggleAll(roomName, false)}
                />
              </Box>
            </div>
          </Box>
        )}
      </FormContainer>
    </>
  );
};

export default UserForm;
