import { createFileRoute } from "@tanstack/react-router";

import RoomConfigPage from "@/pages/room-config-page";
import CommandJournalAccessTemplate from "@/template/command-journal-access-template";

export const Route = createFileRoute("/room-config/$room/$configName")({
  component: () => <Component />,
});

function Component() {
  const { room, configName } = Route.useParams();

  return (
    <CommandJournalAccessTemplate>
      <RoomConfigPage room={room} config={configName} />
    </CommandJournalAccessTemplate>
  );
}
