import { useSysDomainCreateCfgCollection } from "@/api/hooks/sys-domain-hooks";
import { Button } from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import CreateDomainConfigModal from "@/module/room-management-module/components/create-domain-config-modal";


const CreateDomainCfgTableControl = ({
  domain,
  onSuccess
} : {
  domain: string,
  onSuccess?: () => void
}) => {

  const mutation = useSysDomainCreateCfgCollection();

  const [createCfg, setCreateCfg] = useState(false);

  const handleSave = (cfgName: string) => {
    mutation.mutate({
      domain: domain,
      name: cfgName
    }, {
      onSuccess: () => {
        enqueueSnackbar("Config successfully created", { variant: "success" });
        setCreateCfg(false)
        onSuccess && onSuccess()
      },
      onError: (err) => {
        enqueueSnackbar("Operation failure, contact an administrator", { variant: "error" })
        console.error("Create domain config failure:", err);
      }
    })
  }

  return (
    <>
      <Button
        variant="text"
        color="primary"
        size="small"
        startIcon={<AddCircleOutline width={24}/>}
        onClick={() => setCreateCfg(true)}
      >New config</Button>
      <CreateDomainConfigModal
        open={createCfg}
        onClose={() => setCreateCfg(false)}
        onSave={(name) => handleSave(name)}
      />
    </>
  )
}


export default CreateDomainCfgTableControl;