import {
  UseQueryOptions,
  keepPreviousData,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { MRT_PaginationState } from "material-react-table";

import { downloadFile } from "@/lib/common-utils";
import {
  GenerateAccountsDto,
  KeyFilter,
  KeySort,
  PageResponseSysAccountRegularDto,
  QueryDto,
  ResultDtoSysAccountRegularDto,
  SysAccountCreateDto,
  SysAccountRegularDto,
  SysAccountUpdateDto,
} from "@/shared/api";
import { RequiredError } from "@/shared/api/base";
import { useApi } from "@/shared/api/use-api";

export const useSysAccountsQuery = (
  {
    domain,
    filters = new Set<KeyFilter>(),
    sort = [],
    pagination,
  }: {
    domain: string;
    filters?: Set<KeyFilter>;
    sort?: KeySort[];
    pagination?: MRT_PaginationState;
  },
  queryOptions?: Omit<
    UseQueryOptions<AxiosResponse<PageResponseSysAccountRegularDto>>,
    "queryKey"
  >
) => {
  const { sysAccountApi } = useApi();
  return useQuery({
    queryKey: [`/sys-cfg/${domain}/account`, filters, sort, pagination],
    queryFn: ({ signal }) => {
      const query: QueryDto = {
        filters: filters,
        page: pagination
          ? { page_number: pagination.pageIndex, page_size: pagination.pageSize }
          : undefined,
        sort: sort,
      };
      return sysAccountApi.find(domain, query, { signal });
    },
    placeholderData: keepPreviousData,
    ...queryOptions,
  });
};

export const useSysAccountCreateAccountsMutation = () => {
  const { sysAccountApi } = useApi();

  type Options = {
    domain: string;
    accounts: Array<SysAccountCreateDto>;
  };

  return useMutation<AxiosResponse<ResultDtoSysAccountRegularDto[]>, RequiredError, Options>({
    mutationFn: ({ domain, accounts }) => sysAccountApi.createAccounts(domain, accounts),
  });
};

export const useSysAccountGenerateAccountsByNumberMutation = () => {
  const { sysAccountApi } = useApi();

  type Options = {
    domain: string;
    generateAccountsBuNumberDto: GenerateAccountsDto;
  };

  return useMutation<AxiosResponse<SysAccountRegularDto[]>, RequiredError, Options>({
    mutationFn: ({ domain, generateAccountsBuNumberDto }) =>
      sysAccountApi.generateAccountsByNumber(domain, generateAccountsBuNumberDto),
  });
};

export const useSysAccountUpdateMutation = () => {
  const { sysAccountApi } = useApi();
  type Options = {
    domain: string;
    accountName: string;
    account: SysAccountUpdateDto;
  };

  return useMutation<AxiosResponse<void>, RequiredError, Options>({
    mutationFn: ({ domain, accountName, account }: Options) =>
      sysAccountApi.updateAccount(domain, accountName, account),
  });
};

export const useSysAccountsExportCsvMutation = () => {
  const { sysAccountApi } = useApi();
  type Options = {
    domain: string;
    filters?: Set<KeyFilter>;
  };

  const mutationFn = async ({ domain, filters }: Options) => {
    const response = await sysAccountApi.exportCsv(domain, { filters });
    downloadFile(response);
    return response;
  };

  return useMutation<AxiosResponse<string>, RequiredError, Options>({
    mutationFn: mutationFn,
  });
};
