// import { Box, Link } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

// import { supportedLngs } from "@/i18n";

export const LocaleSwitcher: FC = () => {
  const { t, i18n } = useTranslation("locale-switcher");

  return null;

  // return (
  //   <Box display="flex" gap="8px">
  //     {supportedLngs.map((language) => {
  //       if (language === i18n.language) {
  //         return null;
  //       }
  //
  //       const handleLanguageChange = (e: MouseEvent) => {
  //         e.preventDefault();
  //         e.stopPropagation();
  //         i18n.changeLanguage(language);
  //       };
  //
  //       return (
  //         <Link
  //           key={language}
  //           onClick={handleLanguageChange}
  //           sx={{
  //             color: "white.main",
  //             fontWeight: 700,
  //             fontSize: 25,
  //             textDecoration: "none",
  //             "&:hover": {
  //               textDecoration: "underline",
  //             },
  //           }}
  //         >
  //           {t(language, { locale: language })}
  //         </Link>
  //       );
  //     })}
  //   </Box>
  // );
};
