import { Collapse, List, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useMemo, useState } from "react";
import { Link } from '@tanstack/react-router'
import { MenuListItemButton, MenuListSubItemButton } from "@/components/sidebar/styled";


export interface SidebarMenuItemType {
  name: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  link: {
    isDisabled?: boolean;
    to: string,
    params?: {
      [key: string]: string | undefined
    }
  },
  subItemsAccessor?: () => Omit<SidebarMenuItemType, "subItemsAccessor">[];
  isHiddenAccessor?: () => boolean;
}

const SidebarMenuItem = ({
  item,
  sidebarCollapsed
} : {
  item: SidebarMenuItemType;
  sidebarCollapsed: boolean;
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const subItems = useMemo(() => {
    if(!item.subItemsAccessor)
      return undefined;
    const subItems = item.subItemsAccessor();
    return subItems.length > 0 ? subItems : undefined
  }, [item]);

  const handleDropdownOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  }

  if (item.isHiddenAccessor && item.isHiddenAccessor()) {
    return <></>
  }

  return (
    <React.Fragment key={item.name}>

      {/* MENU ITEM ICON AND LABEL BUTTON */}
      <Link
        disabled={!!subItems || item.link?.isDisabled === true}
        to={item.link?.to}
        params={item.link?.params}
        style={{ textDecoration: 'none', color: "inherit"}}
      >
        {(props) => (
          <MenuListItemButton
            selected={props.isActive}
            sx={{
              justifyContent: sidebarCollapsed ? 'center' : 'flex-start',
              px: 2,
            }}
            onClick={() => {
              subItems && !sidebarCollapsed && setIsExpanded(!isExpanded)
              item.onClick && item.onClick();
            }}
          >
            <div
              onMouseOver={(e) => subItems && sidebarCollapsed && handleDropdownOpen(e)}
            >
              {item.icon && (
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: sidebarCollapsed ? 0 : 2,
                    justifyContent: 'center',
                    color: '#ffffff',
                  }}
                >
                  {item.icon}
                </ListItemIcon>
              )}
            </div>

            {!sidebarCollapsed && <ListItemText primary={item.name} primaryTypographyProps={{fontSize: "14px"}}/>}

            {subItems && !sidebarCollapsed ? (
              isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />
            ) : null}
          </MenuListItemButton>
        )}
      </Link>


      {/* SUBMENU IF SIDEBAR IS NOT COLLAPSED */}
      {subItems && !sidebarCollapsed && (
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <List component="div">
            {subItems.map((subItem) => (
              <Link
                key={subItem.name}
                to={subItem.link?.to}
                params={subItem.link?.params}
                style={{ textDecoration: 'none', color: "inherit"}}
                disabled={subItem.link?.isDisabled === true}
              >
                {(props) => (
                  <MenuListSubItemButton
                    sx={{ pl: 4, pr: 2, py: 0.5, minHeight: 32, }}
                    selected={props.isActive}
                    onClick={() => subItem.onClick && subItem.onClick()}
                  >
                    <ListItemText
                      primary={subItem.name.split("_")
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(" ")}
                      primaryTypographyProps={{ fontSize: '13px' }}
                    />
                  </MenuListSubItemButton>
                )}
              </Link>
            ))}
          </List>
        </Collapse>
      )}

      {/* SUBMENU IF SIDEBAR IS COLLAPSED */}
      {subItems && sidebarCollapsed && (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleDropdownClose}
          MenuListProps={{ onMouseLeave: handleDropdownClose }}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          {subItems.map(subItem => (
            <Link
              key={subItem.name}
              to={subItem.link?.to}
              params={subItem.link?.params}
              style={{ textDecoration: 'none', color: "inherit"}}
              disabled={subItem.link?.isDisabled === true}
            >
              {(props) => (
                <MenuItem
                  selected={props.isActive}
                  key={subItem.name}
                  onClick={() => subItem.onClick && subItem.onClick()}
                >{subItem.name.split("_")
                  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
                </MenuItem>
              )}
            </Link>
          ))}
        </Menu>
      )}

    </React.Fragment>
  )
}


export default SidebarMenuItem