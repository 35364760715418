import { Navigate } from "@tanstack/react-router";
import { Layout } from "antd";
import { useAtom } from "jotai/index";
import React, { useEffect } from "react";

import { useMeQuery } from "@/api/hooks/auth-hooks";
import { useSysDomainsQuery } from "@/api/hooks/sys-domain-hooks";
import ACenteredSpinner from "@/components/ACenteredSpinner";
import Sidebar from "@/components/sidebar/sidebar";
import { Route } from "@/routes/__root";
import { appCurrentRoom, meStore } from "@/store/store";

const { Content } = Layout;

const CommonTemplate = ({ children }: { children: React.JSX.Element }) => {
  const { data: meRs } = useMeQuery();

  const {
    data: domainsRs,
    isLoading: domainsIsLoading,
    isError,
    error,
  } = useSysDomainsQuery(false, {
    refetchOnWindowFocus: false,
  });
  const params: { [key: string]: string } = Route.useParams();
  const [currentRoom, setCurrentRoom] = useAtom(appCurrentRoom);
  const [, setMe] = useAtom(meStore);

  useEffect(() => {
    if (meRs?.data) {
      setMe(meRs.data);
    }
  }, [meRs, setMe]);

  useEffect(() => {
    if (currentRoom !== undefined) return;

    const roomParam = params.room;

    if (domainsIsLoading || !domainsRs) return;

    const domains = domainsRs.data;

    if (isError) {
      setCurrentRoom(null);
      return;
    }

    if (!roomParam) {
      if (domains.length === 1) {
        setCurrentRoom(domains[0]);
      } else {
        setCurrentRoom(null);
      }
      return;
    }

    const room = domains.find((it) => it.name === roomParam);

    if (!room) {
      setCurrentRoom(null);
      return;
    }

    setCurrentRoom(room);
  }, [currentRoom, domainsIsLoading, domainsRs, isError, params, setCurrentRoom]);

  return (
    <>
      <Layout hasSider style={{ minHeight: "100vh" }}>
        <Sidebar domains={domainsRs?.data ?? []} dataIsLoading={domainsIsLoading} />
        <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
          {!process.env.REACT_APP_DISABLE_BANNER &&
            ((window.location.port === "3000" && (
              <h2 style={{ position: "fixed", top: "0", left: "800px" }}>THIS IS LOCALHOST</h2>
            )) ||
              (window.location.port === "9443" && (
                <h2 style={{ position: "fixed", top: "0", left: "800px" }}>
                  THIS IS NOT A PRODUCTION VERSION. THIS IS A TEST
                </h2>
              )))}

          {isError ? (
            <Navigate
              to="/error/"
              search={{
                errorMessage: error
                  ? error.message
                  : "unknown error, contact an administrator",
              }}
            />
          ) : currentRoom === undefined ? (
            <ACenteredSpinner />
          ) : (
            children
          )}
        </Content>
      </Layout>
    </>
  );
};

export default CommonTemplate;
