import React, { useState } from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useGenerateNewAccounts } from "@/module/accounts-module/hooks/accounts-mutate-hooks";
import SysAccountsGenerateModal from "@/module/accounts-module/components/sys-accounts-generate-modal";
import { GenerateAccountsDto } from "@/shared/api";


const GenerateAccountsTableControl = (
  {
    roomName,
    onSuccess
  } : {
    roomName: string;
    onSuccess?: () => void
  }
) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const {
    mutate: handleClickGenerateNewAccounts,
    isPending: generatedAccountIsPending
  } = useGenerateNewAccounts({
    roomName: roomName,
    successCallback: () => {
      setIsOpen(false);
      onSuccess && onSuccess();
    }
  });


  return (
    <>
      <Button onClick={() => setIsOpen(true)} >
        <PlusOutlined />
        Generate
      </Button>

      <SysAccountsGenerateModal
        mode="add"
        accountType="regular"
        isOpen={isOpen}
        roomName={roomName}
        onSave={(data: GenerateAccountsDto) => handleClickGenerateNewAccounts({...data})}
        onClose={() => setIsOpen(false)}
        confirmLoading={generatedAccountIsPending}
      />
    </>
  )
}


export default GenerateAccountsTableControl