import { Check } from "@mui/icons-material";
import { HTMLAttributes } from "react";


const CustomSelectOption = ({
  label,
  selected,
  props
} : {
  label: string,
  selected: boolean,
  props: HTMLAttributes<HTMLLIElement>
}) => {

  return (
    <li style={{display: "flex", justifyContent: "space-between"}} {...props}>
      <span> {label} </span>
      <span> {selected && <Check fontSize="small" color="primary"/>} </span>
    </li>
  )
}


export default CustomSelectOption