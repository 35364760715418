import { zodResolver } from "@hookform/resolvers/zod";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-lua";
import "ace-builds/src-noconflict/theme-dracula";
import "ace-builds/webpack-resolver";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useSysCfgRawQuery } from "@/api/hooks/sys-cfg-hooks";
import { EmailDomainForm } from "@/components/config-modals/email-domain-edit-modal/types";
import { UsernameForm } from "@/components/config-modals/username-create-modal/types";
import { ROOM_CONFIGS_NAMES, ROOM_NAMES } from "@/lib/constants";
import { SysCfgRawDto } from "@/shared/api";

export default function UsernameCreateModal({
  open,
  onSave,
  onCancel,
}: {
  open: boolean;
  mode?: string;
  initialData?: EmailDomainForm;
  onSave?: (data: Set<string>) => void;
  onCancel?: () => void;
  confirmLoading?: boolean;
}) {
  const { t } = useTranslation("room-configs");

  const schema = z.object({
    usernames: z
      .string()
      .transform((val) => val.split("\n").map((str) => str.trim()))
      .refine((arr) => arr.every((str) => /^[a-zA-Z0-9]+$/.test(str) && str.length <= 20), {
        message: t("eachUsernameValidation"),
      }),
    domains: z.array(z.string()),
  });

  const { data: emailDomainLiteResponse } = /*useSysCfgQuery*/ useSysCfgRawQuery(
    {
      domain: ROOM_NAMES.SERVICES,
      config_name: ROOM_CONFIGS_NAMES.EMAIL_DOMAIN,
    },
    {
      enabled: open,
    }
  );

  const [emailDomains, setEmailDomains] = useState<SysCfgRawDto[]>([]);
  useEffect(() => {
    setEmailDomains(
      Array.from(new Set(emailDomainLiteResponse?.data?.map((it) => it.name) ?? []))
        .map((it) => ({ name: it }))
        .sort((a, b) => a.name.localeCompare(b.name))
    );
  }, [emailDomainLiteResponse]);

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<UsernameForm>({
    mode: "onTouched",
    resolver: zodResolver(schema),
    defaultValues: { domains: [] },
  });

  useEffect(() => {
    reset();
  }, [open, reset]);

  const onSubmit: SubmitHandler<UsernameForm> = (data) => {
    const usernames =
      data.domains.length > 0
        ? data.usernames.flatMap((username) =>
            data.domains.map((domain) => `${username}@${domain}`)
          )
        : data.usernames;

    if (!onSave) return;
    onSave(new Set(usernames));

    onCancel && onCancel(); //purifying form and close dialog
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <>
      <Dialog open={open} key="email-domain-modal">
        <DialogTitle>
          {t("addUsername")}
          <IconButton
            aria-label="close"
            onClick={() => onCancel && onCancel()}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px" /* Расстояние между элементами */,
              width: "500px" /* Ширина формы */,
            }}
          >
            <Controller
              name="domains"
              control={control}
              render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                <Autocomplete
                  style={{ marginTop: "5px" }}
                  multiple
                  //freeSolo
                  //value={value}
                  //disabled={disabled}
                  disableCloseOnSelect
                  onChange={(event, newValue) => {
                    const newIds = newValue.map((item) => item.name);
                    onChange(newIds);
                    setValue("domains", newIds);
                  }}
                  options={emailDomains ?? []}
                  getOptionLabel={(option) => option.name}
                  value={emailDomains?.filter((option) => value.includes(option.name))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={t("domains")}
                      placeholder={t("addTag")}
                      inputRef={ref}
                      onBlur={onBlur} // Ensure onBlur is called to update the form state
                      error={!!error?.message}
                      helperText={error?.message}
                    />
                  )}
                  renderOption={(props, option, { selected }) => {
                    const { ...optionProps } = props;
                    return (
                      <li {...optionProps}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    );
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip label={option.name} {...getTagProps({ index })} key={index} />
                    ))
                  }
                />
              )}
            />

            <TextFieldElement
              name="usernames"
              control={control}
              label={t("usernames")}
              placeholder={t("writeEachDomainOnNewLine")}
              multiline
              error={!!errors.usernames?.message}
              helperText={errors.usernames?.message}
              minRows={7}
            />

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="button"
                style={{ marginRight: "5px" }}
                onClick={() => onCancel && onCancel()}
              >
                {t("cancel")}
              </Button>
              <Button variant="contained" type="submit">
                {t("submit")}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
