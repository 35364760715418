import { zodResolver } from "@hookform/resolvers/zod";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Alert,
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useAtom } from "jotai/index";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

import { useAuthGroupsQuery } from "@/api/hooks/auth-groups-hooks";
import { useSysCfgCombinedQuery, useSysCfgInRoomsQuery } from "@/api/hooks/sys-cfg-hooks";
import {
  formInitState,
  room,
  technology,
} from "@/components/config-modals/players-reporter/report-chat/constants";
import {
  EditReportChatType,
  EditedReportChatType,
} from "@/components/config-modals/players-reporter/report-chat/types";
import { gameType } from "@/components/config-modals/router-edit-modal/constants";
import CustomSelectOption from "@/components/custom-select-option";
import { isOwner } from "@/lib/auth-predicates";
import { ROOM_CONFIGS_NAMES, ROOM_NAMES } from "@/lib/constants";
import { SysCfgRawDto } from "@/shared/api";
import { meStore } from "@/store/store";

export default function ReportChatEditModal({
  open,
  mode,
  initialData,
  onSave,
  onCancel,
  disabled = false,
}: {
  open: boolean;
  mode?: string;
  initialData?: EditReportChatType;
  onSave?: (data: EditedReportChatType) => void;
  onCancel?: () => void;
  confirmLoading?: boolean;
  disabled?: boolean;
}) {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [me] = useAtom(meStore);

  const schema = useMemo(
    () =>
      z.object({
        authGroups: isOwner(me)
          ? z.array(z.string())
          : z.array(z.string()).min(1, "must contain at least one group"),
        name: z.string().min(1, "Field is required"),
        data: z.object({
          game_group: z.array(z.string()),
          aid: z.array(z.string()),
          cid: z.array(z.string()),
          chat_id: z.string().min(1, "Field is required"),
          bot_id: z.string().min(1, "Field is required"),
          game_type: z.array(z.string()),
          technology: z.array(z.string()),
          room: z.array(z.string()).min(1, "Field is required"),
          rules: z.array(z.string()).min(1, "Field is required"),
        }),
      }),
    [me]
  );

  const { data: authGroupsRs } = useAuthGroupsQuery();

  const authGroupsOptions = useMemo(() => {
    return authGroupsRs?.data.map((it) => it.name).sort((a, b) => a.localeCompare(b)) ?? [];
  }, [authGroupsRs?.data]);

  const defaultValues = useMemo<EditedReportChatType>(() => {
    let authGroups = initialData?.auth_groups ? Array.from(initialData.auth_groups) : [];

    if (authGroupsRs?.data.length === 1 && !isOwner(me)) {
      authGroups = authGroupsRs.data.map((it) => it.name);
    }

    return initialData
      ? {
          ...initialData,
          enabled: true,
          authGroups: authGroups,
        }
      : {
          ...formInitState,
          authGroups: authGroups,
        };
  }, [initialData, /* authGroupsRs,*/ me]); //TODO: fix authGroupsRs. Because of authGroupsRs default values got broken by tab switching

  const { data: gameGroupsBaseRs } = useSysCfgInRoomsQuery(
    {
      configName: ROOM_CONFIGS_NAMES.GAME_GROUP,
    },
    {
      refetchOnMount: true,
    }
  );

  //TODO Remove useEffect and return config names with their rooms from back-end
  const [gameGroupsRs, setGameGroupsRs] = useState<SysCfgRawDto[]>([]);
  useEffect(() => {
    setGameGroupsRs(
      Array.from(new Set(gameGroupsBaseRs?.data?.map((it) => it.name) ?? []))
        .map((it) => ({ name: it }))
        .sort((a, b) => a.name.localeCompare(b.name))
    );
  }, [gameGroupsBaseRs]);

  const { data: reportRulesBaseRs } = useSysCfgCombinedQuery(
    {
      domain: ROOM_NAMES.SERVICES,
      config_names: [ROOM_CONFIGS_NAMES.REPORT_RULE],
    },
    {
      refetchOnMount: true,
    }
  );

  const reportRules = useMemo(() => {
    return (
      reportRulesBaseRs?.data
        ?.find((it) => it.config_name === ROOM_CONFIGS_NAMES.REPORT_RULE)
        ?.configs.map((el) => el.name)
        .sort((a, b) => a.localeCompare(b)) ?? []
    );
  }, [reportRulesBaseRs]);

  const {
    register,
    setValue,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<EditedReportChatType>({
    mode: "all",
    disabled: disabled,
    defaultValues: { ...defaultValues },
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues, open, reset]);

  const onSubmit: SubmitHandler<EditedReportChatType> = (data) => {
    data.name =
      data.name && data.name !== ""
        ? data.name
        : "clustering_type_".concat(Date.now().toString()); //TODO: move to Back-end

    if (!onSave) return;
    onSave(data);

    onCancel && onCancel(); //purifying form and close dialog
  };

  const onInvalid: SubmitErrorHandler<EditedReportChatType> = (dfd) => {
    console.error("form validation error", dfd);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          return 0;
        }}
        key="Report chat modal"
      >
        <DialogTitle>{mode === "add" ? "Add report chat" : "Edit report chat"}</DialogTitle>
        <DialogContent>
          <form
            onSubmit={handleSubmit(onSubmit, onInvalid)}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "500px",
            }}
          >
            <Controller
              name="authGroups"
              control={control}
              render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                <Autocomplete
                  multiple
                  options={authGroupsOptions}
                  value={value}
                  disabled={disabled || (authGroupsOptions.length === 1 && !isOwner(me))}
                  onChange={(_, newValue) => {
                    onChange(newValue);
                    setValue("authGroups", newValue); // Update the value in react-hook-form
                  }}
                  renderOption={(props, option, { selected }) => (
                    <CustomSelectOption label={option} selected={selected} props={props} />
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={isOwner(me) ? "Auth Groups" : "Auth Groups*"}
                      placeholder={isOwner(me) ? "Auth Groups" : "Auth Groups*"}
                      inputRef={ref}
                      onBlur={onBlur} // Ensure onBlur is called to update the form state
                      error={!!error?.message}
                      helperText={error?.message}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip label={option} {...getTagProps({ index })} key={index} />
                    ))
                  }
                />
              )}
            />

            <TextField
              key="dialog-rc"
              {...register("name")}
              label="Name*"
              placeholder="name"
              variant="standard"
              error={!!errors.name}
              helperText={!!errors.name && errors.name.message}
              disabled={disabled}
            />

            <TextField
              key="dialog-rc-chat-id"
              {...register("data.chat_id")}
              label="Chat ID"
              placeholder="Chat ID"
              variant="standard"
              error={!!errors.data?.chat_id}
              helperText={!!errors.data?.chat_id && errors.data.chat_id.message}
              disabled={disabled}
            />

            <TextField
              key="dialog-rc-bot-id"
              {...register("data.bot_id")}
              label="Bot ID"
              placeholder="Bot ID"
              variant="standard"
              error={!!errors.data?.bot_id}
              helperText={!!errors.data?.bot_id && errors.data.bot_id.message}
              disabled={disabled}
            />

            <Controller
              name="data.game_group"
              control={control}
              render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                <Autocomplete
                  multiple
                  freeSolo
                  value={value}
                  disabled={disabled}
                  disableCloseOnSelect
                  onChange={(_event, newValue) => {
                    onChange(newValue);
                    setValue("data.game_group", newValue);
                  }}
                  options={gameGroupsRs?.map((el) => el.name) ?? []}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Game groups"
                      placeholder="Add a tag"
                      inputRef={ref}
                      onBlur={onBlur} // Ensure onBlur is called to update the form state
                      error={!!error?.message}
                      helperText={error?.message}
                    />
                  )}
                  renderOption={(props, option, { selected }) => {
                    const { ...optionProps } = props;
                    return (
                      <li {...optionProps}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    );
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip label={option} {...getTagProps({ index })} key={index} />
                    ))
                  }
                />
              )}
            />

            <Controller
              name="data.game_type"
              control={control}
              render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                <Autocomplete
                  multiple
                  freeSolo
                  options={gameType.map((el) => el.value).sort((a, b) => a.localeCompare(b))}
                  value={value}
                  disabled={disabled}
                  onChange={(_event, newValue) => {
                    onChange(newValue);
                    setValue("data.game_type", newValue); // Update the value in react-hook-form
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Game type"
                      placeholder="Add a tag"
                      inputRef={ref}
                      onBlur={onBlur} // Ensure onBlur is called to update the form state
                      error={!!error?.message}
                      helperText={error?.message}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip label={option} {...getTagProps({ index })} key={index} />
                    ))
                  }
                />
              )}
            />

            <Controller
              name="data.technology"
              control={control}
              render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                <Autocomplete
                  multiple
                  disabled={disabled}
                  options={technology.map((el) => el.value).sort((a, b) => a.localeCompare(b))}
                  getOptionLabel={(option) => option}
                  value={value}
                  onChange={(_event, newValue) => {
                    const newIds = newValue.map((item) => item);
                    onChange(newIds);
                    setValue("data.technology", newIds);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Technology"
                      placeholder="Add a tag"
                      inputRef={ref}
                      onBlur={onBlur} // Ensure onBlur is called to update the form state
                      error={!!error?.message}
                      helperText={error?.message}
                    />
                  )}
                  renderOption={(props, option, { selected }) => {
                    const { ...optionProps } = props;
                    return (
                      <li {...optionProps}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    );
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip label={option} {...getTagProps({ index })} key={index} />
                    ))
                  }
                />
              )}
            />

            <Controller
              name="data.aid"
              control={control}
              render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                <Autocomplete
                  multiple
                  freeSolo
                  options={[]}
                  value={value}
                  disabled={disabled}
                  onChange={(_event, newValue) => {
                    onChange(newValue);
                    setValue("data.aid", newValue); // Update the value in react-hook-form
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Aid"
                      placeholder="Add a tag"
                      inputRef={ref}
                      onBlur={onBlur} // Ensure onBlur is called to update the form state
                      error={!!error?.message}
                      helperText={error?.message}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip label={option} {...getTagProps({ index })} key={index} />
                    ))
                  }
                />
              )}
            />

            <Controller
              name="data.cid"
              control={control}
              render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                <Autocomplete
                  multiple
                  freeSolo
                  options={[]}
                  value={value}
                  disabled={disabled}
                  onChange={(_event, newValue) => {
                    onChange(newValue);
                    setValue("data.cid", newValue); // Update the value in react-hook-form
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Cid"
                      placeholder="Add a tag"
                      inputRef={ref}
                      onBlur={onBlur} // Ensure onBlur is called to update the form state
                      error={!!error?.message}
                      helperText={error?.message}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip label={option} {...getTagProps({ index })} key={index} />
                    ))
                  }
                />
              )}
            />

            <Controller
              name="data.room"
              control={control}
              render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                <Autocomplete
                  multiple
                  options={room.map((el) => el.value).sort((a, b) => a.localeCompare(b))}
                  getOptionLabel={(option) => option}
                  value={value}
                  disabled={disabled}
                  onChange={(_event, newValue) => {
                    const newIds = newValue.map((item) => item);
                    onChange(newValue);
                    setValue("data.room", newIds); // Update the value in react-hook-form
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Room"
                      placeholder="Add a tag"
                      inputRef={ref}
                      onBlur={onBlur} // Ensure onBlur is called to update the form state
                      error={!!error?.message}
                      helperText={error?.message}
                    />
                  )}
                  renderOption={(props, option, { selected }) => {
                    const { ...optionProps } = props;
                    return (
                      <li {...optionProps}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    );
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip label={option} {...getTagProps({ index })} key={index} />
                    ))
                  }
                />
              )}
            />

            <Controller
              name="data.rules"
              control={control}
              render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                <Autocomplete
                  multiple
                  freeSolo
                  options={reportRules ?? []}
                  value={value}
                  disabled={disabled}
                  onChange={(_event, newValue) => {
                    onChange(newValue);
                    setValue("data.rules", newValue); // Update the value in react-hook-form
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Rules"
                      placeholder="Add a tag"
                      inputRef={ref}
                      onBlur={onBlur} // Ensure onBlur is called to update the form state
                      error={!!error?.message}
                      helperText={error?.message}
                    />
                  )}
                  renderOption={(props, option, { selected }) => {
                    const { ...optionProps } = props;
                    return (
                      <li {...optionProps}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    );
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip label={option} {...getTagProps({ index })} key={index} />
                    ))
                  }
                />
              )}
            />

            <Controller
              control={control}
              name="data"
              render={({ fieldState: { error } }) => (
                <>
                  {error?.root?.message && (
                    <Alert severity="error">{error.root.message}</Alert>
                  )}
                </>
              )}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button type="submit" disabled={disabled}>
                Submit
              </Button>
              <Button type="button" onClick={() => onCancel && onCancel()}>
                Cancel
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
