import { Grid } from "@mui/material";
import { Divider, Typography } from "antd";

import AuthGroupsModule from "@/module/auth-groups-module";

const AuthGroupsPage = () => {
  return (
    <>
      <Grid container spacing={2} height="100%" direction="column" flexWrap="nowrap">
        <Grid item>
          <Typography.Title level={2}>Auth groups</Typography.Title>
        </Grid>
        <Grid item>
          <Divider style={{ marginTop: 0 }} />
        </Grid>
        <Grid item>
          <AuthGroupsModule />
        </Grid>
      </Grid>
    </>
  );
};

export default AuthGroupsPage;
