import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { ContentCopy, Edit, Power, PowerOff } from "@mui/icons-material";
import { Box, FormControlLabel, IconButton, Switch } from "@mui/material";
import { Button, Checkbox, Popconfirm } from "antd";
import {
  MRT_ColumnDef,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSysCfgQuery } from "@/api/hooks/sys-cfg-hooks";
import { useSysDataClubsQuery } from "@/api/hooks/sys-data-hooks";
import { useCfgPerm, useIsOwnerOrSuperAdmin } from "@/auth/hooks";
import ApplyAuthGroupsTableControl from "@/components/apply-auth-groups-table-control";
import BulkOperationReportModal from "@/components/bulk-operation-report-modal";
import CommonConfigWriteModal from "@/components/config-modals/common-config-write-modal";
import RegularConfigCreateModal from "@/components/config-modals/regular-config-create-modal";
import RegularConfigEditModal from "@/components/config-modals/regular-config-edit-modal";
import { useTableSettings } from "@/hooks/use-table-settings";
import { assembleNamesFilter, enrichClubManagerWithClubData } from "@/lib/cfg-utils";
import { DATE_FORMAT_WITH_UTC_TAG, ROOM_CONFIGS_NAMES } from "@/lib/constants";
import {
  useConfigChangeEnabled,
  useDeleteConfigs,
  useGetJsonForm,
  useSaveConfig,
  useUpdateConfig,
} from "@/module/room-configs-module/hooks/room-configs-hooks";
import { ResultDtoLong, SysCfgCreateRequestDto } from "@/shared/api";
import { SysCfgCmsAggregator } from "@/types/roomConfigTypes";

const CmsAggregatorConfigTable = ({ room }: { room: string }) => {
  const { t } = useTranslation("room-configs");
  const {
    data: configsRs,
    isLoading: configsIsLoading,
    refetch,
  } = useSysCfgQuery({
    domain: room,
    config_name: ROOM_CONFIGS_NAMES.CMS_AGGREGATOR,
  });

  const { data: clubConfigsData } = useSysDataClubsQuery({
    domain: room,
  });

  const cfgPerm = useCfgPerm({
    roomName: room,
    cfgName: ROOM_CONFIGS_NAMES.CMS_AGGREGATOR,
  });

  const isOwner = useIsOwnerOrSuperAdmin();

  const roomConfigsSortedData = React.useMemo(() => {
    const data = enrichClubManagerWithClubData(
      configsRs?.data.content ?? [],
      clubConfigsData?.data ?? []
    );
    return (
      data.sort(
        (a, b) => new Date(b.created_at).getDate() - new Date(a.created_at).getDate()
      ) ?? []
    );
  }, [clubConfigsData, configsRs]);

  const [bulkOperationResult, setBulkOperationResult] = useState<ResultDtoLong[] | undefined>(
    undefined
  );

  const { data: jsonFormSchema } = useGetJsonForm({
    roomName: room,
    configName: ROOM_CONFIGS_NAMES.CMS_AGGREGATOR,
  });

  const handleClickSaveNewConfig = useSaveConfig({
    roomName: room,
    cfgName: ROOM_CONFIGS_NAMES.CMS_AGGREGATOR,
    onSuccessCallback: () => {
      void refetch();
      closeAddConfig();
    },
  });

  const handleClickUpdateConfig = useUpdateConfig({
    roomName: room,
    cfgName: ROOM_CONFIGS_NAMES.CMS_AGGREGATOR,
    onSuccessCallback: () => {
      void refetch();
      setEditingConfig(undefined);
    },
  });

  const handleChangeEnableBulk = useConfigChangeEnabled({
    roomName: room,
    cfgName: ROOM_CONFIGS_NAMES.CMS_AGGREGATOR,
    onSuccessCallback: (table) => {
      void refetch();
      table.resetRowSelection();
    },
  });

  const handleDeleteConfigs = useDeleteConfigs({
    roomName: room,
    cfgName: ROOM_CONFIGS_NAMES.CMS_AGGREGATOR,
    onSuccessCallback: (table, response) => {
      setBulkOperationResult([response.data]);
      void refetch();
      table.resetRowSelection();
    },
  });

  //This logic for reset row selection while using filtering/grouping
  const [editingConfig, setEditingConfig] = useState<SysCfgCmsAggregator | undefined>(
    undefined
  );
  const [addConfig, setAddConfig] = useState<boolean>(false);
  const [addingConfig, setAddingConfig] = useState<SysCfgCreateRequestDto | undefined>(
    undefined
  );

  function handleClickAddConfig() {
    setAddConfig(true);
  }

  function closeAddConfig() {
    setAddingConfig(undefined);
    setAddConfig(false);
  }

  const [enableColumnDragging, setEnableColumnDragging] = useState<boolean>(false);
  const [enableColumnSorting, setEnableColumnSorting] = useState<boolean>(false);

  const {
    defaultProps,
    columnVisibility,
    columnOrder,
    columnFilters,
    globalFilter,
    grouping,
    pagination,
    sorting,
  } = useTableSettings<SysCfgCmsAggregator>("cmsAggregator");

  const columns = useMemo<MRT_ColumnDef<SysCfgCmsAggregator>[]>(
    () => [
      {
        header: t("cmsAggregatorTable.enable"),
        filterFn: "contains",
        enableEditing: false,
        accessorFn: (row) => row.enabled ?? "-",
        size: 80,
        Cell: ({ row }) => <Checkbox checked={row.original.enabled} disabled />,
      },
      {
        header: t("cmsAggregatorTable.name"),
        accessorFn: (row) => row.name ?? "-",
        filterFn: "contains",
      },

      {
        header: t("cmsAggregatorTable.username"),
        accessorFn: (row) => row.data?.username ?? "-",
        filterFn: "contains",
      },
      {
        header: t("cmsAggregatorTable.gameGroup"),
        accessorFn: (row) => row.data?.game_group ?? "-",
        filterFn: "contains",
        size: 120,
      },
      {
        header: t("cmsAggregatorTable.cid"),
        accessorFn: (row) => row.data?.cid ?? "-",
        filterFn: "contains",
      },
      {
        header: t("cmsAggregatorTable.aid"),
        accessorFn: (row) => row.data?.aid ?? "-",
        filterFn: "contains",
      },
      {
        header: t("cmsAggregatorTable.isGold"),
        accessorFn: (row) => row.data?.is_gold?.toString() ?? "-",
        filterFn: "contains",
      },

      {
        header: t("cmsAggregatorTable.accountGems"),
        accessorFn: (row) => row?.cmsAggregatorRedisInfo?.account_gems ?? "-",
        filterFn: "contains",
      },
      {
        header: t("cmsAggregatorTable.clubGems"),
        accessorFn: (row) => row?.cmsAggregatorRedisInfo?.club_gems ?? "-",
        filterFn: "contains",
      },
      {
        header: t("cmsAggregatorTable.goldBalance"),
        accessorFn: (row) => row.cmsAggregatorRedisInfo?.gold_balance ?? "-",
        filterFn: "contains",
      },
      {
        header: t("cmsAggregatorTable.alcBalance"),
        accessorFn: (row) => row.cmsAggregatorRedisInfo?.alc_balance ?? "-",
        filterFn: "contains",
      },
      {
        header: t("cmsAggregatorTable.alcIssued"),
        accessorFn: (row) => row.cmsAggregatorRedisInfo?.alc_issued ?? "-",
        filterFn: "contains",
      },
      {
        header: t("cmsAggregatorTable.clubLevel"),
        accessorFn: (row) => row.cmsAggregatorRedisInfo?.club_level ?? "-",
        filterFn: "contains",
      },
      {
        header: t("cmsAggregatorTable.currMems"),
        accessorFn: (row) => row.cmsAggregatorRedisInfo?.cur_members ?? "-",
        filterFn: "contains",
      },
      {
        header: t("cmsAggregatorTable.maxMems"),
        accessorFn: (row) => row.cmsAggregatorRedisInfo?.max_members ?? "-",
        filterFn: "contains",
      },
      {
        header: t("cmsAggregatorTable.updatedAt"),
        accessorFn: (row) => row.cmsAggregatorRedisInfo?.updated_at ?? "-",
        Cell: ({ row }) => {
          return moment
            .unix(Number(row.original.cmsAggregatorRedisInfo?.updated_at) / 1000)
            .utc(false)
            .format(DATE_FORMAT_WITH_UTC_TAG);
        },
        filterFn: "contains",
      },
      {
        header: t("cmsAggregatorTable.nlhExportTimestamp"),
        accessorFn: (row) => row.data?.nlh_export_timestamp ?? "-",
        Cell: ({ row }) => {
          return moment
            .unix(Number(row.original.data?.nlh_export_timestamp) / 1000)
            .utc(false)
            .format(DATE_FORMAT_WITH_UTC_TAG);
        },
        filterFn: "contains",
      },
      {
        header: t("cmsAggregatorTable.ploExportTimestamp"),
        accessorFn: (row) => row.data?.plo_export_timestamp ?? "-",
        Cell: ({ row }) => {
          return moment
            .unix(Number(row.original.data?.plo_export_timestamp) / 1000)
            .utc(false)
            .format(DATE_FORMAT_WITH_UTC_TAG);
        },
        filterFn: "contains",
      },
      {
        header: t("cmsAggregatorTable.nlhsExportTimestamp"),
        accessorFn: (row) => row.data?.nlhs_export_timestamp ?? "-",
        Cell: ({ row }) => {
          return moment
            .unix(Number(row.original.data?.nlhs_export_timestamp) / 1000)
            .utc(false)
            .format(DATE_FORMAT_WITH_UTC_TAG);
        },
        filterFn: "contains",
      },
      {
        header: t("cmsAggregatorTable.ofcExportTimestamp"),
        accessorFn: (row) => row.data?.ofc_export_timestamp ?? "-",
        Cell: ({ row }) => {
          return moment
            .unix(Number(row.original.data?.ofc_export_timestamp) / 1000)
            .utc(true)
            .format(DATE_FORMAT_WITH_UTC_TAG);
        },
        filterFn: "contains",
      },
    ],
    [t]
  );

  const table = useMaterialReactTable({
    data: roomConfigsSortedData ?? [],
    columns: columns,
    getRowId: (originalRow) => originalRow.id,
    selectAllMode: "all",
    state: {
      isLoading: configsIsLoading,
      columnFilters,
      globalFilter,
      grouping,
      columnVisibility,
      columnOrder,
      pagination,
      sorting,
    },
    ...defaultProps,
    initialState: {
      pagination: { pageSize: 100, pageIndex: 0 },
      density: "compact",
      sorting: [{ id: "name", desc: false }],
    },
    enableRowActions: true,
    enableSelectAll: true,
    enableRowSelection: true,
    enableGrouping: true,
    enableColumnDragging: enableColumnDragging,
    enableColumnOrdering: true,
    enableColumnFilterModes: true,
    enablePagination: true,
    enableSorting: enableColumnSorting,
    muiTableHeadCellProps: {
      sx: {
        verticalAlign: "middle",
        "& *": {
          whiteSpace: "normal",
          lineHeight: 1.35,
        },
      },
    },
    defaultColumn: {
      enableResizing: false,
      grow: false,
      maxSize: 30,
      minSize: 10,
    },

    renderTopToolbarCustomActions: ({ table }) => {
      const selectedCount = table.getSelectedRowModel().rows.length;

      return (
        <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
          {cfgPerm.write && (
            <Button onClick={() => handleClickAddConfig()}>
              <PlusOutlined /> {t("new")}
            </Button>
          )}

          {selectedCount > 0 && (
            <div style={{ marginLeft: 30 }}>
              {cfgPerm.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="success"
                  onClick={() => handleChangeEnableBulk(table, true)}
                >
                  <Power /> {t("enable")}
                </IconButton>
              )}
              {cfgPerm.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="error"
                  onClick={() => handleChangeEnableBulk(table, false)}
                >
                  <PowerOff /> {t("disable")}
                </IconButton>
              )}

              {cfgPerm.delete && (
                <Popconfirm
                  title={t("deleteItems")}
                  description={t("areYouSureToDeleteItems")}
                  onConfirm={() => handleDeleteConfigs(table)}
                >
                  <IconButton size="small" style={{ fontSize: "15px" }} color="error">
                    <DeleteOutlined /> {t("delete")}
                  </IconButton>
                </Popconfirm>
              )}

              {isOwner && (
                <ApplyAuthGroupsTableControl
                  domain={room}
                  cfgName={ROOM_CONFIGS_NAMES.CMS_AGGREGATOR}
                  table={table}
                  filters={assembleNamesFilter(table)}
                  totalElementsCount={selectedCount}
                  onSuccess={() => void refetch()}
                />
              )}
            </div>
          )}
        </div>
      );
    },
    renderRowActions: ({ row }) => (
      <Box>
        <IconButton
          onClick={() =>
            setEditingConfig({
              ...row.original,
              auth_groups: row.original.auth_groups ?? new Set(),
            })
          }
        >
          <Edit />
        </IconButton>

        {cfgPerm.write && (
          <IconButton
            onClick={() => {
              setAddingConfig({ ...row.original, name: "", auth_groups: new Set() });
            }}
          >
            <ContentCopy />
          </IconButton>
        )}
      </Box>
    ),
    renderBottomToolbarCustomActions: () => (
      <>
        <FormControlLabel
          value={enableColumnDragging}
          control={
            <Switch
              size="small"
              onClick={() => setEnableColumnDragging(!enableColumnDragging)}
            />
          }
          label={t("columnsDragging")}
          labelPlacement="start"
        />
        <FormControlLabel
          value={enableColumnSorting}
          control={
            <Switch
              size="small"
              onClick={() => setEnableColumnSorting(!enableColumnSorting)}
            />
          }
          label={t("columnsSorting")}
          labelPlacement="start"
        />
      </>
    ),
  });

  useEffect(() => {
    table.resetRowSelection();
  }, [columnFilters, grouping, globalFilter, table]);

  return (
    <>
      <MaterialReactTable table={table} />

      {addConfig && (
        <>
          {jsonFormSchema ? (
            <RegularConfigCreateModal
              title={t("createConfig")}
              open={!!(addConfig && jsonFormSchema)}
              uiSchema={jsonFormSchema}
              defaultValues={addingConfig}
              onSave={(config) => handleClickSaveNewConfig(config)}
              onCancel={() => closeAddConfig()}
            />
          ) : (
            <CommonConfigWriteModal
              mode="add"
              isOpen={addConfig}
              defaultValues={addingConfig}
              onCancel={() => closeAddConfig()}
              onSave={(config) => handleClickSaveNewConfig(config)}
              disabled={!cfgPerm.write}
            />
          )}
        </>
      )}

      {editingConfig && (
        <>
          {jsonFormSchema ? (
            <RegularConfigEditModal
              title={t("editConfig")}
              open={!!(editingConfig && jsonFormSchema)}
              uiSchema={jsonFormSchema}
              config={editingConfig}
              onSave={(config) => handleClickUpdateConfig(config.name, config)}
              onCancel={() => setEditingConfig(undefined)}
              disabled={!cfgPerm.write}
            />
          ) : (
            <CommonConfigWriteModal
              mode="edit"
              isOpen={!!editingConfig}
              defaultValues={editingConfig}
              onCancel={() => setAddingConfig(undefined)}
              onSave={(config) => handleClickUpdateConfig(config.name, config)}
              disabled={!cfgPerm.write}
            />
          )}
        </>
      )}
      {bulkOperationResult && (
        <BulkOperationReportModal
          resultList={bulkOperationResult}
          title={t("operationResult")}
          showProblematic
          showSuccess
          onCancel={() => setBulkOperationResult(undefined)}
          open={!!bulkOperationResult}
        />
      )}
    </>
  );
};

export default CmsAggregatorConfigTable;
