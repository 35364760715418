import React, { useMemo } from "react";
import { SysAccountRegularDto } from "@/shared/api";
import { Avatar, Checkbox } from "@mui/material";
import { MRT_OrderedColumnDef, NilObj } from "@/types/commonTypes";
import { Tooltip } from "antd";
import { ACCOUNT_STATUS, APP_GMT_DATE_FORMAT } from "@/lib/constants";
import moment from "moment";
import { Link } from "@tanstack/react-router";
import { getTabTypeBySessionType } from "@/lib/accountsUtils";


const useAccountCommonColumns = ({
  room,
} : {
  room: string
}) => useMemo<MRT_OrderedColumnDef<SysAccountRegularDto>[]>(() => [
  {
    id: "data.avatar",
    header: "Avatar",
    columnFilterModeOptions: ["contains", "empty", "notEmpty"],
    order: 100,
    accessorFn: ({ data }) => (data as any)?.avatar ?? "",
    Cell: ({ row }) => {
      const dt = row.original.data as any
      return (
        <Avatar alt={dt?.nick ?? "?"} src={dt?.avatar}/>
      )
    }
  },
  {
    id: "name",
    header: "Name",
    order: 200,
    accessorFn: (row) => row.name ?? "-"
  },
  {
    id: "data.source",
    header: "Source",
    order: 300,
    accessorFn: ({data}) => (data as NilObj)?.source ?? "-",
  },
  {
    id: "data.cid",
    header: "Cid",
    order: 400,
    accessorFn: ({data}) => (data as NilObj)?.cid ?? "-",
  },
  {
    id: "data.status",
    header: "Status",
    order: 500,
    accessorFn: ({data}) => (data as NilObj)?.status ?? "-",
    Cell: ({ row: { original } }) => {
      const status = (original.data as NilObj)?.status as unknown;

      return status !== undefined && typeof status === "number" ? (
        <Tooltip title={ACCOUNT_STATUS.get(status)}>{status}</Tooltip>
      ) : "-";
    }
  },
  {
    id: "created_at",
    header: "Created at",
    order: 600,
    accessorFn: (row) => (row.created_at ? new Date(row.created_at) : 0),
    Cell: ({ row: { original: { created_at } } }) => created_at
      ? moment(created_at).utc(false).format(APP_GMT_DATE_FORMAT)
      : "-",
  },
  {
    id: "enabled",
    header: "Enabled",
    order: 700,
    accessorFn: ({ enabled }) => enabled ?? "-",
    Cell: ({ row: { original: { enabled } } }) => (
      <Checkbox checked={enabled} disabled />
    ),
  },
  {
    id: "session",
    header: "Session",
    order: 800,
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ row: { original: { session } } }) => (
      session ? (
        <Link
          to="/account/$room/$type"
          params={{
            room: room,
            type: getTabTypeBySessionType(session.session_type),
          }}
          search={{
            session_id: session.id
          }}
        />
      ) : ( "-" )
    )
  }
], [room])


export default useAccountCommonColumns