import React, { useEffect, useState } from "react";
import {Divider, Form, InputNumber, message, Modal, Typography} from "antd";
import {dataFieldName} from "@/components/config-modals/clusterizer-edit-modal/constants";
import {red} from "@ant-design/colors";
import { required } from "@/components/config-modals/router-edit-modal/constants";

const RequestReplayModal = (
  {
    title,
    onCancel,
    confirmLoading = false,
    onSave,
    isOpen
  }: {
    title?: string,
    confirmLoading?: boolean,
    onCancel: () => void;
    onSave: (hid: string, tid: string) => void;
    isOpen: boolean;
  }
) => {
  const [instancesValidationError, setInstancesValidationError] = useState<string | undefined>(undefined);
  const [form] = Form.useForm();

  function handleSaveClick() {
    form.validateFields()
      .then(value => {
        if(!onSave) return;
        onSave(value.data.hid, value.data.tid);
      })
      .catch((reason) => {
        message.error("Form data is invalid. Fix the errors and try again.").then();
        console.error("Form data is invalid. Fix the errors and try again.", reason);
        handleShowProbabilityErrorMessage();
      });
  }

  function handleShowProbabilityErrorMessage() {
    const fieldError = form.getFieldError([dataFieldName, "instances"]);
    setInstancesValidationError(fieldError[0]);
  }

  useEffect(() => {
    form.resetFields()
  }, [form, isOpen]);


  return (<>
    <Modal open={isOpen}
           okText="Submit"
           onCancel={() => onCancel && onCancel()}
           maskClosable={false}
           width={450}
           confirmLoading={confirmLoading}
           onOk={() => handleSaveClick()}
           title={title && <Typography.Title level={3} style={{marginTop: 15}}>{title}</Typography.Title>}>

      {title && (<Divider style={{marginTop: 10, marginBottom: 30}}/>)}

      <Form size="large" className="a-common-form" form={form} autoComplete="off" key="requestReplyForm">
        <Form.Item name={[dataFieldName, "hid"]} label="Hid" required rules={required} >
          <InputNumber placeholder="Hid" min={1} style={{ width: 345 }}/>
        </Form.Item>
        <Form.Item name={[dataFieldName, "tid"]} label="Tid" required rules={required}>
          <InputNumber placeholder="Tid" min={1} style={{ width: 345 }}/>
        </Form.Item>
      </Form>
      <div hidden={!instancesValidationError}
           style={{marginTop: 10, color: red.primary}}>{instancesValidationError}</div>
    </Modal>
  </>)
}

export default RequestReplayModal;