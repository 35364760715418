import {NumberOption, StringOption} from "@/types/commonTypes";
import { EditedReportRuleType } from "@/components/config-modals/players-reporter/report-rule/types";

export const dataFieldName = "data";
export const room: StringOption[] = [
  {value: "hh", label: "HH"},
  {value: "pw", label: "PW"},
  {value: "ff", label: "FF"},
  {value: "wpc", label: "WPC"},
  {value: "wpk", label: "WPK"},
  {value: "pl", label: "PL"}
];
export const technology: StringOption[] = [
  {value: "aicon", label: "aicon"},
  {value: "adv", label: "adv"},
  {value: "eco", label: "eco"},
];
export const gameType: StringOption[] = [
  {value: "nlh", label: "nlh"},
  {value: "nlhs", label: "nlhs"},
  {value: "nlo", label: "nlo"},
  {value: "plo", label: "plo"},
  {value: "plo5", label: "plo5"},
  {value: "nlo5", label: "nlo5"},
  {value: "nlp", label: "nlp"},
  {value: "ofc", label: "ofc"},
  {value: "mtt", label: "mtt"},
  {value: "nlb", label: "nlb"},
  {value: "plob", label: "plob"},
  {value: "plo5b", label: "plo5b"},
];
export const sizeRange = [2, 9];
export const straddle: NumberOption[] = [
  {value: 0, label: "0"},
  {value: 2, label: "2"},
  {value: 4, label: "4"},
  {value: 6, label: "6"},
  {value: 8, label: "8"}
];
export const playerCountRange = [2, 9];
export const aiType: StringOption[] = [{value: "aws", label: "AWS"}, {value: "balancer", label: "Balancer"}];
export const formInitState: EditedReportRuleType = {
  name: "",
  authGroups: [],
  auth_groups: new Set(),
  enabled: true,
  data: {
    display_name: "",
    pattern: [],
    limits: [],
    replay: false,
    amount: 0,
    frequency: 0,
  }
};

export const required = [{required: true, message: "Field is required"}];
