import { PlusOutlined } from "@ant-design/icons";
import { useSearch } from "@tanstack/react-router";
import { Button } from "antd";
import {
  MRT_ColumnFilterFnsState,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSysCfgQuery, useUsernamesCreateMutation } from "@/api/hooks/sys-cfg-hooks";
import UsernameCreateModal from "@/components/config-modals/username-create-modal/username-create-modal";
import { useTableSettings } from "@/hooks/use-table-settings";
import { assembleRegularAccountsFiltersFns } from "@/lib/accountsUtils";
import { assembleKeyFilters, assembleSort } from "@/lib/cfg-utils";
import { assembleSearchParamsFilters } from "@/lib/common-utils";
import { COMMON_REFETCH_INTERVAL, ROOM_CONFIGS_NAMES } from "@/lib/constants";
import { useUsernameTableColumns } from "@/module/room-configs-module/username-config-table/username-table-columns";
import { KeyFilter, KeyFilterModeEnum, KeySort, SysCfgRegularDto } from "@/shared/api";
import { SearchParams } from "@/types/commonTypes";

const requiredFilters: KeyFilter[] = [];
const defaultFilter: KeyFilter = {
  key: "data.is_used",
  mode: "equals" as KeyFilterModeEnum,
  value: "false" as unknown as object,
};

const UsernameConfigTable = ({ room }: { room: string }) => {
  const { t } = useTranslation("room-configs");
  const columns = useUsernameTableColumns();

  const searchParams: SearchParams = useSearch({ strict: false });

  const [sorting, setSorting] = useState<MRT_SortingState>([{ id: "created_at", desc: true }]);

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(() =>
    assembleSearchParamsFilters(searchParams)
  );

  const [columnFilterFns, setColumnFilterFns] = useState<MRT_ColumnFilterFnsState>(
    assembleRegularAccountsFiltersFns({ columns, searchParams: searchParams })
  );

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 30,
  });

  const filters = useMemo<Set<KeyFilter>>(
    () => assembleKeyFilters(columnFilters, columnFilterFns, requiredFilters),
    [columnFilters, columnFilterFns]
  );

  const sort = useMemo<KeySort[]>(() => assembleSort(sorting), [sorting]);

  const {
    data: configsRs,
    isLoading: configsIsLoading,
    refetch: refetchConfigs,
    isRefetching: isConfigsRefetching,
  } = useSysCfgQuery(
    {
      domain: room,
      config_name: ROOM_CONFIGS_NAMES.USERNAME,
      filters: [...filters].find((el) => el.key === defaultFilter.key)
        ? filters
        : filters.add(defaultFilter),
      sort: sort,
    },
    {
      enabled: true,
      refetchInterval: COMMON_REFETCH_INTERVAL,
    }
  );

  const { mutate: createUsernamesMutate } = useUsernamesCreateMutation();

  const [isCreateModalOpened, setIsCreateModalOpened] = useState<boolean>(false);

  function handleClickSaveNewConfig(usernames: Set<string>) {
    createUsernamesMutate(
      {
        domain: room,
        body: usernames,
      },
      {
        onSuccess: () => {
          enqueueSnackbar(t("elementsHaveBeenCreated"), { variant: "success" });
          setIsCreateModalOpened(false);
          void refetchConfigs();
        },
        onError: (err) => {
          enqueueSnackbar(
            t("operationFailureContactAdministrator", { message: err.message }),
            {
              variant: "error",
            }
          );
          console.error("Adding config failure", err);
        },
      }
    );
  }

  const { defaultProps, grouping, columnVisibility, columnOrder } =
    useTableSettings<SysCfgRegularDto>("username");

  const table = useMaterialReactTable({
    data: configsRs?.data.content ?? [],
    columns: columns,
    getRowId: (originalRow) => originalRow.id,
    ...defaultProps,
    enableRowSelection: true,
    enableGrouping: true,
    enableColumnDragging: true,
    enableColumnOrdering: true,
    enableColumnFilterModes: true,
    enableRowActions: false,
    enablePagination: true,
    enableSorting: true,
    manualPagination: true,
    state: {
      pagination,
      grouping,
      columnVisibility,
      columnOrder,
      columnFilterFns,
      columnFilters,
      sorting,
      isLoading: configsIsLoading,
      showProgressBars: isConfigsRefetching,
    },
    initialState: {
      ...defaultProps.initialState,
      sorting: [{ id: "name", desc: false }],
    },
    muiTableBodyCellProps: {
      sx: {
        whiteSpace: "pre",
      },
    },
    muiTableBodyRowProps: ({ row }) => {
      const data = row.original.data;

      return (data as any)?.status !== 0 && !configsIsLoading
        ? { sx: { backgroundColor: "#e9b1cd" } }
        : {};
    },
    onPaginationChange: (value) => {
      setPagination(value);
    },
    onColumnFiltersChange: setColumnFilters,
    onColumnFilterFnsChange: setColumnFilterFns,
    onSortingChange: setSorting,
    selectAllMode: "page",

    renderTopToolbarCustomActions: () => {
      return (
        <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
          <Button
            onClick={() => {
              setIsCreateModalOpened(true);
            }}
          >
            <PlusOutlined />
            {t("new")}
          </Button>
        </div>
      );
    },
  });

  useEffect(() => {
    table.resetRowSelection();
  }, [table, columnFilters, sorting, pagination]);

  return (
    <>
      <MaterialReactTable table={table} />

      <UsernameCreateModal
        open={isCreateModalOpened}
        onSave={(data) => {
          handleClickSaveNewConfig(data);
        }}
        onCancel={() => {
          setIsCreateModalOpened(false);
        }}
      />
    </>
  );
};

export default UsernameConfigTable;
