import * as React from "react";
import {Button, Popover, PopoverProps, Select, SelectProps} from "antd";


interface ASelectPopover {
  containerStyle?: React.CSSProperties;
  children?: React.ReactNode;
  buttonText?: string;
  disabled?: boolean;
  onOk?: () => void;
  loading?: boolean;

  popoverProps: PopoverProps;
  selectProps: SelectProps;
}

const ASelectPopover = (
  {
    containerStyle,
    children,
    buttonText,
    disabled,
    onOk,
    loading,
    popoverProps,
    selectProps
  } : ASelectPopover
) => {

  return <>
    <Popover {...popoverProps} content={
      <>
        <div style={containerStyle}>
          <Select {...selectProps}/>
          {onOk && (
            <Button title={buttonText}
                    disabled={disabled}
                    loading={loading}
                    onClick={() => onOk()}
                    style={{marginLeft: 10}}
                    type="primary">{buttonText}</Button>
          )}
        </div>
      </>
    }>
      {children}
    </Popover>
  </>
}

export default ASelectPopover;