import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";

import { useSysDomainDeleteCfgCollection } from "@/api/hooks/sys-domain-hooks";

import DeleteConfigModal from "./delete-config-modal";

const DeleteDomainConfigTableControl = ({
  domain,
  cfgName,
  refetch,
}: {
  domain: string;
  cfgName: string;
  refetch: () => void;
}) => {
  const mutation = useSysDomainDeleteCfgCollection();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleDelete = () => {
    mutation.mutate(
      {
        domain: domain,
        name: cfgName,
      },
      {
        onSuccess: () => {
          enqueueSnackbar(`Domain config '${cfgName}' successfully deleted.`, {
            variant: "success",
          });
          setIsOpen(false);
          refetch();
        },
        onError: (err) => {
          enqueueSnackbar(`Operation failure, contact an administrator`, { variant: "error" });
          console.error("Delete domain config failure:", err);
        },
      }
    );
  };

  return (
    <div onClick={ (e) => e.stopPropagation() }>
      <IconButton onClick={() => setIsOpen(true)} color="error">
        <Delete />
      </IconButton>
      <DeleteConfigModal
        open={isOpen}
        configName={cfgName}
        onClose={() => setIsOpen(false)}
        confirmIsLoading={mutation.isPending}
        onConfirm={() => handleDelete()}
      />
    </div>
  );
};

export default DeleteDomainConfigTableControl;
