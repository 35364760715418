import { MRT_TableInstance } from "material-react-table";
import { SysAccountRegularDto } from "@/shared/api";
import React, { useState } from "react";
import { IconButton } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import { useSaveNewAccount } from "@/module/accounts-module/hooks/accounts-mutate-hooks";
import SysAccountWriteModal from "@/module/accounts-module/components/sys-account-write-modal";


const CopyAccountTableControl = (
  {
    roomName,
    table,
    onSuccess
  } : {
    roomName: string;
    table: MRT_TableInstance<SysAccountRegularDto>,
    onSuccess?: () => void,
  }
) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [account, setAccount] = useState<SysAccountRegularDto | undefined>(undefined);

  function handleCopyClick() {
    const account = table.getSelectedRowModel().rows.map((item) => item.original)[0];
    setIsOpen(true)
    setAccount({...account, name: ""})
    table.resetRowSelection()
  }

  const {
    mutate: handleClickSaveNewAccount,
    isPending: createAccountIsPending
  } = useSaveNewAccount({
    roomName: roomName,
    successCallback: () => {
      setIsOpen(false)
      onSuccess && onSuccess()
    }
  });


  return (
    <>
      <IconButton
        size="small"
        style={{ fontSize: 12 }}
        color="warning"
        disabled={ table.getSelectedRowModel().rows.length !== 1}
        onClick={() => handleCopyClick()}
      >
        <ContentCopy /> Copy
      </IconButton>

      <SysAccountWriteModal
        mode="add"
        accountType="regular"
        isOpen={isOpen}
        roomName={roomName}
        defaultValues={account}
        onSave={(name, account) => handleClickSaveNewAccount({ name, ...account})}
        onClose={() => setIsOpen(false)}
        confirmLoading={createAccountIsPending}
      />
    </>
  )
}


export default CopyAccountTableControl