import { Autocomplete, TextField } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useRoomInfoQuery, useRoomsListQuery } from "@/api/hooks/table-cache-hooks";
import { useMuiTableToolbarAlertBannerProps } from "@/hooks/use-mui-table-toolbar-alert-banner-props";
import { useTableSettings } from "@/hooks/use-table-settings";
import {
  PlayerInfo,
  usePlayersTableColumns,
} from "@/module/service-configs-module/table-cache-module/players-table-columns";

const PlayersTable = ({
  selectedRoom,
  setSelectedRoom,
  tablesQuery,
  roomsQuery,
  playersList,
}: {
  selectedRoom: string | null;
  setSelectedRoom: (value: string | null) => void;
  tablesQuery: ReturnType<typeof useRoomInfoQuery>;
  roomsQuery: ReturnType<typeof useRoomsListQuery>;
  playersList: PlayerInfo[];
}) => {
  const { t } = useTranslation();

  const columns = usePlayersTableColumns();
  const { data: roomList, isLoading: isRoomsListLoading } = roomsQuery;

  const roomsListOptions = useMemo(() => {
    if (roomList) {
      return roomList.data.rooms;
    }
    return [];
  }, [roomList]);

  const {
    data: tableResponse,
    isError: isDataLoadingError,
    isLoading: isDataLoading,
    isRefetching: isDataRefetching,
  } = tablesQuery;

  const muiToolbarAlertBannerProps = useMuiTableToolbarAlertBannerProps(
    isDataLoadingError,
    tableResponse?.statusText
  );

  const { columnVisibility, defaultProps, pagination, sorting } =
    useTableSettings<PlayerInfo>("playersTable");

  const table = useMaterialReactTable({
    data: playersList,
    rowCount: tableResponse?.data.tables.length ?? 0,
    columns: columns,
    muiToolbarAlertBannerProps,
    state: {
      columnVisibility,
      isLoading: isDataLoading,
      showProgressBars: isDataRefetching,
      pagination,
      sorting,
    },
    ...defaultProps,
    renderTopToolbarCustomActions: () => {
      return (
        <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
          <Autocomplete
            onChange={(event, newValue) => {
              setSelectedRoom(newValue);
            }}
            value={selectedRoom}
            loading={isRoomsListLoading}
            options={roomsListOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                style={{ width: 200 }}
                label={t("selectRoom")}
                variant="outlined"
              />
            )}
          />
        </div>
      );
    },
    selectAllMode: "page",
  });

  return <MaterialReactTable table={table} />;
};

export default PlayersTable;
