import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Power, PowerOff } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Button, Checkbox, Popconfirm } from "antd";
import {
  MRT_ColumnDef,
  MRT_TableInstance,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  useSysCfgAddNicknamesMutation,
  useSysCfgChangeEnabledMutation,
  useSysCfgDeleteMutation,
  useSysCfgQuery,
} from "@/api/hooks/sys-cfg-hooks";
import { useCfgPerm } from "@/auth/hooks";
import BulkOperationReportModal from "@/components/bulk-operation-report-modal";
import NameConfigAddModal from "@/components/config-modals/name-config-add-modal";
import { useTableSettings } from "@/hooks/use-table-settings";
import { ROOM_CONFIGS_NAMES } from "@/lib/constants";
import { ResultDtoLong, SysCfgRegularDto } from "@/shared/api";

const NameConfigTable = ({ room }: { room: string }) => {
  const { t } = useTranslation("room-configs");

  const {
    data: configsRs,
    isLoading: configsIsLoading,
    refetch,
  } = useSysCfgQuery({
    domain: room,
    config_name: ROOM_CONFIGS_NAMES.NICKNAME,
  });

  const cfgPerm = useCfgPerm({
    roomName: room,
    cfgName: ROOM_CONFIGS_NAMES.NICKNAME,
  });

  const roomConfigsSortedData = React.useMemo(() => {
    return (
      configsRs?.data.content?.sort(
        (a, b) => new Date(b.created_at).getDate() - new Date(a.created_at).getDate()
      ) ?? []
    );
  }, [configsRs]);

  const { mutate: addNicknamesMutate } = useSysCfgAddNicknamesMutation();
  const { mutate: changeEnabledMutate } = useSysCfgChangeEnabledMutation();
  const { mutate: deleteCfgsMutate } = useSysCfgDeleteMutation();

  const [bulkOperationResult, setBulkOperationResult] = useState<ResultDtoLong[] | undefined>(
    undefined
  );

  //This logic for reset row selection while using filtering/grouping
  const [addNameConfigModalIsOpen, setAddNameConfigModalIsOpen] = useState<boolean>(false);

  function handleClickAddConfig() {
    setAddNameConfigModalIsOpen(true);
  }

  function handleClickSaveNewConfig(value: string[]) {
    addNicknamesMutate(
      {
        domain: room,
        nicknames: value,
      },
      {
        onSuccess: (response) => {
          void refetch();
          enqueueSnackbar(
            t("nicknamesHasBeenAdded", {
              data: response.data.uploaded,
            }),
            {
              variant: "success",
            }
          );
          setAddNameConfigModalIsOpen(false);
        },
        onError: (err) => {
          enqueueSnackbar(
            t("operationFailureContactAdministrator", { message: err.message }),
            {
              variant: "error",
            }
          );
          console.error("Adding nicknames failure", err);
        },
      }
    );
  }

  function handleChangeEnableBulk(
    table: MRT_TableInstance<SysCfgRegularDto>,
    enabled: boolean
  ) {
    const names = table.getSelectedRowModel().rows.map((item) => item.original.name);

    changeEnabledMutate(
      {
        domain: room,
        cfgName: ROOM_CONFIGS_NAMES.NICKNAME,
        enabled: enabled,
        filters: new Set([
          { key: "name", mode: "STRICT_IN", value: names as unknown as object },
        ]),
      },
      {
        onSuccess: (response) => {
          enqueueSnackbar(
            t("elementsHasBeenUpdated", {
              data: response.data,
            }),
            {
              variant: "success",
            }
          );
          table.resetRowSelection();
          void refetch();
        },
        onError: (err) => {
          enqueueSnackbar(
            t("operationFailureContactAdministrator", { message: err.message }),
            {
              variant: "error",
            }
          );
          console.error("Change enabled operation failure", err);
        },
      }
    );
  }

  function handleDeleteConfigs(table: MRT_TableInstance<SysCfgRegularDto>) {
    const names = table.getSelectedRowModel().rows.map((item) => item.original.name);

    deleteCfgsMutate(
      {
        domain: room,
        cfgName: ROOM_CONFIGS_NAMES.NICKNAME,
        filters: new Set([
          { key: "name", mode: "STRICT_IN", value: names as unknown as object },
        ]),
      },
      {
        onSuccess: (response) => {
          setBulkOperationResult([response.data]);
          response.data.dataOnError
            ? enqueueSnackbar(t("somethingWentWrong"), { variant: "error" })
            : enqueueSnackbar(t("elementsHasBeenDeleted"), { variant: "success" });
          table.resetRowSelection();
          void refetch();
        },
        onError: (err) => {
          enqueueSnackbar(
            t("operationFailureContactAdministrator", { message: err.message }),
            {
              variant: "error",
            }
          );
          console.error("Delete operation failure", err);
        },
      }
    );
  }

  const columns = useMemo<MRT_ColumnDef<SysCfgRegularDto>[]>(
    () => [
      {
        header: t("nameTable.name"),
        accessorKey: "name",
        filterFn: "contains",
      },
      {
        header: t("nameTable.isUsed"),
        accessorFn: (row) => `${(row.data as any)?.is_used ?? "-"}`,
        filterFn: "contains",
      },
      {
        header: t("nameTable.enabled"),
        filterFn: "contains",
        enableEditing: false,
        accessorFn: (row) => row.enabled ?? "-",
        Cell: ({ row }) => <Checkbox disabled checked={row.original.enabled} />,
      },
    ],
    [t]
  );

  const {
    defaultProps,
    columnVisibility,
    columnOrder,
    columnFilters,
    globalFilter,
    grouping,
    pagination,
    sorting,
  } = useTableSettings<SysCfgRegularDto>("name");

  const table = useMaterialReactTable({
    data: roomConfigsSortedData ?? [],
    columns: columns,
    getRowId: (originalRow) => originalRow.id,
    ...defaultProps,
    initialState: {
      pagination: { pageSize: 20, pageIndex: 0 },
      density: "compact",
      isLoading: configsIsLoading,
      sorting: [{ id: "name", desc: false }],
    },
    state: {
      columnFilters,
      globalFilter,
      grouping,
      columnVisibility,
      columnOrder,
      pagination,
      sorting,
    },
    enableRowSelection: true,
    enableGrouping: true,
    enableColumnDragging: true,
    enableColumnOrdering: true,
    enableRowActions: true,
    enablePagination: true,
    enableRowVirtualization: true,
    rowVirtualizerOptions: { overscan: 20 },
    renderTopToolbarCustomActions: () => {
      return (
        <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
          {cfgPerm.write && (
            <Button onClick={() => handleClickAddConfig()}>
              <PlusOutlined />
              {t("new")}
            </Button>
          )}

          {table.getSelectedRowModel().rows.length > 0 && (
            <div style={{ marginLeft: 30 }}>
              {cfgPerm.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="success"
                  onClick={() => handleChangeEnableBulk(table, true)}
                >
                  <Power /> {t("enable")}
                </IconButton>
              )}

              {cfgPerm.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="error"
                  onClick={() => handleChangeEnableBulk(table, false)}
                >
                  <PowerOff /> {t("disable")}
                </IconButton>
              )}

              {cfgPerm.delete && (
                <Popconfirm
                  title={t("deleteItems")}
                  description={t("areYouSureToDeleteItems")}
                  onConfirm={() => handleDeleteConfigs(table)}
                >
                  <IconButton size="small" style={{ fontSize: "15px" }} color="error">
                    <DeleteOutlined /> {t("delete")}
                  </IconButton>
                </Popconfirm>
              )}
            </div>
          )}
        </div>
      );
    },
  });

  useEffect(() => {
    table.resetRowSelection();
  }, [columnFilters, grouping, globalFilter, table]);

  return (
    <>
      <MaterialReactTable table={table} />
      {addNameConfigModalIsOpen && cfgPerm.write && (
        <NameConfigAddModal
          title={t("addNewName")}
          open={addNameConfigModalIsOpen}
          onCancel={() => setAddNameConfigModalIsOpen(false)}
          onSave={(config) => handleClickSaveNewConfig(config)}
        />
      )}

      {bulkOperationResult && (
        <BulkOperationReportModal
          resultList={bulkOperationResult}
          title={t("operationResult")}
          showProblematic
          showSuccess
          onCancel={() => setBulkOperationResult(undefined)}
          open={!!bulkOperationResult}
        />
      )}
    </>
  );
};

export default NameConfigTable;
