import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-lua";
import "ace-builds/src-noconflict/theme-dracula";
import "ace-builds/webpack-resolver";
import { useAtom } from "jotai/index";
import React, { useEffect, useMemo } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useFieldArray } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

import { useAuthGroupsQuery } from "@/api/hooks/auth-groups-hooks";
import { useSysCfgRawQuery } from "@/api/hooks/sys-cfg-hooks";
import { useSysDomainsQuery } from "@/api/hooks/sys-domain-hooks";
import {
  beforeSendNormalizers,
  formInitState,
  ruleRange,
} from "@/components/config-modals/compensator-edit-modal/constants";
import {
  EditCompensator,
  EditedCompensator,
} from "@/components/config-modals/compensator-edit-modal/types";
import CustomSelectOption from "@/components/custom-select-option";
import { isOwner } from "@/lib/auth-predicates";
import { ROOM_CONFIGS_NAMES, ROOM_NAMES } from "@/lib/constants";
import { meStore } from "@/store/store";

export default function CompensatorCreateModal({
  open,
  mode,
  initialData,
  onSave,
  onCancel,
  disabled = false,
}: {
  open: boolean;
  mode?: string;
  initialData?: EditCompensator;
  onSave?: (data: EditedCompensator) => void;
  onCancel?: () => void;
  confirmLoading?: boolean;
  disabled?: boolean;
}) {
  const { t } = useTranslation("room-configs");
  const { t: tValidation } = useTranslation("validation");
  const [me] = useAtom(meStore);

  const { data: authGroupsRs, isLoading: authGroupsIsLoading } = useAuthGroupsQuery();

  const defaultValues = useMemo<EditedCompensator>(() => {
    let authGroups = initialData?.auth_groups ? Array.from(initialData.auth_groups) : [];

    if (authGroupsRs?.data.length === 1 && !isOwner(me)) {
      authGroups = authGroupsRs.data.map((it) => it.name);
    }

    return initialData
      ? {
          ...initialData,
          enabled: true,
          authGroups: authGroups,
        }
      : {
          ...formInitState,
          authGroups: authGroups,
        };
  }, [initialData, me, authGroupsRs]);

  const {
    register,
    control,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<EditedCompensator>({ mode: "onTouched", defaultValues: defaultValues });

  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues, open, reset]);

  const chosenRoom = watch("data.room");

  const { data: roomsRs, isLoading: roomsIsLoading } = useSysDomainsQuery(true);

  const { data: aiProfilesRs, isLoading: aiProfilesIsLoading } = useSysCfgRawQuery(
    {
      domain: ROOM_NAMES.SERVICES,
      config_name: ROOM_CONFIGS_NAMES.AI_PROFILE,
    },
    {
      enabled: open,
    }
  );

  const { data: gameGroupsRs } = useSysCfgRawQuery(
    {
      domain: chosenRoom as string,
      config_name: ROOM_CONFIGS_NAMES.GAME_GROUP,
    },
    {
      enabled: open && !!chosenRoom,
    }
  );

  const aiProfilesShorten = useMemo(() => aiProfilesRs?.data ?? [], [aiProfilesRs]);
  const gameGroupsShorten = useMemo(() => gameGroupsRs?.data ?? [], [gameGroupsRs]);

  const onSubmit: SubmitHandler<EditedCompensator> = (data) => {
    beforeSendNormalizers.forEach((normalizer) => (data = normalizer(data)));

    data.name = "" //TODO: move to Back-end
      .concat(data.data?.room ?? "empty")
      .concat("_")
      .concat(data.data?.game_group ?? "empty")
      .concat("_")
      .concat(data.data?.game_type ?? "empty")
      .concat("_")
      .concat(data.data?.limit?.toString() ?? "empty");

    if (!onSave) return;
    onSave(data);

    onCancel && onCancel();
  };

  const send = handleSubmit(onSubmit);

  const { fields, append, remove } = useFieldArray({
    name: "data.comp_min",
    rules: { minLength: 1, required: t("oneElementRequired") },
    control,
  });

  const {
    fields: compMaxFields,
    append: compMaxAppend,
    remove: compMaxRemove,
  } = useFieldArray({
    name: "data.comp_max",
    rules: { minLength: 1, required: t("oneElementRequired") },
    control,
  });

  return (
    <>
      <Dialog open={open} key="compensator-modal">
        <DialogTitle>
          {mode === "add" ? t("addCompensator") : t("editCompensator")}
          <IconButton
            aria-label="close"
            onClick={() => onCancel && onCancel()}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "500px",
            }}
          >
            <input key="dialog-compensator-name" type="hidden" {...register("name")} />

            <Controller
              control={control}
              name="authGroups"
              rules={{
                required: !isOwner(me) ? tValidation("fieldRequired") : undefined,
              }}
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  multiple
                  disabled={disabled || (authGroupsRs?.data.length === 1 && !isOwner(me))}
                  loading={authGroupsIsLoading}
                  value={field.value}
                  onChange={(_, data) => field.onChange(data)}
                  options={authGroupsRs?.data?.map((i) => i.name) ?? []}
                  disableCloseOnSelect
                  renderOption={(props, option, { selected }) => (
                    <CustomSelectOption label={option} selected={selected} props={props} />
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={!isOwner(me) ? t("authGroupsRequired") : t("authGroups")}
                      placeholder={t("authGroups")}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />

            {mode === "add" && (
              <div style={{ display: "flex", alignContent: "center" }}>
                <input
                  key="dialog-compensator-checkbox"
                  type="checkbox"
                  {...register("enabled")}
                  disabled={disabled}
                />
                <p style={{ marginLeft: "3px" }}>{t("enabled")}</p>
              </div>
            )}

            <TextField
              key="dialog-compensator-comment"
              {...register("data.comment")}
              label={t("comment")}
              placeholder={t("comment")}
              variant="standard"
              disabled={disabled}
              error={!!errors.data?.comment}
              helperText={!!errors.data?.comment && errors.data?.comment.message}
            />

            <p>{t("compMin")}</p>
            {fields.map((field, index) => (
              <div key={field.id} style={{ display: "flex" }}>
                <Controller
                  control={control}
                  name={`data.comp_min.${index}.from`}
                  rules={{ required: tValidation("fieldRequired") }}
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      {...field}
                      sx={{ width: "220px", mr: "10px" }}
                      loading={aiProfilesIsLoading}
                      value={field.value === "" ? null : field.value}
                      onChange={(_, data) => field.onChange(data)}
                      disabled={disabled}
                      options={aiProfilesShorten.map((it) => it.name)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label={t("fromRequired")}
                          placeholder={t("from")}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name={`data.comp_min.${index}.to`}
                  rules={{ required: tValidation("fieldRequired") }}
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      {...field}
                      sx={{ width: "220px", mr: "10px" }}
                      loading={aiProfilesIsLoading}
                      value={field.value === "" ? null : field.value}
                      disabled={disabled}
                      onChange={(_, data) => field.onChange(data)}
                      options={aiProfilesShorten.map((it) => it.name)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label={t("toRequired")}
                          placeholder={t("to")}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />

                <IconButton onClick={() => remove(index)} disabled={disabled}>
                  <ClearIcon />
                </IconButton>
              </div>
            ))}
            <Button
              type="button"
              disabled={disabled}
              onClick={() => append({ from: "", to: "" })}
            >
              {t("add")}
            </Button>
            {!!errors?.data?.comp_min?.root?.message && (
              <div>
                <span style={{ color: "red" }}>{errors?.data?.comp_min?.root?.message}</span>
              </div>
            )}

            <p>{t("compMax")}</p>
            {compMaxFields.map((field, index) => (
              <div key={field.id} style={{ display: "flex" }}>
                <Controller
                  control={control}
                  name={`data.comp_max.${index}.from`}
                  rules={{ required: tValidation("fieldRequired") }}
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      {...field}
                      sx={{ width: "220px", mr: "10px" }}
                      loading={aiProfilesIsLoading}
                      value={field.value === "" ? null : field.value}
                      disabled={disabled}
                      onChange={(_, data) => field.onChange(data)}
                      options={aiProfilesShorten.map((it) => it.name)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label={t("fromRequired")}
                          placeholder={t("from")}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name={`data.comp_max.${index}.to`}
                  rules={{ required: tValidation("fieldRequired") }}
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      {...field}
                      sx={{ width: "220px", mr: "10px" }}
                      loading={aiProfilesIsLoading}
                      value={field.value === "" ? null : field.value}
                      disabled={disabled}
                      onChange={(_, data) => field.onChange(data)}
                      options={aiProfilesShorten.map((it) => it.name)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label={t("toRequired")}
                          placeholder={t("to")}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />

                <IconButton onClick={() => compMaxRemove(index)} disabled={disabled}>
                  <ClearIcon />
                </IconButton>
              </div>
            ))}

            <Button
              type="button"
              disabled={disabled}
              onClick={() => compMaxAppend({ from: "", to: "" })}
            >
              {t("add")}
            </Button>
            {errors?.data?.comp_max?.root?.message && (
              <div>
                <span style={{ color: "red" }}>{errors?.data?.comp_max?.root?.message}</span>
              </div>
            )}

            <Controller
              control={control}
              name="data.room"
              rules={{ required: tValidation("fieldRequired") }}
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  loading={roomsIsLoading}
                  value={field.value === "" ? null : field.value}
                  disabled={disabled}
                  onChange={(_, data) => field.onChange(data)}
                  options={roomsRs?.data.map((it) => it.name) ?? []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={t("roomRequired")}
                      placeholder={t("roomSimple")}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />

            <Controller
              control={control}
              name="data.rule_range"
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  loading={roomsIsLoading}
                  value={field.value === "" ? null : field.value}
                  disabled={disabled}
                  onChange={(_, data) => field.onChange(data)}
                  options={ruleRange.map((it) => it.value) ?? []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={t("periodRuleRange")}
                      placeholder={t("periodRuleRange")}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />

            <TextField
              key="dialog-input-aid"
              {...register("data.aid", { required: tValidation("fieldRequired") })}
              label={t("aidRequired")}
              placeholder={t("aid")}
              variant="standard"
              error={!!errors?.data?.aid?.message}
              helperText={errors?.data?.aid?.message}
              disabled={disabled}
            />

            <Controller
              control={control}
              name="data.game_group"
              rules={{ required: tValidation("fieldRequired") }}
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  loading={roomsIsLoading}
                  value={field.value === "" ? null : field.value}
                  disabled={disabled || !chosenRoom}
                  onChange={(_, data) => field.onChange(data)}
                  options={gameGroupsShorten.map((it) => it.name) ?? []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("gameGroupRequired")}
                      placeholder={t("gameGroup")}
                      variant="standard"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />

            <TextField
              key="dialog-input-limit"
              {...register("data.limit", {
                required: tValidation("fieldRequired"),
                valueAsNumber: true,
                max: {
                  value: 50000000,
                  message: tValidation("numberShouldBeLessThan", { value: 50000000 }),
                },
                min: {
                  value: 0,
                  message: tValidation("numberShouldBeHigherThan", { value: 0 }),
                },
              })}
              label={t("limitRequired")}
              placeholder={t("limit")}
              variant="standard"
              error={!!errors.data?.limit?.message}
              helperText={errors?.data?.limit?.message}
              disabled={disabled}
            />

            <TextField
              key="dialog-input-game-type"
              {...register("data.game_type", { required: tValidation("fieldRequired") })}
              label={t("gameTypeRequired")}
              placeholder={t("gameType")}
              variant="standard"
              error={!!errors?.data?.game_type?.message}
              helperText={errors?.data?.game_type?.message}
              disabled={disabled}
            />

            <TextField
              key="dialog-input-raa-min"
              {...register("data.RAA_min", {
                required: tValidation("fieldRequired"),
                valueAsNumber: true,
                max: {
                  value: 50000000,
                  message: tValidation("numberShouldBeLessThan", { value: 50000000 }),
                },
                min: {
                  value: -50000000,
                  message: t("numberShouldBeHigherThan", { value: -50000000 }),
                },
              })}
              label={t("resultMinRequired")}
              placeholder={t("resultMin")}
              variant="standard"
              error={!!errors?.data?.RAA_min?.message}
              helperText={errors?.data?.RAA_min?.message}
              disabled={disabled}
            />

            <TextField
              key="dialog-input-raa-max"
              {...register("data.RAA_max", {
                required: tValidation("fieldRequired"),
                valueAsNumber: true,
                max: { value: 50000000, message: "Number should be less than 50000000" },
                min: { value: -50000000, message: "Number should be higher than -50000000" },
              })}
              label={t("resultMaxRequired")}
              placeholder={t("resultMax")}
              variant="standard"
              error={!!errors?.data?.RAA_max?.message}
              helperText={errors?.data?.RAA_max?.message}
              disabled={disabled}
            />

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="button"
                style={{ marginRight: "5px" }}
                onClick={() => onCancel && onCancel()}
              >
                {t("submit")}
              </Button>
              <Button variant="contained" onClick={send} disabled={disabled}>
                {t("submit")}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
