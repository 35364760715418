import { MRT_ColumnDef, MRT_TableOptions, useMaterialReactTable } from "material-react-table";
import { SysAccountSessionRegularDto } from "@/shared/api";


export const useSessionsTable = (
  {
    columns,
    data,
    isDataLoadingError,
    loadingErrorMessage
  } : {
    columns: MRT_ColumnDef<SysAccountSessionRegularDto>[];
    data?: SysAccountSessionRegularDto[];
    isDataLoadingError?: boolean;
    loadingErrorMessage?: string;
  },
  tableOptions?: Omit<MRT_TableOptions<SysAccountSessionRegularDto>, 'columns' | 'data'>
) => useMaterialReactTable({
  data: data ?? [],
  columns: columns,
  getRowId: (originalRow) => originalRow.id,
  enableSelectAll: true,
  enableRowSelection: true,
  enableColumnDragging: false,
  enableColumnFilterModes: true,
  muiToolbarAlertBannerProps: isDataLoadingError ? {
    color: 'error',
    children: `Error loading data ${loadingErrorMessage ? ":" + loadingErrorMessage : ""}`,
  } : undefined,

  enablePagination: true,
  manualPagination: true,

  enableStickyHeader: true,
  enableSorting: true,
  autoResetPageIndex: false,
  enableGlobalFilter: false,
  defaultColumn: {
    minSize: 20, //allow columns to get smaller than default
    maxSize: 40, //allow columns to get larger than default
    size: 40, //make columns wider by default
  },

  muiTablePaperProps: {
    sx: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
    },
  },
  muiTableContainerProps: {
    sx: {
      flexGrow: 10,
      overflowY: "scroll",
      height: "500px",
    },
  },
  ...tableOptions,
  initialState: {
    columnVisibility: {
      clubs: false,
      "$.gold": false,
      "$.account.data.alc": false,
      "$.account.data.clubs": false
    },
    density: "compact",
    ...tableOptions?.initialState
  },
})