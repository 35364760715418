import { UserResponseRegularDto } from "@/shared/api";

export type Me = UserResponseRegularDto | undefined;

export const isAuthorized = (me: Me): boolean => !!me

export const isSuperAdmin = (me?: Me): boolean => me?.username === "super_admin"

export const isOwner = (me?: Me): boolean => !!me && (
  me.username === "super_admin" || me.is_owner
)

export const hasAnyRoomConfigInAccess = (me: Me): boolean => {
  if(!me) return false;
  return me.rooms.some(room => room.configs.length > 0)
}

export const hasAnyServiceConfigInAccess = (me: Me): boolean => {
  if(!me) return false;
  return me.service.configs.length > 0;
}

export const hasConfig = (me: Me, configName: string): boolean => {
  if(!me) return false;
  return me.rooms.some(room =>
    room.configs.some(config =>
      config.name === configName
    )
  )
}