import { FormContainer, TextFieldElement, useForm } from "react-hook-form-mui";
import { useEffect, useMemo } from "react";
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Stack } from "@mui/system";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";


const CreateDomainConfigModal = (
  {
    open,
    onClose,
    onSave,
    isLoading = false
  } : {
    open: boolean;
    onClose: () => void;
    onSave: (name: string) => void;
    isLoading?: boolean;
  }
) => {

  const schema = useMemo(() => (z.object({
    name: z.string().min(1, "Field is required")
      // eslint-disable-next-line no-control-regex
      .regex(/^(?!system\.)(?!.*\.system\.)[A-Za-z_][^\x00$]*$/,
        "Starts with (A-Z or a-z or _). Doesn't start with 'system.'. " +
        "Without '.system.'. Without '$' sign."
      )
  })), [])

  type SchemaType = z.infer<typeof schema>

  const form = useForm<SchemaType>({
    mode: "onChange",
    defaultValues: {name: ""},
    resolver: zodResolver(schema)
  });

  const onCloseHandler = () => {
    form.reset()
    onClose()
  }

  useEffect(() => {
    form.reset()
  }, [open, form])


  return (
    <Dialog open={open} onClose={onCloseHandler} fullWidth maxWidth="sm">
      <DialogTitle title="Create User">Create domain config</DialogTitle>
      <DialogContent style={{paddingTop: "5px"}}>
        <FormContainer formContext={form} onSuccess={val => onSave(val.name)}>
          <Stack spacing={2}>
            <TextFieldElement name="name" label="Config Name" required fullWidth/>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={isLoading}
              startIcon={isLoading && <CircularProgress size={18} />}
            >Create</Button>
          </Stack>
        </FormContainer>
      </DialogContent>
    </Dialog>
  );
}


export default CreateDomainConfigModal