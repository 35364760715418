import { EditedClusteringPattern} from "./types";

export const formInitState: EditedClusteringPattern = {
  name: "",
  authGroups: [],
  enabled: true,
  data: {
    script: "",
    category: undefined,
    pids: undefined,
  }
};

export const required = [{required: true, message: "Field is required"}];

export const beforeSendNormalizers: ((val: EditedClusteringPattern) => EditedClusteringPattern)[] = [
  (value: EditedClusteringPattern) => {
    value.name = value.name?.trim();
    return value;
  }
]