import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import StatserviceTable from "@/module/service-configs-module/statservice-module/statservice-table";
import { Route } from "@/routes/service-config/$configName";

export const statServiceRoomVariants = [
  "hh",
  "ff",
  "aa",
  "wpc",
  "pw",
  "pl",
  "dpzx",
  "wp",
  "dd",
  "ep",
  "tpuke",
  "rd",
];

const StatserviceModule = () => {
  const { t } = useTranslation("service-configs");
  const navigate = Route.useNavigate();
  const { statserviceTab: tabValue } = Route.useSearch();

  const setTabValue = (value: number) => {
    void navigate({ search: { statserviceTab: value } });
  };

  const [oneDayAmount, setOneDayAmount] = useState<number>(0);
  const [sevenDaysAmount, setSevenDaysAmount] = useState<number>(0);
  const [thirtyDaysAmount, setThirtyDaysAmount] = useState<number>(0);

  const [selectedRoom, setSelectedRoom] = useState<string | null>(null);

  return (
    <>
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs onChange={(_e, value) => setTabValue(value)} value={tabValue}>
            <Tab
              label={t("daysAmount", {
                days: 1,
                amount: oneDayAmount,
              })}
              value={0}
            />
            <Tab
              label={t("daysAmount", {
                days: 7,
                amount: sevenDaysAmount,
              })}
              value={1}
            />
            <Tab
              label={t("daysAmount", {
                days: 30,
                amount: thirtyDaysAmount,
              })}
              value={2}
            />
          </Tabs>
        </Box>

        <div style={{ height: "100%" }}>
          {tabValue === 0 && (
            <div style={{ height: "100%" }}>
              <StatserviceTable
                setRowsAmount={(amount: number) => setOneDayAmount(amount)}
                period={1}
                selectedRoom={selectedRoom}
                setSelectedRoom={setSelectedRoom}
              />
            </div>
          )}

          {tabValue === 1 && (
            <div style={{ height: "100%" }}>
              <StatserviceTable
                setRowsAmount={(amount: number) => setSevenDaysAmount(amount)}
                period={7}
                selectedRoom={selectedRoom}
                setSelectedRoom={setSelectedRoom}
              />
            </div>
          )}

          {tabValue === 2 && (
            <div style={{ height: "100%" }}>
              <StatserviceTable
                setRowsAmount={(amount: number) => setThirtyDaysAmount(amount)}
                period={30}
                selectedRoom={selectedRoom}
                setSelectedRoom={setSelectedRoom}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default StatserviceModule;
