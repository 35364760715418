import { IconButton } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import React, { useState } from "react";
import RequestReplyModal from "@/module/accounts-module/components/request-replay-modal";
import { MRT_TableInstance } from "material-react-table";
import { SysAccountSessionRegularDto } from "@/shared/api";
import { useRequestReplayMutation } from "@/api/hooks/command-hooks";
import { message } from "antd";


const RequestReplayTableControl = (
  {
    roomName,
    table,
    onSuccess
  } : {
    roomName: string;
    table: MRT_TableInstance<SysAccountSessionRegularDto>;
    onSuccess?: () => void;
  }
) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const mutation = useRequestReplayMutation();

  function handleMutationAction(tid: string, hid: string) {
    const rows = table.getSelectedRowModel().rows;

    if (rows.length !== 1) {
      message.error("More than one rows are selected").then()
      return
    }

    const sessionId = rows[0].original.id;
    let hidNumber;

    try {
      hidNumber = Number.parseInt(hid);
    } catch (e) {
      message.error("Couldn't find hid number").then();
      return;
    }

    callMutation(sessionId, tid, hidNumber);
  }

  function callMutation(sessionId: string, tid: string, hid: number) {
    mutation.mutate({
      domain: roomName,
      sessionId: sessionId,
      tid: tid,
      hid: hid
    }, {
      onSuccess: () => {
        message.success(`leave clubs command successfully sent`).then()
        setIsOpen(false);
        onSuccess && onSuccess()
      },
      onError: (err) => {
        console.error("leave clubs command error", err)
        message.error("Something went wrong during clubs is being leaved, contact the administrator").then()
      }
    })
  }

  return (
    <>
      <IconButton
        size="small"
        style={{ fontSize: 12 }}
        color="primary"
        disabled={table.getSelectedRowModel().rows.length !== 1}
        onClick={() => setIsOpen(true)}
      >
        <ReplayIcon /> Request replay
      </IconButton>
      <RequestReplyModal
        isOpen={isOpen}
        onCancel={() => setIsOpen(false)}
        title="Request replay"
        onSave={(hid, tid) => handleMutationAction(tid, hid)}
      />
    </>
  )
}



export default RequestReplayTableControl;