import { MRT_ColumnDef, MRT_TableOptions, useMaterialReactTable } from "material-react-table";
import React, { useMemo } from "react";
import { NameIdentityItem } from "@/types/commonTypes";
import CreateDomainCfgTableControl from "@/module/room-management-module/components/create-domain-cfg-table-control";


const useConfigsListTable = (
  {
    domain,
    configs,
    refetchConfigs
  } : {
    domain: string,
    configs: NameIdentityItem[],
    refetchConfigs: () => void,
  },
  tableOptions?: Omit<MRT_TableOptions<NameIdentityItem>, 'columns' | 'data'>
) => {

  const columns = useMemo<MRT_ColumnDef<NameIdentityItem>[]>(() => [
    {
      id: "name",
      header: "Name",
      accessorFn: (row) => row.name,
      grow: 1
    }
  ], [])


  return useMaterialReactTable({
    data: configs,
    columns: columns,
    getRowId: (row) => String(row.name),
    layoutMode: "grid",
    enableStickyHeader: true,
    renderTopToolbarCustomActions: () => {
      return (
        <div>
          <CreateDomainCfgTableControl domain={domain} onSuccess={() => refetchConfigs()}/>
        </div>
      )
    },
    ...tableOptions,
    initialState: {
      density: "compact",
      ...tableOptions?.initialState,
      sorting: [{ id: 'name', desc: false }]
    },
  })
}

export default useConfigsListTable;
