import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form-mui";
import { SysCfgApplyAuthGroupsRequestDto } from "@/shared/api";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useMemo } from "react";
import { z } from "zod";
import { Controller } from "react-hook-form";


type FormData = Omit<SysCfgApplyAuthGroupsRequestDto, "filters">;

interface ApplyAuthGroupsModalProps extends Omit<DialogProps, 'children' | 'onSubmit'> {
  onClose: () => void;
  onSave: (data: FormData) => void;  
  authGroups: string[],
  authGroupsIsLoading?: boolean;
  disabled?: boolean;
  confirmLoading?: boolean;
}


const ApplyAuthGroupsModal = ({
  onSave,
  onClose,
  authGroups,
  authGroupsIsLoading = false,
  disabled = false,
  confirmLoading = false,
  open,
  ...dialogProps
} : ApplyAuthGroupsModalProps) => {

  const schema = useMemo(() => (
    z.object({
      auth_groups: z.array(z.string()),
    })
  ), []);
  
  const { control, handleSubmit, reset } =
    useForm<FormData>({
      mode: "onChange",
      defaultValues: { auth_groups: [] },
      resolver: zodResolver(schema)
    })

  useEffect(() => {
    reset({ auth_groups: [] })
  }, [open, reset]);

  const submit = handleSubmit((data) => onSave(data));
  const handleSave = () => void submit()
  
  return (
    <Dialog open={open} {...dialogProps}>
      <DialogTitle>Apply Auth Groups</DialogTitle>
      <DialogContent>
        <Controller
          control={control}
          name="auth_groups"
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              {...field}
              multiple
              disabled={disabled}
              loading={authGroupsIsLoading}
              value={field.value}
              onChange={(_, data) => field.onChange(data)}
              options={authGroups}
              disableCloseOnSelect
              renderInput={params => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Auth Groups"
                  placeholder="Auth Groups"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button
          sx={{width: "100px"}}
          variant="outlined"
          onClick={onClose}
        >Cancel</Button>

        <Button
          sx={{width: "100px"}}
          variant="contained"
          disabled={disabled || confirmLoading}
          startIcon={confirmLoading ? <CircularProgress size={20}/> : undefined}
          onClick={handleSave}
        >Save</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ApplyAuthGroupsModal