import { MRT_ColumnDef, MRT_TableOptions, useMaterialReactTable } from "material-react-table";
import { SysDomainRegularDto } from "@/shared/api";
import React, { useMemo } from "react";
import { Checkbox } from "@mui/material";
import CreateDomainTableControl from "@/module/rooms-management-module/components/create-domain-table-control";


const useDomainsListTable = (
  {
    domains,
    refetchDomains
  } : {
    domains: SysDomainRegularDto[],
    refetchDomains: () => void,
  },
  tableOptions?: Omit<MRT_TableOptions<SysDomainRegularDto>, 'columns' | 'data'>
) => {

  const columns = useMemo<MRT_ColumnDef<SysDomainRegularDto>[]>(() => [
    {
      id: "name",
      header: "Name",
      accessorFn: (row) => row.name
    },
    {
      id: "is_room",
      header: "Is Room",
      accessorFn: (row) => row.is_room ? 'true' : 'false',
      Cell: ({ cell }) => (
        <Checkbox checked={cell.getValue() === 'true'} disabled />
      )
    }
  ], [])


  return useMaterialReactTable({
    data: domains,
    columns: columns,
    getRowId: (row) => String(row.name),
    initialState: {
      density: "compact",
    },
    enableStickyHeader: true,
    renderTopToolbarCustomActions: () => {
      return (
        <div>
          <CreateDomainTableControl onSuccess={() => refetchDomains()}/>
        </div>
      )
    },
    ...tableOptions
  })
}


export default useDomainsListTable