import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Edit, Power, PowerOff } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { Button, Checkbox, Popconfirm } from "antd";
import {
  MRT_ColumnDef,
  MRT_TableInstance,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  useSysCfgChangeEnabledMutation,
  useSysCfgCreateMutation,
  useSysCfgDeleteMutation,
  useSysCfgQuery,
  useSysCfgUpdateMutation,
} from "@/api/hooks/sys-cfg-hooks";
import { useCfgPerm, useIsOwnerOrSuperAdmin } from "@/auth/hooks";
import ApplyAuthGroupsTableControl from "@/components/apply-auth-groups-table-control";
import BulkOperationReportModal from "@/components/bulk-operation-report-modal";
import CommonConfigWriteModal from "@/components/config-modals/common-config-write-modal";
import RegularConfigCreateModal from "@/components/config-modals/regular-config-create-modal";
import RegularConfigEditModal from "@/components/config-modals/regular-config-edit-modal";
import { useTableSettings } from "@/hooks/use-table-settings";
import { assembleNamesFilter } from "@/lib/cfg-utils";
import { ROOM_CONFIGS_NAMES } from "@/lib/constants";
import { useGetJsonForm } from "@/module/room-configs-module/hooks/room-configs-hooks";
import {
  ResultDtoLong,
  SysCfgCreateRequestDto,
  SysCfgRegularDto,
  SysCfgUpdateRequestDto,
} from "@/shared/api";

const GameGroupConfigTable = ({ room }: { room: string }) => {
  const { t } = useTranslation("room-configs");
  const {
    data: configsRs,
    isLoading: configsIsLoading,
    refetch,
  } = useSysCfgQuery({
    domain: room,
    config_name: ROOM_CONFIGS_NAMES.GAME_GROUP,
  });

  const cfgPerm = useCfgPerm({
    roomName: room,
    cfgName: ROOM_CONFIGS_NAMES.GAME_GROUP,
  });

  const roomConfigsSortedData = React.useMemo(() => {
    return (
      configsRs?.data.content?.sort(
        (a, b) => new Date(b.created_at).getDate() - new Date(a.created_at).getDate()
      ) ?? []
    );
  }, [configsRs]);

  const isOwner = useIsOwnerOrSuperAdmin();

  const { data: jsonFormSchema } = useGetJsonForm({
    roomName: room,
    configName: ROOM_CONFIGS_NAMES.GAME_GROUP,
  });

  const { mutate: createCfgMutate } = useSysCfgCreateMutation();
  const { mutate: changeEnabledMutate } = useSysCfgChangeEnabledMutation();
  const { mutate: deleteCfgsMutate } = useSysCfgDeleteMutation();
  const { mutate: updateCfgMutate } = useSysCfgUpdateMutation();

  //This logic for reset row selection while using filtering/grouping
  const [editingConfig, setEditingConfig] = useState<SysCfgRegularDto | undefined>(undefined);
  const [addConfig, setAddConfig] = useState<boolean>(false);
  const [bulkOperationResult, setBulkOperationResult] = useState<ResultDtoLong[] | undefined>(
    undefined
  );

  //

  function handleClickAddConfig() {
    setAddConfig(true);
  }

  function handleClickSaveNewConfig(config: SysCfgCreateRequestDto) {
    createCfgMutate(
      {
        domain: room,
        cfgName: ROOM_CONFIGS_NAMES.GAME_GROUP,
        body: { ...config, auth_groups: config.auth_groups ?? new Set<string>() },
      },
      {
        onSuccess: () => {
          enqueueSnackbar(t("configSaved"), { variant: "success" });
          setAddConfig(false);
          void refetch();
        },
        onError: (err) => {
          enqueueSnackbar(
            t("operationFailureContactAdministrator", { message: err.message }),
            {
              variant: "error",
            }
          );
          console.error("Adding config failure", err);
        },
      }
    );
  }

  function handleClickUpdateConfig(name: string, config: SysCfgUpdateRequestDto) {
    updateCfgMutate(
      {
        domain: room,
        cfgName: ROOM_CONFIGS_NAMES.GAME_GROUP,
        name: name,
        body: config,
      },
      {
        onSuccess: () => {
          enqueueSnackbar(t("configUpdated"), { variant: "success" });
          setEditingConfig(undefined);
          void refetch();
        },
        onError: (err) => {
          enqueueSnackbar(
            t("operationFailureContactAdministrator", { message: err.message }),
            {
              variant: "error",
            }
          );
          console.error("Config edit failure", err);
        },
      }
    );
  }

  function handleChangeEnableBulk(
    table: MRT_TableInstance<SysCfgRegularDto>,
    enabled: boolean
  ) {
    const names = table.getSelectedRowModel().rows.map((item) => item.original.name);

    changeEnabledMutate(
      {
        domain: room,
        cfgName: ROOM_CONFIGS_NAMES.GAME_GROUP,
        enabled: enabled,
        filters: new Set([
          { key: "name", mode: "STRICT_IN", value: names as unknown as object },
        ]),
      },
      {
        onSuccess: (response) => {
          enqueueSnackbar(
            t("elementsHasBeenUpdated", {
              data: response.data,
            }),
            {
              variant: "success",
            }
          );
          table.resetRowSelection();
          void refetch();
        },
        onError: (err) => {
          enqueueSnackbar(
            t("operationFailureContactAdministrator", { message: err.message }),
            {
              variant: "error",
            }
          );
          console.error("Change enabled operation failure", err);
        },
      }
    );
  }

  function handleDeleteConfigs(table: MRT_TableInstance<SysCfgRegularDto>) {
    const names = table.getSelectedRowModel().rows.map((item) => item.original.name);

    deleteCfgsMutate(
      {
        domain: room,
        cfgName: ROOM_CONFIGS_NAMES.GAME_GROUP,
        filters: new Set([
          { key: "name", mode: "STRICT_IN", value: names as unknown as object },
        ]),
      },
      {
        onSuccess: (response) => {
          setBulkOperationResult([response.data]);
          response.data.dataOnError
            ? enqueueSnackbar(t("somethingWentWrong"), { variant: "error" })
            : enqueueSnackbar(t("elementsHasBeenDeleted"), { variant: "success" });
          table.resetRowSelection();
          void refetch();
        },
        onError: (err) => {
          enqueueSnackbar(
            t("operationFailureContactAdministrator", { message: err.message }),
            {
              variant: "error",
            }
          );
          console.error("Delete operation failure", err);
        },
      }
    );
  }

  const columns = useMemo<MRT_ColumnDef<SysCfgRegularDto>[]>(
    () => [
      {
        header: t("gameGroupTable.enable"),
        filterFn: "contains",
        enableEditing: false,
        accessorFn: (row) => row.enabled ?? "-",
        size: 80,
        Cell: ({ row }) => <Checkbox disabled checked={row.original.enabled} />,
      },
      {
        header: t("gameGroupTable.name"),
        accessorKey: "name",
        filterFn: "contains",
        size: 120,
      },
      {
        header: t("gameGroupTable.gold"),
        accessorKey: "data.chat_hub.gold",
        filterFn: "contains",
        accessorFn: (row) => (row.data as any).chat_hub?.gold ?? "-",
        size: 120,
      },
      {
        header: t("gameGroupTable.alc"),
        accessorFn: (row) => (row.data as any).play_alc?.toString(),
        filterFn: "contains",
        size: 120,
      },
    ],
    [t]
  );

  const {
    defaultProps,
    columnVisibility,
    columnOrder,
    columnFilters,
    globalFilter,
    grouping,
    pagination,
    sorting,
  } = useTableSettings<SysCfgRegularDto>("gameGroup");

  const table = useMaterialReactTable({
    data: roomConfigsSortedData ?? [],
    columns: columns,
    getRowId: (originalRow) => originalRow.id,
    selectAllMode: "all",
    ...defaultProps,
    initialState: {
      ...defaultProps.initialState,
      sorting: [{ id: "name", desc: false }],
    },
    state: {
      isLoading: configsIsLoading,
      columnFilters,
      globalFilter,
      grouping,
      columnVisibility,
      columnOrder,
      pagination,
      sorting,
    },
    enableSelectAll: true,
    enableRowSelection: true,
    enableGrouping: true,
    enableColumnDragging: true,
    enableColumnOrdering: true,
    enableRowActions: true,
    enablePagination: true,
    renderTopToolbarCustomActions: ({ table }) => {
      const selectedCount = table.getSelectedRowModel().rows.length;

      return (
        <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
          {cfgPerm.write && (
            <Button onClick={() => handleClickAddConfig()}>
              <PlusOutlined />
              {t("new")}
            </Button>
          )}

          {selectedCount > 0 && (
            <div style={{ marginLeft: 30 }}>
              {cfgPerm.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="success"
                  onClick={() => handleChangeEnableBulk(table, true)}
                >
                  <Power /> {t("enable")}
                </IconButton>
              )}

              {cfgPerm.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="error"
                  onClick={() => handleChangeEnableBulk(table, false)}
                >
                  <PowerOff /> {t("disable")}
                </IconButton>
              )}

              {cfgPerm.delete && (
                <Popconfirm
                  title={t("deleteItems")}
                  description={t("areYouSureToDeleteItems")}
                  onConfirm={() => handleDeleteConfigs(table)}
                >
                  <IconButton size="small" style={{ fontSize: "15px" }} color="error">
                    <DeleteOutlined /> {t("delete")}
                  </IconButton>
                </Popconfirm>
              )}

              {isOwner && (
                <ApplyAuthGroupsTableControl
                  domain={room}
                  cfgName={ROOM_CONFIGS_NAMES.GAME_GROUP}
                  table={table}
                  filters={assembleNamesFilter(table)}
                  totalElementsCount={selectedCount}
                  onSuccess={() => void refetch()}
                />
              )}
            </div>
          )}
        </div>
      );
    },
    renderRowActions: ({ row }) => (
      <Box>
        <IconButton onClick={() => setEditingConfig({ ...row.original })}>
          <Edit />
        </IconButton>
      </Box>
    ),
  });

  useEffect(() => {
    table.resetRowSelection();
  }, [columnFilters, grouping, globalFilter, table]);

  return (
    <>
      <MaterialReactTable table={table} />

      {addConfig && (
        <>
          {jsonFormSchema ? (
            <RegularConfigCreateModal
              title={t("createConfig")}
              open={!!(addConfig && jsonFormSchema)}
              uiSchema={jsonFormSchema}
              onSave={(config) => handleClickSaveNewConfig(config)}
              onCancel={() => setAddConfig(false)}
            />
          ) : (
            <CommonConfigWriteModal
              mode="add"
              isOpen={addConfig}
              onSave={(config) => handleClickSaveNewConfig(config)}
              onCancel={() => setAddConfig(false)}
              disabled={!cfgPerm.write}
            />
          )}
        </>
      )}

      {editingConfig && (
        <>
          {jsonFormSchema ? (
            <RegularConfigEditModal
              title={t("editConfig")}
              open={!!(editingConfig && jsonFormSchema)}
              uiSchema={jsonFormSchema}
              config={editingConfig}
              onSave={(config) => handleClickUpdateConfig(config.name, config)}
              onCancel={() => setEditingConfig(undefined)}
              disabled={!cfgPerm.write}
            />
          ) : (
            <CommonConfigWriteModal
              mode="edit"
              isOpen={!!editingConfig}
              defaultValues={editingConfig}
              onCancel={() => setEditingConfig(undefined)}
              onSave={(config) => handleClickUpdateConfig(config.name, config)}
              disabled={!cfgPerm.write}
            />
          )}
        </>
      )}

      {bulkOperationResult && (
        <BulkOperationReportModal
          resultList={bulkOperationResult}
          title={t("operationResult")}
          showProblematic
          showSuccess
          onCancel={() => setBulkOperationResult(undefined)}
          open={!!bulkOperationResult}
        />
      )}
    </>
  );
};

export default GameGroupConfigTable;
