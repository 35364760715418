import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  message,
  Modal,
  Typography,
  Upload,
  UploadProps,
  UploadFile
} from "antd";
import {UploadOutlined} from "@ant-design/icons";


const UpdateAvatarModal = (
  {
    title,
    onCancel,
    confirmLoading = false,
    onSave,
    isOpen
  }: {
    title?: string,
    confirmLoading?: boolean,
    onCancel: () => void;
    onSave: (image?: File) => void;
    isOpen: boolean;
  }
) => {
  const [isRandom, setIsRandom] = useState<boolean>(true);

  const [form] = Form.useForm();

  const [file, setFile] = useState<UploadFile | undefined>(undefined);

  function handleSaveClick() {
    form.validateFields({validateOnly: false})
      .then(() =>
        onSave && onSave(file?.originFileObj)
      )
      .catch(err => console.error(err));
  }

  useEffect(() => {
    form.resetFields();
  }, [form, isOpen]);

  const props: UploadProps = {
    maxCount: 1,
    multiple: false,
    onRemove: () => setFile(undefined),
    beforeUpload: (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('You can upload only JPG/PNG file!');
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('Image must be smaller than 2MB!');
        return false;
      }
      setFile(file);
      return false;
    },
    fileList: file ? [file] : [],
  };

  return (<>
    <Modal
      open={isOpen}
      okText="Submit"
      onCancel={() => onCancel && onCancel()}
      maskClosable={false}
      width={600}
      confirmLoading={confirmLoading}
      onOk={() => handleSaveClick()}
      title={title && (
        <Typography.Title level={3} style={{marginTop: 15}}>{title}</Typography.Title>
      )}
    >
      {title && (<Divider style={{marginTop: 10, marginBottom: 30}}/>)}

      <Form
        size="large"
        className="a-common-form"
        form={form}
        autoComplete="off"
        key="addingMode"
      >
        <Form.Item
          name="image"
          label="Custom avatar (only for single account)"
          rules={[
            {required: !isRandom},
          ]}
        >
          <Upload
            maxCount={1}
            {...props}
          >
            <Button
              disabled={isRandom}
              icon={<UploadOutlined />}
            >
              Select File
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item
          name="isRandom"
          label="Set random avatar from the pool"
        >
          <Checkbox
            style={{width: 25, height: 25}}
            checked={isRandom}
            onChange={() => {
              setFile(undefined);
              setIsRandom(!isRandom)
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  </>)
}

export default UpdateAvatarModal;