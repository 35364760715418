import {Col, Row, Typography} from "antd";
import {getPublicUrl} from "../lib/common-utils";


const AError = ({
  errorMessage
} : {
  errorMessage?: string
}) => {

  return (

    <div style={{width: "100%", maxWidth: "500px"}}>
      <Row>
        <Col span={24}>
          <div style={{display: "flex", justifyContent: "space-around"}}>
            <img src={`${getPublicUrl()}/error_logo.png`} alt="error" width="300px" style={{margin: "0 auto"}}/>
          </div>
        </Col>
        <Col span={24}>
          <Typography.Title level={1} style={{textAlign: "center"}}>
          {errorMessage ? errorMessage : "Something went wrong"}
          </Typography.Title>
        </Col>
      </Row>
    </div>
  )
}


export default AError;