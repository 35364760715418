import { TextFieldElement, useForm } from "react-hook-form-mui";
import { useEffect, useMemo } from "react";
import z from "zod"
import { Alert, Box, Button, Dialog, DialogContent, Stack, Typography } from "@mui/material";


const DeleteConfigModal = ({
  open,
  configName,
  onClose,
  confirmIsLoading,
  onConfirm
} : {
  open: boolean,
  configName: string,
  onClose: () => void,
  confirmIsLoading: boolean,
  onConfirm: () => void
}) => {
  const schema = useMemo(() => z.object({
    confirmName: z.string(),
  }), []);

  type FormType = z.infer<typeof schema>

  const form = useForm<FormType>({
    mode: "onChange",
    defaultValues: {
      confirmName: ""
    }
  });

  const confirmName = form.watch("confirmName")

  const handleSubmit = form.handleSubmit(() => onConfirm());

  useEffect(() => {
    form.reset()
  }, [open, form])


  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        <Box>
          <form onSubmit={() => handleSubmit()} noValidate>
            <Stack spacing={2}>
              <Typography variant="h5" style={{fontWeight: "bold"}}>{`Delete config '${configName}'`}</Typography>
              <Typography variant="caption" style={{ marginTop: 0}}>{`Are you sure you want to delete config '${configName}'?`}</Typography>
              <br/>
              <span style={{ fontWeight: "bold" }}>{`Type '${configName}' to confirm action`}</span>
              <TextFieldElement
                control={form.control}
                name="confirmName" fullWidth />
              <Alert severity="warning">{`The config '${configName}' will be deleted with its collection and its all data in database`}</Alert>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={confirmIsLoading || (confirmName !== configName)}
              >Delete config</Button>
            </Stack>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  )
}


export default DeleteConfigModal