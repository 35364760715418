import { AxiosError } from "axios";
import globalAxios from "axios";


export class BadRequestError extends Error {
  constructor(message: string) {
    super(message); // (1)
    this.name = "BadRequestError"; // (2)
  }
}


const errorHandler = (error: AxiosError) => {
  const response = error.response
  const statusCode = response?.status;

  console.error("server error", error);

  if (statusCode && statusCode === 400) {
    const message = (response?.data as any)?.message;

    if( message && typeof message === "string" ) {
      return Promise.reject(new BadRequestError(message));
    }
  }

  if (statusCode && statusCode === 401) {
    window.location.replace(`/auth/?redirect_uri=${window.location.href}`);
  }

  return Promise.reject(error);
};

globalAxios.interceptors.response.use(undefined, errorHandler);

