import { FileDownload } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { download, generateCsv, mkConfig } from "export-to-csv";
import { useAtom } from "jotai/index";
import {
  MRT_ColumnDef,
  MRT_TableInstance,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useSysClusteringPatternResultsQuery } from "@/api/hooks/sys-clusteing-pattern-results-hooks";
import { useTableSettings } from "@/hooks/use-table-settings";
import { appCurrentRoom } from "@/store/store";

type ClusteringPatternResult = {
  pid: string;
  gameType: string;
  patterns: string;
};

const ClusteringPatternResultsTable = ({
  setRowsAmount,
}: {
  setRowsAmount: (amount: number) => void;
}) => {
  const { t } = useTranslation("room-configs");
  const [selectedRoom] = useAtom(appCurrentRoom);

  const { data: configsRs, isLoading: configsIsLoading } = useSysClusteringPatternResultsQuery(
    {
      domain: selectedRoom?.name as string,
      queryOptions: {
        enabled: !!selectedRoom as boolean,
      },
    }
  );

  const results = useMemo<ClusteringPatternResult[]>(() => {
    const obj = configsRs?.data ?? {};
    return Object.entries(obj).flatMap((entry) => {
      const pid = entry[0];
      const gameTypes = entry[1];

      return Object.entries(gameTypes).map((gtEntry) => {
        const gameType = gtEntry[0];
        const patterns = gtEntry[1];

        return { pid: pid, gameType: gameType, patterns: patterns.join(", ") };
      });
    });
  }, [configsRs]);

  function handleExportRoutersToCsvClick(table: MRT_TableInstance<ClusteringPatternResult>) {
    const preparedForExport = new Array<any>();
    const patterns = table.getSelectedRowModel().rows.map((item) => item.original);

    patterns.forEach((pattern) => {
      preparedForExport.push({
        pid: pattern.pid,
        game_type: pattern.gameType,
        pattern: pattern.patterns,
      });
    });

    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
    });

    const csv = generateCsv(csvConfig)(preparedForExport);
    download(csvConfig)(csv);
  }

  useEffect(() => {
    setRowsAmount(Object.values(configsRs?.data ?? {}).length);
  }, [configsRs, setRowsAmount]);

  const {
    defaultProps,
    columnVisibility,
    columnOrder,
    columnFilters,
    globalFilter,
    grouping,
    pagination,
    sorting,
  } = useTableSettings<ClusteringPatternResult>("clusteringPatternResult");

  const columns = useMemo<MRT_ColumnDef<ClusteringPatternResult>[]>(() => {
    return [
      {
        id: "pid",
        header: t("clusteringPatternResultTable.pid"),
        filterFn: "contains",
        accessorFn: (row) => row.pid,
      },
      {
        id: "gameType",
        header: t("clusteringPatternResultTable.gameType"),
        accessorFn: (row) => row.gameType,
        filterFn: "contains",
      },
      {
        id: "pattern",
        header: t("clusteringPatternResultTable.pattern"),
        accessorFn: (row) => row.patterns,
        filterFn: "contains",
      },
    ];
  }, [t]);

  const table = useMaterialReactTable({
    getRowId: (row) => row.pid + row.gameType,
    data: results ?? [],
    columns: columns,
    selectAllMode: "all",
    ...defaultProps,
    initialState: {
      ...defaultProps.initialState,
      sorting: [{ id: "name", desc: false }],
    },
    enableRowSelection: true,
    enableGrouping: true,
    // enableColumnDragging: true,
    // enableColumnOrdering: true,
    enableColumnFilterModes: true,
    enablePagination: true,
    enableSorting: true,
    state: {
      pagination,
      sorting,
      columnFilters,
      globalFilter,
      grouping,
      columnVisibility,
      columnOrder,
      isLoading: configsIsLoading,
    },
    renderTopToolbarCustomActions: () => {
      return (
        <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
          <IconButton
            size="small"
            style={{ fontSize: "15px" }}
            color="secondary"
            disabled={table.getSelectedRowModel().rows.length < 1}
            onClick={() => handleExportRoutersToCsvClick(table)}
          >
            <FileDownload /> {t("exportCsv")}
          </IconButton>
        </div>
      );
    },
  });

  useEffect(() => {
    table.resetRowSelection();
  }, [columnFilters, grouping, globalFilter, table]);

  return <MaterialReactTable table={table} />;
};

export default ClusteringPatternResultsTable;
