import { useMemo } from "react";
import { SysAccountRegularDto } from "@/shared/api";
import { MRT_OrderedColumnDef, NilObj } from "@/types/commonTypes";
import { AccountTypeEnum } from "@/lib/constants";
import useAccountCommonColumns from "@/module/accounts-module/hooks/account-common-columns-hook";


export const useServiceAccountsTableColumns = (
  {
    room
  } : {
    room: string
  }
) => {

  const commonColumns = useAccountCommonColumns({room});

  return useMemo<MRT_OrderedColumnDef<SysAccountRegularDto>[]>(() => [
    {
      id: "data.account_type",
      header: "Account Type",
      order: 301,
      accessorFn: ({data}) => {
        const accountType = (data as NilObj)?.account_type;
        const accountTypeStr = Object.keys(AccountTypeEnum)
          .find(key => {
            const k = key as keyof typeof AccountTypeEnum
            return AccountTypeEnum[k] === accountType as unknown as number
          });

        return accountType
          ? `${accountType}${accountTypeStr ? " (" + accountTypeStr + ")" : ""}`
          : "-"
      }
    },
    {
      id: "data.comment",
      header: "Comment",
      order: 301,
      accessorFn: ({data}) => (data as NilObj)?.comment ?? "-",
    },
    ...commonColumns
  ], [])
}