import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import ClusteringPatternConfigTable from "@/module/room-configs-module/clustering-pattern-module/pattern-table/clustering-pattern-config-table";
import ClusteringPatternResultsTable from "@/module/room-configs-module/clustering-pattern-module/result-table/clustering-pattern-results-table";
import { Route } from "@/routes/service-config/$configName";

const ClusteringPatternModule = () => {
  const { t } = useTranslation("room-configs");
  const navigate = Route.useNavigate();
  const { clusteringPatternTab: tabValue } = Route.useSearch();

  const setTabValue = (value: number) => {
    void navigate({ search: { clusteringPatternTab: value } });
  };

  const [patternsAmount, setPatternsAmount] = useState<number>(0);
  const [resultsAmount, setResultsAmount] = useState<number>(0);

  return (
    <>
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs onChange={(_e, value) => setTabValue(value)} value={tabValue}>
            <Tab
              label={t("patternsAmount", {
                amount: patternsAmount,
              })}
              value={0}
            />
            <Tab label={t("resultsAmount", { amount: resultsAmount })} value={1} />
          </Tabs>
        </Box>

        <div style={{ height: "100%" }}>
          <div hidden={tabValue !== 0} style={{ height: "100%" }}>
            <ClusteringPatternConfigTable
              setRowsAmount={(amount: number) => setPatternsAmount(amount)}
            />
          </div>

          <div hidden={tabValue !== 1} style={{ height: "100%" }}>
            <ClusteringPatternResultsTable
              setRowsAmount={(amount: number) => setResultsAmount(amount)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ClusteringPatternModule;
