import {useEffect, useState} from "react";

const TimeAgo = ({pastDate}: {pastDate: number}) => {
    const [timeAgo, setTimeAgo] = useState('');

    useEffect(() => {
        const timer = setInterval(() => {
            const now = new Date();
            const past = new Date(pastDate);
            const ms = now.getTime() - past.getTime();
            const seconds = Math.floor((ms / 1000) % 60);
            const minutes = Math.floor((ms / 1000 / 60) % 60);
            const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
            const days = Math.floor((ms / (1000 * 60 * 60 * 24)) % 30);
            const months = Math.floor((ms / (1000 * 60 * 60 * 24 * 30)) % 12);
            const years = Math.floor(ms / (1000 * 60 * 60 * 24 * 365));

            setTimeAgo(`
        ${years !== 0 ? years + " years " : ""}
        ${months !== 0 ? months + " moths " : ""}
        ${days !== 0 ? days + " days " : ""}
        ${hours !== 0 ? hours + " hours " : ""}
        ${minutes !== 0 ? minutes + " minutes " : ""}
        ${seconds} seconds ago
      `);
        }, 1000);

        return () => clearInterval(timer);
    }, [pastDate]);

    return <div>{timeAgo}</div>;
}


export default TimeAgo;