import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { ContentCopy, Edit, Power, PowerOff } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { Updater, VisibilityState } from "@tanstack/table-core";
import { Button, Popconfirm } from "antd";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

import { useSysCfgQuery, useSysCfgsCreateMutation } from "@/api/hooks/sys-cfg-hooks";
import { useCfgPerm, useIsOwnerOrSuperAdmin } from "@/auth/hooks";
import ApplyAuthGroupsTableControl from "@/components/apply-auth-groups-table-control";
import ReportRuleEditModal from "@/components/config-modals/players-reporter/report-rule/report-rule-edit-modal";
import { EditedReportRuleType } from "@/components/config-modals/players-reporter/report-rule/types";
import { assembleNamesFilter, mapSysCfgToEditReportRule } from "@/lib/cfg-utils";
import { ROOM_CONFIGS_NAMES, ROOM_NAMES } from "@/lib/constants";
import {
  useConfigChangeEnabled,
  useDeleteConfigs,
  useUpdateConfig,
} from "@/module/room-configs-module/hooks/room-configs-hooks";
import { useReporterRuleTableColumns } from "@/module/service-configs-module/players-reporter-module/rules/report-rule-table-columns";
import { ResultDtoLong, SysCfgCreateRequestDto, SysCfgRegularDto } from "@/shared/api";

export const ReportRuleTable = ({
  setRowsAmount,
}: {
  setRowsAmount: (amount: number) => void;
}) => {
  const { mutate: createCfgsMutate } = useSysCfgsCreateMutation();
  const [bulkOperationResult, setBulkOperationResult] = useState<ResultDtoLong[] | undefined>(
    undefined
  );

  const cfgPerm = useCfgPerm({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.REPORT_RULE,
  });

  const isOwner = useIsOwnerOrSuperAdmin();

  const {
    data: configsRs,
    isLoading: configsIsLoading,
    refetch: refetchConfigs,
  } = useSysCfgQuery({
    domain: ROOM_NAMES.SERVICES,
    config_name: ROOM_CONFIGS_NAMES.REPORT_RULE,
  });

  useEffect(() => {
    setRowsAmount(configsRs?.data.content.length ?? 0);
  }, [configsRs, setRowsAmount]);

  const updateConfig = useUpdateConfig({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.REPORT_RULE,
    onSuccessCallback: () => {
      void refetchConfigs();
    },
  });

  function handleClickUpdateConfig(name: string, config: EditedReportRuleType) {
    updateConfig(name, { ...config, auth_groups: new Set(config.authGroups) });
  }

  const handleChangeEnableBulk = useConfigChangeEnabled({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.REPORT_RULE,
    onSuccessCallback: (table) => {
      void refetchConfigs();
      table.resetRowSelection();
    },
  });

  const handleDeleteConfigs = useDeleteConfigs({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.REPORT_RULE,
    onSuccessCallback: (table, response) => {
      setBulkOperationResult([response.data]);
      void refetchConfigs();
      table.resetRowSelection();
    },
  });

  function handleClickSaveNewConfig(config: SysCfgCreateRequestDto[]) {
    createCfgsMutate(
      {
        domain: ROOM_NAMES.SERVICES,
        cfgName: ROOM_CONFIGS_NAMES.REPORT_RULE,
        body: config.map((item) => ({ ...item, authGroups: item.auth_groups })),
      },
      {
        onSuccess: (response) => {
          enqueueSnackbar(`Elements have been created`, { variant: "success" });
          setModalInitValues(undefined);
          setModalMode(undefined);
          setEditItemName("");
          void refetchConfigs();
        },
        onError: (err) => {
          enqueueSnackbar(`Operation failure: ${err.message}. Contact an administrator`, {
            variant: "error",
          });
          console.error("Adding config failure", err);
        },
      }
    );
  }

  //This logic for reset row selection while using filtering/grouping
  const [columnFilters, setColumnFilters] = useState<any>([]);
  const [grouping, setGrouping] = useState<any>([]);
  const [globalFilter, setGlobalFilter] = useState<any>(undefined);

  const [modalMode, setModalMode] = useState<"edit" | "add" | undefined>(undefined);
  const [modalInitValues, setModalInitValues] = useState<SysCfgRegularDto | undefined>(
    undefined
  );
  const [editItemName, setEditItemName] = useState<string>("");

  //Storing table setting into localStorage
  const loadTablesSettingsFromLocalStorage = () => {
    const item = localStorage.getItem("tablesSettings");
    return item ? JSON.parse(item) : {};
  };

  const [allTablesUserSetting, setAllTablesUserSetting] = useState<any>(() =>
    loadTablesSettingsFromLocalStorage()
  );

  useEffect(() => {
    localStorage.setItem("tablesSettings", JSON.stringify(allTablesUserSetting));
  }, [allTablesUserSetting]);

  const columns = useReporterRuleTableColumns({
    updateConfig: (cfg: SysCfgRegularDto) =>
      updateConfig(cfg.name, { ...cfg, auth_groups: cfg.auth_groups ?? new Set() }),
  });

  const table = useMaterialReactTable({
    data: configsRs?.data.content ?? [],
    columns: columns,
    getRowId: (originalRow) => originalRow.id,
    initialState: {
      pagination: { pageSize: 20, pageIndex: 0 },
      density: "compact",
    },

    enableRowSelection: true,
    enableGrouping: true,
    enableColumnDragging: true,
    enableColumnOrdering: true,
    enableColumnFilterModes: true,
    enableRowActions: true,
    enablePagination: false,
    enableStickyHeader: true,
    enableSorting: true,
    autoResetPageIndex: false,
    state: {
      globalFilter,
      grouping,
      columnFilters,
      columnVisibility: allTablesUserSetting?.router?.columnVisibility ?? {},
      columnOrder: allTablesUserSetting?.router?.columnOrder ?? [],
      isLoading: configsIsLoading,
    },
    muiTablePaperProps: {
      sx: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
      },
    },
    muiTableContainerProps: {
      sx: {
        flexGrow: 10,
        overflowY: "scroll",
        height: "500px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        whiteSpace: "pre",
      },
    },

    onColumnFiltersChange: setColumnFilters,
    onGroupingChange: setGrouping,
    onGlobalFilterChange: setGlobalFilter,
    onColumnOrderChange: (newColumnOrder) => {
      setAllTablesUserSetting({
        ...allTablesUserSetting,
        email_domain: {
          ...allTablesUserSetting.router,
          columnOrder: newColumnOrder as string[],
        },
      });
    },
    onColumnVisibilityChange: (updaterFn: Updater<VisibilityState>) => {
      setAllTablesUserSetting((prev: any) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const newVisibility = updaterFn(prev.email_domain?.columnVisibility);
        return {
          ...prev,
          email_domain: { ...prev.email_domain, columnVisibility: newVisibility },
        };
      });
    },

    renderTopToolbarCustomActions: () => {
      const selectedCount = table.getSelectedRowModel().rows.length;
      return (
        <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
          {cfgPerm.write && (
            <Button
              onClick={() => {
                setModalInitValues(undefined);
                setModalMode("add");
              }}
            >
              <PlusOutlined />
              New
            </Button>
          )}
          {selectedCount > 0 && (
            <div style={{ marginLeft: 30 }}>
              {cfgPerm.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="success"
                  onClick={() => handleChangeEnableBulk(table, true)}
                >
                  <Power /> Enable
                </IconButton>
              )}
              {cfgPerm.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="error"
                  onClick={() => handleChangeEnableBulk(table, false)}
                >
                  <PowerOff /> Disable
                </IconButton>
              )}
              {cfgPerm.delete && (
                <Popconfirm
                  title="Delete items"
                  description="Are you sure to delete these items? (Changes cannot be undone)"
                  onConfirm={() => handleDeleteConfigs(table)}
                >
                  <IconButton size="small" style={{ fontSize: "15px" }} color="error">
                    <DeleteOutlined /> Delete
                  </IconButton>
                </Popconfirm>
              )}

              {isOwner && (
                <ApplyAuthGroupsTableControl
                  domain={ROOM_NAMES.SERVICES}
                  cfgName={ROOM_CONFIGS_NAMES.REPORT_CHAT}
                  table={table}
                  filters={assembleNamesFilter(table)}
                  totalElementsCount={selectedCount}
                  onSuccess={() => void refetchConfigs()}
                />
              )}
            </div>
          )}
        </div>
      );
    },

    renderRowActions: ({ row }) => (
      <Box>
        {cfgPerm.write && (
          <>
            <IconButton
              onClick={() => {
                setModalInitValues({ ...row.original });
                setModalMode("edit");
                setEditItemName(row.original.name);
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              onClick={() => {
                setModalInitValues({
                  ...row.original,
                  data: { ...row.original.data },
                  name: "",
                  enabled: false,
                });
                setModalMode("add");
              }}
            >
              <ContentCopy />
            </IconButton>
          </>
        )}
      </Box>
    ),
  });

  useEffect(() => {
    table.resetRowSelection();
  }, [columnFilters, grouping, globalFilter, table]);

  return (
    <>
      <MaterialReactTable table={table} />

      <ReportRuleEditModal
        open={!!modalMode}
        mode={modalMode}
        initialData={mapSysCfgToEditReportRule(modalInitValues)}
        onSave={(data) => {
          if (modalMode === "add") return handleClickSaveNewConfig([data]);
          if (modalMode === "edit") return handleClickUpdateConfig(editItemName, data);
        }}
        /*onSave={(data) => {
          handleClickSaveNewConfig([data]);
        }}*/
        onCancel={() => {
          setModalMode(undefined);
          setModalInitValues(undefined);
          setEditItemName("");
        }}
      />
    </>
  );
};
