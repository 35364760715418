import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { ContentCopy, Edit, Power, PowerOff } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { Button, Checkbox, Popconfirm } from "antd";
import {
  MRT_ColumnDef,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSysCfgQuery } from "@/api/hooks/sys-cfg-hooks";
import { useCfgPerm, useIsOwnerOrSuperAdmin } from "@/auth/hooks";
import ApplyAuthGroupsTableControl from "@/components/apply-auth-groups-table-control";
import BulkOperationReportModal from "@/components/bulk-operation-report-modal";
import ClusteringTypeEditModal from "@/components/config-modals/clusterizer-edit-modal/clustering-type-edit-modal";
import { EditedClusteringType } from "@/components/config-modals/clusterizer-edit-modal/types";
import { useTableSettings } from "@/hooks/use-table-settings";
import { assembleNamesFilter, mapSysCfgToEditClusteringType } from "@/lib/cfg-utils";
import { ROOM_CONFIGS_NAMES, ROOM_NAMES } from "@/lib/constants";
import {
  useConfigChangeEnabled,
  useDeleteConfigs,
  useSaveConfig,
  useUpdateConfig,
} from "@/module/room-configs-module/hooks/room-configs-hooks";
import { ResultDtoLong, SysCfgRegularDto } from "@/shared/api";
import { AnyObj } from "@/types/commonTypes";

const ClusteringTypeConfigTable = () => {
  const { t } = useTranslation("room-configs");

  const {
    data: configsRs,
    isLoading: configsIsLoading,
    refetch,
  } = useSysCfgQuery({
    domain: ROOM_NAMES.SERVICES,
    config_name: ROOM_CONFIGS_NAMES.CLUSTERING_TYPE,
  });

  const cfgPerm = useCfgPerm({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.CLUSTERING_TYPE,
  });

  const roomConfigsSortedData = React.useMemo(() => {
    return (
      configsRs?.data.content?.sort(
        (a, b) => new Date(b.created_at).getDate() - new Date(a.created_at).getDate()
      ) ?? []
    );
  }, [configsRs]);

  const [bulkOperationResult, setBulkOperationResult] = useState<ResultDtoLong[] | undefined>(
    undefined
  );

  const isOwner = useIsOwnerOrSuperAdmin();

  const saveNewConfig = useSaveConfig({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.CLUSTERING_TYPE,
    onSuccessCallback: () => {
      void refetch();
    },
  });

  function handleClickSaveNewConfig(config: EditedClusteringType) {
    saveNewConfig({
      ...config,
      enabled: config.enabled ?? true,
      auth_groups: new Set(config.authGroups),
    });
  }

  const updateConfig = useUpdateConfig({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.CLUSTERING_TYPE,
    onSuccessCallback: () => {
      void refetch();
    },
  });

  function handleClickUpdateConfig(name: string, config: EditedClusteringType) {
    updateConfig(name, { ...config, auth_groups: new Set(config.authGroups) });
  }

  const handleChangeEnableBulk = useConfigChangeEnabled({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.CLUSTERING_TYPE,
    onSuccessCallback: (table) => {
      void refetch();
      table.resetRowSelection();
    },
  });

  const handleDeleteConfigs = useDeleteConfigs({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.CLUSTERING_TYPE,
    onSuccessCallback: (table, response) => {
      setBulkOperationResult([response.data]);
      void refetch();
      table.resetRowSelection();
    },
  });

  const [modalMode, setModalMode] = useState<"edit" | "add" | undefined>(undefined);
  const [modalInitValues, setModalInitValues] = useState<SysCfgRegularDto | undefined>(
    undefined
  );
  const [editItemName, setEditItemName] = useState<string>("");

  const columns = useMemo<MRT_ColumnDef<SysCfgRegularDto>[]>(
    () => [
      {
        header: t("clusteringTypeConfigTable.enabled"),
        filterFn: "contains",
        enableEditing: false,
        accessorFn: (row) => row.enabled ?? "-",
        Cell: ({ row }) => <Checkbox disabled checked={row.original.enabled} />,
      },
      {
        header: t("clusteringTypeConfigTable.comment"),
        accessorFn: ({ data }) => (data as AnyObj)?.comment ?? "-",
        filterFn: "contains",
      },
      {
        header: t("clusteringTypeConfigTable.gameGroup"),
        accessorFn: ({ data }) => (data as AnyObj)?.game_group?.join(", ") ?? "-",
        filterFn: "contains",
      },
      {
        header: t("clusteringTypeConfigTable.gameType"),
        accessorFn: ({ data }) => (data as AnyObj)?.game_type?.join(", ") ?? "-",
        filterFn: "contains",
      },
      {
        header: t("clusteringTypeConfigTable.limit"),
        accessorFn: ({ data }) => (data as AnyObj)?.limit?.join(", ") ?? "-",
        filterFn: "contains",
      },
      {
        header: t("clusteringTypeConfigTable.clusteringPattern"),
        accessorFn: ({ data }) => (data as AnyObj)?.pattern?.join(", ") ?? "-",
        filterFn: "contains",
      },
      {
        header: t("clusteringTypeConfigTable.postFlop"),
        accessorFn: ({ data }) =>
          ((data as AnyObj)?.post_flop as Array<string>)?.join(" ") ?? "-",
        filterFn: "contains",
      },
      {
        header: t("clusteringTypeConfigTable.preFlop"),
        accessorFn: ({ data }) =>
          ((data as AnyObj)?.pre_flop as Array<string>)?.join(" ") ?? "-",
        filterFn: "contains",
      },
    ],
    [t]
  );

  const {
    pagination,
    sorting,
    defaultProps,
    columnVisibility,
    columnOrder,
    columnFilters,
    globalFilter,
    grouping,
  } = useTableSettings<SysCfgRegularDto>("clusteringType");

  const table = useMaterialReactTable({
    data: roomConfigsSortedData ?? [],
    columns: columns,
    getRowId: (originalRow) => originalRow.id,
    state: {
      pagination,
      sorting,
      columnFilters,
      globalFilter,
      grouping,
      columnVisibility,
      columnOrder,
      isLoading: configsIsLoading,
    },
    ...defaultProps,
    initialState: {
      ...defaultProps.initialState,
      sorting: [{ id: "name", desc: false }],
    },
    enableHiding: true,
    enableRowSelection: true,
    enableGrouping: true,
    enableColumnDragging: true,
    enableColumnOrdering: true,
    enableColumnFilterModes: true,
    enableRowActions: true,
    enablePagination: false,

    renderTopToolbarCustomActions: () => {
      const selectedCount = table.getSelectedRowModel().rows.length;
      return (
        <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
          {cfgPerm.write && (
            <Button
              onClick={() => {
                setModalInitValues(undefined);
                setEditItemName("");
                setModalMode("add");
              }}
            >
              <PlusOutlined /> {t("new")}
            </Button>
          )}

          {selectedCount > 0 && (
            <div style={{ marginLeft: 30 }}>
              {cfgPerm.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="success"
                  onClick={() => handleChangeEnableBulk(table, true)}
                >
                  <Power /> {t("enable")}
                </IconButton>
              )}
              {cfgPerm.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="error"
                  onClick={() => handleChangeEnableBulk(table, false)}
                >
                  <PowerOff /> {t("disable")}
                </IconButton>
              )}
              {cfgPerm.delete && (
                <Popconfirm
                  title={t("deleteItems")}
                  description={t("areYouSureToDeleteItems")}
                  onConfirm={() => handleDeleteConfigs(table)}
                >
                  <IconButton size="small" style={{ fontSize: "15px" }} color="error">
                    <DeleteOutlined /> {t("delete")}
                  </IconButton>
                </Popconfirm>
              )}

              {isOwner && (
                <ApplyAuthGroupsTableControl
                  domain={ROOM_NAMES.SERVICES}
                  cfgName={ROOM_CONFIGS_NAMES.CLUSTERING_TYPE}
                  table={table}
                  filters={assembleNamesFilter(table)}
                  totalElementsCount={selectedCount}
                  onSuccess={() => void refetch()}
                />
              )}
            </div>
          )}
        </div>
      );
    },
    renderRowActions: ({ row }) => (
      <Box>
        <IconButton
          onClick={() => {
            setModalInitValues({ ...row.original });
            setModalMode("edit");
            setEditItemName(row.original.name);
          }}
        >
          <Edit />
        </IconButton>
        {cfgPerm.write && (
          <IconButton
            onClick={() => {
              setModalInitValues({
                ...row.original,
                data: { ...row.original.data, comment: "" },
                name: "",
                enabled: false,
              });
              setModalMode("add");
            }}
          >
            <ContentCopy />
          </IconButton>
        )}
      </Box>
    ),
  });

  useEffect(() => {
    table.resetRowSelection();
  }, [columnFilters, grouping, globalFilter, table]);

  return (
    <>
      <MaterialReactTable table={table} />

      <ClusteringTypeEditModal
        disabled={!cfgPerm.write}
        open={!!modalMode}
        mode={modalMode}
        initialData={mapSysCfgToEditClusteringType(modalInitValues)}
        onSave={(data) => {
          if (modalMode === "add") return handleClickSaveNewConfig(data);
          if (modalMode === "edit") return handleClickUpdateConfig(editItemName, data);
        }}
        onCancel={() => {
          setModalMode(undefined);
          setModalInitValues(undefined);
          setEditItemName("");
        }}
      />

      {bulkOperationResult && (
        <BulkOperationReportModal
          resultList={bulkOperationResult}
          title={t("operationResult")}
          showProblematic
          showSuccess
          onCancel={() => setBulkOperationResult(undefined)}
          open={!!bulkOperationResult}
        />
      )}
    </>
  );
};

export default ClusteringTypeConfigTable;
