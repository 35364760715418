import { createFileRoute } from '@tanstack/react-router'
import RoomManagementPage from "@/pages/room-management-page";

export const Route = createFileRoute('/room-management/$name')({
  component: Component,
})

function Component() {
  const { name } = Route.useParams();
  return <RoomManagementPage name={name}/>
}