/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface KeyFilter
 */
export interface KeyFilter {
  /**
   *
   * @type {string}
   * @memberof KeyFilter
   */
  key: string;
  /**
   *
   * @type {string}
   * @memberof KeyFilter
   */
  mode: KeyFilterModeEnum;
  /**
   *
   * @type {object}
   * @memberof KeyFilter
   */
  value: object;
}

export const KeyFilterModeEnum = {
  Contains: "CONTAINS",
  StartsWith: "STARTS_WITH",
  EndsWith: "ENDS_WITH",
  Equals: "EQUALS",
  NotEquals: "NOT_EQUALS",
  Between: "BETWEEN",
  BetweenInclusive: "BETWEEN_INCLUSIVE",
  Gt: "GT",
  Gte: "GTE",
  Lt: "LT",
  Lte: "LTE",
  Empty: "EMPTY",
  NotEmpty: "NOT_EMPTY",
  StrictIn: "STRICT_IN",
} as const;

export type KeyFilterModeEnum = (typeof KeyFilterModeEnum)[keyof typeof KeyFilterModeEnum];
