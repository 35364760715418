import { ContentCopy, Edit, Power, PowerOff } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Box, Button, IconButton } from "@mui/material";
import { Checkbox, Popconfirm } from "antd";
import {
  MRT_ColumnDef,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSysCfgQuery } from "@/api/hooks/sys-cfg-hooks";
import { useCfgPerm, useIsOwnerOrSuperAdmin } from "@/auth/hooks";
import ApplyAuthGroupsTableControl from "@/components/apply-auth-groups-table-control";
import BulkOperationReportModal from "@/components/bulk-operation-report-modal";
import CompensatorCreateModal from "@/components/config-modals/compensator-edit-modal/compensator-create-modal";
import { EditedCompensator } from "@/components/config-modals/compensator-edit-modal/types";
import { useTableSettings } from "@/hooks/use-table-settings";
import { assembleNamesFilter, mapSysCfgToEditCompensator } from "@/lib/cfg-utils";
import { ROOM_CONFIGS_NAMES, ROOM_NAMES } from "@/lib/constants";
import {
  useConfigChangeEnabled,
  useDeleteConfigs,
  useSaveConfig,
  useUpdateConfig,
} from "@/module/room-configs-module/hooks/room-configs-hooks";
import { ResultDtoLong, SysCfgRegularDto } from "@/shared/api";
import { AnyObj } from "@/types/commonTypes";

export default function CompensatorConfigTable() {
  const { t } = useTranslation("room-configs");

  const cfgPerms = useCfgPerm({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.COMPENSATOR,
  });

  const {
    data: configsRs,
    isLoading: configsIsLoading,
    refetch: refetchConfigs,
  } = useSysCfgQuery({
    domain: ROOM_NAMES.SERVICES,
    config_name: ROOM_CONFIGS_NAMES.COMPENSATOR,
    sort: [{ key: "created_at", mode: "DESC" }],
  });

  const configs = React.useMemo(() => {
    return configsRs?.data?.content ?? [];
  }, [configsRs]);

  const isOwner = useIsOwnerOrSuperAdmin();

  const [bulkOperationResult, setBulkOperationResult] = useState<ResultDtoLong[] | undefined>(
    undefined
  );

  const handleClickSaveNewConfig = (config: EditedCompensator) => {
    handleSave({
      ...config,
      auth_groups: new Set(config.authGroups),
    });
  };

  const handleSave = useSaveConfig({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.COMPENSATOR,
    onSuccessCallback: () => {
      void refetchConfigs();
      setModalInitValues(undefined);
      setModalMode(undefined);
    },
  });

  const handleClickUpdateConfig = (name: string, config: EditedCompensator) => {
    handleUpdate(name, {
      ...config,
      auth_groups: new Set(config.authGroups),
    });
  };

  const handleUpdate = useUpdateConfig({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.COMPENSATOR,
    onSuccessCallback: () => {
      void refetchConfigs();
      setModalInitValues(undefined);
      setModalMode(undefined);
      setEditItemName("");
    },
  });

  const handleChangeEnableBulk = useConfigChangeEnabled({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.COMPENSATOR,
    onSuccessCallback: (table) => {
      void refetchConfigs();
      table.resetRowSelection();
    },
  });

  const handleDeleteConfigs = useDeleteConfigs({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.COMPENSATOR,
    onSuccessCallback: (table, response) => {
      setBulkOperationResult([response.data]);
      void refetchConfigs();
      table.resetRowSelection();
    },
  });

  const [modalMode, setModalMode] = useState<"edit" | "add" | undefined>(undefined);
  const [modalInitValues, setModalInitValues] = useState<SysCfgRegularDto | undefined>(
    undefined
  );
  const [editItemName, setEditItemName] = useState<string>("");

  const {
    defaultProps,
    columnVisibility,
    columnOrder,
    columnFilters,
    globalFilter,
    grouping,
    pagination,
    sorting,
  } = useTableSettings<SysCfgRegularDto>("compensator");

  const columns = useMemo<MRT_ColumnDef<SysCfgRegularDto>[]>(() => {
    return [
      {
        header: t("compensatorTable.enabled"),
        filterFn: "contains",
        enableEditing: false,
        accessorFn: (row) => row.enabled ?? "-",
        Cell: ({ row }) => <Checkbox checked={row.original.enabled} disabled />,
      },
      {
        id: "comment",
        header: t("compensatorTable.comment"),
        filterFn: "contains",
        accessorFn: ({ data }) => (data as AnyObj)?.comment ?? "-",
      },
      {
        header: t("compensatorTable.room"),
        filterFn: "contains",
        accessorFn: ({ data }) => (data as AnyObj)?.room ?? "-",
      },
      {
        header: t("compensatorTable.gameGroup"),
        filterFn: "contains",
        accessorFn: ({ data }) => (data as AnyObj)?.game_group ?? "-",
      },
      {
        header: t("compensatorTable.gameType"),
        filterFn: "contains",
        accessorFn: ({ data }) => (data as AnyObj)?.game_type ?? "-",
      },
      {
        header: t("compensatorTable.limit"),
        filterFn: "contains",
        accessorFn: ({ data }) => (data as AnyObj)?.limit?.toLocaleString("ru-RU") ?? "-",
      },
      {
        header: t("compensatorTable.period"),
        filterFn: "contains",
        accessorFn: ({ data }) => (data as AnyObj)?.rule_range ?? "-",
      },
      {
        header: t("compensatorTable.resultMin"),
        filterFn: "contains",
        accessorFn: ({ data }) => (data as AnyObj)?.RAA_min?.toLocaleString("ru-RU") ?? "-",
      },
      {
        header: t("compensatorTable.resultMax"),
        filterFn: "contains",
        accessorFn: ({ data }) => (data as AnyObj)?.RAA_max?.toLocaleString("ru-RU") ?? "-",
      },
      {
        header: t("compensatorTable.currentState"),
        filterFn: "contains",
        accessorFn: ({ data }) => (data as AnyObj)?.current_state ?? "-",
      },
    ];
  }, [t]);

  const table = useMaterialReactTable({
    data: configs ?? [],
    columns: columns,
    getRowId: (originalRow) => originalRow.id,
    selectAllMode: "all",
    ...defaultProps,
    initialState: {
      ...defaultProps.initialState,
      sorting: [{ id: "comment", desc: false }],
    },
    enableSelectAll: true,
    enableRowSelection: true,
    enableGrouping: true,
    enableColumnDragging: true,
    enableColumnOrdering: true,
    enableColumnFilterModes: true,
    enableRowActions: true,
    enablePagination: true,
    enableSorting: true,
    state: {
      columnFilters,
      globalFilter,
      grouping,
      columnVisibility,
      columnOrder,
      isLoading: configsIsLoading,
      pagination,
      sorting,
    },
    renderTopToolbarCustomActions: ({ table }) => {
      const selectedCount = table.getSelectedRowModel().rows.length;
      return (
        <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
          {cfgPerms.write && (
            <Button
              onClick={() => {
                setModalInitValues(undefined);
                setModalMode("add");
                setEditItemName("");
              }}
            >
              <AddIcon />
              {t("new")}
            </Button>
          )}

          {selectedCount > 0 && (
            <div style={{ marginLeft: 30 }}>
              {cfgPerms.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="success"
                  onClick={() => handleChangeEnableBulk(table, true)}
                >
                  <Power /> {t("enable")}
                </IconButton>
              )}

              {cfgPerms.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="error"
                  onClick={() => handleChangeEnableBulk(table, false)}
                >
                  <PowerOff /> {t("disable")}
                </IconButton>
              )}

              {cfgPerms.delete && (
                <Popconfirm
                  title={t("deleteItems")}
                  description={t("areYouSureToDeleteItems")}
                  onConfirm={() => handleDeleteConfigs(table)}
                >
                  <IconButton size="small" style={{ fontSize: "15px" }} color="error">
                    <DeleteOutlineOutlinedIcon /> {t("delete")}
                  </IconButton>
                </Popconfirm>
              )}

              {isOwner && (
                <ApplyAuthGroupsTableControl
                  domain={ROOM_NAMES.SERVICES}
                  cfgName={ROOM_CONFIGS_NAMES.COMPENSATOR}
                  table={table}
                  filters={assembleNamesFilter(table)}
                  totalElementsCount={selectedCount}
                  onSuccess={() => void refetchConfigs()}
                />
              )}
            </div>
          )}
        </div>
      );
    },
    renderRowActions: ({ row }) => (
      <Box>
        <IconButton
          onClick={() => {
            setModalInitValues({ ...row.original });
            setModalMode("edit");
            setEditItemName(row.original.name);
          }}
        >
          <Edit />
        </IconButton>
        {cfgPerms.write && (
          <IconButton
            onClick={() => {
              setModalInitValues({
                ...row.original,
                data: { ...row.original.data },
                name: "",
                enabled: false,
              });
              setModalMode("add");
            }}
          >
            <ContentCopy />
          </IconButton>
        )}
      </Box>
    ),
  });

  useEffect(() => {
    table.resetRowSelection();
  }, [columnFilters, grouping, globalFilter, table]);

  return (
    <>
      <MaterialReactTable table={table} />
      <CompensatorCreateModal
        disabled={!cfgPerms.write}
        open={!!modalMode}
        mode={modalMode}
        initialData={mapSysCfgToEditCompensator(modalInitValues)}
        onSave={(data) => {
          if (modalMode === "add") return handleClickSaveNewConfig(data);
          if (modalMode === "edit") return handleClickUpdateConfig(editItemName, data);
        }}
        onCancel={() => {
          setModalMode(undefined);
          setModalInitValues(undefined);
          setEditItemName("");
        }}
      />

      {bulkOperationResult && (
        <BulkOperationReportModal
          resultList={bulkOperationResult}
          title={t("operationResult")}
          showProblematic
          showSuccess
          onCancel={() => setBulkOperationResult(undefined)}
          open={!!bulkOperationResult}
        />
      )}
    </>
  );
}
