import {Path, SpecificControl} from "./types/baseTypes";
import {Checkbox, Form, Input, InputNumber, Radio, Select} from "antd";
import {
  calculateInputRequiredRule,
  calculatePatternRule,
  findObjectOptionValueByKeyValue,
  label,
  numberInputWidth,
  valueByKeyPath
} from "./util";
import _Group from "./_Group";
import _SimpleDynamicObjectArray from "./_SimpleDynamicObjectArray";
import NumberTagsSelect from "../NumberTagsSelect";


const _SpecificControl = (
  {
    control,
    parentPath = [],
    disabled = false
  } : {
    control: SpecificControl,
    parentPath?: Path,
    disabled?: boolean
  }
) => {
  if(control.type === "TextInput") {
    return (
      <Form.Item
        label={label(control.label)}
        labelCol={{span: 24}}
        name={[...parentPath, ...control.path]}
        rules={[
          calculateInputRequiredRule(control),
          calculatePatternRule(control)
        ]}
      >
        <Input placeholder={control.placeholder}/>
      </Form.Item>
    )
  }

  if(control.type === "IntegerInput") {
    return (
      <Form.Item
        label={label(control.label) ?? ""}
        labelCol={{span: 24}}
        name={[...parentPath, ...control.path]}
        rules={[
          calculateInputRequiredRule(control),
        ]}
      >
        <InputNumber
          placeholder={control.placeholder}
          min={control.min}
          max={control.max}
          precision={0}
          style={{width: numberInputWidth(control)}}
        />
      </Form.Item>
    );
  }

  if(control.type === "DoubleInput") {
    return (
      <Form.Item
        label={label(control.label)}
        labelCol={{span: 24}}
        name={[...parentPath, ...control.path]}
        rules={[
          calculateInputRequiredRule(control),
        ]}
      >
        <InputNumber
          placeholder={control.placeholder}
          min={control.min}
          max={control.max}
          style={{width: numberInputWidth(control)}}
          step={0.1}
        />
      </Form.Item>
    );
  }

  if(control.type === "SingleSelect") {
    return (
      <Form.Item
        labelCol={{span: 24}}
        label={label(control.label)}
        name={[...parentPath, ...control.path]}
        rules={[
          calculateInputRequiredRule(control),
        ]}
      >
        <Select
          allowClear
          showSearch
          placeholder={control.placeholder}
          options={control.options}
        />
      </Form.Item>
    );
  }

  if(control.type === "MultiSelect") {
    return (
      <Form.Item
        labelCol={{span: 24}}
        label={label(control.label)}
        name={[...parentPath, ...control.path]}
        rules={[
          calculateInputRequiredRule(control),
        ]}
      >
        <Select
          allowClear
          showSearch
          placeholder={control.placeholder}
          options={control.options}
          mode="multiple"
        />
      </Form.Item>
    );
  }

  if(control.type === "TextTags") {
    return (
      <Form.Item
        labelCol={{span: 24}}
        name={control.path}
        label={label(control.label)}
      >
        <Select allowClear mode="tags" placeholder={control.placeholder}/>
      </Form.Item>
    );
  }

  if(control.type === "IntegerTags") {
    return (
      <Form.Item
        labelCol={{span: 24}}
        name={control.path}
        label={label(control.label)}
      >
        <NumberTagsSelect placeholder={control.placeholder} allowClear/>
      </Form.Item>
    );
  }

  if(control.type === "DoubleTags") {
    return (
      <Form.Item
        labelCol={{span: 24}}
        name={control.path}
        label={label(control.label)}
      >
        <NumberTagsSelect placeholder={control.placeholder} allowFloat allowClear/>
      </Form.Item>
    );
  }

  if(control.type === "ObjectSelect") {
    return (
      <Form.Item
        labelCol={{span: 24}}
        label={label(control.label)}
        name={control.path}
        normalize={value => findObjectOptionValueByKeyValue(control, value)}
        rules={[
          calculateInputRequiredRule(control),
        ]}
      >
        <Select
          allowClear
          showSearch
          placeholder={control.placeholder}
          options={control.options.map(item => (
            {label: item.label, value: valueByKeyPath(item.value, control.key)}
          ))}
        />
      </Form.Item>
    );
  }

  if(control.type === "ObjectMultiSelect") {
    return (
      <Form.Item
        labelCol={{span: 24}}
        label={label(control.label)}
        name={[...parentPath, ...control.path]}
        normalize={(values: string[]) => values.map(value => findObjectOptionValueByKeyValue(control, value))}
        rules={[
          calculateInputRequiredRule(control),
        ]}
      >
        <Select
          allowClear
          showSearch
          mode="multiple"
          placeholder={control.placeholder}
          options={control.options.map(item => ({label: item.label, value: valueByKeyPath(item.value, control.key)}))}
        />
      </Form.Item>
    );
  }

  if(control.type === "RadioButton") {
    return (
      <Form.Item
        labelCol={{span: 24}}
        label={label(control.label)}
        name={[...parentPath, ...control.path]}
        rules={[
          calculateInputRequiredRule(control),
        ]}
      >
        <Radio.Group>
          {control.options.map((item, index) => (
            <Radio key={index} value={item.value}>{item.label}</Radio>
          ))}
        </Radio.Group>
      </Form.Item>
    );
  }

  if(control.type === "Checkbox") {
    return (<>
      <br/>
      <Form.Item
        label={label(control.label) ? <span className={"right-required-mark"}>{label(control.label)}</span> : undefined}
        wrapperCol={{order: 0, flex: "0 1 auto", style:{marginRight: "7px"} }}
        labelCol={{order: 1, flex: "0 1 auto"}}
        name={[...parentPath, ...control.path]}
        valuePropName="checked"
        colon={false}
        rules={[
          calculateInputRequiredRule(control),
        ]}
      >
        <Checkbox/>
      </Form.Item>
    </>);
  }

  if(control.type === "SimpleDynamicObjectArray") {
    return (
      <_SimpleDynamicObjectArray
        control={control}
        parentPath={[...parentPath]}
      />
    );
  }

  if(control.type === "Group") {
    return (
      <_Group
        control={control}
        parentPath={[...parentPath]}
      />
    );
  }

  // @ts-ignore
  return <>Unknown type of control {control.type} for {control.path} field path</>;
}

export default _SpecificControl;