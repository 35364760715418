const originalStringify = JSON.stringify;

JSON.stringify = function(o, r, s) {
  r = function(key, value) {
    if (value instanceof Set) {
      return Array.from(value);
    } else {
      return value;
    }
  };

  return originalStringify(o, r, s);
};

export default null; // Просто для экспорта
