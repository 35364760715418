/* tslint:disable */

/* eslint-disable */

/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { AxiosInstance, AxiosPromise, RawAxiosRequestConfig } from "axios";
import globalAxios from "axios";

// @ts-ignore
import {
  BASE_PATH,
  BaseAPI,
  COLLECTION_FORMATS,
  RequestArgs,
  RequiredError,
  operationServerMap,
} from "../base";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  createRequestFunction,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  toPathString,
} from "../common";
import type { Configuration } from "../configuration";
// @ts-ignore
import { CreateCfgCollectionDto } from "../models";
// @ts-ignore
import { CreateSysDomainDto } from "../models";
// @ts-ignore
import { DeleteCfgCollectionDto } from "../models";
// @ts-ignore
import { DeleteSysDomainOptionsDto } from "../models";
// @ts-ignore
import { SysDomainRegularDto } from "../models";

/**
 * SysDomainControllerApi - axios parameter creator
 * @export
 */
export const SysDomainControllerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} domain
     * @param {CreateCfgCollectionDto} createCfgCollectionDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCfgCollection: async (
      domain: string,
      createCfgCollectionDto: CreateCfgCollectionDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("createCfgCollection", "domain", domain);
      // verify required parameter 'createCfgCollectionDto' is not null or undefined
      assertParamExists(
        "createCfgCollection",
        "createCfgCollectionDto",
        createCfgCollectionDto
      );
      const localVarPath = `/api/v2/domain/{domain}/create-cfg-collection`.replace(
        `{${"domain"}}`,
        encodeURIComponent(String(domain))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createCfgCollectionDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} domain
     * @param {DeleteCfgCollectionDto} deleteCfgCollectionDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCfgCollection: async (
      domain: string,
      deleteCfgCollectionDto: DeleteCfgCollectionDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("deleteCfgCollection", "domain", domain);
      // verify required parameter 'deleteCfgCollectionDto' is not null or undefined
      assertParamExists(
        "deleteCfgCollection",
        "deleteCfgCollectionDto",
        deleteCfgCollectionDto
      );
      const localVarPath = `/api/v2/domain/{domain}/delete-cfg-collection`.replace(
        `{${"domain"}}`,
        encodeURIComponent(String(domain))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteCfgCollectionDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} domain
     * @param {DeleteSysDomainOptionsDto} deleteSysDomainOptionsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDomain: async (
      domain: string,
      deleteSysDomainOptionsDto: DeleteSysDomainOptionsDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("deleteDomain", "domain", domain);
      // verify required parameter 'deleteSysDomainOptionsDto' is not null or undefined
      assertParamExists(
        "deleteDomain",
        "deleteSysDomainOptionsDto",
        deleteSysDomainOptionsDto
      );
      const localVarPath = `/api/v2/domain/{domain}`.replace(
        `{${"domain"}}`,
        encodeURIComponent(String(domain))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteSysDomainOptionsDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} domain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDomain: async (
      domain: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("getDomain", "domain", domain);
      const localVarPath = `/api/v2/domain/{domain}`.replace(
        `{${"domain"}}`,
        encodeURIComponent(String(domain))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {boolean} [onlyRooms]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDomains: async (
      onlyRooms?: boolean,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/domain`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (onlyRooms !== undefined) {
        localVarQueryParameter["onlyRooms"] = onlyRooms;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateSysDomainDto} createSysDomainDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerDomain: async (
      createSysDomainDto: CreateSysDomainDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createSysDomainDto' is not null or undefined
      assertParamExists("registerDomain", "createSysDomainDto", createSysDomainDto);
      const localVarPath = `/api/v2/domain`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createSysDomainDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SysDomainControllerApi - functional programming interface
 * @export
 */
export const SysDomainControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SysDomainControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} domain
     * @param {CreateCfgCollectionDto} createCfgCollectionDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCfgCollection(
      domain: string,
      createCfgCollectionDto: CreateCfgCollectionDto,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createCfgCollection(
        domain,
        createCfgCollectionDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysDomainControllerApi.createCfgCollection"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} domain
     * @param {DeleteCfgCollectionDto} deleteCfgCollectionDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteCfgCollection(
      domain: string,
      deleteCfgCollectionDto: DeleteCfgCollectionDto,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCfgCollection(
        domain,
        deleteCfgCollectionDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysDomainControllerApi.deleteCfgCollection"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} domain
     * @param {DeleteSysDomainOptionsDto} deleteSysDomainOptionsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteDomain(
      domain: string,
      deleteSysDomainOptionsDto: DeleteSysDomainOptionsDto,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDomain(
        domain,
        deleteSysDomainOptionsDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysDomainControllerApi.deleteDomain"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} domain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDomain(
      domain: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SysDomainRegularDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDomain(domain, options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysDomainControllerApi.getDomain"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {boolean} [onlyRooms]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDomains(
      onlyRooms?: boolean,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SysDomainRegularDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDomains(onlyRooms, options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysDomainControllerApi.getDomains"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {CreateSysDomainDto} createSysDomainDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async registerDomain(
      createSysDomainDto: CreateSysDomainDto,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.registerDomain(
        createSysDomainDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysDomainControllerApi.registerDomain"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * SysDomainControllerApi - factory interface
 * @export
 */
export const SysDomainControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SysDomainControllerApiFp(configuration);
  return {
    /**
     *
     * @param {string} domain
     * @param {CreateCfgCollectionDto} createCfgCollectionDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCfgCollection(
      domain: string,
      createCfgCollectionDto: CreateCfgCollectionDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .createCfgCollection(domain, createCfgCollectionDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} domain
     * @param {DeleteCfgCollectionDto} deleteCfgCollectionDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCfgCollection(
      domain: string,
      deleteCfgCollectionDto: DeleteCfgCollectionDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .deleteCfgCollection(domain, deleteCfgCollectionDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} domain
     * @param {DeleteSysDomainOptionsDto} deleteSysDomainOptionsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDomain(
      domain: string,
      deleteSysDomainOptionsDto: DeleteSysDomainOptionsDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .deleteDomain(domain, deleteSysDomainOptionsDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} domain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDomain(domain: string, options?: any): AxiosPromise<SysDomainRegularDto> {
      return localVarFp.getDomain(domain, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {boolean} [onlyRooms]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDomains(onlyRooms?: boolean, options?: any): AxiosPromise<Array<SysDomainRegularDto>> {
      return localVarFp
        .getDomains(onlyRooms, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CreateSysDomainDto} createSysDomainDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registerDomain(createSysDomainDto: CreateSysDomainDto, options?: any): AxiosPromise<void> {
      return localVarFp
        .registerDomain(createSysDomainDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SysDomainControllerApi - object-oriented interface
 * @export
 * @class SysDomainControllerApi
 * @extends {BaseAPI}
 */
export class SysDomainControllerApi extends BaseAPI {
  /**
   *
   * @param {string} domain
   * @param {CreateCfgCollectionDto} createCfgCollectionDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysDomainControllerApi
   */
  public createCfgCollection(
    domain: string,
    createCfgCollectionDto: CreateCfgCollectionDto,
    options?: RawAxiosRequestConfig
  ) {
    return SysDomainControllerApiFp(this.configuration)
      .createCfgCollection(domain, createCfgCollectionDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} domain
   * @param {DeleteCfgCollectionDto} deleteCfgCollectionDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysDomainControllerApi
   */
  public deleteCfgCollection(
    domain: string,
    deleteCfgCollectionDto: DeleteCfgCollectionDto,
    options?: RawAxiosRequestConfig
  ) {
    return SysDomainControllerApiFp(this.configuration)
      .deleteCfgCollection(domain, deleteCfgCollectionDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} domain
   * @param {DeleteSysDomainOptionsDto} deleteSysDomainOptionsDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysDomainControllerApi
   */
  public deleteDomain(
    domain: string,
    deleteSysDomainOptionsDto: DeleteSysDomainOptionsDto,
    options?: RawAxiosRequestConfig
  ) {
    return SysDomainControllerApiFp(this.configuration)
      .deleteDomain(domain, deleteSysDomainOptionsDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} domain
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysDomainControllerApi
   */
  public getDomain(domain: string, options?: RawAxiosRequestConfig) {
    return SysDomainControllerApiFp(this.configuration)
      .getDomain(domain, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {boolean} [onlyRooms]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysDomainControllerApi
   */
  public getDomains(onlyRooms?: boolean, options?: RawAxiosRequestConfig) {
    return SysDomainControllerApiFp(this.configuration)
      .getDomains(onlyRooms, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreateSysDomainDto} createSysDomainDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysDomainControllerApi
   */
  public registerDomain(
    createSysDomainDto: CreateSysDomainDto,
    options?: RawAxiosRequestConfig
  ) {
    return SysDomainControllerApiFp(this.configuration)
      .registerDomain(createSysDomainDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
