import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { ContentCopy, Edit, Power, PowerOff } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { Button, Checkbox, Popconfirm } from "antd";
import {
  MRT_ColumnDef,
  MRT_TableInstance,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  useSysCfgChangeEnabledMutation,
  useSysCfgCreateMutation,
  useSysCfgDeleteMutation,
  useSysCfgQuery,
  useSysCfgUpdateMutation,
} from "@/api/hooks/sys-cfg-hooks";
import { useCfgPerm, useIsOwnerOrSuperAdmin } from "@/auth/hooks";
import ApplyAuthGroupsTableControl from "@/components/apply-auth-groups-table-control";
import BulkOperationReportModal from "@/components/bulk-operation-report-modal";
import CommonConfigWriteModal from "@/components/config-modals/common-config-write-modal";
import RegularConfigCreateModal from "@/components/config-modals/regular-config-create-modal";
import RegularConfigEditModal from "@/components/config-modals/regular-config-edit-modal";
import { useTableSettings } from "@/hooks/use-table-settings";
import { assembleNamesFilter } from "@/lib/cfg-utils";
import { ROOM_CONFIGS_NAMES, ROOM_NAMES } from "@/lib/constants";
import { useGetJsonForm } from "@/module/room-configs-module/hooks/room-configs-hooks";
import {
  ResultDtoLong,
  SysCfgCreateRequestDto,
  SysCfgRegularDto,
  SysCfgUpdateRequestDto,
} from "@/shared/api";
import { AnyObj } from "@/types/commonTypes";

const AiProfileConfigTable = () => {
  const { t } = useTranslation("room-configs");
  const {
    data: configsRs,
    isLoading: configsIsLoading,
    refetch: refetchConfigs,
  } = useSysCfgQuery({
    domain: ROOM_NAMES.SERVICES,
    config_name: ROOM_CONFIGS_NAMES.AI_PROFILE,
  });

  const cfgPerm = useCfgPerm({
    roomName: ROOM_NAMES.SERVICES,
    cfgName: ROOM_CONFIGS_NAMES.AI_PROFILE,
  });

  const isOwner = useIsOwnerOrSuperAdmin();

  const roomConfigsSortedData = React.useMemo(() => {
    return (
      configsRs?.data.content?.sort(
        (a, b) => new Date(b.created_at).getDate() - new Date(a.created_at).getDate()
      ) ?? []
    );
  }, [configsRs]);

  const { data: jsonFormSchema } = useGetJsonForm({
    roomName: ROOM_NAMES.SERVICES,
    configName: ROOM_CONFIGS_NAMES.AI_PROFILE,
  });

  const { mutate: createCfgMutate } = useSysCfgCreateMutation();
  const { mutate: changeEnabledMutate } = useSysCfgChangeEnabledMutation();
  const { mutate: deleteCfgsMutate } = useSysCfgDeleteMutation();
  const { mutate: updateCfgMutate } = useSysCfgUpdateMutation();

  //This logic for reset row selection while using filtering/grouping
  const [editingConfig, setEditingConfig] = useState<SysCfgRegularDto | undefined>(undefined);
  const [addConfig, setAddConfig] = useState<boolean>(false);
  const [addingConfig, setAddingConfig] = useState<SysCfgCreateRequestDto | undefined>(
    undefined
  );
  const [bulkOperationResult, setBulkOperationResult] = useState<ResultDtoLong[] | undefined>(
    undefined
  );

  function closeAddConfig() {
    setAddingConfig(undefined);
    setAddConfig(false);
  }

  function handleClickAddConfig() {
    setAddConfig(true);
  }

  function handleClickSaveNewConfig(config: SysCfgCreateRequestDto) {
    createCfgMutate(
      {
        domain: ROOM_NAMES.SERVICES,
        cfgName: ROOM_CONFIGS_NAMES.AI_PROFILE,
        body: config,
      },
      {
        onSuccess: (response) => {
          enqueueSnackbar(
            t("elementsHasBeenUpdated", {
              data: response.data,
            }),
            {
              variant: "success",
            }
          );
          closeAddConfig();
          void refetchConfigs();
        },
        onError: (err) => {
          enqueueSnackbar(
            t("operationFailureContactAdministrator", { message: err.message }),
            {
              variant: "error",
            }
          );
          console.error("Adding config failure", err);
        },
      }
    );
  }

  function handleClickUpdateConfig(name: string, config: SysCfgUpdateRequestDto) {
    updateCfgMutate(
      {
        domain: ROOM_NAMES.SERVICES,
        cfgName: ROOM_CONFIGS_NAMES.AI_PROFILE,
        name: name,
        body: config,
      },
      {
        onSuccess: (response) => {
          enqueueSnackbar(
            t("elementsHasBeenUpdated", {
              data: response.data,
            }),
            {
              variant: "success",
            }
          );
          setEditingConfig(undefined);
          void refetchConfigs();
        },
        onError: (err) => {
          enqueueSnackbar(
            t("operationFailureContactAdministrator", { message: err.message }),
            {
              variant: "error",
            }
          );
          console.error("Config edit failure", err);
        },
      }
    );
  }

  function handleChangeEnableBulk(
    table: MRT_TableInstance<SysCfgRegularDto>,
    enabled: boolean
  ) {
    const names = table.getSelectedRowModel().rows.map((item) => item.original.name);

    changeEnabledMutate(
      {
        domain: ROOM_NAMES.SERVICES,
        cfgName: ROOM_CONFIGS_NAMES.AI_PROFILE,
        enabled: enabled,
        filters: new Set([
          { key: "name", mode: "STRICT_IN", value: names as unknown as object },
        ]),
      },
      {
        onSuccess: (response) => {
          enqueueSnackbar(
            t("elementsHasBeenUpdated", {
              data: response.data,
            }),
            {
              variant: "success",
            }
          );
          table.resetRowSelection();
          void refetchConfigs();
        },
        onError: (err) => {
          enqueueSnackbar(
            t("operationFailureContactAdministrator", { message: err.message }),
            {
              variant: "error",
            }
          );
          console.error("Change enabled operation failure", err);
        },
      }
    );
  }

  function handleDeleteConfigs(table: MRT_TableInstance<SysCfgRegularDto>) {
    const names = table.getSelectedRowModel().rows.map((item) => item.original.name);

    deleteCfgsMutate(
      {
        domain: ROOM_NAMES.SERVICES,
        cfgName: ROOM_CONFIGS_NAMES.AI_PROFILE,
        filters: new Set([
          { key: "name", mode: "STRICT_IN", value: names as unknown as object },
        ]),
      },
      {
        onSuccess: (response) => {
          setBulkOperationResult([response.data]);
          response.data.dataOnError
            ? enqueueSnackbar(t("somethingWentWrong"), { variant: "error" })
            : enqueueSnackbar(t("elementsHasBeenDeleted"), { variant: "success" });
          table.resetRowSelection();
          void refetchConfigs();
        },
        onError: (err) => {
          enqueueSnackbar(
            t("operationFailureContactAdministrator", { message: err.message }),
            {
              variant: "error",
            }
          );
          console.error("Delete operation failure", err);
        },
      }
    );
  }

  const columns = useMemo<MRT_ColumnDef<SysCfgRegularDto>[]>(
    () => [
      {
        header: t("aiProfileConfigsTable.enabled"),
        filterFn: "contains",
        enableEditing: false,
        accessorFn: (row) => row.enabled ?? "-",
        size: 80,
        Cell: ({ row }) => <Checkbox disabled checked={row.original.enabled} />,
      },
      {
        header: t("aiProfileConfigsTable.name"),
        accessorKey: "name",
        filterFn: "contains",
      },
      {
        header: t("aiProfileConfigsTable.gameType"),
        accessorFn: (row) => {
          const data = row.data as AnyObj;
          if (Array.isArray(data?.profile)) {
            return data?.profile
              .map((itm) => {
                const gt = itm?.game_type;

                if (typeof gt === "string" || gt instanceof String) {
                  return gt;
                }

                return "";
              })
              .toString();
          }

          return "-";
        },
        filterFn: "contains",
      },
    ],
    [t]
  );

  const {
    defaultProps,
    columnVisibility,
    columnOrder,
    columnFilters,
    globalFilter,
    grouping,
    pagination,
    sorting,
  } = useTableSettings<SysCfgRegularDto>("aiProfile");

  const table = useMaterialReactTable({
    data: roomConfigsSortedData ?? [],
    columns: columns,
    getRowId: (originalRow) => originalRow.id,
    selectAllMode: "all",
    ...defaultProps,
    initialState: {
      ...defaultProps.initialState,
      sorting: [{ id: "name", desc: false }],
    },
    enableSelectAll: true,
    enableRowSelection: true,
    enableGrouping: true,
    enableColumnDragging: true,
    enableColumnOrdering: true,
    enableRowActions: true,
    state: {
      columnFilters,
      globalFilter,
      grouping,
      columnVisibility,
      columnOrder,
      isLoading: configsIsLoading,
      pagination,
      sorting,
    },
    renderTopToolbarCustomActions: ({ table }) => {
      const selectedCount = table.getSelectedRowModel().rows.length;
      return (
        <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
          <Button onClick={() => handleClickAddConfig()}>
            <PlusOutlined />
            {t("new")}
          </Button>
          {selectedCount > 0 && (
            <div style={{ marginLeft: 30 }}>
              <IconButton
                size="small"
                style={{ fontSize: "15px" }}
                color="success"
                onClick={() => handleChangeEnableBulk(table, true)}
              >
                <Power /> {t("enable")}
              </IconButton>
              <IconButton
                size="small"
                style={{ fontSize: "15px" }}
                color="error"
                onClick={() => handleChangeEnableBulk(table, false)}
              >
                <PowerOff /> {t("disable")}
              </IconButton>
              <Popconfirm
                title={t("deleteItems")}
                description={t("areYouSureToDeleteItems")}
                onConfirm={() => handleDeleteConfigs(table)}
              >
                <IconButton size="small" style={{ fontSize: "15px" }} color="error">
                  <DeleteOutlined /> {t("delete")}
                </IconButton>
              </Popconfirm>

              {isOwner && (
                <ApplyAuthGroupsTableControl
                  domain={ROOM_NAMES.SERVICES}
                  cfgName={ROOM_CONFIGS_NAMES.AI_PROFILE}
                  table={table}
                  filters={assembleNamesFilter(table)}
                  totalElementsCount={selectedCount}
                  onSuccess={() => void refetchConfigs()}
                />
              )}
            </div>
          )}
        </div>
      );
    },
    renderRowActions: ({ row }) => (
      <Box>
        <IconButton onClick={() => setEditingConfig({ ...row.original })}>
          <Edit />
        </IconButton>
        <IconButton
          onClick={() => {
            setAddingConfig({
              ...row.original,
              name: "",
              auth_groups: row.original.auth_groups ?? new Set(),
            });
            setAddConfig(true);
          }}
        >
          <ContentCopy />
        </IconButton>
      </Box>
    ),
  });

  useEffect(() => {
    table.resetRowSelection();
  }, [columnFilters, grouping, globalFilter, table]);

  return (
    <>
      <MaterialReactTable table={table} />

      {addConfig && (
        <>
          {jsonFormSchema ? (
            <RegularConfigCreateModal
              title={t("createConfig")}
              open={addConfig}
              uiSchema={jsonFormSchema}
              defaultValues={addingConfig}
              onSave={(config) => handleClickSaveNewConfig(config)}
              onCancel={() => closeAddConfig()}
            />
          ) : (
            <CommonConfigWriteModal
              mode="add"
              isOpen={addConfig}
              defaultValues={addingConfig}
              onSave={(config) => handleClickSaveNewConfig({ ...config })}
              onCancel={() => closeAddConfig()}
            />
          )}
        </>
      )}

      {editingConfig && (
        <>
          {jsonFormSchema ? (
            <RegularConfigEditModal
              title={t("editConfig")}
              open={!!(editingConfig && jsonFormSchema)}
              uiSchema={jsonFormSchema}
              config={editingConfig}
              onSave={(config) => handleClickUpdateConfig(config.name, config)}
              onCancel={() => setEditingConfig(undefined)}
              disabled={!cfgPerm.write}
            />
          ) : (
            <CommonConfigWriteModal
              mode="edit"
              isOpen={!!editingConfig}
              defaultValues={editingConfig}
              onSave={(config) => handleClickUpdateConfig(config.name, { ...config })}
              onCancel={() => setEditingConfig(undefined)}
              disabled={!cfgPerm.write}
            />
          )}
        </>
      )}

      {bulkOperationResult && (
        <BulkOperationReportModal
          resultList={bulkOperationResult}
          title={t("operationResult")}
          showProblematic
          showSuccess
          onCancel={() => setBulkOperationResult(undefined)}
          open={!!bulkOperationResult}
        />
      )}
    </>
  );
};

export default AiProfileConfigTable;
