import { IRoomConfigRawResponse } from "./roomConfigTypes";

export interface ICreateAccounts {
  accounts: ICreateAccount[];
}

export interface ICreateAccount {
  name: string;
  password: string;
  walletCode: string;
  cid?: string;
  source?: string;
  gameGroup?: string;
  proxy?: string;
  behaviour?: string;
  schedule?: string;
  gameType?: string;
  aiProfile?: string;
  aiProfileOb?: string;
  timingProfile?: string;
  timingProfileOb?: string;
  insuranceProfile?: string;
  insuranceProfileOb?: string;
  osType?: number;
  accountType?: number;
}

export interface IEditableAccount {
  id?: string;
  name: string;
  password: string;
  walletCode: string;
  cid?: string;
  pid?: number;
  source?: string;
  osType?: number;
  accountType?: number;
  comment?: string;
  nick?: string;
  gameGroup?: string;
  proxy?: string;
  behaviour?: string;
  schedule?: string;
  gameType?: string;
  aiProfile?: string;
  aiProfileOb?: string;
  timingProfile?: string;
  timingProfileOb?: string;
  insuranceProfile?: string;
  insuranceProfileOb?: string;
}

export interface IAccountTableDisplayable {
  id: string;
  name: string;
  roomName: string;
  createdAt: string;
  updatedAt: string;
  enabled: boolean;
  password: string;
  clubs: any;
  walletCode: string;
  cid?: string;
  pid?: number;
  aid?: string;
  alc?: number;
  gold?: number;
  status?: number;
  source?: string;
  osType?: number;
  accountType?: number;
  comment?: string;
  nick?: string;
  proxy?: IRoomConfigRawResponse;
  gameGroup?: IRoomConfigRawResponse;
  behaviour?: IRoomConfigRawResponse;
  schedule?: IRoomConfigRawResponse;
  gameType?: IRoomConfigRawResponse;
  aiProfile?: IRoomConfigRawResponse;
  aiProfileOb?: IRoomConfigRawResponse;
  timingProfile?: IRoomConfigRawResponse;
  timingProfileOb?: IRoomConfigRawResponse;
  insuranceProfile?: IRoomConfigRawResponse;
  insuranceProfileOb?: IRoomConfigRawResponse;
}

export type AccountBulkUpdateFields = Map<string, string | null >;

export interface IAccountRegularResponse {
  id: string;
  roomName: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  enabled: boolean;
  data: IAccountData;
  session?: IAccountSessionRegularResponse
}

export interface IAccountData {
  uuid: number,
  username: string;
  password: string;
  clubs: any;
  walletCode: string;

  device?: IAccountDevice;
  avatar?: string;
  statistic?: any;
  account_type?: number;
  comment?: string;

  cid?: string;
  pid?: number;
  aid?: string;
  alc?: number;
  gold?: number;
  status?: number;
  source?: string;
  nick?: string;
  proxy?: string;
  game_group?: string;
  behaviour?: string;
  schedule?: string;
  game_type?: string;
  ai_profile?: string;
  ai_profile_ob?: string;
  timing_profile?: string;
  timing_profile_ob?: string;
  insurance_profile?: string;
  insurance_profile_ob?: string;
}

export interface IAccountSessionRegularResponse {
  id: string;
  sessionType: number;
  username: string;
  aid?: string;
  clubs?: any;
  cid?: string;
  proxy?: string;
  schedule?: string;
  gs?: string;
  tes?: string;
  pid?: string;
  uuid?: number;
  nick?: string;
  avatar?: string;
  gold?: number;
  gameInfo?: IGameInfoRegularResponse;
  statistic?: Map<string, Map<string, IGameTypeRegularResponse>>
}

export interface IGameInfoRegularResponse {
  hid?: number;
  tid?: string;
  type?: string;
  name?: string;
  size?: number;
  limit?: number;
  hands?: number;
  result?: number;
}

export interface IGameTypeRegularResponse {
  pfr?: number;
  vpip?: number;
  hands?: number;
}

export interface IAccountSessionTableDisplayable {
  id: string;
  sessionType: number;
  accountId: string; //
  username: string;
  pid?: string;
  nick?: string;
  clubs?: any; //
  cid?: string;
  aid?: string;
  tid?: string; //
  tableName?: string; //
  gameType?: string;
  tableLimit?: number;
  tableHands?: number;
  tableResult?: number;
  vpip?: number;
  pfr?: number;
  hands?: number;
  proxy?: string;
  gs?: string;
  gsMode?: string;
  gsVersion?: string;
  tes?:string;
  tesMode?: string;
  tesVersion?: string;
  
  schedule?: string;
  uuid?: number;
  avatar?: string;
  alc?: number;
  gold?: number;
}

export interface IAccountDevice {
  osType?: number
}

export enum AccountSessionType {
  GAME = 0,
  DEBUG = 1,
  SCANNER = 2,
  MANAGER = 3,
  GRABBER = 4,
}

// export type SysAccountEditForm = {
//   [K in keyof SysAccountUpdateDto]: SysAccountUpdateDto[K] extends undefined ? null : SysAccountUpdateDto[K];
// }

export interface SysAccountEditForm {
  name: string,
  authGroups: string[];
  password: string;
  walletCode: string;
  source: string | null;
  osType: number;
  accountType: number;
  comment: string | null;
  gameGroup: string | null;
  cid: string | null;
  proxy: string | null;
  behaviour: string | null;
  schedule: string | null;
  gameType: string | null;
  aiProfile: string | null;
  aiProfileOb: string | null;
  timingProfile: string | null;
  timingProfileOb: string | null;
  insuranceProfile: string | null;
  insuranceProfileOb: string | null;
}

export interface SysAccountsGenerateForm {
  authGroups: string[];
  amount: number;
  accounts: ICreateAccount[]
}