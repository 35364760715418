/* tslint:disable */

/* eslint-disable */

/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { AxiosInstance, AxiosPromise, RawAxiosRequestConfig } from "axios";
import globalAxios from "axios";

// @ts-ignore
import {
  BASE_PATH,
  BaseAPI,
  COLLECTION_FORMATS,
  RequestArgs,
  RequiredError,
  operationServerMap,
} from "../base";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  createRequestFunction,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  toPathString,
} from "../common";
import type { Configuration } from "../configuration";
// @ts-ignore
import { ClubRegularDto } from "../models";

/**
 * SysDataMainControllerApi - axios parameter creator
 * @export
 */
export const SysDataMainControllerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} domain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClubs: async (
      domain: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("getClubs", "domain", domain);
      const localVarPath = `/api/v2/sys-data/{domain}/clubs`.replace(
        `{${"domain"}}`,
        encodeURIComponent(String(domain))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SysDataMainControllerApi - functional programming interface
 * @export
 */
export const SysDataMainControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SysDataMainControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} domain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClubs(
      domain: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClubRegularDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getClubs(domain, options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysDataMainControllerApi.getClubs"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * SysDataMainControllerApi - factory interface
 * @export
 */
export const SysDataMainControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SysDataMainControllerApiFp(configuration);
  return {
    /**
     *
     * @param {string} domain
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClubs(domain: string, options?: any): AxiosPromise<Array<ClubRegularDto>> {
      return localVarFp.getClubs(domain, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * SysDataMainControllerApi - object-oriented interface
 * @export
 * @class SysDataMainControllerApi
 * @extends {BaseAPI}
 */
export class SysDataMainControllerApi extends BaseAPI {
  /**
   *
   * @param {string} domain
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysDataMainControllerApi
   */
  public getClubs(domain: string, options?: RawAxiosRequestConfig) {
    return SysDataMainControllerApiFp(this.configuration)
      .getClubs(domain, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
