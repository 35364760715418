import { Grid } from "@mui/material";
import { Divider, Typography } from "antd";

import RunningServicesModule from "@/module/running-services-module/running-services-module";

const DashboardPage = () => {
  return (
    <>
      <Grid container spacing={2} height="100%" direction="column" flexWrap="nowrap">
        <Grid item>
          <Typography.Title level={2}>Services</Typography.Title>
        </Grid>
        <Grid item>
          <Divider style={{ marginTop: 0 }} />
        </Grid>
        <Grid item xs={10}>
          <RunningServicesModule />
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardPage;
