/* tslint:disable */

/* eslint-disable */

/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { AxiosInstance, AxiosPromise, RawAxiosRequestConfig } from "axios";
import globalAxios from "axios";

// @ts-ignore
import {
  BASE_PATH,
  BaseAPI,
  COLLECTION_FORMATS,
  RequestArgs,
  RequiredError,
  operationServerMap,
} from "../base";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  createRequestFunction,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  toPathString,
} from "../common";
import type { Configuration } from "../configuration";
// @ts-ignore
import { ChangePasswordRequestDto } from "../models";
// @ts-ignore
import { CreateUserRequestDto } from "../models";
// @ts-ignore
import { DeleteUsersRequestDto } from "../models";
// @ts-ignore
import { RevokeTokensRequestDto } from "../models";
// @ts-ignore
import { UpdateUserRequestDto } from "../models";
// @ts-ignore
import { UserResponseRegularDto } from "../models";

/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {ChangePasswordRequestDto} changePasswordRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePassword: async (
      changePasswordRequestDto: ChangePasswordRequestDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'changePasswordRequestDto' is not null or undefined
      assertParamExists(
        "changePassword",
        "changePasswordRequestDto",
        changePasswordRequestDto
      );
      const localVarPath = `/api/v2/user/change-password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        changePasswordRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateUserRequestDto} createUserRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser: async (
      createUserRequestDto: CreateUserRequestDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createUserRequestDto' is not null or undefined
      assertParamExists("createUser", "createUserRequestDto", createUserRequestDto);
      const localVarPath = `/api/v2/user`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createUserRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {DeleteUsersRequestDto} deleteUsersRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUsers: async (
      deleteUsersRequestDto: DeleteUsersRequestDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'deleteUsersRequestDto' is not null or undefined
      assertParamExists("deleteUsers", "deleteUsersRequestDto", deleteUsersRequestDto);
      const localVarPath = `/api/v2/user/delete-users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteUsersRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllUsers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/user`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserById: async (
      id: number,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getUserById", "id", id);
      const localVarPath = `/api/v2/user/id/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserByUsername: async (
      username: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'username' is not null or undefined
      assertParamExists("getUserByUsername", "username", username);
      const localVarPath = `/api/v2/user/username/{username}`.replace(
        `{${"username"}}`,
        encodeURIComponent(String(username))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {RevokeTokensRequestDto} revokeTokensRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    revokeTokens: async (
      revokeTokensRequestDto: RevokeTokensRequestDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'revokeTokensRequestDto' is not null or undefined
      assertParamExists("revokeTokens", "revokeTokensRequestDto", revokeTokensRequestDto);
      const localVarPath = `/api/v2/user/revoke-tokens`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        revokeTokensRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpdateUserRequestDto} updateUserRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser: async (
      updateUserRequestDto: UpdateUserRequestDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateUserRequestDto' is not null or undefined
      assertParamExists("updateUser", "updateUserRequestDto", updateUserRequestDto);
      const localVarPath = `/api/v2/user`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUserRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {ChangePasswordRequestDto} changePasswordRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changePassword(
      changePasswordRequestDto: ChangePasswordRequestDto,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(
        changePasswordRequestDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["UserControllerApi.changePassword"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {CreateUserRequestDto} createUserRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createUser(
      createUserRequestDto: CreateUserRequestDto,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(
        createUserRequestDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["UserControllerApi.createUser"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {DeleteUsersRequestDto} deleteUsersRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteUsers(
      deleteUsersRequestDto: DeleteUsersRequestDto,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUsers(
        deleteUsersRequestDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["UserControllerApi.deleteUsers"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllUsers(
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserResponseRegularDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllUsers(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["UserControllerApi.getAllUsers"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserById(
      id: number,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponseRegularDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserById(id, options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["UserControllerApi.getUserById"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserByUsername(
      username: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponseRegularDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserByUsername(
        username,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["UserControllerApi.getUserByUsername"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {RevokeTokensRequestDto} revokeTokensRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async revokeTokens(
      revokeTokensRequestDto: RevokeTokensRequestDto,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.revokeTokens(
        revokeTokensRequestDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["UserControllerApi.revokeTokens"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {UpdateUserRequestDto} updateUserRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUser(
      updateUserRequestDto: UpdateUserRequestDto,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(
        updateUserRequestDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["UserControllerApi.updateUser"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = UserControllerApiFp(configuration);
  return {
    /**
     *
     * @param {ChangePasswordRequestDto} changePasswordRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePassword(
      changePasswordRequestDto: ChangePasswordRequestDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .changePassword(changePasswordRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CreateUserRequestDto} createUserRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser(createUserRequestDto: CreateUserRequestDto, options?: any): AxiosPromise<void> {
      return localVarFp
        .createUser(createUserRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {DeleteUsersRequestDto} deleteUsersRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUsers(
      deleteUsersRequestDto: DeleteUsersRequestDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .deleteUsers(deleteUsersRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllUsers(options?: any): AxiosPromise<Array<UserResponseRegularDto>> {
      return localVarFp.getAllUsers(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserById(id: number, options?: any): AxiosPromise<UserResponseRegularDto> {
      return localVarFp.getUserById(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserByUsername(username: string, options?: any): AxiosPromise<UserResponseRegularDto> {
      return localVarFp
        .getUserByUsername(username, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {RevokeTokensRequestDto} revokeTokensRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    revokeTokens(
      revokeTokensRequestDto: RevokeTokensRequestDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .revokeTokens(revokeTokensRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UpdateUserRequestDto} updateUserRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser(updateUserRequestDto: UpdateUserRequestDto, options?: any): AxiosPromise<void> {
      return localVarFp
        .updateUser(updateUserRequestDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
  /**
   *
   * @param {ChangePasswordRequestDto} changePasswordRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public changePassword(
    changePasswordRequestDto: ChangePasswordRequestDto,
    options?: RawAxiosRequestConfig
  ) {
    return UserControllerApiFp(this.configuration)
      .changePassword(changePasswordRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreateUserRequestDto} createUserRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public createUser(
    createUserRequestDto: CreateUserRequestDto,
    options?: RawAxiosRequestConfig
  ) {
    return UserControllerApiFp(this.configuration)
      .createUser(createUserRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DeleteUsersRequestDto} deleteUsersRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public deleteUsers(
    deleteUsersRequestDto: DeleteUsersRequestDto,
    options?: RawAxiosRequestConfig
  ) {
    return UserControllerApiFp(this.configuration)
      .deleteUsers(deleteUsersRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public getAllUsers(options?: RawAxiosRequestConfig) {
    return UserControllerApiFp(this.configuration)
      .getAllUsers(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public getUserById(id: number, options?: RawAxiosRequestConfig) {
    return UserControllerApiFp(this.configuration)
      .getUserById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} username
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public getUserByUsername(username: string, options?: RawAxiosRequestConfig) {
    return UserControllerApiFp(this.configuration)
      .getUserByUsername(username, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RevokeTokensRequestDto} revokeTokensRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public revokeTokens(
    revokeTokensRequestDto: RevokeTokensRequestDto,
    options?: RawAxiosRequestConfig
  ) {
    return UserControllerApiFp(this.configuration)
      .revokeTokens(revokeTokensRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UpdateUserRequestDto} updateUserRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserControllerApi
   */
  public updateUser(
    updateUserRequestDto: UpdateUserRequestDto,
    options?: RawAxiosRequestConfig
  ) {
    return UserControllerApiFp(this.configuration)
      .updateUser(updateUserRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
