import React, { useState } from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSaveNewAccount } from "@/module/accounts-module/hooks/accounts-mutate-hooks";
import SysAccountWriteModal from "@/module/accounts-module/components/sys-account-write-modal";


const CreateServiceAccountTableControl = (
  {
    roomName,
    onSuccess
  } : {
    roomName: string;
    onSuccess?: () => void
  }
) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const {
    mutate: handleClickSaveNewAccount,
    isPending: createAccountIsPending
  } = useSaveNewAccount({
    roomName: roomName,
    successCallback: () => {
      setIsOpen(false);
      onSuccess && onSuccess();
    }
  });

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>
        <PlusOutlined />
        New
      </Button>
      <SysAccountWriteModal
        mode="add"
        accountType="service"
        isOpen={isOpen}
        roomName={roomName}
        onSave={(name, account) => handleClickSaveNewAccount({ name, ...account})}
        onClose={() => setIsOpen(false)}
        confirmLoading={createAccountIsPending}
      />
    </>
  )
}


export default CreateServiceAccountTableControl