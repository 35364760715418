import { UploadOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Modal, Typography, Upload, UploadProps } from "antd";
import { RcFile } from "antd/es/upload";
import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const AvatarConfigAddModal = ({
  title,
  confirmLoading = false,
  onCancel,
  onSave,
}: {
  title?: string;
  confirmLoading?: boolean;
  onCancel?: () => void;
  onSave?: (zipArchive: File) => void;
}) => {
  const { t } = useTranslation("room-configs");
  const [form] = Form.useForm();
  const [file, setFile] = useState<RcFile>();

  function handleSaveClick() {
    form
      .validateFields({ validateOnly: false })
      .then(() => {
        const fileData = file;

        if (fileData === undefined) {
          enqueueSnackbar(t("fileIsNotSat"), { variant: "error" });
          return;
        }

        // if(!fileData.originFileObj) {
        //   enqueueSnackbar("File is not defined. Contact an administrator", {variant: "error"})
        //   console.error("fileData.originalFileObj is not defined", fileData);
        //   return;
        // }

        if (onSave) onSave(fileData);
      })
      .catch((err) => console.error(err));
  }

  const props: UploadProps = {
    maxCount: 1,
    multiple: false,
    onRemove: () => setFile(undefined),
    beforeUpload: (fileParam) => {
      const isZip =
        fileParam.type === "application/zip" ||
        fileParam.type === "application/x-zip-compressed";

      if (!isZip) {
        enqueueSnackbar(t("uploadOnlyZipArchive"), { variant: "error" });
        return false;
      }

      const isLt10Mb = fileParam.size / 1024 / 1024 < 10;

      if (!isLt10Mb) {
        enqueueSnackbar(t("zipMustSmaller"), { variant: "error" });
        return false;
      }

      setFile(fileParam);

      return false;
    },
    fileList: file ? [file] : [],
  };

  return (
    <>
      <Modal
        open
        okText={t("submit")}
        onCancel={() => onCancel && onCancel()}
        maskClosable={false}
        width={600}
        confirmLoading={confirmLoading}
        onOk={() => handleSaveClick()}
        title={
          title && (
            <Typography.Title level={3} style={{ marginTop: 15 }}>
              {title}
            </Typography.Title>
          )
        }
      >
        {title && <Divider style={{ marginTop: 10, marginBottom: 30 }} />}

        <Divider />

        <Form
          size="large"
          className="a-common-form"
          form={form}
          autoComplete="off"
          key="addingMode"
        >
          <Form.Item
            name="image"
            label={t("zipArchiveWithLogos")}
            rules={[{ required: true }]}
          >
            <Upload maxCount={1} {...props}>
              <Button icon={<UploadOutlined />}>{t("selectZipArchive")}</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AvatarConfigAddModal;
