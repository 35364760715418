import { Box } from "@mui/material";
import { useNavigate } from "@tanstack/react-router";
import { MaterialReactTable } from "material-react-table";
import React, { useMemo } from "react";

import { useSysDomainsQuery } from "@/api/hooks/sys-domain-hooks";
import DeleteDomainTableControl from "@/module/rooms-management-module/components/delete-domain-table-control";
import useDomainsListTable from "@/module/rooms-management-module/hooks/domains-list-table-hook";

const RoomsManagementModule = () => {
  const { data: domainsRs, isLoading, isRefetching, isError, refetch } = useSysDomainsQuery();
  const navigate = useNavigate();

  const domains = useMemo(() => domainsRs?.data ?? [], [domainsRs]);

  const table = useDomainsListTable(
    {
      domains: domains,
      refetchDomains: () => refetch(),
    },
    {
      muiToolbarAlertBannerProps: isError
        ? { color: "error", children: "Error loading data" }
        : undefined,
      state: {
        isLoading: isLoading,
        showProgressBars: isRefetching,
        showAlertBanner: isError,
      },
      enableRowActions: true,
      positionActionsColumn: "last",
      displayColumnDefOptions: {
        "mrt-row-actions": { header: "" },
      },
      muiTableBodyRowProps: ({ row }) => ({
        onClick: () =>
          navigate({ to: "/room-management/$name", params: { name: row.original.name } }),
        sx: {
          cursor: "pointer",
        },
      }),
      renderRowActions: ({ row }) => (
        <Box>
          <DeleteDomainTableControl domain={row.original.name} refetch={() => refetch()} />
        </Box>
      ),
    }
  );

  return <MaterialReactTable table={table} />;
};

export default RoomsManagementModule;
