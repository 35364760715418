import { DeleteOutlined } from "@ant-design/icons";
import { Power, PowerOff } from "@mui/icons-material";
import { Avatar, IconButton } from "@mui/material";
import { Checkbox, Popconfirm } from "antd";
import {
  MRT_ColumnDef,
  MRT_TableInstance,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  useSysCfgAddAvatarsMutation,
  useSysCfgChangeEnabledMutation,
  useSysCfgDeleteMutation,
  useSysCfgQuery,
} from "@/api/hooks/sys-cfg-hooks";
import { useCfgPerm } from "@/auth/hooks";
import BulkOperationReportModal from "@/components/bulk-operation-report-modal";
import AvatarConfigAddModal from "@/components/config-modals/avatar-config-add-modal";
import { useTableSettings } from "@/hooks/use-table-settings";
import { ROOM_CONFIGS_NAMES } from "@/lib/constants";
import { ResultDtoLong, SysCfgRegularDto } from "@/shared/api";

const AvatarConfigTable = ({ room }: { room: string }) => {
  const { t } = useTranslation("room-configs");
  const { data: configsRs, refetch: refetchConfigs } = useSysCfgQuery({
    domain: room,
    config_name: ROOM_CONFIGS_NAMES.AVATAR,
  });

  const cfgPerm = useCfgPerm({
    roomName: room,
    cfgName: ROOM_CONFIGS_NAMES.AVATAR,
  });

  const roomConfigsSortedData = React.useMemo(() => {
    return (
      configsRs?.data.content?.sort(
        (a, b) => new Date(b.created_at).getDate() - new Date(a.created_at).getDate()
      ) ?? []
    );
  }, [configsRs]);

  const { mutate: addAvatarsMutate } = useSysCfgAddAvatarsMutation();
  const { mutate: changeEnabledMutate } = useSysCfgChangeEnabledMutation();
  const { mutate: deleteCfgsMutate } = useSysCfgDeleteMutation();

  //This logic for reset row selection while using filtering/grouping
  const [isUploadAvatarModalOpened, setIsUploadAvatarModalOpened] = useState<boolean>(false);

  const [bulkOperationResult, setBulkOperationResult] = useState<ResultDtoLong[] | undefined>(
    undefined
  );

  function handleClickSaveNewConfig(data: File) {
    addAvatarsMutate(
      {
        domain: room,
        file: data,
      },
      {
        onSuccess: (response) => {
          void refetchConfigs();
          enqueueSnackbar(
            t("elementsHasBeenUpdated", {
              data: response.data,
            }),
            {
              variant: "success",
            }
          );
          setIsUploadAvatarModalOpened(false);
        },
        onError: (err) => {
          enqueueSnackbar(
            t("operationFailureContactAdministrator", { message: err.message }),
            {
              variant: "error",
            }
          );
          console.error("Adding config failure", err);
        },
      }
    );
  }

  function handleChangeEnableBulk(
    table: MRT_TableInstance<SysCfgRegularDto>,
    enabled: boolean
  ) {
    const names = table.getSelectedRowModel().rows.map((item) => item.original.name);

    changeEnabledMutate(
      {
        domain: room,
        cfgName: ROOM_CONFIGS_NAMES.AVATAR,
        enabled: enabled,
        filters: new Set([
          { key: "name", mode: "STRICT_IN", value: names as unknown as object },
        ]),
      },
      {
        onSuccess: (response) => {
          void refetchConfigs();
          enqueueSnackbar(
            t("elementsHasBeenUpdated", {
              data: response.data,
            }),
            {
              variant: "success",
            }
          );
          table.resetRowSelection();
        },
        onError: (err) => {
          enqueueSnackbar(
            t("operationFailureContactAdministrator", { message: err.message }),
            {
              variant: "error",
            }
          );
          console.error("Change enabled operation failure", err);
        },
      }
    );
  }

  function handleDeleteConfigs(table: MRT_TableInstance<SysCfgRegularDto>) {
    const names = table.getSelectedRowModel().rows.map((item) => item.original.name);

    deleteCfgsMutate(
      {
        domain: room,
        cfgName: ROOM_CONFIGS_NAMES.AVATAR,
        filters: new Set([
          { key: "name", mode: "STRICT_IN", value: names as unknown as object },
        ]),
      },
      {
        onSuccess: (response) => {
          setBulkOperationResult([response.data]);
          response.data.dataOnError
            ? enqueueSnackbar(t("somethingWentWrong"), { variant: "error" })
            : enqueueSnackbar(t("elementsHasBeenDeleted"), { variant: "success" });
          table.resetRowSelection();
          void refetchConfigs();
        },
        onError: (err) => {
          enqueueSnackbar(
            t("operationFailureContactAdministrator", { message: err.message }),
            {
              variant: "error",
            }
          );
          console.error("Delete operation failure", err);
        },
      }
    );
  }

  const columns = useMemo<MRT_ColumnDef<SysCfgRegularDto>[]>(
    () => [
      {
        id: "image",
        header: t("avatarConfigTable.image"),
        accessorFn: (row) => (row.data as any)?.avatar_key,
        Cell: ({ row }) => (
          <Avatar
            alt="?"
            src={`/api/v2/sys-cfg/${room}/${ROOM_CONFIGS_NAMES.AVATAR}/download/${row.original.name}`}
          />
        ),
      },
      {
        header: t("avatarConfigTable.name"),
        accessorKey: "name",
        filterFn: "contains",
      },
      {
        header: t("avatarConfigTable.avatarLink"),
        filterFn: "contains",
        accessorKey: "data.avatar_link",
      },
      {
        header: t("avatarConfigTable.isUsed"),
        filterFn: "contains",
        accessorFn: (row) => `${(row.data as any)?.is_used ?? "-"}`,
      },
      {
        header: t("avatarConfigTable.enabled"),
        filterFn: "contains",
        enableEditing: false,
        accessorFn: (row) => row.enabled ?? "-",
        size: 80,
        Cell: ({ row }) => <Checkbox disabled checked={row.original.enabled} />,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    defaultProps,
    columnVisibility,
    columnOrder,
    columnFilters,
    globalFilter,
    grouping,
    pagination,
    sorting,
  } = useTableSettings<SysCfgRegularDto>("avatar");

  const table = useMaterialReactTable({
    data: roomConfigsSortedData ?? [],
    columns: columns,
    getRowId: (originalRow) => originalRow.id,
    ...defaultProps,
    state: {
      columnFilters,
      globalFilter,
      grouping,
      columnVisibility,
      columnOrder,
      pagination,
      sorting,
    },
    initialState: {
      ...defaultProps.initialState,
      sorting: [{ id: "name", desc: false }],
    },
    enableRowSelection: true,
    enableGrouping: true,
    enableColumnDragging: true,
    enableColumnOrdering: true,
    enableRowVirtualization: true,
    rowVirtualizerOptions: { overscan: 20 },
    renderTopToolbarCustomActions: () => {
      return (
        <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
          {table.getSelectedRowModel().rows.length > 0 && (
            <div style={{ marginLeft: 30 }}>
              {cfgPerm.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="success"
                  onClick={() => handleChangeEnableBulk(table, true)}
                >
                  <Power /> {t("enabled")}
                </IconButton>
              )}

              {cfgPerm.execute && (
                <IconButton
                  size="small"
                  style={{ fontSize: "15px" }}
                  color="error"
                  onClick={() => handleChangeEnableBulk(table, false)}
                >
                  <PowerOff /> {t("disable")}
                </IconButton>
              )}

              {cfgPerm.execute && (
                <Popconfirm
                  title={t("deleteItems")}
                  description={t("areYouSureToDeleteItems")}
                  onConfirm={() => handleDeleteConfigs(table)}
                >
                  <IconButton size="small" style={{ fontSize: "15px" }} color="error">
                    <DeleteOutlined /> {t("delete")}
                  </IconButton>
                </Popconfirm>
              )}
            </div>
          )}

          {cfgPerm.write && (
            <IconButton
              size="small"
              style={{ fontSize: "15px" }}
              color="primary"
              onClick={() => setIsUploadAvatarModalOpened(true)}
            >
              {t("uploadAvatars")}
            </IconButton>
          )}
        </div>
      );
    },
  });

  useEffect(() => {
    table.resetRowSelection();
  }, [columnFilters, grouping, globalFilter, table]);

  return (
    <>
      <MaterialReactTable table={table} />

      {isUploadAvatarModalOpened && cfgPerm.write && (
        <AvatarConfigAddModal
          title={t("addNewName")}
          onCancel={() => setIsUploadAvatarModalOpened(false)}
          onSave={(file) => handleClickSaveNewConfig(file)}
        />
      )}

      {bulkOperationResult && (
        <BulkOperationReportModal
          resultList={bulkOperationResult}
          title={t("operationResult")}
          showProblematic
          showSuccess
          onCancel={() => setBulkOperationResult(undefined)}
          open={!!bulkOperationResult}
        />
      )}
    </>
  );
};

export default AvatarConfigTable;
