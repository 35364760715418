import { Grid } from "@mui/material";
import { Divider, Typography } from "antd";
import RoomsManagementModule from "@/module/rooms-management-module/rooms-management-module";


const RoomsManagementPage = () => {

  return (
    <Grid container spacing={2} height="100%" direction="column" flexWrap="nowrap">
      <Grid item>
        <Typography.Title level={2}>Room management</Typography.Title>
      </Grid>
      <Grid item>
        <Divider style={{marginTop: 0}}/>
      </Grid>
      <Grid item>
        <RoomsManagementModule/>
      </Grid>
    </Grid>
  )
}


export default RoomsManagementPage