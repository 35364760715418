/* tslint:disable */

/* eslint-disable */

/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { AxiosInstance, AxiosPromise, RawAxiosRequestConfig } from "axios";
import globalAxios from "axios";

// @ts-ignore
import {
  BASE_PATH,
  BaseAPI,
  COLLECTION_FORMATS,
  RequestArgs,
  RequiredError,
  operationServerMap,
} from "../base";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  createRequestFunction,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  toPathString,
} from "../common";
import type { Configuration } from "../configuration";
// @ts-ignore
import { RoomTablesCacheFetchRoomInfoRequestDto } from "../models";
// @ts-ignore
import { RoomTablesCacheFetchRoomInfoResponseDto } from "../models";
// @ts-ignore
import { RoomTablesCacheFetchRoomListResponseDto } from "../models";

/**
 * RoomTablesCacheControllerApi - axios parameter creator
 * @export
 */
export const RoomTablesCacheControllerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} room
     * @param {RoomTablesCacheFetchRoomInfoRequestDto} roomTablesCacheFetchRoomInfoRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomInfo: async (
      room: string,
      roomTablesCacheFetchRoomInfoRequestDto: RoomTablesCacheFetchRoomInfoRequestDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'room' is not null or undefined
      assertParamExists("roomInfo", "room", room);
      // verify required parameter 'roomTablesCacheFetchRoomInfoRequestDto' is not null or undefined
      assertParamExists(
        "roomInfo",
        "roomTablesCacheFetchRoomInfoRequestDto",
        roomTablesCacheFetchRoomInfoRequestDto
      );
      const localVarPath = `/api/v2/room-tables-cache/fetch-room-info/{room}`.replace(
        `{${"room"}}`,
        encodeURIComponent(String(room))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        roomTablesCacheFetchRoomInfoRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/room-tables-cache/fetch-room-list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RoomTablesCacheControllerApi - functional programming interface
 * @export
 */
export const RoomTablesCacheControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    RoomTablesCacheControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} room
     * @param {RoomTablesCacheFetchRoomInfoRequestDto} roomTablesCacheFetchRoomInfoRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async roomInfo(
      room: string,
      roomTablesCacheFetchRoomInfoRequestDto: RoomTablesCacheFetchRoomInfoRequestDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<RoomTablesCacheFetchRoomInfoResponseDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.roomInfo(
        room,
        roomTablesCacheFetchRoomInfoRequestDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["RoomTablesCacheControllerApi.roomInfo"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async roomList(
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<RoomTablesCacheFetchRoomListResponseDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.roomList(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["RoomTablesCacheControllerApi.roomList"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * RoomTablesCacheControllerApi - factory interface
 * @export
 */
export const RoomTablesCacheControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = RoomTablesCacheControllerApiFp(configuration);
  return {
    /**
     *
     * @param {string} room
     * @param {RoomTablesCacheFetchRoomInfoRequestDto} roomTablesCacheFetchRoomInfoRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomInfo(
      room: string,
      roomTablesCacheFetchRoomInfoRequestDto: RoomTablesCacheFetchRoomInfoRequestDto,
      options?: any
    ): AxiosPromise<RoomTablesCacheFetchRoomInfoResponseDto> {
      return localVarFp
        .roomInfo(room, roomTablesCacheFetchRoomInfoRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    roomList(options?: any): AxiosPromise<RoomTablesCacheFetchRoomListResponseDto> {
      return localVarFp.roomList(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * RoomTablesCacheControllerApi - object-oriented interface
 * @export
 * @class RoomTablesCacheControllerApi
 * @extends {BaseAPI}
 */
export class RoomTablesCacheControllerApi extends BaseAPI {
  /**
   *
   * @param {string} room
   * @param {RoomTablesCacheFetchRoomInfoRequestDto} roomTablesCacheFetchRoomInfoRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomTablesCacheControllerApi
   */
  public roomInfo(
    room: string,
    roomTablesCacheFetchRoomInfoRequestDto: RoomTablesCacheFetchRoomInfoRequestDto,
    options?: RawAxiosRequestConfig
  ) {
    return RoomTablesCacheControllerApiFp(this.configuration)
      .roomInfo(room, roomTablesCacheFetchRoomInfoRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomTablesCacheControllerApi
   */
  public roomList(options?: RawAxiosRequestConfig) {
    return RoomTablesCacheControllerApiFp(this.configuration)
      .roomList(options)
      .then((request) => request(this.axios, this.basePath));
  }
}
