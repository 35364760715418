import { AxiosResponse } from "axios";
import { MRT_TableInstance } from "material-react-table";
import { enqueueSnackbar } from "notistack";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { BadRequestError } from "@/api/config/config";
import { useJsonFormByFiltersQuery } from "@/api/hooks/json-form-hooks";
import {
  useSysCfgChangeEnabledMutation,
  useSysCfgCreateMutation,
  useSysCfgDeleteMutation,
  useSysCfgUpdateMutation,
} from "@/api/hooks/sys-cfg-hooks";
import { UiSchema } from "@/components/JsonForm/types/wrapperTypes";
import {
  ResultDtoLong,
  SysCfgCreateRequestDto,
  SysCfgRegularDto,
  SysCfgUpdateRequestDto,
} from "@/shared/api";

export const useSaveConfig = ({
  roomName,
  cfgName,
  onSuccessCallback,
}: {
  roomName: string;
  cfgName: string;
  onSuccessCallback?: () => void;
}) => {
  const { t } = useTranslation("room-configs");
  const { mutate: createCfgMutate } = useSysCfgCreateMutation();

  return useCallback(
    (config: SysCfgCreateRequestDto) => {
      createCfgMutate(
        {
          domain: roomName,
          cfgName: cfgName,
          body: config,
        },
        {
          onSuccess: () => {
            enqueueSnackbar(t("configAdded"), { variant: "success" });
            onSuccessCallback && onSuccessCallback();
          },
          onError: (err) => {
            if (err instanceof BadRequestError) {
              enqueueSnackbar(`${err.message}`, { variant: "error" });
            } else {
              enqueueSnackbar(
                t("operationFailureContactAdministrator", { message: err.message }),
                {
                  variant: "error",
                }
              );
            }
            console.error("Adding config failure", err);
          },
        }
      );
    },
    [createCfgMutate, roomName, cfgName, onSuccessCallback, t]
  );
};

export const useUpdateConfig = ({
  roomName,
  cfgName,
  onSuccessCallback,
}: {
  roomName: string;
  cfgName: string;
  onSuccessCallback?: () => void;
}) => {
  const { t } = useTranslation("room-configs");
  const { mutate: updateCfgMutate } = useSysCfgUpdateMutation();

  return useCallback(
    (name: string, body: SysCfgUpdateRequestDto) => {
      updateCfgMutate(
        {
          domain: roomName,
          cfgName: cfgName,
          name: name,
          body: body,
        },
        {
          onSuccess: () => {
            enqueueSnackbar(t("configUpdated"), { variant: "success" });
            onSuccessCallback && onSuccessCallback();
          },
          onError: (err) => {
            enqueueSnackbar(
              t("operationFailureContactAdministrator", { message: err.message }),
              {
                variant: "error",
              }
            );
            console.error("Config edit failure", err);
          },
        }
      );
    },
    [cfgName, onSuccessCallback, roomName, updateCfgMutate, t]
  );
};

export const useConfigChangeEnabled = ({
  roomName,
  cfgName,
  onSuccessCallback,
}: {
  roomName: string;
  cfgName: string;
  onSuccessCallback?: <T extends SysCfgRegularDto>(table: MRT_TableInstance<T>) => void;
}) => {
  const { t } = useTranslation("room-configs");
  const { mutate: changeEnabledMutate } = useSysCfgChangeEnabledMutation();

  return useCallback(
    <T extends SysCfgRegularDto>(table: MRT_TableInstance<T>, enabled: boolean) => {
      const names = table.getSelectedRowModel().rows.map((item) => item.original.name);

      changeEnabledMutate(
        {
          domain: roomName,
          cfgName: cfgName,
          enabled: enabled,
          filters: new Set([
            { key: "name", mode: "STRICT_IN", value: names as unknown as object },
          ]),
        },
        {
          onSuccess: (response) => {
            enqueueSnackbar(
              t("elementsHasBeenUpdated", {
                data: response.data,
              }),
              {
                variant: "success",
              }
            );
            onSuccessCallback && onSuccessCallback(table);
          },
          onError: (err) => {
            enqueueSnackbar(
              t("operationFailureContactAdministrator", { message: err.message }),
              {
                variant: "error",
              }
            );
            console.error("Change enabled operation failure", err);
          },
        }
      );
    },
    [cfgName, changeEnabledMutate, onSuccessCallback, roomName, t]
  );
};

export const useDeleteConfigs = ({
  roomName,
  cfgName,
  onSuccessCallback,
}: {
  roomName: string;
  cfgName: string;
  onSuccessCallback?: <T extends SysCfgRegularDto>(
    table: MRT_TableInstance<T>,
    response: AxiosResponse<ResultDtoLong, any>
  ) => void;
}) => {
  const { t } = useTranslation("room-configs");
  const { mutate: deleteCfgsMutate } = useSysCfgDeleteMutation();

  return useCallback(
    <T extends SysCfgRegularDto>(table: MRT_TableInstance<T>) => {
      const names = table.getSelectedRowModel().rows.map((item) => item.original.name);

      deleteCfgsMutate(
        {
          domain: roomName,
          cfgName: cfgName,
          filters: new Set([
            { key: "name", mode: "STRICT_IN", value: names as unknown as object },
          ]),
        },
        {
          onSuccess: (response) => {
            response.data.dataOnError
              ? enqueueSnackbar(t("somethingWentWrong"), { variant: "error" })
              : enqueueSnackbar(
                  t("elementsHasBeenDeletedLength", { data: response.data.data?.toString() }),
                  { variant: "success" }
                );
            onSuccessCallback && onSuccessCallback(table, response);
          },
          onError: (err) => {
            enqueueSnackbar(
              t("operationFailureContactAdministrator", { message: err.message }),
              {
                variant: "error",
              }
            );
            console.error("Delete operation failure", err);
          },
        }
      );
    },
    [cfgName, deleteCfgsMutate, onSuccessCallback, roomName, t]
  );
};

export const useGetJsonForm = ({
  roomName,
  configName,
}: {
  roomName: string;
  configName: string;
}) => {
  const query = useJsonFormByFiltersQuery({
    keyFilters: [
      { key: "room_name", mode: "EQUALS", value: roomName as unknown as object },
      { key: "collection_name", mode: "EQUALS", value: configName as unknown as object },
    ],
  });

  const jsonFormSchema = useMemo(() => {
    return query.data?.data[0]?.uiSchemas[0]?.schema as UiSchema | undefined;
  }, [query.data]);

  return {
    ...query,
    data: jsonFormSchema,
  };
};
