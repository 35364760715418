import { MRT_PaginationState } from "material-react-table";
import { useApi } from "@/shared/api/use-api";
import { keepPreviousData, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { PageResponseCommandJournalRegularDto, QueryDto } from "@/shared/api";


export const useCommandJournalQuery = (
  {
    pagination
  } : {
    pagination?: MRT_PaginationState
  },
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<PageResponseCommandJournalRegularDto>>, "queryKey">
) => {
  const { commandJournalApi } = useApi();

  return useQuery({
    queryKey: ["/command-journal", pagination],
    queryFn: ({ signal }) => {
      const query: QueryDto = {
        page: pagination ? {page_number: pagination.pageIndex, page_size: pagination.pageSize} : undefined,
      }
      return commandJournalApi.getCommandJournalList(query, { signal })
    },
    placeholderData: keepPreviousData,
    ...queryOptions
  })
}