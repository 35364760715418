import React, { useState } from "react";
import { Box, Fab } from "@mui/material";
import { FormatListNumbered } from "@mui/icons-material";
import CommandJournalTable from "@/module/command-journal-module/command-journal-table";


const CommandJournalAccessTemplate = (
  {
    children
  } : {
    children: React.JSX.Element
  }
) => {
  const [isCommandJournalOpen, setIsCommandJournalOpen] = useState<boolean>(false);

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: 16,
          right: 16,
          zIndex: 500, // Делаем кнопку поверх всех элементов
        }}
      >
        <Fab
          variant="extended"
          size="small"
          sx={{fontSize: 12}}
          color="primary"
          onClick={() => setIsCommandJournalOpen(true)}
        >
          <FormatListNumbered sx={{ mr: 1 }} /> Command Journal
        </Fab>
      </Box>

      {isCommandJournalOpen && (
        <CommandJournalTable onClose={() => setIsCommandJournalOpen(false)}/>
      )}
      {children}
    </>
  )
}

export default CommandJournalAccessTemplate;