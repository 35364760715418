import {Card, Form, Space} from "antd";
import _SpecificControl from "./_SpecificControl";
import {Group} from "./types/wrapperTypes";
import {computeWrapperCardTitle, computeWrapperLabel, computeWrapperTitle} from "./util";
import {Path} from "./types/baseTypes";

const _Group = (
  {
    control,
    parentPath = [],
    disabled = false,
  } : {
    control: Group,
    parentPath?: Path,
    disabled?: boolean
  }
) => {
  const content = <>
    <Form.Item
      label={computeWrapperLabel(control, control.label)}
      style={{margin: 0}}
      labelCol={{span: 24}}
    >
      {computeWrapperTitle(control.label)}
      <Space direction={control.direction} style={{width: "100%"}}>
        {control.elements.map((item, index) => (
          <_SpecificControl
            disabled={disabled}
            key={index}
            control={item}
            parentPath={[...parentPath, ...control.path ?? []]}
          />
        ))}
      </Space>
    </Form.Item>
  </>

  return (
    <>
      {!control.noWrapper ? (
        <Card
          bordered
          type="inner"
          title={computeWrapperCardTitle(control, control.label)}
        >
          <Space direction="vertical" style={{width: "100%"}}>
            {content}
          </Space>
        </Card>
      ) : (
        content
      )}
    </>
  );
};

export default _Group;