import { Grid } from "@mui/material";
import { useNavigate } from "@tanstack/react-router";
import { Divider, Typography } from "antd";
import { useAtom } from "jotai";
import { useEffect } from "react";

import MainTable from "@/module/room-configs-module/main-table";
import { appCurrentRoom } from "@/store/store";

const RoomConfigPage = ({ room, config }: { room: string; config: string }) => {
  const [currentRoom] = useAtom(appCurrentRoom);
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentRoom) {
      void navigate({ to: "/" });
    } else {
      void navigate({ to: `/room-config/${currentRoom.name}/${config}` });
    }
  }, [currentRoom, navigate]);

  return (
    <>
      <Grid container spacing={2} height="100%" direction="column" flexWrap="nowrap">
        <Grid item>
          <Typography.Title level={2}>
            {config
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
          </Typography.Title>
        </Grid>
        <Grid item>
          <Divider style={{ marginTop: 0 }} />
        </Grid>
        <Grid item xs={10}>
          <MainTable room={room} cfg={config} />
        </Grid>
      </Grid>
    </>
  );
};

export default RoomConfigPage;
