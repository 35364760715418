import { Checkbox } from "antd";
import { MRT_ColumnDef } from "material-react-table";
import moment from "moment/moment";
import React, { useMemo } from "react";

import { APP_GMT_DATE_FORMAT } from "@/lib/constants";
import { SysCfgRegularDto } from "@/shared/api";
import { AnyObj } from "@/types/commonTypes";

export const useReporterChatTableColumns = () =>
  useMemo<MRT_ColumnDef<SysCfgRegularDto>[]>(() => {
    return [
      {
        header: "Enabled",
        filterFn: "contains",
        accessorKey: "enabled",
        enableEditing: false,
        accessorFn: (row) => row.enabled ?? "-",
        Cell: ({ row }) => <Checkbox checked={row.original.enabled} disabled />,
        grow: true,
      },
      {
        header: "Name",
        accessorKey: "name",
        filterFn: "contains",
        grow: true,
      },
      {
        header: "Room",
        accessorKey: "data.room",
        accessorFn: ({ data }) => (data as AnyObj)?.room?.join(", ") ?? "-",
        filterFn: "contains",
        grow: true,
      },
      {
        header: "Technology",
        accessorFn: ({ data }) => (data as AnyObj)?.technology?.join(", ") ?? "-",
        accessorKey: "data.technology",
        filterFn: "contains",
      },
      {
        header: "Game type",
        accessorFn: ({ data }) => (data as AnyObj)?.game_type?.join(", ") ?? "-",
        filterFn: "contains",
      },
      {
        header: "AID",
        accessorFn: ({ data }) => (data as AnyObj)?.aid?.join(", ") ?? "-",
        filterFn: "contains",
      },
      {
        header: "CID",
        accessorFn: ({ data }) => (data as AnyObj)?.cid?.join(", ") ?? "-",
        filterFn: "contains",
      },
      {
        header: "Bot id",
        accessorKey: "data.bot_id",
        filterFn: "contains",
      },
      {
        header: "Rules",
        accessorFn: ({ data }) => (data as AnyObj)?.rules?.join(", ") ?? "-",
        accessorKey: "data.rules",
        filterFn: "contains",
      },
      {
        header: "Created at",
        accessorKey: "created_at",
        accessorFn: (row) => (row.created_at ? new Date(row.created_at) : 0),
        Cell: ({
          row: {
            original: { created_at },
          },
        }) => (created_at ? moment(created_at).utc(false).format(APP_GMT_DATE_FORMAT) : "-"),
      },
      {
        header: "Updated at",
        accessorKey: "updated_at",
        accessorFn: (row) => (row.updated_at ? new Date(row.updated_at) : 0),
        Cell: ({
          row: {
            original: { created_at },
          },
        }) => (created_at ? moment(created_at).utc(false).format(APP_GMT_DATE_FORMAT) : "-"),
      },
    ];
  }, []);
