/* tslint:disable */

/* eslint-disable */

/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { AxiosInstance, AxiosPromise, RawAxiosRequestConfig } from "axios";
import globalAxios from "axios";

// @ts-ignore
import {
  BASE_PATH,
  BaseAPI,
  COLLECTION_FORMATS,
  RequestArgs,
  RequiredError,
  operationServerMap,
} from "../base";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  createRequestFunction,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  toPathString,
} from "../common";
import type { Configuration } from "../configuration";
// @ts-ignore
import { UserResponseRegularDto } from "../models";

/**
 * MeControllerApi - axios parameter creator
 * @export
 */
export const MeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMe: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/me`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MeControllerApi - functional programming interface
 * @export
 */
export const MeControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MeControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMe(
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponseRegularDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMe(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap["MeControllerApi.getMe"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * MeControllerApi - factory interface
 * @export
 */
export const MeControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = MeControllerApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMe(options?: any): AxiosPromise<UserResponseRegularDto> {
      return localVarFp.getMe(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * MeControllerApi - object-oriented interface
 * @export
 * @class MeControllerApi
 * @extends {BaseAPI}
 */
export class MeControllerApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeControllerApi
   */
  public getMe(options?: RawAxiosRequestConfig) {
    return MeControllerApiFp(this.configuration)
      .getMe(options)
      .then((request) => request(this.axios, this.basePath));
  }
}
