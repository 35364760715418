import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import globalAxios, { AxiosResponse } from "axios";
import { UserResponseRegularDto } from "@/shared/api";
import { useApi } from "@/shared/api/use-api";
import { RequiredError } from "@/shared/api/base";


export const ME_HOOK_QUERY_KEY = "/me"

export const useMeQuery = (
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<UserResponseRegularDto>>, "queryKey">
) => {
  const { meApi } = useApi()

  return useQuery<AxiosResponse<UserResponseRegularDto>>({
    queryKey: [ME_HOOK_QUERY_KEY],
    queryFn: ({ signal }) => meApi.getMe({ signal }),
    ...queryOptions
  })
}

export const useLogoutMutation = () => useMutation<AxiosResponse<void>, RequiredError, undefined>({
  mutationFn: () => globalAxios.postForm("/auth/logout"),
})
