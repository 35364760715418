import { IconButton } from "@mui/material";
import React from "react";
import { Edit } from "@mui/icons-material";
import { MRT_Row, MRT_TableInstance } from "material-react-table";
import { KeyFilter } from "@/shared/api";
import { useSysCfgEditDataMutation } from "@/api/hooks/sys-cfg-hooks";
import BulkEditModal from "@/components/BulkEditModal/bulk-edit-modal";
import { Input } from "@/components/BulkEditModal/types";
import { message } from "antd";
import { BulkEditFormFieldValue, NameIdentityItem } from "@/types/commonTypes";
import { ROOM_CONFIGS_NAMES } from "@/lib/constants";
import { AxiosResponse } from "axios";
import { assembleParticularFilter } from "@/lib/cfg-utils";


const ConfigsEditTableControl = <T extends NameIdentityItem> (
  {
    title,
    roomName,
    table,
    filters,
    fields,
    onSuccess,
    isOpen,
    setIsOpen,
    totalElementsCount
  } : {
    title?: string;
    roomName: string;
    table: MRT_TableInstance<T>;
    filters: Set<KeyFilter>;
    fields: Input[];
    onSuccess?: (rowsCount: number) => void;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    totalElementsCount: number;
  }
) => {
  const mutation = useSysCfgEditDataMutation();

  function handleMutationAction(rows: MRT_Row<T>[], data: Map<string,  BulkEditFormFieldValue>) {
    if(rows.length > 0) {
      mutateParticular(rows, data)
    } else {
      mutateAll(data)
    }
  }

  function mutateAll(data: Map<string,  BulkEditFormFieldValue>) {
    callMutation(filters, data)
  }

  function mutateParticular(rows: MRT_Row<T>[], data: Map<string, BulkEditFormFieldValue>) {
    callMutation(assembleParticularFilter(rows), data)
  }

  function callMutation(filters: Set<KeyFilter>, data: Map<string, BulkEditFormFieldValue>) {
    mutation.mutate(
      {
        domain: roomName,
        cfgName: ROOM_CONFIGS_NAMES.ACCOUNT,
        filters,
        updates: Object.fromEntries(data) as unknown as {[key: string]: object},
      }, {
        onSuccess: (rs: AxiosResponse<number>) => {
          message.success(`${rs.data} configs successfully updated`).then()
          onSuccess && onSuccess(rs.data)
        },
        onError: (err) => {
          console.error("configs update error", err)
          message.error("Something went wrong during updating data, contact an administrator").then()
        }
      }
    )
  }

  return (
    <>
      <IconButton
        size="small"
        style={{ fontSize: 12 }}
        color="warning"
        onClick={() => setIsOpen(true)}
      >
        <Edit /> Edit
        ({
          table.getSelectedRowModel().rows.length > 0
            ? (table.getSelectedRowModel().rows.length)
            : (totalElementsCount)
        })
      </IconButton>

      <BulkEditModal
        title={title}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onSubmit={(data) => handleMutationAction(table.getSelectedRowModel().rows, data)}
        isLoading={mutation.isPending}
        fields={fields}
        fullWidth
        maxWidth="sm"
        editElementsCount={(
          table.getSelectedRowModel().rows.length > 0
            ? (table.getSelectedRowModel().rows.length)
            : (totalElementsCount)
        )}
      />
    </>
  )
}


export default ConfigsEditTableControl;