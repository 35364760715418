import { green, red } from "@ant-design/colors";
import { CheckCircleFilled, CopyOutlined, InfoCircleFilled } from "@ant-design/icons";
import { Link } from "@tanstack/react-router";
import { Button, Divider, List, Modal, Tooltip, message } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { AccountType } from "@/module/accounts-module/accounts-module";
import { ResultDto } from "@/types/commonTypes";

const BulkOperationReportModal = ({
  title = undefined,
  resultList,
  showSuccess = false,
  showProblematic = false,
  open,
  onCancel,
}: {
  title?: string;
  resultList: ResultDto<any>[];
  showSuccess?: boolean;
  showProblematic?: boolean;
  open?: boolean;
  onCancel: () => void;
}) => {
  const { t } = useTranslation("room-configs");
  const copyToClipboard = (data: string) => {
    navigator.clipboard.writeText(data).then(() => message.success(t("textCopied")));
  };

  const successList = resultList.filter((item) => item.status === "SUCCESS");
  const errorList = resultList.filter((item) => item.status === "ERROR");

  const [successListIsShown, setSuccessListIsShown] = useState<boolean>(false);
  const [errorListIsShown, setErrorListIsShown] = useState<boolean>(false);

  const joinListNamesToString = (data: ResultDto<any>[]) => {
    return data.map((proxy) => proxy.name).join("\n");
  };

  return (
    <>
      <Modal
        open={open}
        onCancel={() => onCancel()}
        maskClosable={false}
        width={1000}
        title={title}
        okText={undefined}
        footer={[]}
      >
        <Divider />

        {showSuccess && successList.length > 0 && (
          <Button onClick={() => setSuccessListIsShown(!successListIsShown)} type="link">
            {successListIsShown ? t("hide") : t("showSuccess", { length: successList.length })}
          </Button>
        )}

        {successList.length > 0 && (
          <div hidden={!successListIsShown}>
            <List
              bordered
              header={
                <List.Item
                  style={{ padding: 0 }}
                  actions={[
                    <Tooltip key="1" title={t("copyAllValues")}>
                      <Button
                        icon={<CopyOutlined />}
                        onClick={() => copyToClipboard(joinListNamesToString(successList))}
                      />
                    </Tooltip>,
                  ]}
                >
                  <List.Item.Meta
                    title={t("successElements", { length: successList.length })}
                  />
                </List.Item>
              }
              size="small"
              dataSource={successList}
              renderItem={(item: ResultDto<any>) => (
                <List.Item
                  actions={[
                    <Tooltip key="1" title={t("copyValue")}>
                      <Button
                        icon={<CopyOutlined />}
                        onClick={() => copyToClipboard(item?.name ?? "")}
                      />
                    </Tooltip>,
                  ]}
                >
                  <List.Item.Meta
                    title={
                      <div>
                        <CheckCircleFilled style={{ color: green.primary }} />
                        &nbsp;&nbsp;
                        {item.data?.name ?? item.name}
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          </div>
        )}

        {showSuccess && showProblematic && successList.length > 0 && errorList.length > 0 && (
          <Divider />
        )}

        {showProblematic && errorList.length > 0 && (
          <Button onClick={() => setErrorListIsShown(!errorListIsShown)} type="link">
            {errorListIsShown
              ? t("hide")
              : t("showProblematic", {
                  length: errorList.length,
                })}
          </Button>
        )}

        {errorList.length > 0 && (
          <div hidden={!errorListIsShown}>
            <List
              bordered
              header={
                <List.Item
                  style={{ padding: 0 }}
                  actions={[
                    <Tooltip key="1" title={t("copyAllValues")}>
                      <Button
                        icon={<CopyOutlined />}
                        onClick={() => copyToClipboard(joinListNamesToString(errorList))}
                      />
                    </Tooltip>,
                  ]}
                >
                  <List.Item.Meta
                    title={t("problematicElements", { length: errorList.length })}
                  />
                </List.Item>
              }
              size="small"
              dataSource={errorList}
              renderItem={(item: ResultDto<any>) => (
                <List.Item
                  key={item.name}
                  actions={[
                    <Tooltip key="1" title={t("copyValue")}>
                      <Button
                        icon={<CopyOutlined />}
                        onClick={() => copyToClipboard(item?.name ?? "")}
                      />
                    </Tooltip>,
                  ]}
                >
                  <List.Item.Meta
                    title={
                      <div>
                        <InfoCircleFilled style={{ color: red.primary }} />
                        &nbsp;&nbsp;
                        {item.name}
                      </div>
                    }
                    description={
                      <>
                        {item.message}
                        <br />
                        {item.dataOnError &&
                          Object.entries(item.dataOnError).map(
                            ([key, value]: [string, any]) => (
                              <div key={key}>
                                <div>
                                  <strong>{t("collectionName")}</strong> {value.collectionName}
                                </div>
                                <div>
                                  <strong>{t("deletableEntityValue")}</strong>{" "}
                                  {value.foreignKeyValue}
                                </div>
                                <div>
                                  <strong>{t("room")}</strong> {value.roomName}
                                </div>
                                <div>
                                  <strong>{t("amountOfDependencies")}</strong>{" "}
                                  {value.collectionName === "account" ? (
                                    <Link
                                      to="/accounts/$room/$type"
                                      params={{
                                        room: value.roomName,
                                        type: AccountType.regular,
                                      }}
                                      search={{
                                        [value.foreignKeyFieldName]: value.foreignKeyValue,
                                      }}
                                    >
                                      {value.amountOfDependencies}
                                    </Link>
                                  ) : (
                                    "value.amountOfDependencies"
                                  )}
                                </div>
                                <br />
                              </div>
                            )
                          )}
                      </>
                    }
                  />
                </List.Item>
              )}
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default BulkOperationReportModal;
