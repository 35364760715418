import React, {useState} from "react";
import {Select} from "antd";


const NumberTagsSelect = (
  {
    value = [],
    onChange,
    allowFloat = false,
    allowClear = false,
    placeholder,
  } : {
    value?: number[],
    onChange?: (value: number[]) => any,
    allowFloat?: boolean,
    allowClear?: boolean,
    placeholder?: string
  }
) => {
  const [tags, setTags] = useState<number[]>(value);

  const handleChange = (value: any) => {
    const numbers = value.map((v: string) => allowFloat ? parseFloat(v) : parseInt(v, 10))
      .filter((v: number) => !isNaN(v));
    setTags(numbers);
    if (onChange) {
      onChange(numbers);
    }
  };


  return (
    <Select
      mode="tags"
      placeholder={placeholder}
      onChange={handleChange}
      allowClear={allowClear}
      value={tags.map(tag => tag.toString())}
    >
      {tags.map(tag => (
        <Select.Option key={tag} value={tag.toString()}>
          {tag}
        </Select.Option>
      ))}
    </Select>
  );
}

export default NumberTagsSelect;