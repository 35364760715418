import { AlertProps } from "@mui/material/Alert";
import { useTranslation } from "react-i18next";

export const useMuiTableToolbarAlertBannerProps = (
  isDataLoadingError: boolean,
  statusText?: string
) => {
  const { t } = useTranslation("common");

  return isDataLoadingError
    ? ({
        color: "error",
        children: t("errorLoadingTableData", {
          error: `${statusText ? ":" + statusText : ""}`,
        }),
      } as AlertProps)
    : undefined;
};
