import React, { useMemo, useState } from "react";
import { MaterialReactTable, MRT_ColumnDef, MRT_PaginationState, useMaterialReactTable } from "material-react-table";
import { JsonViewer } from "@textea/json-viewer";
import { useCommandJournalQuery } from "@/api/hooks/command-journal-hooks";
import { CommandJournalRegularDto } from "@/shared/api";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

const CommandJournalTable = (
  {
    onClose
  } : {
    onClose?: () => void
  }
) => {

  const [pagination, setPagination] =
    useState<MRT_PaginationState>({ pageIndex: 0, pageSize: 30 });

  const {
    data: commandJournalResponse,
    isError: isCommandJournalError,
    isLoading: isCommandJournalLoading,
    isRefetching: isCommandJournalRefetching,
    refetch: refetchCommandJournal,
    error: commandJournalError,
  } = useCommandJournalQuery({
    pagination
  });

  const columns = useMemo<MRT_ColumnDef<CommandJournalRegularDto>[]>(() => [
    {
      id: "username",
      header: "Username",
      accessorFn: (row) => row.username,
      filterFn: "contains",
    },
    {
      id: "action_subject_id",
      header: "Subject identifier",
      accessorFn: row => row.action_subject_id,
      filterFn: "contains",
    },
    {
      id: "broker_topic",
      header: "Broker Topic",
      accessorFn: row => row.broker_topic
    },
    {
      header: "Command type",
      accessorFn: row =>  mapCommandType(row.type),
      filterFn: "contains",
    },
    {
      header: "Command status",
      accessorFn: row =>  mapCommandStatus(row),
      filterFn: "contains",
    },
    {
      header: "Command body",
      accessorFn: row => JSON.stringify(row.body, null, 2),
      filterFn: "contains",
      Cell: ({ row: { original }}) => (
        <JsonViewer
          style={{maxWidth: "120px"}}
          rootName="body"
          displayDataTypes={false}
          value={original.body && JSON.parse(original.body)?.body}
          defaultInspectDepth={0}
        />
      )
    },
    {
      id: "created_at",
      header: "Execution time",
      accessorFn: (row) => new Date(row.created_at).toLocaleString(),
      filterFn: "contains"
    },
    {
      id: "command_response_body",
      header: "Response body",
      accessorFn: row => JSON.stringify(row.response_body, null, 2),
      filterFn: "contains",
      Cell: ({ row: { original } }) => (
        <JsonViewer
          rootName="body"
          displayDataTypes={false}
          value={original.response_body && JSON.parse(original.response_body)}
          defaultInspectDepth={0}
        />
      )
    },
    {
      header: "Response message",
      accessorFn: row => row.error_message ?? "-",
      filterFn: "contains",
    },
    {
      id: "response_received_at",
      header: "Response Time",
      accessorFn: row => row.response_received_at ? new Date(row.response_received_at).toLocaleString() : "-",
    },
  ], []);

  const table = useMaterialReactTable({
    data: (commandJournalResponse?.data.content ?? []) as unknown as CommandJournalRegularDto[],
    columns: columns,
    //selectAllMode: "all",
    initialState: {
      pagination: {pageSize: 20, pageIndex: 0},
      density: 'compact',
      isFullScreen: true
    },
    muiToolbarAlertBannerProps: isCommandJournalError ? {
      color: 'error',
      children: `Error loading data ${ commandJournalError ? ":" + commandJournalError : ""}`,
    } : undefined,
    state: {
      pagination,
      isLoading: isCommandJournalLoading,
      showProgressBars: isCommandJournalRefetching
    },
    rowCount: commandJournalResponse?.data.totalElements,
    pageCount: commandJournalResponse?.data.totalPages,
    enableSelectAll: false,
    enableRowSelection: false,
    enableGrouping: false,
    enableColumnDragging: false,
    enableColumnFilterModes: false,

    renderToolbarInternalActions: () => (
      <>
        <IconButton onClick={() => onClose && onClose()}>
          <Close/>
        </IconButton>
      </>
    ),
    enableHiding: false,
    enableColumnFilters: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableGlobalFilter: false,

    enablePagination: true,
    enableStickyHeader: true,
    enableSorting: false,
    autoResetPageIndex: false,
    muiTablePaperProps: {
      sx: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap"
      }
    },
    muiTableContainerProps: {
      sx: {
        flexGrow: 10,
        overflowY: "scroll",
        height: "500px"
      }
    },
    manualPagination: true,
    onPaginationChange: setPagination
  });

  return <>
    <MaterialReactTable table={table}/>
  </>
}

export default CommandJournalTable;


function mapCommandStatus(journalRecord: CommandJournalRegularDto): string {
  if (journalRecord.status === -1) return "FAILED";
  if (journalRecord.status === 1) return "SUCCESS";

  if (journalRecord.status === 0) {
    const expired = journalRecord.created_at + journalRecord.response_wait_time;

    if (Date.now() > expired) {
      return "RESPONSE_NOT_RECEIVED_IN_TIME"
    }

    return "COMMAND_SENT";
  }

  return "UNKNOWN STATUS";
}

function mapCommandType(commandType: number | undefined): string {
  if (commandType === 1) return "UPDATE NICKNAME";
  if (commandType === 2) return "UPDATE AVATAR";
  if (commandType === 3) return "RUN ACCOUNT";
  if (commandType === 4) return "STOP ACCOUNT";
  if (commandType === 5) return "MIGRATE ACCOUNT";
  if (commandType === 6) return "JOIN CLUB";
  if (commandType === 7) return "LEAVE CLUB";
  if (commandType === 8) return "REQUEST REPLAY";
  if (commandType === 9) return "SWITCH GS MODE";
  if (commandType === 10) return "SWITCH TS MODE";

  return "UNKNOWN COMMAND TYPE";
}