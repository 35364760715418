import { useApi } from "@/shared/api/use-api";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { ClubRegularDto } from "@/shared/api";
import { AxiosResponse } from "axios";


export const useSysDataClubsQuery = (
  {
    domain
  } : {
    domain: string
  },
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<ClubRegularDto[]>>, "queryKey">
) => {
  const { sysDataCommonApi } = useApi();

  return useQuery({
    queryKey: [`/sys-data/${domain}/clubs`],
    queryFn: ({ signal }) => sysDataCommonApi.getClubs(domain, { signal }),
    ...queryOptions
  });
}