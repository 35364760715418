import { useStopSessionsMutation } from "@/api/hooks/command-hooks";
import { IconButton } from "@mui/material";
import { HourglassTop, Stop } from "@mui/icons-material";
import { message, Popconfirm } from "antd";
import React from "react";
import { MRT_Row, MRT_TableInstance } from "material-react-table";
import { KeyFilter, SysAccountSessionRegularDto } from "@/shared/api";

import { assembleParticularFilterCt } from "@/lib/cfg-utils";


const StopSessionsTableControl = (
  {
    roomName,
    table,
    filters,
    totalElementsCount,
    onSuccess,
  } : {
    roomName: string;
    table: MRT_TableInstance<SysAccountSessionRegularDto>;
    filters: Set<KeyFilter>;
    totalElementsCount: number;
    onSuccess?: () => void;
  }
) => {
  const mutation = useStopSessionsMutation();

  function handleMutationAction(rows: MRT_Row<SysAccountSessionRegularDto>[]) {
    if(rows.length > 0) {
      mutateParticular(rows)
    } else {
      mutateAll()
    }
  }

  function mutateAll() {
    callMutation(filters)
  }

  function mutateParticular(rows: MRT_Row<SysAccountSessionRegularDto>[]) {
    callMutation(assembleParticularFilterCt({
      rows: rows, keyName: "id", keySupplier: (val) => val.id
    }))
  }

  function callMutation(filters: Set<KeyFilter>) {
    mutation.mutate({ domain: roomName, filters }, {
      onSuccess: () => {
        message.success(`stop sessions command successfully sent`).then()
        onSuccess && onSuccess()
      },
      onError: (err) => {
        console.error("stop sessions command error", err)
        message.error("Something went wrong during sessions is being stopped, contact the administrator").then()
      }
    })
  }

  return (
    <>
      <Popconfirm
        title="Stop the sessions"
        description="Are you sure to stop the sessions?"
        onConfirm={() => handleMutationAction(table.getSelectedRowModel().rows)}
      >
        <IconButton size="small" style={{ fontSize: 12 }} color="error">
          {mutation.isPending ? <HourglassTop /> : <Stop />} Stop
          ({
            table.getSelectedRowModel().rows.length > 0
              ? (table.getSelectedRowModel().rows.length)
              : (totalElementsCount)
          })
        </IconButton>
      </Popconfirm>
    </>
  )
}


export default StopSessionsTableControl;