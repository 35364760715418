/* tslint:disable */

/* eslint-disable */

/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { AxiosInstance, AxiosPromise, RawAxiosRequestConfig } from "axios";
import globalAxios from "axios";

// @ts-ignore
import {
  BASE_PATH,
  BaseAPI,
  COLLECTION_FORMATS,
  RequestArgs,
  RequiredError,
  operationServerMap,
} from "../base";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  createRequestFunction,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  toPathString,
} from "../common";
import type { Configuration } from "../configuration";
// @ts-ignore
import { PageResponseCommandJournalRegularDto } from "../models";
// @ts-ignore
import { QueryDto } from "../models";

/**
 * CommandJournalControllerApi - axios parameter creator
 * @export
 */
export const CommandJournalControllerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {QueryDto} queryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommandJournalList: async (
      queryDto: QueryDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'queryDto' is not null or undefined
      assertParamExists("getCommandJournalList", "queryDto", queryDto);
      const localVarPath = `/api/v2/command-journal`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        queryDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {QueryDto} queryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMyJournal: async (
      queryDto: QueryDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'queryDto' is not null or undefined
      assertParamExists("getMyJournal", "queryDto", queryDto);
      const localVarPath = `/api/v2/command-journal/my`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        queryDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CommandJournalControllerApi - functional programming interface
 * @export
 */
export const CommandJournalControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    CommandJournalControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {QueryDto} queryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCommandJournalList(
      queryDto: QueryDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PageResponseCommandJournalRegularDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCommandJournalList(
        queryDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["CommandJournalControllerApi.getCommandJournalList"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {QueryDto} queryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMyJournal(
      queryDto: QueryDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PageResponseCommandJournalRegularDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMyJournal(
        queryDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["CommandJournalControllerApi.getMyJournal"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * CommandJournalControllerApi - factory interface
 * @export
 */
export const CommandJournalControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = CommandJournalControllerApiFp(configuration);
  return {
    /**
     *
     * @param {QueryDto} queryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommandJournalList(
      queryDto: QueryDto,
      options?: any
    ): AxiosPromise<PageResponseCommandJournalRegularDto> {
      return localVarFp
        .getCommandJournalList(queryDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {QueryDto} queryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMyJournal(
      queryDto: QueryDto,
      options?: any
    ): AxiosPromise<PageResponseCommandJournalRegularDto> {
      return localVarFp
        .getMyJournal(queryDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CommandJournalControllerApi - object-oriented interface
 * @export
 * @class CommandJournalControllerApi
 * @extends {BaseAPI}
 */
export class CommandJournalControllerApi extends BaseAPI {
  /**
   *
   * @param {QueryDto} queryDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommandJournalControllerApi
   */
  public getCommandJournalList(queryDto: QueryDto, options?: RawAxiosRequestConfig) {
    return CommandJournalControllerApiFp(this.configuration)
      .getCommandJournalList(queryDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {QueryDto} queryDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommandJournalControllerApi
   */
  public getMyJournal(queryDto: QueryDto, options?: RawAxiosRequestConfig) {
    return CommandJournalControllerApiFp(this.configuration)
      .getMyJournal(queryDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
