import * as React from "react";
import { createRootRoute, Outlet } from "@tanstack/react-router";
import CommonTemplate from "@/template/common-template";

export const Route = createRootRoute({
  component: () => (
    <CommonTemplate>
      <Outlet />
    </CommonTemplate>
  ),
})
