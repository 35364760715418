import { AuthGroupRegularResponseDto, AuthGroupRequestDto } from "@/shared/api";
import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useApi } from "@/shared/api/use-api";
import { AxiosResponse } from "axios";
import { RequiredError } from "@/shared/api/base";


export const useAuthGroupsQuery = (
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<AuthGroupRegularResponseDto[]>>, "queryKey">
) => {
  const { authGroupsApi } = useApi();

  return useQuery({
    queryKey: [`/auth-group`],
    queryFn: ({ signal }) => authGroupsApi.getAllAuthGroups({ signal }),
    ...queryOptions
  });
}

export const useCreateAuthGroupMutation = () => {
  const {authGroupsApi} = useApi();

  type Options = {
    group: AuthGroupRequestDto
  };

  const mutationFn = ({ group } : Options) => {
    return authGroupsApi.createAuthGroups({
      groups: [group]
    })
  }

  return useMutation<AxiosResponse<number>, RequiredError, Options>({
    mutationFn: mutationFn
  })
}

export const useDeleteAuthGroupsMutation = () => {
  const {authGroupsApi} = useApi();

  type Options = {
    groups: string[]
  };

  const mutationFn = ({ groups } : Options) => {
    return authGroupsApi.deleteAuthGroups({
      groups: groups.map(item => ({ name: item }))
    })
  }

  return useMutation<AxiosResponse<number>, RequiredError, Options>({
    mutationFn: mutationFn
  })
}