/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface PlatformServiceRegularDto
 */
export interface PlatformServiceRegularDto {
  /**
   *
   * @type {string}
   * @memberof PlatformServiceRegularDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PlatformServiceRegularDto
   */
  type: PlatformServiceRegularDtoTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PlatformServiceRegularDto
   */
  room: string;
  /**
   *
   * @type {string}
   * @memberof PlatformServiceRegularDto
   */
  version?: string;
  /**
   *
   * @type {string}
   * @memberof PlatformServiceRegularDto
   */
  server_addr?: string;
  /**
   *
   * @type {string}
   * @memberof PlatformServiceRegularDto
   */
  server_port?: string;
  /**
   *
   * @type {string}
   * @memberof PlatformServiceRegularDto
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof PlatformServiceRegularDto
   */
  mode?: number;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof PlatformServiceRegularDto
   */
  cpu_load_avg?: { [key: string]: number };
  /**
   *
   * @type {number}
   * @memberof PlatformServiceRegularDto
   */
  updated_at: number;
  /**
   *
   * @type {number}
   * @memberof PlatformServiceRegularDto
   */
  uptime?: number;
  /**
   *
   * @type {number}
   * @memberof PlatformServiceRegularDto
   */
  nclients?: number;
  /**
   *
   * @type {number}
   * @memberof PlatformServiceRegularDto
   */
  nthreads?: number;
  /**
   *
   * @type {number}
   * @memberof PlatformServiceRegularDto
   */
  pid?: number;
  /**
   *
   * @type {number}
   * @memberof PlatformServiceRegularDto
   */
  process_ram_total?: number;
  /**
   *
   * @type {number}
   * @memberof PlatformServiceRegularDto
   */
  process_ram_free?: number;
  /**
   *
   * @type {number}
   * @memberof PlatformServiceRegularDto
   */
  server_ram_total?: number;
  /**
   *
   * @type {number}
   * @memberof PlatformServiceRegularDto
   */
  server_ram_free?: number;
}

export const PlatformServiceRegularDtoTypeEnum = {
  Tes: "TES",
  Gs: "GS",
} as const;

export type PlatformServiceRegularDtoTypeEnum =
  (typeof PlatformServiceRegularDtoTypeEnum)[keyof typeof PlatformServiceRegularDtoTypeEnum];
