import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import React, { useEffect } from "react";

import { useSysRouterInstanceQuery } from "@/api/hooks/sys-router-instances-hooks";
import { useTableSettings } from "@/hooks/use-table-settings";
import { COMMON_REFETCH_INTERVAL } from "@/lib/constants";
import { useRouterInstanceTableColumns } from "@/module/room-configs-module/router-config-module/instance-table/router-instance-table-columns";
import { RouterInstanceDto } from "@/shared/api";

const RouterInstanceTable = ({
  setRowsAmount,
}: {
  setRowsAmount: (amount: number) => void;
}) => {
  const { data: routerInstances, isLoading: configsIsLoading } = useSysRouterInstanceQuery({
    refetchInterval: COMMON_REFETCH_INTERVAL,
  });

  useEffect(() => {
    setRowsAmount(Object.values(routerInstances?.data ?? {}).length);
  }, [routerInstances, setRowsAmount]);

  const {
    defaultProps,
    columnVisibility,
    columnOrder,
    columnFilters,
    globalFilter,
    grouping,
    pagination,
    sorting,
  } = useTableSettings<RouterInstanceDto>("routerInstance");

  const table = useMaterialReactTable({
    data: Object.values(routerInstances?.data ?? {}),
    columns: useRouterInstanceTableColumns(),
    ...defaultProps,
    initialState: {
      pagination: { pageSize: 20, pageIndex: 0 },
      density: "compact",
      sorting: [{ id: "priority", desc: false }],
    },
    enableGrouping: true,
    enableColumnDragging: true,
    enableColumnOrdering: true,
    enableColumnFilterModes: true,
    enablePagination: false,
    enableSorting: true,
    state: {
      globalFilter,
      grouping,
      columnFilters,
      columnVisibility,
      columnOrder,
      isLoading: configsIsLoading,
      pagination,
      sorting,
    },
    muiTableBodyCellProps: {
      sx: {
        whiteSpace: "pre",
      },
    },
  });

  useEffect(() => {
    table.resetRowSelection();
  }, [columnFilters, grouping, globalFilter, table]);

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
};

export default RouterInstanceTable;
