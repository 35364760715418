import { useCreateAuthGroupMutation } from "@/api/hooks/auth-groups-hooks";
import { FormContainer, TextFieldElement, useForm } from "react-hook-form-mui";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { AuthGroupRequestDto } from "@/shared/api";


const CreateAuthGroupModal = (
  {
    onClose,
    onSuccess,
    open = false
  } : {
    onClose: () => void;
    onSuccess?: () => void;
    open?: boolean
  }
) => {
  const {mutate: createAuthGroup, isPending } = useCreateAuthGroupMutation()
  const form = useForm<AuthGroupRequestDto>();

  const handleOnSuccess = (data: AuthGroupRequestDto) => {
    createAuthGroup({ group: data }, {
      onSuccess: () => {
        onClose()
        onSuccess && onSuccess()
        enqueueSnackbar("Auth group successfully created!", { variant: "success" })
      },
      onError: (err) => {
        console.error("crate auth group error:", err);
        enqueueSnackbar(`Something went wrong while saving auth group: ${err.message}`, { variant: "error" })
      }
    })
  }

  useEffect(() => {
    if(!open) {
      form.reset()
    }
  }, [form, open])

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>Add new Auth Group</DialogTitle>

      <FormContainer formContext={form} onSuccess={(data) => handleOnSuccess(data)}>

        <DialogContent>
          <TextFieldElement
            autoFocus
            name="name"
            required
            label="Name"
            variant="outlined"
          />
        </DialogContent>

        <DialogActions>
          <Button type="submit" endIcon={
            isPending ? <CircularProgress color="inherit" size={20}/> : undefined
          }>Save</Button>
        </DialogActions>

      </FormContainer>
    </Dialog>
  )
}

export default CreateAuthGroupModal;