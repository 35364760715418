import { Grid } from "@mui/material";
import { Divider, Typography } from "antd";
import RoomManagementModule from "@/module/room-management-module/room-management-module";


const RoomManagementPage = ({
  name
} : {
  name: string
}) => {

  return (
    <Grid container spacing={2} height="100%" direction="column" flexWrap="nowrap">
      <Grid item>
        <Typography.Title level={2}>{name}</Typography.Title>
      </Grid>
      <Grid item>
        <Divider style={{marginTop: 0}}/>
      </Grid>
      <Grid item>
        <RoomManagementModule domain={name} />
      </Grid>
    </Grid>
  )
}


export default RoomManagementPage