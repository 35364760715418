import { Navigate, createFileRoute } from "@tanstack/react-router";
import { useAtom } from "jotai/index";

import { isOwner } from "@/lib/auth-predicates";
import DashboardPage from "@/pages/dashboard-page";
import { meStore } from "@/store/store";
import CommandJournalAccessTemplate from "@/template/command-journal-access-template";

export const Route = createFileRoute("/dashboard/")({
  component: Component,
});

function Component() {
  const [me] = useAtom(meStore);

  if (!isOwner(me)) {
    return <Navigate to="/" />;
  }

  return (
    <CommandJournalAccessTemplate>
      <DashboardPage />
    </CommandJournalAccessTemplate>
  );
}
