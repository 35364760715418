import { Checkbox } from "antd";
import { MRT_ColumnDef } from "material-react-table";
import moment from "moment";
import React, { useMemo } from "react";

import { APP_GMT_DATE_FORMAT } from "@/lib/constants";
import { SysCfgRegularDto } from "@/shared/api";
import { AnyObj } from "@/types/commonTypes";

export const useReporterRuleTableColumns = ({
  updateConfig,
}: {
  updateConfig: (cfg: SysCfgRegularDto) => void;
}) =>
  useMemo<MRT_ColumnDef<SysCfgRegularDto>[]>(() => {
    return [
      {
        header: "Enabled",
        filterFn: "contains",
        enableEditing: false,
        accessorKey: "enabled",
        accessorFn: (row) => row.enabled ?? "-",
        Cell: ({ row }) => <Checkbox checked={row.original.enabled} disabled />,
      },
      {
        header: "Name",
        accessorKey: "name",
        filterFn: "contains",
      },
      {
        header: "Display name",
        accessorKey: "data.display_name",
        filterFn: "contains",
      },
      {
        header: "Pattern",
        accessorFn: ({ data }) => (data as AnyObj)?.pattern?.join(", ") ?? "-",
        accessorKey: "data.pattern",
        filterFn: "contains",
      },
      {
        header: "Amount",
        accessorKey: "data.amount",
        filterFn: "contains",
      },
      {
        header: "Limits ",
        accessorFn: ({ data }) => (data as AnyObj)?.limits?.join(", ") ?? "-",
        filterFn: "contains",
      },
      {
        header: "Replay",
        filterFn: "contains",
        enableEditing: false,
        accessorFn: (row) => row.enabled ?? "-",
        Cell: ({ row }) => (
          <Checkbox
            checked={(row.original.data as AnyObj)?.replay}
            onChange={(e) =>
              updateConfig({
                ...row.original,
                data: { ...row.original.data, replay: e.target.checked },
              })
            }
          />
        ),
      },
      {
        header: "frequency",
        accessorKey: "data.frequency",
        filterFn: "contains",
      },
      {
        header: "Created at",
        accessorKey: "created_at",
        accessorFn: (row) => (row.created_at ? new Date(row.created_at) : 0),
        Cell: ({
          row: {
            original: { created_at },
          },
        }) => (created_at ? moment(created_at).utc(false).format(APP_GMT_DATE_FORMAT) : "-"),
      },
      {
        header: "Updated at",
        accessorKey: "updated_at",
        accessorFn: (row) => (row.updated_at ? new Date(row.updated_at) : 0),
        Cell: ({
          row: {
            original: { created_at },
          },
        }) => (created_at ? moment(created_at).utc(false).format(APP_GMT_DATE_FORMAT) : "-"),
      },
    ];
  }, []);
