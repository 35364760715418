import { useMemo } from "react";
import { MRT_ColumnDef } from "material-react-table";
import { SysAccountSessionRegularDto } from "@/shared/api";
import { renderClubsCell,
} from "@/lib/accountsUtils";
import { NilObj } from "@/types/commonTypes";
import { Avatar } from "@mui/material";


const useSessionsColumns = (
  {
    roomName
  } : {
    roomName: string;
  }
) => useMemo<MRT_ColumnDef<SysAccountSessionRegularDto>[]>(() => [
  {
    id: "$.avatar",
    header: "Avatar",
    columnFilterModeOptions: ["contains", "empty", "notEmpty"],
    accessorFn: (row) => row.avatar ?? "-",
    Cell: ({ row }) => (
      <Avatar alt={row.original.nick ?? "?"} src={row.original.avatar} />
    )
  },
  {
    id: "$.username",
    header: "Username",
    accessorFn: (row) => row.username ?? "-",
  },
  {
    id: "$.pid",
    header: "Pid",
    accessorFn: (row) => row.pid ?? "-",
  },
  {
    id: "$.nick",
    header: "Nick",
    accessorFn: (row) => row.nick ?? "-",
  },
  {
    id: "$.account.data.clubs",
    header: "Clubs",
    enableColumnFilter: false,
    enableSorting: false,
    Cell: ({ row: { original } }) => renderClubsCell(original.account?.data)
  },
  {
    id: "$.cid",
    header: "Cid",
    accessorFn: (row) => row.cid ?? "-",
  },
  {
    id: "$.aid",
    header: "Aid",
    accessorFn: (row) => row.aid ?? "-",
  },
  {
    id: "$.game_info.tid",
    header: "Tid",
    accessorFn: (row) => row.game_info?.tid ?? "-",
  },
  {
    id: "$.account.data.alc",
    header: "Alc",
    accessorFn: ({account}) => (account?.data as NilObj)?.alc ?? "-",
  },
  {
    id: "$.gold",
    header: "Gold",
    accessorFn: (row) => row.gold ?? "-",
  },
  {
    id: "$.game_info.name",
    header: "Table name",
    accessorFn: (row) => row.game_info?.name ?? "-",
  },
  {
    id: "$.game_info.type",
    header: "Game type",
    accessorFn: (row) => row.game_info?.type ?? "-",
  },
  {
    id: "$.game_info.limit",
    header: "Table limit",
    accessorFn: (row) => row.game_info?.limit ?? "-",
  },
  {
    id: "$.game_info.hands",
    header: "Table hands",
    accessorFn: (row) => row.game_info?.hands ?? "-",
  },
  {
    id: "$.game_info.result",
    header: "Table result",
    accessorFn: (row) => row.game_info?.result ?? "-",
  },
  {
    id: "$.current_stats.vpip",
    header: "VPIP",
    accessorFn: (row) => row.current_stats?.vpip ?? "-"
  },
  {
    id: "$.current_stats.pfr",
    header: "PFR",
    accessorFn: (row) => row.current_stats?.pfr ?? "-",
  },
  {
    id: "$.current_stats.hands",
    header: "Hands",
    accessorFn: (row) => row.current_stats?.hands ?? "-",
  },
  {
    id: "$.proxy",
    header: "Proxy",
    accessorFn: (row) => row.proxy ?? "-",
  },
  {
    id: "$.schedule",
    header: "Schedule",
    accessorFn: (row) => row.schedule ?? "-",
  },
  {
    id: "$.gs_instance.id",
    header: "Gs",
    accessorFn: (row) => row.gs_instance?.id ?? "-",
  },
  {
    id: "$.gs_instance.version",
    header: "Gs version",
    accessorFn: (row) => row.gs_instance?.version ?? "-",
  },
  {
    id: "$.gs_instance.mode",
    header: "Gs mode",
    accessorFn: (row) => row.gs_instance?.mode ?? "-",
  },
  {
    id: "$.tes_instance.id",
    header: "Tes",
    accessorFn: (row) => row.tes_instance?.id ?? "-",
  },
  {
    id: "$.tes_instance.version",
    header: "Tes version",
    accessorFn: (row) => row.tes_instance?.version ?? "-",
  },
  {
    id: "$.tes_instance.mode",
    header: "Tes mode",
    accessorFn: (row) => row.tes_instance?.mode ?? "-",
  }
], [roomName])

export default useSessionsColumns