import { Checkbox } from "antd";
import { MRT_ColumnDef } from "material-react-table";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { SysCfgRegularDto } from "@/shared/api";
import { AnyObj, NilObj } from "@/types/commonTypes";

export const useRouterRuleTableColumns = () => {
  const { t } = useTranslation("room-configs");

  return useMemo<MRT_ColumnDef<SysCfgRegularDto>[]>(
    () => [
      {
        id: "enabled",
        header: t("routerRulesTable.enabled"),
        accessorFn: ({ enabled }) => enabled ?? "-",
        Cell: ({ row: { original } }) => <Checkbox checked={original.enabled} disabled />,
      },
      {
        header: t("routerRulesTable.priority"),
        accessorKey: "priority",
        accessorFn: ({ data }) => (data as NilObj)?.priority ?? "-",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("routerRulesTable.comment"),
        accessorFn: ({ data }) => (data as NilObj)?.comment ?? "-",
        filterFn: "contains",
      },
      {
        header: t("routerRulesTable.room"),
        accessorFn: (row) => {
          const data = row.data as AnyObj;
          if (Array.isArray(data?.room)) {
            return data?.room.join(", ");
          }
          return "-";
        },
        filterFn: "contains",
      },
      {
        header: t("routerRulesTable.technology"),
        accessorFn: (row) => {
          const data = row.data as AnyObj;
          if (Array.isArray(data?.technology)) {
            return data?.technology.join(", ");
          }
          return "-";
        },
        filterFn: "contains",
      },
      {
        header: t("routerRulesTable.partnerAIKey"),
        accessorFn: (row) => {
          const data = row.data as AnyObj;
          if (Array.isArray(data?.partner_ai_key)) {
            return data?.partner_ai_key.join(", ");
          }
          return "-";
        },
        filterFn: "contains",
      },
      {
        header: t("routerRulesTable.gameType"),
        accessorFn: (row) => {
          const data = row.data as AnyObj;
          if (Array.isArray(data?.game_type)) {
            return data?.game_type.join(", ");
          }
          return "-";
        },
        filterFn: "contains",
      },
      {
        header: t("routerRulesTable.size"),
        accessorFn: (row) => {
          const data = row.data as AnyObj;
          if (Array.isArray(data?.size)) {
            return data?.size.join(", ");
          }
          return "-";
        },
        filterFn: "contains",
      },
      {
        header: t("routerRulesTable.aid"),
        accessorFn: (row) => {
          const data = row.data as AnyObj;
          if (Array.isArray(data?.aid)) {
            return data?.aid.join(", ");
          }
          return "-";
        },
        filterFn: "contains",
      },
      {
        header: t("routerRulesTable.cid"),
        accessorFn: (row) => {
          const data = row.data as AnyObj;
          if (Array.isArray(data?.cid)) {
            return data?.cid.join(", ");
          }
          return "-";
        },
        filterFn: "contains",
      },
      {
        header: t("routerRulesTable.gameGroup"),
        accessorFn: (row) => {
          const data = row.data as AnyObj;
          if (Array.isArray(data?.game_group)) {
            return data?.game_group.join(", ");
          }
          return "-";
        },
        filterFn: "contains",
      },
      {
        header: t("routerRulesTable.limit"),
        accessorFn: (row) => {
          const data = row.data as AnyObj;
          if (Array.isArray(data?.limit)) {
            return data?.limit.join(", ");
          }
          return "-";
        },
        filterFn: "contains",
      },
      {
        header: t("routerRulesTable.straddle"),
        accessorFn: (row) => {
          const data = row.data as AnyObj;
          if (Array.isArray(data?.straddle)) {
            return data?.straddle.join(", ");
          }
          return "-";
        },
        filterFn: "contains",
      },
      {
        header: t("routerRulesTable.ante"),
        accessorFn: (row) => {
          const data = row.data as AnyObj;
          if (Array.isArray(data?.ante)) {
            return data?.ante.join(", ");
          }
          return "-";
        },
        filterFn: "contains",
      },
      {
        header: t("routerRulesTable.playersCount"),
        accessorFn: (row) => {
          const data = row.data as AnyObj;
          if (Array.isArray(data?.players_count)) {
            return data?.players_count.join(", ");
          }
          return "-";
        },
        filterFn: "contains",
      },
      {
        header: t("routerRulesTable.groupPattern"),
        accessorFn: (row) => {
          const data = row.data as AnyObj;
          if (Array.isArray(data?.group_pattern)) {
            return data?.group_pattern.join(", ");
          }
          return "-";
        },
        filterFn: "contains",
      },
      {
        header: t("routerRulesTable.onlyBot"),
        accessorFn: ({ data }) => (data as NilObj)?.only_bot ?? "-",
        filterFn: "contains",
      },
      {
        header: t("routerRulesTable.aiType"),
        accessorFn: ({ data }) => (data as NilObj)?.ai_type ?? "-",
        filterFn: "contains",
      },
      {
        header: t("routerRulesTable.aiProfile"),
        accessorFn: (row) => {
          const data = row.data as AnyObj;
          if(Array.isArray(data?.ai_profile)) {
            return data?.ai_profile.join(", ")
          }
          return "-"
        },
        filterFn: "contains",
      },
      {
        header: t("routerRulesTable.instances"),
        filterFn: "contains",
        accessorFn: (row) => {
          const data = row.data as AnyObj;
          if (Array.isArray(data?.instances)) {
            return data?.instances.map((it) => it?.instance).join("\n");
          }
          return "-";
        },
      },
      {
        header: t("routerRulesTable.probability"),
        filterFn: "contains",
        accessorFn: (row) => {
          const data = row.data as AnyObj;
          if (Array.isArray(data?.instances)) {
            return data?.instances.map((it) => it?.probability).join("\n");
          }
          return "-";
        },
      },
      {
        header: "AI Types",
        filterFn: "contains",
        accessorFn: (row) => {
          const data = row.data as AnyObj;
          if (Array.isArray(data?.instances)) {
            return data?.instances.map((it) => it?.ai_type).join("\n");
          }
          return "-";
        },
      },
      {
        header: t("routerRulesTable.alwaysActive"),
        filterFn: "contains",
        accessorFn: (row) => {
          const data = row.data as AnyObj;
          if (Array.isArray(data?.instances)) {
            return data?.instances.map((it) => it?.always_active).join("\n");
          }
          return "-";
        },
      },
      {
        header: t("routerRulesTable.singleInstance"),
        filterFn: "contains",
        accessorFn: ({ data }) =>
          (data as NilObj)?.single_instance == null
            ? "-"
            : `${(data as NilObj)?.single_instance}`,
      },
    ],
    [t]
  );
};
