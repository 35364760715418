import { RouterInstanceDto } from "@/shared/api";
import { keepPreviousData, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useApi } from "@/shared/api/use-api";

export const useSysRouterInstanceQuery = (
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<{ [key: string]: RouterInstanceDto }>>, "queryKey">
) => {
  const {sysRouterInstanceControllerApi} = useApi();
  return useQuery({
    queryKey: [`/sys-data/service/router/instance/all`],
    queryFn: ({ signal }) => {
      return sysRouterInstanceControllerApi.getAllInstances({ signal })
    },
    placeholderData: keepPreviousData,
    ...queryOptions
  })
}