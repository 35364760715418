import { Divider, Form, Input, Modal, Radio, Select, Typography, message } from "antd";
import { useAtom } from "jotai/index";
import React, { useEffect, useMemo, useState } from "react";

import { useAuthGroupsQuery } from "@/api/hooks/auth-groups-hooks";
import { isOwner } from "@/lib/auth-predicates";
import { trimSplitCsvRecord } from "@/lib/common-utils";
import { SysAccountCreateDto } from "@/shared/api";
import { meStore } from "@/store/store";

type CsvDelimiter = "," | ";";

const ImportAccountsModal = ({
  title,
  onCancel,
  confirmLoading = false,
  onSave,
}: {
  title?: string;
  confirmLoading?: boolean;
  onCancel: () => void;
  onSave: (accounts: SysAccountCreateDto[]) => void;
}) => {
  const [me] = useAtom(meStore);

  const { data: authGroupsRs } = useAuthGroupsQuery();

  const authGroupsOptions = useMemo(() => {
    return (
      authGroupsRs?.data
        .map((i) => ({ label: i.name, value: i.name }))
        .sort((a, b) => a.label.localeCompare(b.label)) ?? []
    );
  }, [authGroupsRs?.data]);

  const [csvDelimiter, setCsvDelimiter] = useState<CsvDelimiter>(",");
  const [form] = Form.useForm();

  useEffect(() => {
    if (authGroupsRs?.data.length === 1 && !isOwner(me)) {
      form.setFieldValue(
        "authGroups",
        authGroupsRs?.data.map((it) => it.name)
      );
    }
  }, [authGroupsRs, form, me]);

  const formValues = Form.useWatch([], form);

  function handleChangeDelimiter(value: CsvDelimiter) {
    setCsvDelimiter(value);
  }

  function handleSaveClick() {
    form
      .validateFields({ validateOnly: false })
      .then(() => {
        const value: { csv: string } = formValues.valueOf("csvData");
        const authGroups: string[] = form.getFieldValue("authGroups");
        const accounts = convertCsvInputToAccountsCreateObj(value, authGroups);

        if (accounts === null) {
          message.error("Something went wrong while saving accounts").then();
          return;
        }

        onSave(accounts);
      })
      .catch((err) => console.error(err));
  }

  function accountCsvValidator(value: any) {
    if (!value) return Promise.reject(new Error("'Accounts' are required"));

    if (typeof value !== "string")
      return Promise.reject(new Error("'Accounts' must be a string"));

    if (!value.trim()) return Promise.reject(new Error("'Proxies' are required"));

    const records = value.split("\n");

    for (const record of records) {
      const validationMessage = validateAccountsCsvRecord(record);

      if (validationMessage !== undefined)
        return Promise.reject(
          new Error(`There is invalid record in input, ${validationMessage}: ${record}`)
        );
    }

    return Promise.resolve();
  }

  function validateAccountsCsvRecord(record: string): string | undefined {
    const values = trimSplitCsvRecord(record, csvDelimiter);

    if (!values[0] || values[0] === "") {
      return "name cannot be null or empty";
    }

    if (!values[1] || values[1] === "") {
      return "password cannot be null or empty";
    }

    if (!values[2] || values[2] === "") {
      return "wallet code cannot be null or empty";
    }
    return undefined;
  }

  function convertCsvInputToAccountsCreateObj(
    input: { csv: string },
    commonAuthGroups: string[]
  ) {
    const records = input.csv.split("\n");

    return records.map((item) => convertCsvRecordToAccountJson(item, commonAuthGroups));
  }

  function convertCsvRecordToAccountJson(
    record: string,
    authGroups: string[]
  ): SysAccountCreateDto {
    const values = trimSplitCsvRecord(record, csvDelimiter);

    return {
      name: values[0],
      password: values[1],
      walletCode: values[2],
      authGroups: new Set(authGroups),
      source: values[3],
      cid: values[4],
      gameGroup: values[5],
      gameType: values[6],
      proxy: values[7],
      behaviour: values[8],
      schedule: values[9],
      aiProfile: values[10],
      aiProfileOb: values[11],
      timingProfile: values[12],
      timingProfileOb: values[13],
      insuranceProfile: values[14],
      insuranceProfileOb: values[15],
      osType: values[16] as unknown as number,
      accountType: values[17] as unknown as number,
    };
  }

  return (
    <>
      <Modal
        open
        okText="Add"
        onCancel={() => onCancel && onCancel()}
        maskClosable={false}
        width={1200}
        confirmLoading={confirmLoading}
        onOk={() => handleSaveClick()}
        title={
          title && (
            <Typography.Title level={3} style={{ marginTop: 15 }}>
              {title}
            </Typography.Title>
          )
        }
      >
        {title && <Divider style={{ marginTop: 10, marginBottom: 30 }} />}

        <Divider />

        <Form
          size="large"
          className="a-common-form"
          form={form}
          autoComplete="off"
          key="bulkAddingMode"
        >
          <Form.Item
            name="authGroups"
            label="Auth Groups"
            rules={[{ required: !isOwner(me) }]}
          >
            <Select
              disabled={authGroupsRs?.data.length === 1 && !isOwner(me)}
              options={authGroupsOptions}
              style={{ width: "100%" }}
              mode="multiple"
              placeholder="Auth groups"
            />
          </Form.Item>

          <Form.Item label="Delimiter">
            <Radio.Group
              onChange={(e) => handleChangeDelimiter(e.target.value)}
              value={csvDelimiter}
            >
              <Radio.Button value=",">,</Radio.Button>
              <Radio.Button value=";">;</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="csv"
            label="Accounts"
            rules={[
              {
                required: true,
                validator: (_, value) => accountCsvValidator(value),
              },
            ]}
          >
            <Input.TextArea
              name="csvData"
              rows={30}
              placeholder={`Name${csvDelimiter}Password${csvDelimiter}Wallet code${csvDelimiter}Source${csvDelimiter}CID${csvDelimiter}Group${csvDelimiter}Game type${csvDelimiter}Proxy${csvDelimiter}Behaviour${csvDelimiter}Schedule${csvDelimiter}AI Profile${csvDelimiter}AI Profile Ob${csvDelimiter}Timing Profile${csvDelimiter}Timing Profile Ob${csvDelimiter}Insurance Profile${csvDelimiter}Insurance Profile Ob${csvDelimiter}OS Type${csvDelimiter}Account type (0,1,2,3)`}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ImportAccountsModal;
