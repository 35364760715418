import { red } from "@ant-design/colors";
import { Form, FormInstance, Space } from "antd";
import React from "react";

import _SpecificControl from "./_SpecificControl";
import "./index.css";
import { UiSchema } from "./types/wrapperTypes";
import { calculateWrapperInitialValues } from "./util";
import { isEmpty } from "lodash";

const JsonForm = ({
  ui,
  form,
  initData,
  schema,
  disabled
}: {
  ui: UiSchema;
  form: FormInstance;
  initData?: object;
  schema?: object;
  disabled?: boolean;
}) => {
  const requiredMark = (
    labelNode: React.ReactNode,
    info: { required: boolean }
  ) => {
    const node: any = labelNode;
    if (info.required) {
      const span = <span style={{ color: red.primary }}>*</span>;

      if (node.props?.className?.includes("right-required-mark")) {
        return (
          <>
            {labelNode}&nbsp;{span}
          </>
        );
      }

      return (
        <>
          {span}&nbsp;{labelNode}
        </>
      );
    } else {
      return labelNode;
    }
  };

  return (
    <Form
      form={form}
      layout="horizontal"
      className="json-form a-common-form"
      autoComplete="off"
      size="large"
      initialValues={initData && !isEmpty(initData) ? initData : calculateWrapperInitialValues(ui)}
      requiredMark={requiredMark}
      disabled={disabled}
    >
      <Space direction={ui.direction} style={{ width: "100%" }}>
        {ui.elements.map((item, index) => (
          <_SpecificControl key={index} control={item} disabled={disabled}/>
        ))}
      </Space>
    </Form>
  );
};

export default JsonForm;
