/* tslint:disable */

/* eslint-disable */

/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { AxiosInstance, AxiosPromise, RawAxiosRequestConfig } from "axios";
import globalAxios from "axios";

// @ts-ignore
import {
  BASE_PATH,
  BaseAPI,
  COLLECTION_FORMATS,
  RequestArgs,
  RequiredError,
  operationServerMap,
} from "../base";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  createRequestFunction,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  toPathString,
} from "../common";
import type { Configuration } from "../configuration";
// @ts-ignore
import { GenerateAccountsDto } from "../models";
// @ts-ignore
import { PageResponseSysAccountRegularDto } from "../models";
// @ts-ignore
import { QueryDto } from "../models";
// @ts-ignore
import { QueryFiltersDto } from "../models";
// @ts-ignore
import { ResultDtoSysAccountRegularDto } from "../models";
// @ts-ignore
import { SysAccountCreateDto } from "../models";
// @ts-ignore
import { SysAccountRegularDto } from "../models";
// @ts-ignore
import { SysAccountUpdateDto } from "../models";

/**
 * SysAccountControllerApi - axios parameter creator
 * @export
 */
export const SysAccountControllerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} domain
     * @param {Array<SysAccountCreateDto>} sysAccountCreateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAccounts: async (
      domain: string,
      sysAccountCreateDto: Array<SysAccountCreateDto>,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("createAccounts", "domain", domain);
      // verify required parameter 'sysAccountCreateDto' is not null or undefined
      assertParamExists("createAccounts", "sysAccountCreateDto", sysAccountCreateDto);
      const localVarPath = `/api/v2/sys-cfg/{domain}/account`.replace(
        `{${"domain"}}`,
        encodeURIComponent(String(domain))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sysAccountCreateDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} domain
     * @param {QueryFiltersDto} queryFiltersDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportCsv: async (
      domain: string,
      queryFiltersDto: QueryFiltersDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("exportCsv", "domain", domain);
      // verify required parameter 'queryFiltersDto' is not null or undefined
      assertParamExists("exportCsv", "queryFiltersDto", queryFiltersDto);
      const localVarPath = `/api/v2/sys-cfg/{domain}/account/export/csv`.replace(
        `{${"domain"}}`,
        encodeURIComponent(String(domain))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        queryFiltersDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} domain
     * @param {QueryDto} queryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    find: async (
      domain: string,
      queryDto: QueryDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("find", "domain", domain);
      // verify required parameter 'queryDto' is not null or undefined
      assertParamExists("find", "queryDto", queryDto);
      const localVarPath = `/api/v2/sys-cfg/{domain}/account/find-all`.replace(
        `{${"domain"}}`,
        encodeURIComponent(String(domain))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        queryDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} domain
     * @param {GenerateAccountsDto} generateAccountsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateAccountsByNumber: async (
      domain: string,
      generateAccountsDto: GenerateAccountsDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("generateAccountsByNumber", "domain", domain);
      // verify required parameter 'generateAccountsDto' is not null or undefined
      assertParamExists(
        "generateAccountsByNumber",
        "generateAccountsDto",
        generateAccountsDto
      );
      const localVarPath = `/api/v2/sys-cfg/{domain}/account/generate`.replace(
        `{${"domain"}}`,
        encodeURIComponent(String(domain))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        generateAccountsDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} domain
     * @param {string} name
     * @param {SysAccountUpdateDto} sysAccountUpdateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccount: async (
      domain: string,
      name: string,
      sysAccountUpdateDto: SysAccountUpdateDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("updateAccount", "domain", domain);
      // verify required parameter 'name' is not null or undefined
      assertParamExists("updateAccount", "name", name);
      // verify required parameter 'sysAccountUpdateDto' is not null or undefined
      assertParamExists("updateAccount", "sysAccountUpdateDto", sysAccountUpdateDto);
      const localVarPath = `/api/v2/sys-cfg/{domain}/account/{name}`
        .replace(`{${"domain"}}`, encodeURIComponent(String(domain)))
        .replace(`{${"name"}}`, encodeURIComponent(String(name)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sysAccountUpdateDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SysAccountControllerApi - functional programming interface
 * @export
 */
export const SysAccountControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SysAccountControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} domain
     * @param {Array<SysAccountCreateDto>} sysAccountCreateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createAccounts(
      domain: string,
      sysAccountCreateDto: Array<SysAccountCreateDto>,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<ResultDtoSysAccountRegularDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createAccounts(
        domain,
        sysAccountCreateDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysAccountControllerApi.createAccounts"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} domain
     * @param {QueryFiltersDto} queryFiltersDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportCsv(
      domain: string,
      queryFiltersDto: QueryFiltersDto,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exportCsv(
        domain,
        queryFiltersDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysAccountControllerApi.exportCsv"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} domain
     * @param {QueryDto} queryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async find(
      domain: string,
      queryDto: QueryDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PageResponseSysAccountRegularDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.find(
        domain,
        queryDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysAccountControllerApi.find"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} domain
     * @param {GenerateAccountsDto} generateAccountsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generateAccountsByNumber(
      domain: string,
      generateAccountsDto: GenerateAccountsDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SysAccountRegularDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.generateAccountsByNumber(
        domain,
        generateAccountsDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysAccountControllerApi.generateAccountsByNumber"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} domain
     * @param {string} name
     * @param {SysAccountUpdateDto} sysAccountUpdateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAccount(
      domain: string,
      name: string,
      sysAccountUpdateDto: SysAccountUpdateDto,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccount(
        domain,
        name,
        sysAccountUpdateDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysAccountControllerApi.updateAccount"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * SysAccountControllerApi - factory interface
 * @export
 */
export const SysAccountControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SysAccountControllerApiFp(configuration);
  return {
    /**
     *
     * @param {string} domain
     * @param {Array<SysAccountCreateDto>} sysAccountCreateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAccounts(
      domain: string,
      sysAccountCreateDto: Array<SysAccountCreateDto>,
      options?: any
    ): AxiosPromise<Array<ResultDtoSysAccountRegularDto>> {
      return localVarFp
        .createAccounts(domain, sysAccountCreateDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} domain
     * @param {QueryFiltersDto} queryFiltersDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportCsv(
      domain: string,
      queryFiltersDto: QueryFiltersDto,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .exportCsv(domain, queryFiltersDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} domain
     * @param {QueryDto} queryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    find(
      domain: string,
      queryDto: QueryDto,
      options?: any
    ): AxiosPromise<PageResponseSysAccountRegularDto> {
      return localVarFp
        .find(domain, queryDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} domain
     * @param {GenerateAccountsDto} generateAccountsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateAccountsByNumber(
      domain: string,
      generateAccountsDto: GenerateAccountsDto,
      options?: any
    ): AxiosPromise<Array<SysAccountRegularDto>> {
      return localVarFp
        .generateAccountsByNumber(domain, generateAccountsDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} domain
     * @param {string} name
     * @param {SysAccountUpdateDto} sysAccountUpdateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccount(
      domain: string,
      name: string,
      sysAccountUpdateDto: SysAccountUpdateDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateAccount(domain, name, sysAccountUpdateDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SysAccountControllerApi - object-oriented interface
 * @export
 * @class SysAccountControllerApi
 * @extends {BaseAPI}
 */
export class SysAccountControllerApi extends BaseAPI {
  /**
   *
   * @param {string} domain
   * @param {Array<SysAccountCreateDto>} sysAccountCreateDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysAccountControllerApi
   */
  public createAccounts(
    domain: string,
    sysAccountCreateDto: Array<SysAccountCreateDto>,
    options?: RawAxiosRequestConfig
  ) {
    return SysAccountControllerApiFp(this.configuration)
      .createAccounts(domain, sysAccountCreateDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} domain
   * @param {QueryFiltersDto} queryFiltersDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysAccountControllerApi
   */
  public exportCsv(
    domain: string,
    queryFiltersDto: QueryFiltersDto,
    options?: RawAxiosRequestConfig
  ) {
    return SysAccountControllerApiFp(this.configuration)
      .exportCsv(domain, queryFiltersDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} domain
   * @param {QueryDto} queryDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysAccountControllerApi
   */
  public find(domain: string, queryDto: QueryDto, options?: RawAxiosRequestConfig) {
    return SysAccountControllerApiFp(this.configuration)
      .find(domain, queryDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} domain
   * @param {GenerateAccountsDto} generateAccountsDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysAccountControllerApi
   */
  public generateAccountsByNumber(
    domain: string,
    generateAccountsDto: GenerateAccountsDto,
    options?: RawAxiosRequestConfig
  ) {
    return SysAccountControllerApiFp(this.configuration)
      .generateAccountsByNumber(domain, generateAccountsDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} domain
   * @param {string} name
   * @param {SysAccountUpdateDto} sysAccountUpdateDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysAccountControllerApi
   */
  public updateAccount(
    domain: string,
    name: string,
    sysAccountUpdateDto: SysAccountUpdateDto,
    options?: RawAxiosRequestConfig
  ) {
    return SysAccountControllerApiFp(this.configuration)
      .updateAccount(domain, name, sysAccountUpdateDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
