import { Box } from "@mui/material";
import RunAccountsTableControl from "@/module/accounts-module/components/run-accounts-table-control";
import ChangeEnabledConfigsTableControl from "@/components/change-enabled-configs-table-control";
import DeleteConfigsTableControl from "@/components/delete-configs-table-control";
import React, { useState } from "react";
import { MRT_TableInstance } from "material-react-table";
import { KeyFilter, SysAccountRegularDto } from "@/shared/api";
import { ResultDto } from "@/types/commonTypes";
import BulkOperationReportModal from "@/components/bulk-operation-report-modal";
import CreateServiceAccountTableControl from "@/module/accounts-module/components/create-service-account-table-control";
import EditServiceAccountTableControl from "@/module/accounts-module/components/edit-service-account-table-control";
import CopyServiceAccountTableControl from "@/module/accounts-module/components/copy-service-account-table-control";
import GenerateAccountsTableControl from "@/module/accounts-module/components/generate-accounts-table-control";
import { useCfgPerm, useIsOwnerOrSuperAdmin } from "@/auth/hooks";
import {ROOM_CONFIGS_NAMES} from "@/lib/constants";
import ApplyAuthGroupsTableControl from "@/components/apply-auth-groups-table-control";


const ServiceAccountsTopToolbar = (
  {
    roomName,
    table,
    filters,
    refetchAccounts,
    totalElementsCount
  } : {
    table: MRT_TableInstance<SysAccountRegularDto>;
    roomName: string;
    filters: Set<KeyFilter>;
    refetchAccounts: () => void;
    totalElementsCount: number;
  }
) => {
  const accountPerms = useCfgPerm({roomName: roomName, cfgName: ROOM_CONFIGS_NAMES.ACCOUNT});
  const isOwner = useIsOwnerOrSuperAdmin();
  const [bulkOperationResult, setBulkOperationResult] =
    useState<ResultDto<any>[] | undefined>(undefined);

  return (
    <>
      <div style={{ display: "flex" }}>
        {accountPerms.write && (
          <Box sx={{ mr: 5, display: 'grid', gap: 1, gridTemplateColumns: 'repeat(2, 1fr)' }}>
            <CreateServiceAccountTableControl
              roomName={roomName}
              onSuccess={() => refetchAccounts()}
            />

            <GenerateAccountsTableControl
              roomName={roomName}
              onSuccess={() => refetchAccounts()}
            />
          </Box>
        )}

        {accountPerms.execute && (
          <RunAccountsTableControl
            roomName={roomName}
            table={table}
            filters={filters}
            onSuccessRun={() => refetchAccounts()}
            totalElementsCount={totalElementsCount}
          />
        )}

        {accountPerms.execute && (
          <ChangeEnabledConfigsTableControl
            roomName={roomName}
            table={table}
            enabled
            filters={filters}
            onSuccess={() => refetchAccounts()}
            totalElementsCount={totalElementsCount}
          />
        )}

        {accountPerms.execute && (
          <ChangeEnabledConfigsTableControl
            roomName={roomName}
            table={table}
            enabled={false}
            filters={filters}
            onSuccess={() => refetchAccounts()}
            totalElementsCount={totalElementsCount}
          />
        )}

        <EditServiceAccountTableControl
          disabled={!accountPerms.write}
          roomName={roomName}
          table={table}
          onSuccess={() => refetchAccounts()}
          totalElementsCount={totalElementsCount}
        />

        {accountPerms.delete && (
          <DeleteConfigsTableControl
            roomName={roomName}
            table={table}
            filters={filters}
            onSuccessDelete={() => refetchAccounts()}
            totalElementsCount={totalElementsCount}
          />
        )}

        {accountPerms.write && (
          <CopyServiceAccountTableControl
            roomName={roomName}
            table={table}
            onSuccess={() => refetchAccounts()}
          />
        )}

        {isOwner && (
          <ApplyAuthGroupsTableControl
            domain={roomName}
            cfgName={ROOM_CONFIGS_NAMES.ACCOUNT}
            table={table}
            filters={filters}
            totalElementsCount={totalElementsCount}
            onSuccess={() => refetchAccounts()}
          />
        )}

      </div>

      <BulkOperationReportModal
        resultList={bulkOperationResult ?? []}
        title="Operation result"
        showProblematic
        showSuccess
        onCancel={() => setBulkOperationResult(undefined)}
        open={!!bulkOperationResult && bulkOperationResult.length > 0}
      />
    </>
  )
}


export default ServiceAccountsTopToolbar