import React, { useEffect, useMemo } from "react";


import { Autocomplete, Button, Chip, Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useSysCfgRawQuery } from "@/api/hooks/sys-cfg-hooks";
import { ROOM_CONFIGS_NAMES, ROOM_NAMES } from "@/lib/constants";
import { useAuthGroupsQuery } from "@/api/hooks/auth-groups-hooks";
import {isOwner} from "@/lib/auth-predicates";
import {useAtom} from "jotai/index";
import {meStore} from "@/store/store";

import CustomSelectOption from "@/components/custom-select-option";

import {
  EditedReportRuleType,
  EditReportRuleType,
} from "@/components/config-modals/players-reporter/report-rule/types";
import { formInitState } from "@/components/config-modals/players-reporter/report-rule/constants";


export default function ReportRuleEditModal (
  {
    open,
    mode,
    initialData,
    onSave,
    onCancel,
    disabled = false
  } : {
    open: boolean,
    mode?: string,
    initialData?: EditReportRuleType;
    onSave?: (data: EditedReportRuleType) => void;
    onCancel?: () => void,
    confirmLoading?: boolean,
    disabled?: boolean
  }
) {

  const {
    data: authGroupsRs
  } = useAuthGroupsQuery()
  const [me] = useAtom(meStore);

  const defaultValues = useMemo<EditedReportRuleType>(() => {
    let authGroups = initialData?.auth_groups ? Array.from(initialData.auth_groups) : []

    if(authGroupsRs?.data.length === 1 && !isOwner(me)) {
      authGroups = authGroupsRs.data.map(it => it.name)
    }

    return initialData ? {
      ...initialData,
      enabled: true,
      authGroups: authGroups
    } : {
      ...formInitState,
      authGroups: authGroups
    };

  }, [initialData,/* authGroupsRs,*/ me]); //TODO: fix authGroupsRs. Because of authGroupsRs default values got broken by tab switching



  /*const {
    data: clusteringPatternsRs,
    isLoading: roomConfigsDataIsLoading,
    refetch
  } = useSysCfgRawQuery({
    domain: ROOM_NAMES.SERVICES,
    config_name: ROOM_CONFIGS_NAMES.CLUSTERING_PATTERN
  }, {
    refetchOnMount: true
  });*/

  const { data: clusteringPatternsRs, refetch } = useSysCfgRawQuery({
    domain: ROOM_NAMES.SERVICES,
    config_name: ROOM_CONFIGS_NAMES.CLUSTERING_PATTERN
  }, {
    enabled: open,
    refetchOnMount: true
  });
  /*const {
    data: clusteringPatternBaseRs,
    isLoading: clusteringPatternsIsLoading,
    refetch: refetchClusteringPattern
  } = useSysCfgCombinedQuery ({
    domain: ROOM_NAMES.SERVICES,
    config_names: [ROOM_CONFIGS_NAMES.CLUSTERING_PATTERN]
  }, {
    refetchOnMount: true
  })

  const [clusteringPatternsRs, setClusteringPatternsRs] = useState<SysCfgRawDto[]>([])
  //TODO Remove useEffect and return config names with their rooms from back-end
  useEffect(() => {
    setClusteringPatternsRs(clusteringPatternBaseRs?.data?.find(it => it.config_name === ROOM_CONFIGS_NAMES.REPORT_RULE)?.configs ?? [])
  }, [clusteringPatternBaseRs])*/


  const {
    register,
    setValue,
    control,
    reset,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm<EditedReportRuleType>({
    mode: "all",
    disabled: disabled,
    defaultValues: {...defaultValues}
  })

  useEffect(() => {
    reset({...defaultValues})
  }, [defaultValues, open, reset])


  const onSubmit: SubmitHandler<EditedReportRuleType> = (data) => {
    data.name = data.name && data.name !== ""
      ?  data.name
      : "clustering_type_".concat(Date.now().toString())//TODO: move to Back-end

    if(!onSave) return;
    onSave(data);

    onCancel && onCancel() //purifying form and close dialog
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={()=> {return 0}}
        key="Report chat modal"
      >
        <DialogTitle>{mode === "add" ? "Add report chat" : "Edit report chat" }</DialogTitle>
        <DialogContent>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "500px",
            }}
          >
            <Controller
              name="authGroups"
              control={control}
              rules={{
                required: !isOwner(me) ? "Field is required" : undefined,
              }}
              render={({ field: { onChange, onBlur, value, ref }, fieldState: {error} }) => (
                <Autocomplete
                  multiple
                  options={authGroupsRs?.data.map(it => it.name) ?? []}
                  value={value}
                  disabled={disabled || (authGroupsRs?.data.length === 1 && !isOwner(me))}
                  onChange={(_, newValue) => {
                    onChange(newValue);
                    setValue("authGroups", newValue); // Update the value in react-hook-form
                  }}
                  renderOption={(props, option, { selected }) => (
                    <CustomSelectOption label={option} selected={selected} props={props}/>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Auth groups"
                      placeholder="Auth groups"
                      inputRef={ref}
                      onBlur={onBlur} // Ensure onBlur is called to update the form state
                      error={!!error?.message}
                      helperText={error?.message}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={option}
                        {...getTagProps({ index })}
                        key={index}
                      />
                    ))
                  }
                />
              )}
            />

            <TextField
              key="dialog-rc"
              {...register("name", { required: "Name field is required" })}
              label="Name"
              placeholder="name"
              variant="standard"
              error={!!errors.name}
              helperText={!!errors.name && errors.name.message}
              disabled={disabled}
            />

            <TextField
              key="dialog-rr-display-name"
              {...register("data.display_name", { required: "Display name field is required" })}
              label="Display name"
              placeholder="Display name"
              variant="standard"
              error={!!errors.data?.display_name}
              helperText={!!errors.data?.display_name && errors.data.display_name.message}
              disabled={disabled}
            />

            <TextField
              key="dialog-rr-amount"
              {...register("data.amount", {
                required: "Amount field is required",
                valueAsNumber: true,
                max: { value: 50000000, message: "Number should be less than 50000000" },
                min: { value: 0, message: "Number should be higher than 0" },
              })}
              label="Amount"
              placeholder="Amount"
              variant="standard"
              error={!!errors.data?.amount}
              helperText={!!errors.data?.amount && errors.data.amount.message}
              disabled={disabled}
            />

            <TextField
              key="dialog-rr-frequency"
              {...register("data.frequency", {
                required: "Frequency field is required",
                valueAsNumber: true,
                max: { value: 50000000, message: "Number should be less than 50000000" },
                min: { value: 0, message: "Number should be higher than 0" },
              })}
              label="Frequency"
              placeholder="Frequency"
              variant="standard"
              error={!!errors.data?.frequency}
              helperText={!!errors.data?.frequency && errors.data.frequency.message}
              disabled={disabled}
            />

            <Controller
              name="data.pattern"
              control={control}
              rules={{ required: "Pattern is required" }}
              render={({ field: { onChange, onBlur, value, ref }, fieldState: {invalid, error} }) => (
                <Autocomplete
                  multiple
                  // freeSolo
                  disabled={disabled}
                  options={clusteringPatternsRs?.data ?? []}
                  getOptionLabel={(option) => option.name}
                  value={clusteringPatternsRs?.data?.filter(option => value.includes(option.name))}
                  onChange={(event, newValue) => {
                    const newIds = newValue.map(item => item.name);
                    onChange(newIds);
                    setValue("data.pattern", newIds);
                  }}

                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Pattern"
                      placeholder="Add a tag"
                      inputRef={ref}
                      onBlur={onBlur} // Ensure onBlur is called to update the form state
                      onFocus={() => refetch()}
                      error={!!error?.message}
                      helperText={error?.message}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={option.name}
                        {...getTagProps({ index })}
                        key={index}
                      />
                    ))
                  }
                />
              )}
            />





            <Controller
              name="data.limits"
              control={control}
              rules={{
                required: "Limits is required",
                validate: v => v.filter(a => isNaN(a)).length === 0 || "Please, enter only numbers",
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Pls enter only numbers",
                },
              }}
              render={({ field: { onChange, onBlur, value, ref }, fieldState: {invalid, error}, formState  }) => (
                <Autocomplete
                  multiple
                  freeSolo
                  options={[]}
                  value={value}
                  disabled={disabled}
                  onChange={(event, newValue) => {
                    onChange(newValue);

                    setValue("data.limits", newValue.map(v => parseInt(v.toString()))); // Update the value in react-hook-form
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Limits"
                      placeholder="Add a tag"
                      inputRef={ref}
                      onBlur={onBlur} // Ensure onBlur is called to update the form state
                      error={!!error?.message}
                      helperText={error?.message}
                      // inputProps={{inputMode:"numeric", pattern:"[0-9]*", title: "Enter only digits"}}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={option}
                        {...getTagProps({ index })}
                        key={index}
                      />
                    ))
                  }
                />
              )}
            />

            <div style={{display: "flex",
              justifyContent: "flex-end"}}>
              <Button
                type="submit"
                disabled={disabled}
              >Submit</Button>
              <Button type="button"
                      onClick={() => onCancel && onCancel()}>Cancel</Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
