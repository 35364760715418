

export abstract class Input {
  constructor(
    public readonly key: string,
    public readonly placeholder: string,
    public readonly nullable: boolean = false
  ) {}


}

export class TextInput extends Input {}

export class Select<T extends string | number> extends Input {
  public readonly options: Option<T>[];


  constructor(key: string, placeholder: string, options: Option<T>[], nullable?: boolean) {
    super(key, placeholder, nullable);
    this.options = options;
  }
}

export interface Option<T extends string | number> {
  title: string;
  value: T
}

