

class PropertyResolver {
  private readonly obj: unknown;

  constructor(obj: unknown) {
    this.obj = obj;
  }

  number<T extends number | null>(path: string, defaultValue: T): T {
    const result: unknown = this.get(path, defaultValue);
    return (typeof result === "number" ? result : defaultValue) as T;
  }

  string<T extends string | null>(path: string, defaultValue: T): T {
    const result: unknown = this.get(path, defaultValue);
    return (typeof result === typeof "string" ? result : defaultValue) as T;
  }

  private get(path: string, defaultValue: unknown): unknown {
    if (!this.obj) return defaultValue;

    const keys = path.split('.');
    let result: unknown = this.obj;

    for (const key of keys) {
      if (this.isRecord(result) && key in result) {
        result = result[key];
      } else {
        return defaultValue;
      }
    }

    return result
  }

  private isRecord(value: unknown): value is Record<string, unknown> {
    return typeof value === 'object' && value !== null;
  }
}


const propertyResolver = (obj: unknown) => {
  return new PropertyResolver(obj);
}


export default propertyResolver;