import { Descriptions, message, Spin, Tooltip } from "antd";
import { Box, Button, Paper } from "@mui/material";
import { getEnumNumberValues, mapExploitationStageToStringName } from "@/lib/common-utils";
import { BugOutlined, FileUnknownOutlined, PauseCircleOutlined, RocketOutlined } from "@ant-design/icons";
import { blue, gold, green } from "@ant-design/colors";
import React, { useEffect, useMemo, useState } from "react";
import { COMMON_REFETCH_INTERVAL, ExploitationStage } from "@/lib/constants";
import { useSwitchGsModeMutation, useSwitchTesModeMutation } from "@/api/hooks/command-hooks";
import {
  PlatformServiceRegularDto,
  PlatformServiceRegularDtoTypeEnum,
  SwitchGsModeModeEnum,
  SwitchTesModeModeEnum,
} from "@/shared/api";
import { usePlatformServicesFromAllRoomsQuery } from "@/api/hooks/platform-service-hooks";
import ASelectPopover from "@/components/ASelectPopover";
import TimeAgo from "@/components/TimeAgo";
import { Stack } from "@mui/system";
import { Route as ErrorPageRoute } from "@/routes/error/index";
import { Route } from "@/routes/dashboard";


const RunningServicesModule = () => {

  const [refetchInterval, setRefetchInterval] = useState<number>(COMMON_REFETCH_INTERVAL);

  const {
    data: runningInstancesRs,
    isLoading: runningInstancesIsLoading,
    isError: runningInstancesIsError,
    error: runningInstancesError
  } = usePlatformServicesFromAllRoomsQuery({
    refetchInterval: refetchInterval
  });

  function startFastRefetch() {
    setRefetchInterval(1000);
    setTimeout(() => {
      setRefetchInterval(COMMON_REFETCH_INTERVAL);
    }, COMMON_REFETCH_INTERVAL); //in 15 sec
  }

  const runningInstances = useMemo(() => {
    return runningInstancesRs?.data ?? []
  }, [runningInstancesRs]);


  const switchTesModeMutation = useSwitchTesModeMutation();
  const switchGsModeMutation = useSwitchGsModeMutation();


  const [changingExploitationStageService, setChangingExploitationStageService] =
    useState<string | undefined>(undefined);
  const [newExploitationStageValue, setNewExploitationStageValue] =
    useState<ExploitationStage | undefined>(undefined);
  const navigate = Route.useNavigate();

  useEffect(() => {
    if(runningInstancesIsError) {
      void navigate({
        to: ErrorPageRoute.fullPath,
        search: { errorMessage: runningInstancesError.message }
      })
    }
  }, [navigate, runningInstancesError, runningInstancesIsError]);

  if(runningInstancesIsLoading) {
    return <Spin size="large" fullscreen/>
  }






  function mapManageableServiceTitle (service: PlatformServiceRegularDto) {
    const icon = (() => {
      switch (service.mode) {
        case ExploitationStage.PRODUCTION:
          return <Tooltip title="Production"><RocketOutlined style={{color: green.primary}}/></Tooltip>
        case ExploitationStage.TEST:
          return <Tooltip title="Testing"><BugOutlined style={{color: gold.primary}}/></Tooltip>
        case ExploitationStage.UNAVAILABLE:
          return <Tooltip title="Unavailable"><PauseCircleOutlined style={{color: blue.primary}}/></Tooltip>
        default:
          return <Tooltip title="Unknown mode"><FileUnknownOutlined /></Tooltip>
      }
    })();

    return <>{icon} {service.type} {service.room}</>
  }

  function getServiceExploitationStageSelectOptions(currentStage: number | undefined) {
    return getEnumNumberValues(ExploitationStage)
      .filter(item => item !== currentStage)
      .map(item => ({label: mapExploitationStageToStringName(item), value: item}))
  }

  function resetSwitchExploitationStageState() {
    setChangingExploitationStageService(undefined)
    setNewExploitationStageValue(undefined);
  }


  // TODO temporal solution while scaling isn't implemented
  function handleSwitchMode(service: PlatformServiceRegularDto, mode: SwitchTesModeModeEnum) {
    startFastRefetch();

    if(service.type === PlatformServiceRegularDtoTypeEnum.Tes)
      handleSwitchTesExploitationStage(service, mode);
    else
      handleSwitchGsExploitationStage(service, mode);

    function handleSwitchTesExploitationStage(service: PlatformServiceRegularDto, mode: SwitchTesModeModeEnum) {
      const id = service.id;

      switchTesModeMutation.mutate(
        {
          domain: service.room,
          mode: mode,
          tesId: id
        },
        {
          onSuccess: () => {
            resetSwitchExploitationStageState();
            message.success("Switch mode command has been successfully completed", 5).then();

          },
          onError: (err) => {
            message.error(`Something went wrong while while switching mode: ${err.message}. See console for more details`, 5).then();
            console.error(`Something went wrong while while switching mode`, err);
          }
        }
      )
    }

    function handleSwitchGsExploitationStage(service: PlatformServiceRegularDto, mode: SwitchGsModeModeEnum) {
      const id = service.id;

      switchGsModeMutation.mutate(
        {
          domain: service.room,
          gsId: id,
          mode: mode
        },
        {
          onSuccess: () => {
            resetSwitchExploitationStageState();
            message.success("Switch mode command has been successfully completed", 5).then();
          },
          onError: (err) => {
            message.error(`Something went wrong while while switching mode: ${err.message}. See console for more details`, 5).then();
            console.error(`Something went wrong while while switching mode`, err);
          }
        }
      )
    }
  }

  return <>
    <Stack spacing={2}>
      {runningInstances.map(item => (
        <Paper key={item.id} elevation={3}>
          <Box sx={{ p: 2 }}>
            <Stack spacing={2}>

              <Descriptions title={mapManageableServiceTitle(item)}>
                <Descriptions.Item label="Id">
                  {item.id ?? "-"}
                </Descriptions.Item>

                <Descriptions.Item label="Version">
                  {item.version ?? "-"}
                </Descriptions.Item>

                <Descriptions.Item label="Mode">
                  {item.mode ?? "-"}
                </Descriptions.Item>

                <Descriptions.Item label="Room">
                  {item.room}
                </Descriptions.Item>

                <Descriptions.Item label="Type">
                  {item.type}
                </Descriptions.Item>

                <Descriptions.Item label="Description">
                  {item.description ?? "-"}
                </Descriptions.Item>

                <Descriptions.Item label="Server address">
                  {item.server_addr ?? "-"}
                </Descriptions.Item>

                <Descriptions.Item label="Server port">
                  {item.server_port ?? "-"}
                </Descriptions.Item>

                <Descriptions.Item label="Uptime">
                  <>
                    {item.uptime ? <TimeAgo pastDate={item.uptime}/> : "-"}
                  </>
                </Descriptions.Item>

                <Descriptions.Item label="Number of clients">
                  {item.nclients || 0}
                </Descriptions.Item>

                <Descriptions.Item label="Number of threads">
                  {item.nthreads || 0}
                </Descriptions.Item>

                <Descriptions.Item label="Process id (pid)">
                  {item.pid ?? "-"}
                </Descriptions.Item>

                <Descriptions.Item label="Process RAM consuming">
                  {item.process_ram_total != null && item.process_ram_free != null ? (
                    `${
                      (item.process_ram_total < 0 ? 0 : item.process_ram_total) 
                      - 
                      (item.process_ram_free < 0 ? 0 : item.process_ram_free)
                    } of ${
                      item.process_ram_total < 0 ? 0 : item.process_ram_total
                    }`
                  ) : (
                    "Unknown"
                  )}
                </Descriptions.Item>

                <Descriptions.Item label="Server RAM consuming">
                  {item.server_ram_total != null && item.server_ram_free != null ? (
                    `${
                      (item.server_ram_total < 0 ? 0 : item.server_ram_total) 
                      - 
                      (item.server_ram_free < 0 ? 0 : item.server_ram_free)
                    } of ${
                      item.server_ram_total < 0 ? 0 : item.server_ram_total
                    }`
                  ) : (
                    "Unknown"
                  )}
                </Descriptions.Item>

                <Descriptions.Item label="CPU Avarage Usage">
                  <div>
                    {!item.cpu_load_avg ? "0" : (
                      Object.entries(item.cpu_load_avg).map(([key, value]) => (
                        <p key={key} style={{margin: 0}}>
                          {`During ${key} min: ${value === -1 ? "-" : (value * 100 + "%")}`}
                        </p>
                      ))
                    )}
                  </div>
                </Descriptions.Item>

              </Descriptions>

              <div>
                <ASelectPopover
                  loading={item.type === "TES" ? switchTesModeMutation.isPending : switchGsModeMutation.isPending}
                  containerStyle={{ display: "flex" }}
                  buttonText="Ok"
                  disabled={newExploitationStageValue === undefined}
                  onOk={() => handleSwitchMode(item, newExploitationStageValue as unknown as SwitchTesModeModeEnum)}
                  popoverProps={{
                    title: "Choose Mode",
                    open: item.id === changingExploitationStageService,
                    trigger: "click",
                    onOpenChange: (popoverProps) => {
                      if (!popoverProps) {
                        resetSwitchExploitationStageState();
                      }
                    },
                  }}
                  selectProps={{
                    style: { width: 150 },
                    value: newExploitationStageValue,
                    onChange: (value) => setNewExploitationStageValue(value),
                    allowClear: true,
                    options: getServiceExploitationStageSelectOptions(item.mode),
                    placeholder: "Choose Mode",
                  }}
                >
                  <Button variant="contained"
                          size="small"
                          onClick={() => setChangingExploitationStageService(item.id)}>
                    Switch mode
                  </Button>
                </ASelectPopover>
              </div>

            </Stack>
          </Box>
        </Paper>
      ))}
    </Stack>
  </>
}

export default RunningServicesModule;