import { RoomDto } from "@/shared/api";
import UserConfigsForm, { ColumnName } from "@/components/user-configs-form";


const UserRoomAccessForm = (
  {
    name = "",
    room,
    onSelectColumn,
    onClearColumn,
    onSelectRow,
    onClearRow,
    onSelectAll,
    onClearAll
  } : {
    name?: string,
    room?: RoomDto,
    onSelectColumn?: (column: ColumnName) => void,
    onClearColumn?: (column: ColumnName) => void,
    onSelectRow?: (configName: string) => void,
    onClearRow?: (configName: string) => void,
    onSelectAll?: () => void
    onClearAll?: () => void
  }
) => {
  return <>
    <UserConfigsForm
      name={`${name}.configs`}
      configs={room?.configs ?? []}
      onSelectColumn={onSelectColumn}
      onClearColumn={onClearColumn}
      onSelectRow={onSelectRow}
      onClearRow={onClearRow}
      onSelectAll={onSelectAll}
      onClearAll={onClearAll}
    />
  </>;
};

export default UserRoomAccessForm;