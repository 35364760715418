import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { FormContainer, PasswordElement, useForm } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

import { useChangeUserPasswordMutation } from "@/api/hooks/user-hooks";
import { ChangePasswordRequestDto } from "@/shared/api";

const ChangeUserPasswordModal = ({
  userId,
  onClose,
  onSuccess,
  open = false,
}: {
  userId: number;
  onClose: () => void;
  onSuccess?: () => void;
  open?: boolean;
}) => {
  const { t } = useTranslation("users-list");
  const { mutate: changePassword, isPending } = useChangeUserPasswordMutation();
  const form = useForm<ChangePasswordRequestDto>({ defaultValues: { id: userId } });

  const handleOnSuccess = (data: ChangePasswordRequestDto) => {
    changePassword(
      {
        userId: userId,
        password: data.password,
      },
      {
        onSuccess: () => {
          onClose();
          onSuccess && onSuccess();
          enqueueSnackbar("Password successfully changed!", { variant: "success" });
        },
        onError: (err) => {
          console.error("change user password error:", err);
          enqueueSnackbar(`Something went wrong while changing password: ${err.message}`, {
            variant: "error",
          });
        },
      }
    );
  };

  useEffect(() => {
    if (!open) {
      form.reset();
    }
  }, [form, open]);

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>{t("changeUserPassword")}</DialogTitle>

      <FormContainer formContext={form} onSuccess={(data) => handleOnSuccess(data)}>
        <DialogContent>
          {/*<TextFieldElement*/}
          {/*  autoFocus*/}
          {/*  name="passowrd"*/}
          {/*  required*/}
          {/*  label="New password"*/}
          {/*  variant="outlined"*/}
          {/*/>*/}
          <PasswordElement
            autoFocus
            name="password"
            label={t("password")}
            required
            fullWidth
          />
        </DialogContent>

        <DialogActions>
          <Button
            type="submit"
            endIcon={isPending ? <CircularProgress color="inherit" size={20} /> : undefined}
          >
            {t("save")}
          </Button>
        </DialogActions>
      </FormContainer>
    </Dialog>
  );
};

export default ChangeUserPasswordModal;
