import { UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { SysDomainRegularDto } from "@/shared/api";
import { RequiredError } from "@/shared/api/base";
import { useApi } from "@/shared/api/use-api";

export const useSysDomainsQuery = (
  onlyRooms = false,
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<SysDomainRegularDto[]>>, "queryKey">
) => {
  const { sysDomainApi } = useApi();

  return useQuery({
    queryKey: ["/domain", onlyRooms],
    queryFn: ({ signal }) => sysDomainApi.getDomains(onlyRooms, { signal }),
    ...queryOptions,
  });
};

export const useSysDomainQuery = (
  domain: string,
  queryOptions?: Omit<UseQueryOptions<AxiosResponse<SysDomainRegularDto>>, "queryKey">
) => {
  const { sysDomainApi } = useApi();

  return useQuery({
    queryKey: [`/domain/${domain}`],
    queryFn: ({ signal }) => sysDomainApi.getDomain(domain, { signal }),
    ...queryOptions,
  });
};

export const useSysDomainCreateMutation = () => {
  const { sysDomainApi } = useApi();

  type Options = {
    name: string;
    isRoom?: boolean;
  };

  return useMutation<AxiosResponse<void>, RequiredError, Options>({
    mutationFn: ({ name, isRoom }) =>
      sysDomainApi.registerDomain({ name, is_room: isRoom == null ? true : isRoom }),
  });
};

export const useSysDomainDeleteMutation = () => {
  const { sysDomainApi } = useApi();

  type Options = {
    domain: string;
    withData?: boolean;
  };

  return useMutation<AxiosResponse<void>, RequiredError, Options>({
    mutationFn: ({ domain, withData }) =>
      sysDomainApi.deleteDomain(domain, { with_data: withData == null ? false : withData }),
  });
};

export const useSysDomainCreateCfgCollection = () => {
  const { sysDomainApi } = useApi();

  type Options = {
    domain: string;
    name: string;
  };

  return useMutation<AxiosResponse<void>, RequiredError, Options>({
    mutationFn: ({ domain, name }) => sysDomainApi.createCfgCollection(domain, { name }),
  });
};

export const useSysDomainDeleteCfgCollection = () => {
  const { sysDomainApi } = useApi();

  type Options = {
    domain: string;
    name: string;
  };

  return useMutation<AxiosResponse<void>, RequiredError, Options>({
    mutationFn: ({ domain, name }) => sysDomainApi.deleteCfgCollection(domain, { name }),
  });
};
