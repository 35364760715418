import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ResultStatsDto } from "@/shared/api";
import { MRT_HidedColumnDef } from "@/types/commonTypes";

export const useStatserviceTableColumns = () => {
  const { t } = useTranslation("service-configs");

  return useMemo<MRT_HidedColumnDef<ResultStatsDto>[]>(
    () => [
      {
        id: "player.pid",
        header: t("statsServiceTable.pid"),
        accessorKey: "player.pid",
        sortingFn: "alphanumeric",
        filterFn: "contains",
        visible: true,
      },
      {
        header: t("statsServiceTable.cid"),
        id: "player.cid",
        accessorKey: "player.cid",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.aid"),
        id: "player.aid",
        accessorKey: "player.aid",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.gameGroup"),
        id: "player.game_group",
        accessorKey: "player.game_group",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.room"),
        id: "player.room",
        accessorKey: "player.room",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.limit"),
        id: "player.limit",
        accessorKey: "player.limit",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.gameType"),
        id: "player.game_type",
        accessorKey: "player.game_type",
        sortingFn: "alphanumeric",
        filterFn: "contains",
        visible: true,
      },
      {
        header: t("statsServiceTable.technology"),
        id: "player.technology",
        accessorKey: "player.technology",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.hands_count"),
        id: "hands_count",
        accessorKey: "hands_count",
        sortingFn: "alphanumeric",
        filterFn: "contains",
        visible: true,
      },
      {
        header: t("statsServiceTable.vpip"),
        id: "vpip",
        accessorKey: "vpip",
        sortingFn: "alphanumeric",
        filterFn: "contains",
        visible: true,
      },
      {
        header: t("statsServiceTable.pfr"),
        id: "pfr",
        accessorKey: "pfr",
        sortingFn: "alphanumeric",
        filterFn: "contains",
        visible: true,
      },
      {
        header: t("statsServiceTable.wtsd"),
        id: "wtsd",
        accessorKey: "wtsd",
        sortingFn: "alphanumeric",
        filterFn: "contains",
        visible: true,
      },
      {
        header: t("statsServiceTable.third_bet"),
        id: "third_bet",
        accessorKey: "third_bet",
        sortingFn: "alphanumeric",
        filterFn: "contains",
        visible: true,
      },
      {
        header: t("statsServiceTable.open_raise_pot_bet"),
        id: "open_raise_pot_bet",
        accessorKey: "open_raise_pot_bet",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_05_sb"),
        id: "open_raise_05_sb",
        accessorKey: "open_raise_05_sb",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_0667_sb"),
        id: "open_raise_0667_sb",
        accessorKey: "open_raise_0667_sb",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_10_sb"),
        id: "open_raise_10_sb",
        accessorKey: "open_raise_10_sb",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_05_bb"),
        id: "open_raise_05_bb",
        accessorKey: "open_raise_05_bb",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_0667_bb"),
        id: "open_raise_0667_bb",
        accessorKey: "open_raise_0667_bb",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_10_bb"),
        id: "open_raise_10_bb",
        accessorKey: "open_raise_10_bb",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_05_bt"),
        id: "open_raise_05_bt",
        accessorKey: "open_raise_05_bt",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_0667_bt"),
        id: "open_raise_0667_bt",
        accessorKey: "open_raise_0667_bt",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_10_bt"),
        id: "open_raise_10_bt",
        accessorKey: "open_raise_10_bt",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_05_co"),
        id: "open_raise_05_co",
        accessorKey: "open_raise_05_co",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_0667_co"),
        id: "open_raise_0667_co",
        accessorKey: "open_raise_0667_co",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_10_co"),
        id: "open_raise_10_co",
        accessorKey: "open_raise_10_co",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_05_mp"),
        id: "open_raise_05_mp",
        accessorKey: "open_raise_05_mp",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_0667_mp"),
        id: "open_raise_0667_mp",
        accessorKey: "open_raise_0667_mp",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_10_mp"),
        id: "open_raise_10_mp",
        accessorKey: "open_raise_10_mp",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_05_mp1"),
        id: "open_raise_05_mp1",
        accessorKey: "open_raise_05_mp1",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_0667_mp1"),
        id: "open_raise_0667_mp1",
        accessorKey: "open_raise_0667_mp1",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_10_mp1"),
        id: "open_raise_10_mp1",
        accessorKey: "open_raise_10_mp1",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_05_mp2"),
        id: "open_raise_05_mp2",
        accessorKey: "open_raise_05_mp2",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_0667_mp2"),
        id: "open_raise_0667_mp2",
        accessorKey: "open_raise_0667_mp2",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_10_mp2"),
        id: "open_raise_10_mp2",
        accessorKey: "open_raise_10_mp2",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_05_utg"),
        id: "open_raise_05_utg",
        accessorKey: "open_raise_05_utg",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_05_utg1"),
        id: "open_raise_05_utg1",
        accessorKey: "open_raise_05_utg1",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_05_utg2"),
        id: "open_raise_05_utg2",
        accessorKey: "open_raise_05_utg2",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_0667_utg"),
        id: "open_raise_0667_utg",
        accessorKey: "open_raise_0667_utg",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_0667_utg1"),
        id: "open_raise_0667_utg1",
        accessorKey: "open_raise_0667_utg1",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_0667_utg2"),
        id: "open_raise_0667_utg2",
        accessorKey: "open_raise_0667_utg2",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_10_utg"),
        id: "open_raise_10_utg",
        accessorKey: "open_raise_10_utg",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_10_utg1"),
        id: "open_raise_10_utg1",
        accessorKey: "open_raise_10_utg1",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_raise_10_utg2"),
        id: "open_raise_10_utg2",
        accessorKey: "open_raise_10_utg2",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_limp_sb"),
        id: "open_limp_sb",
        accessorKey: "open_limp_sb",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_limp_bb"),
        id: "open_limp_bb",
        accessorKey: "open_limp_bb",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_limp_bt"),
        id: "open_limp_bt",
        accessorKey: "open_limp_bt",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_limp_co"),
        id: "open_limp_co",
        accessorKey: "open_limp_co",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_limp_mp"),
        id: "open_limp_mp",
        accessorKey: "open_limp_mp",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_limp_mp1"),
        id: "open_limp_mp1",
        accessorKey: "open_limp_mp1",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_limp_mp2"),
        id: "open_limp_mp2",
        accessorKey: "open_limp_mp2",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_limp_utg"),
        id: "open_limp_utg",
        accessorKey: "open_limp_utg",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_limp_utg1"),
        id: "open_limp_utg1",
        accessorKey: "open_limp_utg1",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.open_limp_utg2"),
        id: "open_limp_utg2",
        accessorKey: "open_limp_utg2",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_flop_0001_to_015"),
        id: "bet_flop_0001_to_015",
        accessorKey: "bet_flop_0001_to_015",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_flop_015_to_029"),
        id: "bet_flop_015_to_029",
        accessorKey: "bet_flop_015_to_029",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_flop_029_to_038"),
        id: "bet_flop_029_to_038",
        accessorKey: "bet_flop_029_to_038",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_flop_038_to_045"),
        id: "bet_flop_038_to_045",
        accessorKey: "bet_flop_038_to_045",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_flop_045_to_055"),
        id: "bet_flop_045_to_055",
        accessorKey: "bet_flop_045_to_055",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_flop_055_to_062"),
        id: "bet_flop_055_to_062",
        accessorKey: "bet_flop_055_to_062",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_flop_062_to_071"),
        id: "bet_flop_062_to_071",
        accessorKey: "bet_flop_062_to_071",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_flop_071_to_08"),
        id: "bet_flop_071_to_08",
        accessorKey: "bet_flop_071_to_08",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_flop_08_to_095"),
        id: "bet_flop_08_to_095",
        accessorKey: "bet_flop_08_to_095",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_flop_095_to_105"),
        id: "bet_flop_095_to_105",
        accessorKey: "bet_flop_095_to_105",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_flop_105_to_12"),
        id: "bet_flop_105_to_12",
        accessorKey: "bet_flop_105_to_12",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_flop_12_to_100"),
        id: "bet_flop_12_to_100",
        accessorKey: "bet_flop_12_to_100",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_turn_0001_to_015"),
        id: "bet_turn_0001_to_015",
        accessorKey: "bet_turn_0001_to_015",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_turn_015_to_029"),
        id: "bet_turn_015_to_029",
        accessorKey: "bet_turn_015_to_029",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_turn_029_to_038"),
        id: "bet_turn_029_to_038",
        accessorKey: "bet_turn_029_to_038",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_turn_038_to_045"),
        id: "bet_turn_038_to_045",
        accessorKey: "bet_turn_038_to_045",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_turn_045_to_055"),
        id: "bet_turn_045_to_055",
        accessorKey: "bet_turn_045_to_055",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_turn_055_to_062"),
        id: "bet_turn_055_to_062",
        accessorKey: "bet_turn_055_to_062",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_turn_062_to_071"),
        id: "bet_turn_062_to_071",
        accessorKey: "bet_turn_062_to_071",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_turn_071_to_08"),
        id: "bet_turn_071_to_08",
        accessorKey: "bet_turn_071_to_08",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_turn_08_to_095"),
        id: "bet_turn_08_to_095",
        accessorKey: "bet_turn_08_to_095",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_turn_095_to_105"),
        id: "bet_turn_095_to_105",
        accessorKey: "bet_turn_095_to_105",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_turn_105_to_12"),
        id: "bet_turn_105_to_12",
        accessorKey: "bet_turn_105_to_12",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_turn_12_to_100"),
        id: "bet_turn_12_to_100",
        accessorKey: "bet_turn_12_to_100",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_river_0001_to_015"),
        id: "bet_river_0001_to_015",
        accessorKey: "bet_river_0001_to_015",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_river_015_to_029"),
        id: "bet_river_015_to_029",
        accessorKey: "bet_river_015_to_029",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_river_029_to_038"),
        id: "bet_river_029_to_038",
        accessorKey: "bet_river_029_to_038",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_river_038_to_045"),
        id: "bet_river_038_to_045",
        accessorKey: "bet_river_038_to_045",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_river_045_to_055"),
        id: "bet_river_045_to_055",
        accessorKey: "bet_river_045_to_055",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_river_055_to_062"),
        id: "bet_river_055_to_062",
        accessorKey: "bet_river_055_to_062",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_river_062_to_071"),
        id: "bet_river_062_to_071",
        accessorKey: "bet_river_062_to_071",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_river_071_to_08"),
        id: "bet_river_071_to_08",
        accessorKey: "bet_river_071_to_08",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_river_08_to_095"),
        id: "bet_river_08_to_095",
        accessorKey: "bet_river_08_to_095",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_river_095_to_105"),
        id: "bet_river_095_to_105",
        accessorKey: "bet_river_095_to_105",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_river_105_to_12"),
        id: "bet_river_105_to_12",
        accessorKey: "bet_river_105_to_12",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_river_12_to_100"),
        id: "bet_river_12_to_100",
        accessorKey: "bet_river_12_to_100",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.river_raise"),
        id: "river_raise",
        accessorKey: "river_raise",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.flop_raise"),
        id: "flop_raise",
        accessorKey: "flop_raise",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.turn_raise"),
        id: "turn_raise",
        accessorKey: "turn_raise",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.sb_hands"),
        id: "sb_hands",
        accessorKey: "sb_hands",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bb_hands"),
        id: "bb_hands",
        accessorKey: "bb_hands",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.st_hands"),
        id: "st_hands",
        accessorKey: "st_hands",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bt_hands"),
        id: "bt_hands",
        accessorKey: "bt_hands",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.co_hands"),
        id: "co_hands",
        accessorKey: "co_hands",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.mp_hands"),
        id: "mp_hands",
        accessorKey: "mp_hands",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.mp1_hands"),
        id: "mp1_hands",
        accessorKey: "mp1_hands",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.mp2_hands"),
        id: "mp2_hands",
        accessorKey: "mp2_hands",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.utg_hands"),
        id: "utg_hands",
        accessorKey: "utg_hands",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.utg1_hands"),
        id: "utg1_hands",
        accessorKey: "utg1_hands",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.utg2_hands"),
        id: "utg2_hands",
        accessorKey: "utg2_hands",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.three_bet_bar"),
        id: "three_bet_bar",
        accessorKey: "three_bet_bar",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.wr_vs_hero"),
        id: "wr_vs_hero",
        accessorKey: "wr_vs_hero",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.wr"),
        id: "wr",
        accessorKey: "wr",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.win_rate"),
        id: "win_rate",
        accessorKey: "win_rate",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.wr_bb"),
        id: "wr_bb",
        accessorKey: "wr_bb",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.group"),
        id: "group",
        accessorKey: "group",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_flop_top_diap_001_to_029"),
        id: "bet_flop_top_diap_001_to_029",
        accessorKey: "bet_flop_top_diap_001_to_029",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.bet_flop"),
        id: "bet_flop",
        accessorKey: "bet_flop",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.fold_flop"),
        id: "fold_flop",
        accessorKey: "fold_flop",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.fold_turn"),
        id: "fold_turn",
        accessorKey: "fold_turn",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.fold_river"),
        id: "fold_river",
        accessorKey: "fold_river",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.abnormal_raise"),
        id: "abnormal_raise",
        accessorKey: "abnormal_raise",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.wr_vs_hero_100"),
        id: "wr_vs_hero_100",
        accessorKey: "wr_vs_hero_100",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.min_raise_vs_bet_flop_exp"),
        id: "min_raise_vs_bet_flop_exp",
        accessorKey: "min_raise_vs_bet_flop_exp",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.min_raise_vs_bet_turn_exp"),
        id: "min_raise_vs_bet_turn_exp",
        accessorKey: "min_raise_vs_bet_turn_exp",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.min_raise_vs_bet_river_exp"),
        id: "min_raise_vs_bet_river_exp",
        accessorKey: "min_raise_vs_bet_river_exp",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.raise_vs_pot_bet_flop"),
        id: "raise_vs_pot_bet_flop",
        accessorKey: "raise_vs_pot_bet_flop",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.raise_vs_pot_bet_turn"),
        id: "raise_vs_pot_bet_turn",
        accessorKey: "raise_vs_pot_bet_turn",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.raise_vs_pot_bet_river"),
        id: "raise_vs_pot_bet_river",
        accessorKey: "raise_vs_pot_bet_river",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.raise_vs_pot_bet_pos_flop"),
        id: "raise_vs_pot_bet_pos_flop",
        accessorKey: "raise_vs_pot_bet_pos_flop",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.raise_vs_pot_bet_pos_turn"),
        id: "raise_vs_pot_bet_pos_turn",
        accessorKey: "raise_vs_pot_bet_pos_turn",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.raise_vs_pot_bet_pos_river"),
        id: "raise_vs_pot_bet_pos_river",
        accessorKey: "raise_vs_pot_bet_pos_river",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.raise_vs_pot_bet_flop_count"),
        id: "raise_vs_pot_bet_flop_count",
        accessorKey: "raise_vs_pot_bet_flop_count",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.raise_vs_pot_bet_turn_count"),
        id: "raise_vs_pot_bet_turn_count",
        accessorKey: "raise_vs_pot_bet_turn_count",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.raise_vs_pot_bet_river_count"),
        id: "raise_vs_pot_bet_river_count",
        accessorKey: "raise_vs_pot_bet_river_count",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.raise_vs_pot_bet_flop_pos_count"),
        id: "raise_vs_pot_bet_flop_pos_count",
        accessorKey: "raise_vs_pot_bet_flop_pos_count",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.raise_vs_pot_bet_turn_pos_count"),
        id: "raise_vs_pot_bet_turn_pos_count",
        accessorKey: "raise_vs_pot_bet_turn_pos_count",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.raise_vs_pot_bet_river_pos_count"),
        id: "raise_vs_pot_bet_river_pos_count",
        accessorKey: "raise_vs_pot_bet_river_pos_count",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.finger_print_01"),
        id: "finger_print_01",
        accessorKey: "finger_print_01",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.huge_open_raise"),
        id: "huge_open_raise",
        accessorKey: "huge_open_raise",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.huge_limp_raise"),
        id: "huge_limp_raise",
        accessorKey: "huge_limp_raise",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: t("statsServiceTable.huge_3bet"),
        id: "huge_3bet",
        accessorKey: "huge_3bet",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },

      //Timing
      {
        header: "pre_avg_folds",
        id: "timing.pre_avg_folds",
        accessorKey: "timing.pre_avg_folds",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "flop_avg_folds",
        id: "timing.flop_avg_folds",
        accessorKey: "timing.flop_avg_folds",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "turn_avg_folds",
        id: "timing.turn_avg_folds",
        accessorKey: "timing.turn_avg_folds",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "river_avg_folds",
        id: "timing.river_avg_folds",
        accessorKey: "timing.river_avg_folds",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "pre_avg_checks",
        id: "timing.pre_avg_checks",
        accessorKey: "timing.pre_avg_checks",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "flop_avg_checks",
        id: "timing.flop_avg_checks",
        accessorKey: "timing.flop_avg_checks",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "turn_avg_checks",
        id: "timing.turn_avg_checks",
        accessorKey: "timing.turn_avg_checks",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "river_avg_checks",
        id: "timing.river_avg_checks",
        accessorKey: "timing.river_avg_checks",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "pre_avg_calls",
        id: "timing.pre_avg_calls",
        accessorKey: "timing.pre_avg_calls",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "flop_avg_calls",
        id: "timing.flop_avg_calls",
        accessorKey: "timing.flop_avg_calls",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "turn_avg_calls",
        id: "timing.turn_avg_calls",
        accessorKey: "timing.turn_avg_calls",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "river_avg_calls",
        id: "timing.river_avg_calls",
        accessorKey: "timing.river_avg_calls",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "pre_avg_raises",
        id: "timing.pre_avg_raises",
        accessorKey: "timing.pre_avg_raises",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "flop_avg_raises",
        id: "timing.flop_avg_raises",
        accessorKey: "timing.flop_avg_raises",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "turn_avg_raises",
        id: "timing.turn_avg_raises",
        accessorKey: "timing.turn_avg_raises",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "river_avg_raises",
        id: "timing.river_avg_raises",
        accessorKey: "timing.river_avg_raises",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "flop_avg_bets",
        id: "timing.flop_avg_bets",
        accessorKey: "timing.flop_avg_bets",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "turn_avg_bets",
        id: "timing.turn_avg_bets",
        accessorKey: "timing.turn_avg_bets",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "river_avg_bets",
        id: "timing.river_avg_bets",
        accessorKey: "timing.river_avg_bets",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "pre_max_folds",
        id: "timing.pre_max_folds",
        accessorKey: "timing.pre_max_folds",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "flop_max_folds",
        id: "timing.flop_max_folds",
        accessorKey: "timing.flop_max_folds",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "turn_max_folds",
        id: "timing.turn_max_folds",
        accessorKey: "timing.turn_max_folds",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "river_max_folds",
        id: "timing.river_max_folds",
        accessorKey: "timing.river_max_folds",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "pre_max_checks",
        id: "timing.pre_max_checks",
        accessorKey: "timing.pre_max_checks",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "flop_max_checks",
        id: "timing.flop_max_checks",
        accessorKey: "timing.flop_max_checks",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "turn_max_checks",
        id: "timing.turn_max_checks",
        accessorKey: "timing.turn_max_checks",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "river_max_checks",
        id: "timing.river_max_checks",
        accessorKey: "timing.river_max_checks",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "pre_max_calls",
        id: "timing.pre_max_calls",
        accessorKey: "timing.pre_max_calls",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "flop_max_calls",
        id: "timing.flop_max_calls",
        accessorKey: "timing.flop_max_calls",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "turn_max_calls",
        id: "timing.turn_max_calls",
        accessorKey: "timing.turn_max_calls",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "river_max_calls",
        id: "timing.river_max_calls",
        accessorKey: "timing.river_max_calls",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "pre_max_raises",
        id: "timing.pre_max_raises",
        accessorKey: "timing.pre_max_raises",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "flop_max_raises",
        id: "timing.flop_max_raises",
        accessorKey: "timing.flop_max_raises",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "turn_max_raises",
        id: "timing.turn_max_raises",
        accessorKey: "timing.turn_max_raises",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "river_max_raises",
        id: "timing.river_max_raises",
        accessorKey: "timing.river_max_raises",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "flop_max_bets",
        id: "timing.flop_max_bets",
        accessorKey: "timing.flop_max_bets",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "turn_max_bets",
        id: "timing.turn_max_bets",
        accessorKey: "timing.turn_max_bets",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "river_max_bets",
        id: "timing.river_max_bets",
        accessorKey: "timing.river_max_bets",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "pre_min_checks",
        id: "timing.pre_min_checks",
        accessorKey: "timing.pre_min_checks",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "flop_min_checks",
        id: "timing.flop_min_checks",
        accessorKey: "timing.flop_min_checks",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "turn_min_checks",
        id: "timing.turn_min_checks",
        accessorKey: "timing.turn_min_checks",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "river_min_checks",
        id: "timing.river_min_checks",
        accessorKey: "timing.river_min_checks",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "pre_min_calls",
        id: "timing.pre_min_calls",
        accessorKey: "timing.pre_min_calls",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "flop_min_calls",
        id: "timing.flop_min_calls",
        accessorKey: "timing.flop_min_calls",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "turn_min_calls",
        id: "timing.turn_min_calls",
        accessorKey: "timing.turn_min_calls",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "river_min_calls",
        id: "timing.river_min_calls",
        accessorKey: "timing.river_min_calls",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "pre_min_raises",
        id: "timing.pre_min_raises",
        accessorKey: "timing.pre_min_raises",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "flop_min_raises",
        id: "timing.flop_min_raises",
        accessorKey: "timing.flop_min_raises",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "turn_min_raises",
        id: "timing.turn_min_raises",
        accessorKey: "timing.turn_min_raises",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "river_min_raises",
        id: "timing.river_min_raises",
        accessorKey: "timing.river_min_raises",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "flop_min_bets",
        id: "timing.flop_min_bets",
        accessorKey: "timing.flop_min_bets",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "turn_min_bets",
        id: "timing.turn_min_bets",
        accessorKey: "timing.turn_min_bets",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "river_min_bets",
        id: "timing.river_min_bets",
        accessorKey: "timing.river_min_bets",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "pre_min_folds",
        id: "timing.pre_min_folds",
        accessorKey: "timing.pre_min_folds",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "flop_min_folds",
        id: "timing.flop_min_folds",
        accessorKey: "timing.flop_min_folds",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "turn_min_folds",
        id: "timing.turn_min_folds",
        accessorKey: "timing.turn_min_folds",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "river_min_folds",
        id: "timing.river_min_folds",
        accessorKey: "timing.river_min_folds",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "pre_first_folds_times",
        id: "timing.pre_first_folds_times",
        accessorKey: "timing.pre_first_folds_times",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "flop_first_folds_times",
        id: "timing.flop_first_folds_times",
        accessorKey: "timing.flop_first_folds_times",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "turn_first_folds_times",
        id: "timing.turn_first_folds_times",
        accessorKey: "timing.turn_first_folds_times",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "river_first_folds_times",
        id: "timing.river_first_folds_times",
        accessorKey: "timing.river_first_folds_times",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "pre_first_checks_times",
        id: "timing.pre_first_checks_times",
        accessorKey: "timing.pre_first_checks_times",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "flop_first_checks_times",
        id: "timing.flop_first_checks_times",
        accessorKey: "timing.flop_first_checks_times",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "turn_first_checks_times",
        id: "timing.turn_first_checks_times",
        accessorKey: "timing.turn_first_checks_times",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "river_first_checks_times",
        id: "timing.river_first_checks_times",
        accessorKey: "timing.river_first_checks_times",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "pre_first_bets_times",
        id: "timing.pre_first_bets_times",
        accessorKey: "timing.pre_first_bets_times",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "flop_first_bets_times",
        id: "timing.flop_first_bets_times",
        accessorKey: "timing.flop_first_bets_times",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "turn_first_bets_times",
        id: "timing.turn_first_bets_times",
        accessorKey: "timing.turn_first_bets_times",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
      {
        header: "river_first_bets_times",
        id: "timing.river_first_bets_times",
        accessorKey: "timing.river_first_bets_times",
        sortingFn: "alphanumeric",
        filterFn: "contains",
      },
    ],
    [t]
  );
};
