import { CheckboxElement, FormContainer, TextFieldElement, useForm } from "react-hook-form-mui";
import { CreateSysDomainDto } from "@/shared/api";
import { useEffect, useMemo } from "react";
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Stack } from "@mui/system";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";


const CreateDomainModal = (
  {
    open,
    onClose,
    onSave,
    isLoading = false
  } : {
    open: boolean;
    onClose: () => void;
    onSave: (data: CreateSysDomainDto) => void;
    isLoading?: boolean;
  }
) => {

  const schema = useMemo(() => (z.object({
    name: z.string().min(1, "Field is required")
      // eslint-disable-next-line no-control-regex
      // .regex(/^(?!system\.)(?!.*\.system\.)[A-Za-z_][^\x00$]*$/,
      //   "Starts with (A-Z or a-z or _). Doesn't start with 'system.'. " + 
      //   "Without '.system.'. Without '$' sign."
      // ),
      // eslint-disable-next-line no-control-regex
      .regex(/^[^\x00/."$\\]+$/, "Please enter a domain name without any of these characters: \\x00, /, , ., \", $."),
    is_room: z.boolean()
  })), [])

  const form = useForm<CreateSysDomainDto>({
    mode: "onChange",
    defaultValues: {name: "", is_room: true},
    resolver: zodResolver(schema)
  });

  const onCloseHandler = () => {
    form.reset()
    onClose()
  }

  useEffect(() => {
    form.reset()
  }, [open, form])


  return (
    <Dialog open={open} onClose={onCloseHandler} fullWidth maxWidth="sm">
      <DialogTitle title="Create User">Create Domain</DialogTitle>
      <DialogContent style={{paddingTop: "5px"}}>
        <FormContainer formContext={form} onSuccess={val => onSave(val)}>
          <Stack spacing={2}>
            <TextFieldElement name="name" label="Domain Name" required fullWidth/>
            <CheckboxElement name="is_room" label="Is Room"/>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={isLoading}
              startIcon={isLoading && <CircularProgress size={18} />}
            >Create Domain</Button>
          </Stack>
        </FormContainer>
      </DialogContent>
    </Dialog>
  );
}


export default CreateDomainModal