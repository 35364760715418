import { keepPreviousData, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useApi } from "@/shared/api/use-api";

export type ClusteringPatternPlayerTypeDto = {
  [p: string]: {
    [p: string]: string[]
  }
}

export const useSysClusteringPatternResultsQuery = ({
    domain,
    queryOptions
  } :
  {
    domain: string,
    queryOptions?: Omit<UseQueryOptions<AxiosResponse<ClusteringPatternPlayerTypeDto>>, "queryKey">
  }) => {
  const { sysClusteringPatternExternalDataApi } = useApi();
  return useQuery({
    queryKey: [`/sys-data/${domain}/clustering-pattern/player-type`],
    queryFn: ({ signal }) => {
      return sysClusteringPatternExternalDataApi.getAllClusteringPatternPlayerTypesByDomain(domain, {signal})
    },
    placeholderData: keepPreviousData,
    ...queryOptions
  })
}