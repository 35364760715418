import { FileDownload } from "@mui/icons-material";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import { useSearch } from "@tanstack/react-router";
import { message } from "antd";
import {
  MRT_ColumnFilterFnsState,
  MRT_ColumnFiltersState,
  MRT_Row,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton,
  MaterialReactTable,
  getColumnId,
  useMaterialReactTable,
} from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  useSysStatserviceExportCsvMutation,
  useSysStatserviceQuery,
} from "@/api/hooks/sys-statservice-hooks";
import { useMuiTableToolbarAlertBannerProps } from "@/hooks/use-mui-table-toolbar-alert-banner-props";
import { useTablePersistentSettings, useTableSettings } from "@/hooks/use-table-settings";
import { assembleFiltersFns } from "@/lib/accountsUtils";
import {
  assembleKeyFilters,
  assembleParticularFilterForStatserviceCsv,
  assembleSort,
} from "@/lib/cfg-utils";
import { assembleSearchParamsFilters } from "@/lib/common-utils";
import { statServiceRoomVariants } from "@/module/service-configs-module/statservice-module/statservice-module";
import { useStatserviceTableColumns } from "@/module/service-configs-module/statservice-module/statservice-table-columns";
import { KeyFilter, KeyFilterModeEnum, KeySort, ResultStatsDto } from "@/shared/api";
import { SearchParams } from "@/types/commonTypes";

const StatserviceTable = ({
  setRowsAmount,
  period,
  selectedRoom,
  setSelectedRoom,
}: {
  setRowsAmount: (amount: number) => void;
  period: number;
  selectedRoom: string | null;
  setSelectedRoom: (value: string | null) => void;
}) => {
  const { t } = useTranslation("service-configs");

  const defaultFilters = useMemo(
    () => [
      {
        key: "player.technology",
        mode: "equals" as KeyFilterModeEnum,
        value: "HUMAN" as unknown as object,
      },
      {
        key: "player.room",
        mode: "equals" as KeyFilterModeEnum,
        value: selectedRoom as unknown as object,
      },
    ],
    [selectedRoom]
  );

  ///////////////////////////
  const columns = useStatserviceTableColumns();
  const defaultColumnVisibility = useMemo(
    () => Object.fromEntries(columns.map((val) => [getColumnId(val), !!val.visible])),
    [columns]
  );
  const {
    state: persistentTableState,
    onColumnVisibilityChange,
    onColumnOrderChange,
  } = useTablePersistentSettings({
    tableName: "statService",
    initialColumnVisibilityState: defaultColumnVisibility,
  });

  const searchParams: SearchParams = useSearch({ strict: false });

  // TODO чет мне кажется, что условие фильтра неверное @Ohio86
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(() =>
    assembleSearchParamsFilters(searchParams).filter((it) => it.id !== "statserviceTab")
  ); // Просто searchParams преобразуюстся в массив объектов { id: it[0], value: it[1] } где id =

  const [columnFilterFns, setColumnFilterFns] = useState<MRT_ColumnFilterFnsState>(
    assembleFiltersFns({ columns, searchParams: searchParams })
  );

  const { sorting, pagination, onPaginationChange, defaultProps } =
    useTableSettings<ResultStatsDto>("statService");

  const filters = useMemo<Set<KeyFilter>>(
    () => assembleKeyFilters(columnFilters, columnFilterFns, [], defaultFilters),
    [columnFilters, columnFilterFns, defaultFilters]
  );

  const sort = useMemo<KeySort[]>(() => assembleSort(sorting), [sorting]);

  const {
    data: configsRs,
    isError: isDataLoadingError,
    isLoading: isDataLoading,
    refetch: refetchData,
    isRefetching: isDataRefetching,
  } = useSysStatserviceQuery(
    {
      period: period,
      filters: filters,
      pagination,
      sort: sort,
    },
    {
      enabled: true,
    }
  );

  useEffect(() => {
    setRowsAmount(configsRs?.data.total_elements ?? 0);
  }, [configsRs, setRowsAmount]);

  ///////////START -Export to CSV TODO: create separate button component
  const mutation = useSysStatserviceExportCsvMutation();

  function handleMutationAction(rows: MRT_Row<ResultStatsDto>[]) {
    if (rows.length > 0) {
      mutateParticular(rows);
    } else {
      mutateAll();
    }
  }

  function mutateAll() {
    callMutation(filters);
  }

  function mutateParticular(rows: MRT_Row<ResultStatsDto>[]) {
    callMutation(assembleParticularFilterForStatserviceCsv(rows));
  }

  function callMutation(filters: Set<KeyFilter>) {
    mutation.mutate(
      { period, filters },
      {
        onError: (err) => {
          console.error("accounts csv export error", err);
          message
            .error("Something went wrong during exporting, contact the administrator")
            .then();
        },
      }
    );
  }
  ////END -Export to CSV

  function clearFilters() {
    setColumnFilters([]);
    setSelectedRoom(null);
    void refetchData();
  }

  const muiToolbarAlertBannerProps = useMuiTableToolbarAlertBannerProps(
    isDataLoadingError,
    configsRs?.statusText
  );

  const table = useMaterialReactTable({
    ...defaultProps,
    data: configsRs?.data.results ?? [],
    columns: columns,
    selectAllMode: "page",
    muiToolbarAlertBannerProps,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    rowCount: configsRs?.data.total_elements ?? 0,
    pageCount: configsRs?.data.total_pages ?? 0,
    enableHiding: true,
    state: {
      ...persistentTableState,
      columnFilterFns,
      pagination,
      columnFilters,
      sorting,
      isLoading: isDataLoading,
      showProgressBars: isDataRefetching,
    },
    onColumnVisibilityChange: onColumnVisibilityChange,
    onColumnOrderChange: onColumnOrderChange,
    onPaginationChange: onPaginationChange,
    onColumnFilterFnsChange: setColumnFilterFns,
    onColumnFiltersChange: setColumnFilters,
    renderTopToolbarCustomActions: () => {
      return (
        <div style={{ height: "40px", display: "flex", alignItems: "center" }}>
          <Autocomplete
            onChange={(_event, newValue) => {
              setSelectedRoom(newValue);
            }}
            value={selectedRoom}
            options={statServiceRoomVariants}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                style={{ width: 200 }}
                label={t("selectedRoom")}
                variant="outlined"
              />
            )}
          />
          <IconButton
            size="small"
            style={{ fontSize: 12 }}
            color="secondary"
            onClick={() => handleMutationAction(table.getSelectedRowModel().rows)}
          >
            <FileDownload />{" "}
            {t("exportCsv", {
              rows:
                table.getSelectedRowModel().rows.length > 0
                  ? table.getSelectedRowModel().rows.length
                  : configsRs?.data.total_elements,
            })}
          </IconButton>
        </div>
      );
    },
    renderToolbarInternalActions: ({ table }) => (
      <>
        <IconButton
          size="small"
          style={{ fontSize: 12 }}
          color="primary"
          onClick={() => clearFilters()}
        >
          {t("clearFilters")}
        </IconButton>
        <MRT_ToggleFiltersButton table={table} />
        <MRT_ShowHideColumnsButton table={table} />
        <MRT_ToggleDensePaddingButton table={table} />
        <MRT_ToggleFullScreenButton table={table} />
      </>
    ),
  });

  useEffect(() => {
    table.resetRowSelection();
  }, [table, columnFilters, sorting, pagination]);

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
};

export default StatserviceTable;
