import { createFileRoute, Navigate } from "@tanstack/react-router";
import { useAtom } from "jotai/index";
import { meStore } from "@/store/store";
import { isSuperAdmin } from "@/lib/auth-predicates";
import RoomsManagementPage from "@/pages/rooms-management-page";

export const Route = createFileRoute('/room-management/')({
  component: Component,
})

function Component() {
  const [me] = useAtom(meStore);

  if(!isSuperAdmin(me)) {
    return <Navigate to="/"/>
  }

  return (
    <RoomsManagementPage />
  )
}