/* tslint:disable */

/* eslint-disable */

/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { AxiosInstance, AxiosPromise, RawAxiosRequestConfig } from "axios";
import globalAxios from "axios";

// @ts-ignore
import {
  BASE_PATH,
  BaseAPI,
  COLLECTION_FORMATS,
  RequestArgs,
  RequiredError,
  operationServerMap,
} from "../base";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  createRequestFunction,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  toPathString,
} from "../common";
import type { Configuration } from "../configuration";
// @ts-ignore
import { AddNewConfigDto } from "../models";
// @ts-ignore
import { AddNewNameConfigDto } from "../models";
// @ts-ignore
import { BulkUpdateResponseDto } from "../models";
// @ts-ignore
import { Club } from "../models";
// @ts-ignore
import { ResultDtoRoomConfigRegularResponseDto } from "../models";
// @ts-ignore
import { RoomConfigByDataStructureResponseDto } from "../models";
// @ts-ignore
import { RoomConfigMultipleConfigsRequestDto } from "../models";
// @ts-ignore
import { RoomConfigRegularResponseDto } from "../models";
// @ts-ignore
import { RoomConfigShortResponseDto } from "../models";
// @ts-ignore
import { RoomConfigUpdateConfigDto } from "../models";
// @ts-ignore
import { ValueDtoString } from "../models";

/**
 * RoomConfigControllerApi - axios parameter creator
 * @export
 */
export const RoomConfigControllerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} roomName
     * @param {string} dataStructureName
     * @param {AddNewConfigDto} addNewConfigDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addCommonConfig: async (
      roomName: string,
      dataStructureName: string,
      addNewConfigDto: AddNewConfigDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'roomName' is not null or undefined
      assertParamExists("addCommonConfig", "roomName", roomName);
      // verify required parameter 'dataStructureName' is not null or undefined
      assertParamExists("addCommonConfig", "dataStructureName", dataStructureName);
      // verify required parameter 'addNewConfigDto' is not null or undefined
      assertParamExists("addCommonConfig", "addNewConfigDto", addNewConfigDto);
      const localVarPath =
        `/api/v1/room-config/{room-name}/{data-structure-name}/add-common-config`
          .replace(`{${"room-name"}}`, encodeURIComponent(String(roomName)))
          .replace(
            `{${"data-structure-name"}}`,
            encodeURIComponent(String(dataStructureName))
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addNewConfigDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} roomName
     * @param {string} dataStructureName
     * @param {AddNewNameConfigDto} addNewNameConfigDto
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    addNameConfig: async (
      roomName: string,
      dataStructureName: string,
      addNewNameConfigDto: AddNewNameConfigDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'roomName' is not null or undefined
      assertParamExists("addNameConfig", "roomName", roomName);
      // verify required parameter 'dataStructureName' is not null or undefined
      assertParamExists("addNameConfig", "dataStructureName", dataStructureName);
      // verify required parameter 'addNewNameConfigDto' is not null or undefined
      assertParamExists("addNameConfig", "addNewNameConfigDto", addNewNameConfigDto);
      const localVarPath =
        `/api/v1/room-config/{room-name}/{data-structure-name}/add-name-config`
          .replace(`{${"room-name"}}`, encodeURIComponent(String(roomName)))
          .replace(
            `{${"data-structure-name"}}`,
            encodeURIComponent(String(dataStructureName))
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addNewNameConfigDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} roomName
     * @param {string} dataStructureName
     * @param {string} configId
     * @param {boolean} status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeEnabledStatus: async (
      roomName: string,
      dataStructureName: string,
      configId: string,
      status: boolean,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'roomName' is not null or undefined
      assertParamExists("changeEnabledStatus", "roomName", roomName);
      // verify required parameter 'dataStructureName' is not null or undefined
      assertParamExists("changeEnabledStatus", "dataStructureName", dataStructureName);
      // verify required parameter 'configId' is not null or undefined
      assertParamExists("changeEnabledStatus", "configId", configId);
      // verify required parameter 'status' is not null or undefined
      assertParamExists("changeEnabledStatus", "status", status);
      const localVarPath =
        `/api/v1/room-config/{room-name}/{data-structure-name}/{config-id}/change-enabled-status/{status}`
          .replace(`{${"room-name"}}`, encodeURIComponent(String(roomName)))
          .replace(`{${"data-structure-name"}}`, encodeURIComponent(String(dataStructureName)))
          .replace(`{${"config-id"}}`, encodeURIComponent(String(configId)))
          .replace(`{${"status"}}`, encodeURIComponent(String(status)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} roomName
     * @param {string} dataStructureName
     * @param {boolean} status
     * @param {Set<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeEnabledStatusBulk: async (
      roomName: string,
      dataStructureName: string,
      status: boolean,
      requestBody: Set<string>,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'roomName' is not null or undefined
      assertParamExists("changeEnabledStatusBulk", "roomName", roomName);
      // verify required parameter 'dataStructureName' is not null or undefined
      assertParamExists("changeEnabledStatusBulk", "dataStructureName", dataStructureName);
      // verify required parameter 'status' is not null or undefined
      assertParamExists("changeEnabledStatusBulk", "status", status);
      // verify required parameter 'requestBody' is not null or undefined
      assertParamExists("changeEnabledStatusBulk", "requestBody", requestBody);
      const localVarPath =
        `/api/v1/room-config/{room-name}/{data-structure-name}/change-enabled-status/{status}/bulk`
          .replace(`{${"room-name"}}`, encodeURIComponent(String(roomName)))
          .replace(`{${"data-structure-name"}}`, encodeURIComponent(String(dataStructureName)))
          .replace(`{${"status"}}`, encodeURIComponent(String(status)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} roomName
     * @param {string} dataStructureName
     * @param {string} configId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRoomConfig: async (
      roomName: string,
      dataStructureName: string,
      configId: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'roomName' is not null or undefined
      assertParamExists("deleteRoomConfig", "roomName", roomName);
      // verify required parameter 'dataStructureName' is not null or undefined
      assertParamExists("deleteRoomConfig", "dataStructureName", dataStructureName);
      // verify required parameter 'configId' is not null or undefined
      assertParamExists("deleteRoomConfig", "configId", configId);
      const localVarPath = `/api/v1/room-config/{room-name}/{data-structure-name}/{config-id}`
        .replace(`{${"room-name"}}`, encodeURIComponent(String(roomName)))
        .replace(`{${"data-structure-name"}}`, encodeURIComponent(String(dataStructureName)))
        .replace(`{${"config-id"}}`, encodeURIComponent(String(configId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} roomName
     * @param {string} dataStructureName
     * @param {Array<ValueDtoString>} valueDtoString
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRoomConfigs: async (
      roomName: string,
      dataStructureName: string,
      valueDtoString: Array<ValueDtoString>,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'roomName' is not null or undefined
      assertParamExists("deleteRoomConfigs", "roomName", roomName);
      // verify required parameter 'dataStructureName' is not null or undefined
      assertParamExists("deleteRoomConfigs", "dataStructureName", dataStructureName);
      // verify required parameter 'valueDtoString' is not null or undefined
      assertParamExists("deleteRoomConfigs", "valueDtoString", valueDtoString);
      const localVarPath = `/api/v1/room-config/{room-name}/{data-structure-name}`
        .replace(`{${"room-name"}}`, encodeURIComponent(String(roomName)))
        .replace(`{${"data-structure-name"}}`, encodeURIComponent(String(dataStructureName)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        valueDtoString,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} roomName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRoomClubs: async (
      roomName: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'roomName' is not null or undefined
      assertParamExists("getRoomClubs", "roomName", roomName);
      const localVarPath = `/api/v1/room-config/{room-name}/club`.replace(
        `{${"room-name"}}`,
        encodeURIComponent(String(roomName))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} roomName
     * @param {string} dataStructureName
     * @param {string} configId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRoomConfig: async (
      roomName: string,
      dataStructureName: string,
      configId: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'roomName' is not null or undefined
      assertParamExists("getRoomConfig", "roomName", roomName);
      // verify required parameter 'dataStructureName' is not null or undefined
      assertParamExists("getRoomConfig", "dataStructureName", dataStructureName);
      // verify required parameter 'configId' is not null or undefined
      assertParamExists("getRoomConfig", "configId", configId);
      const localVarPath = `/api/v1/room-config/{room-name}/{data-structure-name}/{config-id}`
        .replace(`{${"room-name"}}`, encodeURIComponent(String(roomName)))
        .replace(`{${"data-structure-name"}}`, encodeURIComponent(String(dataStructureName)))
        .replace(`{${"config-id"}}`, encodeURIComponent(String(configId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} roomName
     * @param {string} dataStructureName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRoomConfigs: async (
      roomName: string,
      dataStructureName: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'roomName' is not null or undefined
      assertParamExists("getRoomConfigs", "roomName", roomName);
      // verify required parameter 'dataStructureName' is not null or undefined
      assertParamExists("getRoomConfigs", "dataStructureName", dataStructureName);
      const localVarPath = `/api/v1/room-config/{room-name}/{data-structure-name}`
        .replace(`{${"room-name"}}`, encodeURIComponent(String(roomName)))
        .replace(`{${"data-structure-name"}}`, encodeURIComponent(String(dataStructureName)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} roomName
     * @param {RoomConfigMultipleConfigsRequestDto} roomConfigMultipleConfigsRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRoomConfigsByDataStructure: async (
      roomName: string,
      roomConfigMultipleConfigsRequestDto: RoomConfigMultipleConfigsRequestDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'roomName' is not null or undefined
      assertParamExists("getRoomConfigsByDataStructure", "roomName", roomName);
      // verify required parameter 'roomConfigMultipleConfigsRequestDto' is not null or undefined
      assertParamExists(
        "getRoomConfigsByDataStructure",
        "roomConfigMultipleConfigsRequestDto",
        roomConfigMultipleConfigsRequestDto
      );
      const localVarPath = `/api/v1/room-config/{room-name}/multiple`.replace(
        `{${"room-name"}}`,
        encodeURIComponent(String(roomName))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        roomConfigMultipleConfigsRequestDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} roomName
     * @param {string} dataStructureName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getShortRoomConfigs: async (
      roomName: string,
      dataStructureName: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'roomName' is not null or undefined
      assertParamExists("getShortRoomConfigs", "roomName", roomName);
      // verify required parameter 'dataStructureName' is not null or undefined
      assertParamExists("getShortRoomConfigs", "dataStructureName", dataStructureName);
      const localVarPath = `/api/v1/room-config/{room-name}/{data-structure-name}/short`
        .replace(`{${"room-name"}}`, encodeURIComponent(String(roomName)))
        .replace(`{${"data-structure-name"}}`, encodeURIComponent(String(dataStructureName)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} roomName
     * @param {string} dataStructureName
     * @param {string} configId
     * @param {RoomConfigUpdateConfigDto} roomConfigUpdateConfigDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRoomConfig: async (
      roomName: string,
      dataStructureName: string,
      configId: string,
      roomConfigUpdateConfigDto: RoomConfigUpdateConfigDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'roomName' is not null or undefined
      assertParamExists("updateRoomConfig", "roomName", roomName);
      // verify required parameter 'dataStructureName' is not null or undefined
      assertParamExists("updateRoomConfig", "dataStructureName", dataStructureName);
      // verify required parameter 'configId' is not null or undefined
      assertParamExists("updateRoomConfig", "configId", configId);
      // verify required parameter 'roomConfigUpdateConfigDto' is not null or undefined
      assertParamExists(
        "updateRoomConfig",
        "roomConfigUpdateConfigDto",
        roomConfigUpdateConfigDto
      );
      const localVarPath = `/api/v1/room-config/{room-name}/{data-structure-name}/{config-id}`
        .replace(`{${"room-name"}}`, encodeURIComponent(String(roomName)))
        .replace(`{${"data-structure-name"}}`, encodeURIComponent(String(dataStructureName)))
        .replace(`{${"config-id"}}`, encodeURIComponent(String(configId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        roomConfigUpdateConfigDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RoomConfigControllerApi - functional programming interface
 * @export
 */
export const RoomConfigControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = RoomConfigControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} roomName
     * @param {string} dataStructureName
     * @param {AddNewConfigDto} addNewConfigDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addCommonConfig(
      roomName: string,
      dataStructureName: string,
      addNewConfigDto: AddNewConfigDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomConfigRegularResponseDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addCommonConfig(
        roomName,
        dataStructureName,
        addNewConfigDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["RoomConfigControllerApi.addCommonConfig"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} roomName
     * @param {string} dataStructureName
     * @param {AddNewNameConfigDto} addNewNameConfigDto
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async addNameConfig(
      roomName: string,
      dataStructureName: string,
      addNewNameConfigDto: AddNewNameConfigDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomConfigRegularResponseDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addNameConfig(
        roomName,
        dataStructureName,
        addNewNameConfigDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["RoomConfigControllerApi.addNameConfig"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} roomName
     * @param {string} dataStructureName
     * @param {string} configId
     * @param {boolean} status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changeEnabledStatus(
      roomName: string,
      dataStructureName: string,
      configId: string,
      status: boolean,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomConfigRegularResponseDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.changeEnabledStatus(
        roomName,
        dataStructureName,
        configId,
        status,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["RoomConfigControllerApi.changeEnabledStatus"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} roomName
     * @param {string} dataStructureName
     * @param {boolean} status
     * @param {Set<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changeEnabledStatusBulk(
      roomName: string,
      dataStructureName: string,
      status: boolean,
      requestBody: Set<string>,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkUpdateResponseDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.changeEnabledStatusBulk(
        roomName,
        dataStructureName,
        status,
        requestBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["RoomConfigControllerApi.changeEnabledStatusBulk"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} roomName
     * @param {string} dataStructureName
     * @param {string} configId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteRoomConfig(
      roomName: string,
      dataStructureName: string,
      configId: string,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRoomConfig(
        roomName,
        dataStructureName,
        configId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["RoomConfigControllerApi.deleteRoomConfig"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} roomName
     * @param {string} dataStructureName
     * @param {Array<ValueDtoString>} valueDtoString
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteRoomConfigs(
      roomName: string,
      dataStructureName: string,
      valueDtoString: Array<ValueDtoString>,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<ResultDtoRoomConfigRegularResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRoomConfigs(
        roomName,
        dataStructureName,
        valueDtoString,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["RoomConfigControllerApi.deleteRoomConfigs"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} roomName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRoomClubs(
      roomName: string,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Club>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRoomClubs(
        roomName,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["RoomConfigControllerApi.getRoomClubs"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} roomName
     * @param {string} dataStructureName
     * @param {string} configId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRoomConfig(
      roomName: string,
      dataStructureName: string,
      configId: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomConfigRegularResponseDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRoomConfig(
        roomName,
        dataStructureName,
        configId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["RoomConfigControllerApi.getRoomConfig"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} roomName
     * @param {string} dataStructureName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRoomConfigs(
      roomName: string,
      dataStructureName: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<RoomConfigRegularResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRoomConfigs(
        roomName,
        dataStructureName,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["RoomConfigControllerApi.getRoomConfigs"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} roomName
     * @param {RoomConfigMultipleConfigsRequestDto} roomConfigMultipleConfigsRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRoomConfigsByDataStructure(
      roomName: string,
      roomConfigMultipleConfigsRequestDto: RoomConfigMultipleConfigsRequestDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<RoomConfigByDataStructureResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRoomConfigsByDataStructure(
        roomName,
        roomConfigMultipleConfigsRequestDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["RoomConfigControllerApi.getRoomConfigsByDataStructure"]?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} roomName
     * @param {string} dataStructureName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getShortRoomConfigs(
      roomName: string,
      dataStructureName: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<RoomConfigShortResponseDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getShortRoomConfigs(
        roomName,
        dataStructureName,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["RoomConfigControllerApi.getShortRoomConfigs"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} roomName
     * @param {string} dataStructureName
     * @param {string} configId
     * @param {RoomConfigUpdateConfigDto} roomConfigUpdateConfigDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateRoomConfig(
      roomName: string,
      dataStructureName: string,
      configId: string,
      roomConfigUpdateConfigDto: RoomConfigUpdateConfigDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomConfigRegularResponseDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateRoomConfig(
        roomName,
        dataStructureName,
        configId,
        roomConfigUpdateConfigDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["RoomConfigControllerApi.updateRoomConfig"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * RoomConfigControllerApi - factory interface
 * @export
 */
export const RoomConfigControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = RoomConfigControllerApiFp(configuration);
  return {
    /**
     *
     * @param {string} roomName
     * @param {string} dataStructureName
     * @param {AddNewConfigDto} addNewConfigDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addCommonConfig(
      roomName: string,
      dataStructureName: string,
      addNewConfigDto: AddNewConfigDto,
      options?: any
    ): AxiosPromise<RoomConfigRegularResponseDto> {
      return localVarFp
        .addCommonConfig(roomName, dataStructureName, addNewConfigDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} roomName
     * @param {string} dataStructureName
     * @param {AddNewNameConfigDto} addNewNameConfigDto
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    addNameConfig(
      roomName: string,
      dataStructureName: string,
      addNewNameConfigDto: AddNewNameConfigDto,
      options?: any
    ): AxiosPromise<RoomConfigRegularResponseDto> {
      return localVarFp
        .addNameConfig(roomName, dataStructureName, addNewNameConfigDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} roomName
     * @param {string} dataStructureName
     * @param {string} configId
     * @param {boolean} status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeEnabledStatus(
      roomName: string,
      dataStructureName: string,
      configId: string,
      status: boolean,
      options?: any
    ): AxiosPromise<RoomConfigRegularResponseDto> {
      return localVarFp
        .changeEnabledStatus(roomName, dataStructureName, configId, status, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} roomName
     * @param {string} dataStructureName
     * @param {boolean} status
     * @param {Set<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeEnabledStatusBulk(
      roomName: string,
      dataStructureName: string,
      status: boolean,
      requestBody: Set<string>,
      options?: any
    ): AxiosPromise<BulkUpdateResponseDto> {
      return localVarFp
        .changeEnabledStatusBulk(roomName, dataStructureName, status, requestBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} roomName
     * @param {string} dataStructureName
     * @param {string} configId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRoomConfig(
      roomName: string,
      dataStructureName: string,
      configId: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .deleteRoomConfig(roomName, dataStructureName, configId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} roomName
     * @param {string} dataStructureName
     * @param {Array<ValueDtoString>} valueDtoString
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteRoomConfigs(
      roomName: string,
      dataStructureName: string,
      valueDtoString: Array<ValueDtoString>,
      options?: any
    ): AxiosPromise<Array<ResultDtoRoomConfigRegularResponseDto>> {
      return localVarFp
        .deleteRoomConfigs(roomName, dataStructureName, valueDtoString, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} roomName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRoomClubs(roomName: string, options?: any): AxiosPromise<Array<Club>> {
      return localVarFp
        .getRoomClubs(roomName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} roomName
     * @param {string} dataStructureName
     * @param {string} configId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRoomConfig(
      roomName: string,
      dataStructureName: string,
      configId: string,
      options?: any
    ): AxiosPromise<RoomConfigRegularResponseDto> {
      return localVarFp
        .getRoomConfig(roomName, dataStructureName, configId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} roomName
     * @param {string} dataStructureName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRoomConfigs(
      roomName: string,
      dataStructureName: string,
      options?: any
    ): AxiosPromise<Array<RoomConfigRegularResponseDto>> {
      return localVarFp
        .getRoomConfigs(roomName, dataStructureName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} roomName
     * @param {RoomConfigMultipleConfigsRequestDto} roomConfigMultipleConfigsRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRoomConfigsByDataStructure(
      roomName: string,
      roomConfigMultipleConfigsRequestDto: RoomConfigMultipleConfigsRequestDto,
      options?: any
    ): AxiosPromise<Array<RoomConfigByDataStructureResponseDto>> {
      return localVarFp
        .getRoomConfigsByDataStructure(roomName, roomConfigMultipleConfigsRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} roomName
     * @param {string} dataStructureName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getShortRoomConfigs(
      roomName: string,
      dataStructureName: string,
      options?: any
    ): AxiosPromise<Array<RoomConfigShortResponseDto>> {
      return localVarFp
        .getShortRoomConfigs(roomName, dataStructureName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} roomName
     * @param {string} dataStructureName
     * @param {string} configId
     * @param {RoomConfigUpdateConfigDto} roomConfigUpdateConfigDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRoomConfig(
      roomName: string,
      dataStructureName: string,
      configId: string,
      roomConfigUpdateConfigDto: RoomConfigUpdateConfigDto,
      options?: any
    ): AxiosPromise<RoomConfigRegularResponseDto> {
      return localVarFp
        .updateRoomConfig(
          roomName,
          dataStructureName,
          configId,
          roomConfigUpdateConfigDto,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * RoomConfigControllerApi - object-oriented interface
 * @export
 * @class RoomConfigControllerApi
 * @extends {BaseAPI}
 */
export class RoomConfigControllerApi extends BaseAPI {
  /**
   *
   * @param {string} roomName
   * @param {string} dataStructureName
   * @param {AddNewConfigDto} addNewConfigDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomConfigControllerApi
   */
  public addCommonConfig(
    roomName: string,
    dataStructureName: string,
    addNewConfigDto: AddNewConfigDto,
    options?: RawAxiosRequestConfig
  ) {
    return RoomConfigControllerApiFp(this.configuration)
      .addCommonConfig(roomName, dataStructureName, addNewConfigDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} roomName
   * @param {string} dataStructureName
   * @param {AddNewNameConfigDto} addNewNameConfigDto
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof RoomConfigControllerApi
   */
  public addNameConfig(
    roomName: string,
    dataStructureName: string,
    addNewNameConfigDto: AddNewNameConfigDto,
    options?: RawAxiosRequestConfig
  ) {
    return RoomConfigControllerApiFp(this.configuration)
      .addNameConfig(roomName, dataStructureName, addNewNameConfigDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} roomName
   * @param {string} dataStructureName
   * @param {string} configId
   * @param {boolean} status
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomConfigControllerApi
   */
  public changeEnabledStatus(
    roomName: string,
    dataStructureName: string,
    configId: string,
    status: boolean,
    options?: RawAxiosRequestConfig
  ) {
    return RoomConfigControllerApiFp(this.configuration)
      .changeEnabledStatus(roomName, dataStructureName, configId, status, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} roomName
   * @param {string} dataStructureName
   * @param {boolean} status
   * @param {Set<string>} requestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomConfigControllerApi
   */
  public changeEnabledStatusBulk(
    roomName: string,
    dataStructureName: string,
    status: boolean,
    requestBody: Set<string>,
    options?: RawAxiosRequestConfig
  ) {
    return RoomConfigControllerApiFp(this.configuration)
      .changeEnabledStatusBulk(roomName, dataStructureName, status, requestBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} roomName
   * @param {string} dataStructureName
   * @param {string} configId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomConfigControllerApi
   */
  public deleteRoomConfig(
    roomName: string,
    dataStructureName: string,
    configId: string,
    options?: RawAxiosRequestConfig
  ) {
    return RoomConfigControllerApiFp(this.configuration)
      .deleteRoomConfig(roomName, dataStructureName, configId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} roomName
   * @param {string} dataStructureName
   * @param {Array<ValueDtoString>} valueDtoString
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomConfigControllerApi
   */
  public deleteRoomConfigs(
    roomName: string,
    dataStructureName: string,
    valueDtoString: Array<ValueDtoString>,
    options?: RawAxiosRequestConfig
  ) {
    return RoomConfigControllerApiFp(this.configuration)
      .deleteRoomConfigs(roomName, dataStructureName, valueDtoString, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} roomName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomConfigControllerApi
   */
  public getRoomClubs(roomName: string, options?: RawAxiosRequestConfig) {
    return RoomConfigControllerApiFp(this.configuration)
      .getRoomClubs(roomName, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} roomName
   * @param {string} dataStructureName
   * @param {string} configId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomConfigControllerApi
   */
  public getRoomConfig(
    roomName: string,
    dataStructureName: string,
    configId: string,
    options?: RawAxiosRequestConfig
  ) {
    return RoomConfigControllerApiFp(this.configuration)
      .getRoomConfig(roomName, dataStructureName, configId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} roomName
   * @param {string} dataStructureName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomConfigControllerApi
   */
  public getRoomConfigs(
    roomName: string,
    dataStructureName: string,
    options?: RawAxiosRequestConfig
  ) {
    return RoomConfigControllerApiFp(this.configuration)
      .getRoomConfigs(roomName, dataStructureName, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} roomName
   * @param {RoomConfigMultipleConfigsRequestDto} roomConfigMultipleConfigsRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomConfigControllerApi
   */
  public getRoomConfigsByDataStructure(
    roomName: string,
    roomConfigMultipleConfigsRequestDto: RoomConfigMultipleConfigsRequestDto,
    options?: RawAxiosRequestConfig
  ) {
    return RoomConfigControllerApiFp(this.configuration)
      .getRoomConfigsByDataStructure(roomName, roomConfigMultipleConfigsRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} roomName
   * @param {string} dataStructureName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomConfigControllerApi
   */
  public getShortRoomConfigs(
    roomName: string,
    dataStructureName: string,
    options?: RawAxiosRequestConfig
  ) {
    return RoomConfigControllerApiFp(this.configuration)
      .getShortRoomConfigs(roomName, dataStructureName, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} roomName
   * @param {string} dataStructureName
   * @param {string} configId
   * @param {RoomConfigUpdateConfigDto} roomConfigUpdateConfigDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoomConfigControllerApi
   */
  public updateRoomConfig(
    roomName: string,
    dataStructureName: string,
    configId: string,
    roomConfigUpdateConfigDto: RoomConfigUpdateConfigDto,
    options?: RawAxiosRequestConfig
  ) {
    return RoomConfigControllerApiFp(this.configuration)
      .updateRoomConfig(
        roomName,
        dataStructureName,
        configId,
        roomConfigUpdateConfigDto,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
