import { zodResolver } from "@hookform/resolvers/zod";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
// import { useForm } from "react-hook-form";
import { Stack } from "@mui/system";
import { useEffect, useMemo } from "react";
import {
  CheckboxElement,
  FormContainer,
  TextFieldElement,
  useForm,
} from "react-hook-form-mui";
import { z } from "zod";

const DeleteDomainModal = ({
  open,
  domainName,
  onClose,
  onConfirm,
  confirmIsLoading = false,
}: {
  open: boolean;
  domainName: string;
  onClose: () => void;
  onConfirm: (domainName: string, withData: boolean) => void;
  confirmIsLoading?: boolean;
}) => {
  const schema = useMemo(
    () =>
      z.object({
        confirmDomainName: z.string(),
        withData: z.boolean(),
      }),
    []
  );

  type SchemaType = z.infer<typeof schema>;

  const form = useForm<SchemaType>({
    mode: "onChange",
    defaultValues: { withData: false, confirmDomainName: "" },
    resolver: zodResolver(schema),
  });

  const confirmDomainName = form.watch("confirmDomainName");
  const withData = form.watch("withData");

  const onCloseHandler = () => {
    form.reset();
    onClose();
  };

  useEffect(() => {
    form.reset();
  }, [open, form]);

  return (
    <Dialog open={open} onClose={onCloseHandler} fullWidth maxWidth="sm">
      <DialogContent>
        <Box>
          <FormContainer
            formContext={form}
            onSuccess={(data) => onConfirm(domainName, data.withData)}
          >
            <Stack spacing={2}>
              <Typography variant="h5" style={{ fontWeight: "bold" }}>
                Delete domain {domainName}
              </Typography>
              <Typography variant="caption" style={{ marginTop: 0 }}>
                Are you sure you want to delete domain {domainName}?
              </Typography>
              <br />

              <span style={{ fontWeight: "bold" }}>
                {`Type ${domainName} to confirm action`}
              </span>
              <TextFieldElement control={form.control} name="confirmDomainName" fullWidth />

              <CheckboxElement name="withData" label="Delete database if exists" />
              {withData && (
                <Alert severity="warning">{`The database ${domainName} will be deleted with all it's data`}</Alert>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={confirmIsLoading || (confirmDomainName !== domainName)}
                startIcon={confirmIsLoading && <CircularProgress size={18} />}
              >
                Delete Domain
              </Button>
            </Stack>
          </FormContainer>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteDomainModal;
