import { Divider, Form, Input, Modal, Radio, Typography, message } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

type AddingMode = "one" | "list";

const NameConfigAddModal = ({
  open,
  title,
  confirmLoading = false,
  onCancel,
  onSave,
}: {
  open: boolean;
  title?: string;
  confirmLoading?: boolean;
  onCancel?: () => void;
  onSave?: (value: string[]) => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation("room-configs");
  const [addingMode, setAddingMode] = useState<AddingMode>("one");

  const [form] = Form.useForm();
  const formValues = Form.useWatch([], form);

  function handleChangeAddingMode(value: AddingMode) {
    setAddingMode(value);
  }

  function handleSaveClick() {
    form
      .validateFields({ validateOnly: false })
      .then(() => {
        const values: string[] = formValues[addingMode].trim().split("\n");
        values.forEach((value) => validateNotEmpty(value));
        if (onSave) onSave(values);
      })
      .catch((err) => console.error(err));
  }

  function validateNotEmpty(value: string) {
    if (!value || !value.trim()) {
      message.error(t("namesRequired")).then();
      return Promise.reject(new Error(t("namesRequired")));
    }

    return Promise.resolve();
  }

  return (
    <>
      <Modal
        open={open}
        okText={t("add")}
        onCancel={() => onCancel && onCancel()}
        maskClosable={false}
        width={600}
        confirmLoading={confirmLoading}
        onOk={() => handleSaveClick()}
        title={
          title && (
            <Typography.Title level={3} style={{ marginTop: 15 }}>
              {title}
            </Typography.Title>
          )
        }
      >
        {title && <Divider style={{ marginTop: 10, marginBottom: 30 }} />}

        <Form size="large" className="a-common-form">
          <Form.Item label={t("chooseAddingType")}>
            <Radio.Group
              onChange={(e) => handleChangeAddingMode(e.target.value)}
              value={addingMode}
            >
              <Radio.Button value="one">{t("one")}</Radio.Button>
              <Radio.Button value="list">{t("list")}</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Form>

        <Divider />

        <Form
          size="large"
          className="a-common-form"
          form={form}
          autoComplete="off"
          key={addingMode}
        >
          {addingMode === "one" && (
            <Form.Item name="one" label={t("name")} rules={[{ required: true }]}>
              <Input placeholder={t("typeName")} />
            </Form.Item>
          )}

          {addingMode === "list" && (
            <Form.Item
              name="list"
              label={t("names")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.TextArea rows={30} placeholder={t("insertNamesOnePerLine")} />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default NameConfigAddModal;
