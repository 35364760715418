import { EditCompensatorComp, EditedCompensator } from "@/components/config-modals/compensator-edit-modal/types";
import { StringOption } from "@/types/commonTypes";

export const formInitState: EditedCompensator = {
  name: "",
  authGroups: [],
  enabled: true,
  data: {
    room: "",
    game_group: "",
    rule_range: "",
    aid: "",
    game_type: "",
    limit: undefined,
    RAA_min: undefined,
    RAA_max: undefined,
    comp_min:  new Array<EditCompensatorComp>(),
    comp_max: new Array<EditCompensatorComp>(),
    current_state: "",
    comment: "",
  }
};

export const required = [{required: true, message: "Field is required"}];

export const beforeSendNormalizers: ((val: EditedCompensator) => EditedCompensator)[] = [
  (value: EditedCompensator) => {
    value.name = value.name?.trim();
    return value;
  }
]

export const ruleRange: StringOption[] = [
  { value: "calendar_month", label: "Calendar month" },
  { value: "calendar_week", label: "Calendar week" },
  { value: "day", label: "Day" },
  { value: "last_week", label: "Last week" },
  { value: "last_month", label: "Last month" },
];

export const roomName: StringOption[] = [
  { value: "hh_eco", label: "HH eco" },
  { value: "pw_eco", label: "PW eco" },
  { value: "wpc_eco", label: "WPC eco" },
  { value: "ff_eco", label: "FF eco" },
];