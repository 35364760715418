/* tslint:disable */

/* eslint-disable */

/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { AxiosInstance, AxiosPromise, RawAxiosRequestConfig } from "axios";
import globalAxios from "axios";

// @ts-ignore
import {
  BASE_PATH,
  BaseAPI,
  COLLECTION_FORMATS,
  RequestArgs,
  RequiredError,
  operationServerMap,
} from "../base";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  createRequestFunction,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  toPathString,
} from "../common";
import type { Configuration } from "../configuration";
// @ts-ignore
import { PageResponseSysAccountSessionRegularDto } from "../models";
// @ts-ignore
import { QueryDto } from "../models";

/**
 * SysAccountSessionControllerApi - axios parameter creator
 * @export
 */
export const SysAccountSessionControllerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} domain
     * @param {string} groupingBy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countGroupingBy: async (
      domain: string,
      groupingBy: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("countGroupingBy", "domain", domain);
      // verify required parameter 'groupingBy' is not null or undefined
      assertParamExists("countGroupingBy", "groupingBy", groupingBy);
      const localVarPath = `/api/v2/sys-data/{domain}/session/count`.replace(
        `{${"domain"}}`,
        encodeURIComponent(String(domain))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (groupingBy !== undefined) {
        localVarQueryParameter["grouping-by"] = groupingBy;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} domain
     * @param {QueryDto} queryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllSessions: async (
      domain: string,
      queryDto: QueryDto,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'domain' is not null or undefined
      assertParamExists("getAllSessions", "domain", domain);
      // verify required parameter 'queryDto' is not null or undefined
      assertParamExists("getAllSessions", "queryDto", queryDto);
      const localVarPath = `/api/v2/sys-data/{domain}/session`.replace(
        `{${"domain"}}`,
        encodeURIComponent(String(domain))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        queryDto,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SysAccountSessionControllerApi - functional programming interface
 * @export
 */
export const SysAccountSessionControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    SysAccountSessionControllerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} domain
     * @param {string} groupingBy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countGroupingBy(
      domain: string,
      groupingBy: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number }>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countGroupingBy(
        domain,
        groupingBy,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysAccountSessionControllerApi.countGroupingBy"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @param {string} domain
     * @param {QueryDto} queryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllSessions(
      domain: string,
      queryDto: QueryDto,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PageResponseSysAccountSessionRegularDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSessions(
        domain,
        queryDto,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap["SysAccountSessionControllerApi.getAllSessions"]?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
  };
};

/**
 * SysAccountSessionControllerApi - factory interface
 * @export
 */
export const SysAccountSessionControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SysAccountSessionControllerApiFp(configuration);
  return {
    /**
     *
     * @param {string} domain
     * @param {string} groupingBy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countGroupingBy(
      domain: string,
      groupingBy: string,
      options?: any
    ): AxiosPromise<{ [key: string]: number }> {
      return localVarFp
        .countGroupingBy(domain, groupingBy, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} domain
     * @param {QueryDto} queryDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllSessions(
      domain: string,
      queryDto: QueryDto,
      options?: any
    ): AxiosPromise<PageResponseSysAccountSessionRegularDto> {
      return localVarFp
        .getAllSessions(domain, queryDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SysAccountSessionControllerApi - object-oriented interface
 * @export
 * @class SysAccountSessionControllerApi
 * @extends {BaseAPI}
 */
export class SysAccountSessionControllerApi extends BaseAPI {
  /**
   *
   * @param {string} domain
   * @param {string} groupingBy
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysAccountSessionControllerApi
   */
  public countGroupingBy(domain: string, groupingBy: string, options?: RawAxiosRequestConfig) {
    return SysAccountSessionControllerApiFp(this.configuration)
      .countGroupingBy(domain, groupingBy, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} domain
   * @param {QueryDto} queryDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SysAccountSessionControllerApi
   */
  public getAllSessions(domain: string, queryDto: QueryDto, options?: RawAxiosRequestConfig) {
    return SysAccountSessionControllerApiFp(this.configuration)
      .getAllSessions(domain, queryDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
