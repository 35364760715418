import { MRT_TableInstance } from "material-react-table";
import { KeyFilter, SysAccountSessionRegularDto } from "@/shared/api";
import MigrateSessionsTableControl from "@/module/accounts-module/components/migrate-sessions-table-control";
import StopSessionsTableControl from "@/module/accounts-module/components/stop-sessions-table-control";
import JoinClubsTableControl from "@/module/accounts-module/components/join-clubs-table-control";
import LeaveClubsTableControl from "@/module/accounts-module/components/leave-clubs-table-control";
import UpdateNicknamesTableControl from "@/module/accounts-module/components/update-nicknames-table-control";
import UpdateAvatarTableControl from "@/module/accounts-module/components/update-avatar-table-control";
import RequestReplayTableControl from "@/module/accounts-module/components/request-replay-table-control";
import {useCfgPerm} from "@/auth/hooks";
import {ROOM_CONFIGS_NAMES} from "@/lib/constants";


const DebugSessionsTopToolbar = (
  {
    roomName,
    table,
    filters,
    onSuccess,
    totalElementsCount
  } : {
    roomName: string;
    table: MRT_TableInstance<SysAccountSessionRegularDto>;
    filters: Set<KeyFilter>;
    onSuccess?: () => void;
    totalElementsCount: number;
  }
) => {
  const accountPerms = useCfgPerm({roomName: roomName, cfgName: ROOM_CONFIGS_NAMES.ACCOUNT});

  return (
    <>
      <div style={{ display: "flex" }}>
        {accountPerms.exclusive && (
          <MigrateSessionsTableControl
            roomName={roomName}
            table={table}
            filters={filters}
            totalElementsCount={totalElementsCount}
            onSuccess={() => onSuccess && onSuccess()}
          />
        )}

        {accountPerms.execute && (
          <StopSessionsTableControl
            roomName={roomName}
            table={table}
            filters={filters}
            totalElementsCount={totalElementsCount}
            onSuccess={() => onSuccess && onSuccess()}
          />
        )}

        {accountPerms.execute && (
          <JoinClubsTableControl
            roomName={roomName}
            table={table}
            filters={filters}
            totalElementsCount={totalElementsCount}
            onSuccess={() => onSuccess && onSuccess()}
          />
        )}

        {accountPerms.execute && (
          <LeaveClubsTableControl
            roomName={roomName}
            table={table}
            filters={filters}
            totalElementsCount={totalElementsCount}
            onSuccess={() => onSuccess && onSuccess()}
          />
        )}

        {accountPerms.execute && (
          <UpdateNicknamesTableControl
            roomName={roomName}
            table={table}
            filters={filters}
            totalElementsCount={totalElementsCount}
            onSuccess={() => onSuccess && onSuccess()}
          />
        )}

        {accountPerms.execute && (
          <UpdateAvatarTableControl
            roomName={roomName}
            table={table}
            filters={filters}
            totalElementsCount={totalElementsCount}
            onSuccess={() => onSuccess && onSuccess()}
          />
        )}

        {accountPerms.execute && (
          <RequestReplayTableControl
            roomName={roomName}
            table={table}
            onSuccess={() => onSuccess && onSuccess()}
          />
        )}

      </div>
    </>
  )
}



export default DebugSessionsTopToolbar