import {Col, Row, Spin} from "antd";

const ACenteredSpinner = ({
  tip
} : {
  tip?: string
}) => {
  return (
    <Row gutter={[16, 16]} justify="center">
      <Col style={{width: "300px"}}>
        <Spin size="large" tip={tip}>
          <div className="content"/>
        </Spin>
      </Col>
    </Row>
  )
}

export default ACenteredSpinner;