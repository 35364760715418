import { Divider, Form, message, Modal, Select, Typography } from "antd";
import { dataFieldName } from "@/components/config-modals/clusterizer-edit-modal/constants";
import { red } from "@ant-design/colors";
import { arrayNumberNormalizer } from "@/lib/common-utils";
import { useEffect, useState } from "react";

const JoinLeaveClubModal = (
  {
    title,
    onCancel,
    confirmLoading = false,
    onSave,
    isOpen
  }: {
    title?: string,
    confirmLoading?: boolean,
    onCancel: () => void;
    onSave: (clubIds: string[]) => void;
    isOpen: boolean;
  }
) => {
  const [instancesValidationError, setInstancesValidationError] = useState<string | undefined>(undefined);
  const [form] = Form.useForm();

  function handleSaveClick() {
    form.validateFields()
      .then(value => onSave && onSave(value.clubIds))
      .catch((reason) => {
        message.error("Form data is invalid. Fix the errors and try again.").then();
        console.error("Form data is invalid. Fix the errors and try again.", reason);
        handleShowProbabilityErrorMessage();
      });
  }

  function handleShowProbabilityErrorMessage() {
    const fieldError = form.getFieldError([dataFieldName, "instances"]);
    setInstancesValidationError(fieldError[0]);
  }

  useEffect(() => {
    form.resetFields()
  }, [form, isOpen]);

  return (<>
    <Modal
      open={isOpen}
      okText="Submit"
      onCancel={() => onCancel && onCancel()}
      maskClosable={false}
      width={450}
      confirmLoading={confirmLoading}
      onOk={() => handleSaveClick()}
      title={title && <Typography.Title level={3} style={{marginTop: 15}}>{title}</Typography.Title>}
    >

      {title && (<Divider style={{marginTop: 10, marginBottom: 30}}/>)}

      <Form
        size="large"
        className="a-common-form"
        form={form}
        autoComplete="off"
        key="joinClubForm"
      >
        <Form.Item
          name={["clubIds"]}
          label="Club id(s)"
          normalize={(value) => arrayNumberNormalizer(value)}
        >
          <Select
            mode="tags"
            placeholder="Enter here"
          />
        </Form.Item>
      </Form>

      <div
        hidden={!instancesValidationError}
        style={{marginTop: 10, color: red.primary}}
      >
        {instancesValidationError ?? ""}
      </div>
    </Modal>
  </>)
}

export default JoinLeaveClubModal;